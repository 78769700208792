import LoginPage from '@/pages/login';
import {useSelector} from '@/utils/dva';
import {useNavigation, useRoute} from '@react-navigation/core';
import {useFocusEffect} from '@react-navigation/native';
import {once} from 'lodash';
import {useCallback, useRef} from 'react';

const useAuthed = (gotoLogin = false, backToPrev = false) => {
  const currentUser = useSelector(s => s.global.currentUser);
  const authed = !!currentUser;
  const navigation = useNavigation();
  const route = useRoute();
  const _gotoLogin = useCallback(() => navigation.navigate(LoginPage.screenName), [navigation]);
  const _goBack = useCallback(() => navigation.canGoBack() && navigation.goBack(), [navigation]);
  const onces = useRef({
    gotoLogin: once(() => {
      if (backToPrev) {
        /**
         * 为防止登陆页面返回后再此出发跳转：
         * 跳转到登陆页前保存一个一次性函数onBack，
         * 再次回到此页面时调用的onBack返回此页面的上一页
         */
        onces.backToPre = once(_goBack);
      }
      _gotoLogin();
    }),
  }).current;

  useFocusEffect(() => {
    if (!authed) {
      if (gotoLogin) {
        onces?.backToPre?.();
        onces?.gotoLogin?.();
      }
    }
  });

  return [authed, {currentUser, gotoLogin: _gotoLogin, goBack: _goBack, route, navigation}];
};

export default useAuthed;
