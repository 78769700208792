import {cancel, confirmResult, getOrder, getPagedOrders, getResult, pay, receive} from '@/pages/order/service';
import _ from 'lodash';

export const namespace = 'order';
const model = {
  namespace,
  state: {
    data: [], // 所有订单数据
    // [${status}_data]: [], // 对应订单状态的订单数据
  },
  effects: {
    /**
     * 订单分页查询
     * @actionParam params: {
     *   current,
     *   pageSize,
     *   status: '订单状态'
     * }
     * */
    *getPagedOrders({params}, {call, put}) {
      const res = yield call(getPagedOrders, params);
      // if (res?.success) {
      //   yield put({type: 'addData', status, data: res?.data});
      // }
      return res;
    },

    /**
     * 查询订单信息
     * @actionParam id: 订单的id
     * */
    *getOrder({id}, {call, put}) {
      return yield call(getOrder, id);
    },

    /**
     * 订单支付（确认）
     * @actionParam data: {
     *   id: 订单id
     *   addressId: 地址
     *   remark: 订单备注
     *   payType: 支付方式
     *   deduct: 口袋币余额抵扣人民币
     * }
     * */
    *pay({data}, {call}) {
      return yield call(pay, data);
    },

    /**
     * 支付结果查询（需要轮询直到返回成功）
     * @param id: 订单ID
     * */
    *getResult({id}, {call}) {
      return yield call(getResult, id);
    },

    /**
     * 支付结果查询（需要轮询直到返回成功）
     * @param id: 订单ID
     * @param paid: 是否支付
     * */
    *confirmResult({id, paid}, {call}) {
      return yield call(confirmResult, id, paid);
    },

    /**
     * 确认收货
     * @actionParam id: 订单id
     * */
    *receive({id}, {call}) {
      return yield call(receive, id);
    },

    /**
     * 取消订单
     * @actionParam id: 订单id
     * */
    *cancel({id}, {call}) {
      return yield call(cancel, id);
    },
  },
  reducers: {
    saveState(state, action) {
      const {type, ...nextState} = action || {};
      return {...state, ...nextState};
    },

    addData(state, action) {
      const {status, data} = action || {};
      if (status) {
        const key = `${status}_data`;
        const oldData = state?.[key];
        return {...state, [key]: _.concat([], oldData ?? [], data ?? [])};
      } else {
        const oldData = state.data;
        return {...state, data: _.concat([], oldData ?? [], data ?? [])};
      }
    },

    clearData(state, action) {
      const {status} = action || {};
      if (status) {
        const key = `${status}_data`;
        return {...state, [key]: []};
      } else {
        return {...state, data: []};
      }
    },
  },
  subscriptions: {
    // orderCount({dispatch}) {
    //   console.warn('订阅在途单数量（1分钟刷新）');
    //   const fetch = () => {
    //     // console.warn('刷新在途单数量');
    //     dispatch({type: 'getProgressingOrderCount'});
    //   };
    //   fetch();
    //   const handleId = setInterval(fetch, 60000);
    //   return () => clearInterval(handleId);
    // },
  },
};

export default model;
