import {useMount} from 'ahooks';
import {useEffect, useRef, useState} from 'react';
import {Animated, Dimensions, Easing} from 'react-native';

export default function (shouldShow, options) {
  const {timingAnimationConfig} = Object.assign({}, options);

  const _timingAnimationConfig = Object.assign(
    {
      duration: 300,
      easing: Easing.inOut(Easing.ease),
      useNativeDriver: false,
    },
    timingAnimationConfig,
  );

  const screenWidth = Dimensions.get('window').width;
  const fadeAnim = useRef(new Animated.Value(screenWidth));
  const left = fadeAnim.current;

  const fadeIn = (aminate = true) => {
    if (aminate === false) {
      fadeAnim.current = new Animated.Value(0);
      return;
    }
    Animated.timing(left, {..._timingAnimationConfig, toValue: 0}).start();
  };

  const fadeOut = (aminate = true) => {
    if (aminate === false) {
      fadeAnim.current = new Animated.Value(screenWidth);
      return;
    }
    Animated.timing(left, {..._timingAnimationConfig, toValue: screenWidth * -1}).start(({finished}) => {
      if (finished) {
        fadeAnim.current = new Animated.Value(screenWidth);
      }
    });
  };

  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    if (mounted) {
      shouldShow ? fadeIn() : fadeOut();
    }
  }, [shouldShow]);

  useMount(() => {
    if (shouldShow) {
      fadeIn(false);
    }
    setMounted(true);
  });
  return {position: 'absolute', left};
}
