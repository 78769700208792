import Button from '@/components/Button';
import {assets_empty_svg, assets_error_svg} from '@/components/Empty/assets';
import {Center} from '@/components/Flex';
import React from 'react';
import {StyleSheet, Text} from 'react-native';
import _ from 'lodash';

const IMAGES = {
  Empty: assets_empty_svg,
  Error: assets_error_svg,
};

const Empty = ({image = IMAGES.Empty, text, actions, style}) => {
  text = text ?? (image === IMAGES.Error ? '出错拉~' : '暂无数据');
  return (
    <Center direction="column" style={[styles.container, style, StyleSheet.absoluteFill]}>
      {React.createElement(image, {
        height: 113,
      })}
      <Text numberOfLines={4} style={styles.text}>
        {text}
      </Text>
      <Center>
        {_.map(actions, ({text, onPress, style: _style}, i) => (
          <Button key={`$btn_${i}`} color="#666" style={[styles.btn, _style]} onPress={onPress}>
            {text}
          </Button>
        ))}
      </Center>
    </Center>
  );
};
Empty.IMAGES = IMAGES;
export default Empty;

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#fff',
    paddingBottom: 200,
    zIndex: 1,
  },
  text: {
    fontSize: 14,
    color: '#D8D8D8',
    marginTop: 30,
    marginBottom: 12,
    marginHorizontal: 20,
  },
  btn: {
    width: 110,
    height: 38,
    marginHorizontal: 5,
  },
});
