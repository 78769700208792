import {Dialog} from '@/components';
import {IconButton} from '@/components/Icon';
import QQApi from '@/NativeModules/QQ';
import {useDispatch} from '@/utils/dva';
import LoginType from '@/utils/localData/LoginType';
import {useRequest} from 'ahooks';
import {isFunction} from 'lodash';
import React from 'react';
import {StyleSheet} from 'react-native';

const LoginByQQ = ({state, setState, onSuccess, onChangeLoginType}) => {
  const dispatch = useDispatch();
  const {run: loginByQQ} = useRequest(
    (accessToken, openId) => dispatch({type: 'user/loginByQQ', accessToken, openId}),
    {
      manual: true,
      onSuccess(res) {
        isFunction(onSuccess) && onSuccess(res);
        if (res?.success) {
          // Dialog.success(res.msg || '登录成功');
        } else if (res?.code == 401 && res?.data) {
          // 绑定手机号
          setState({token: res.data});
          onChangeLoginType(LoginType.BindPhone);
        } else {
          Dialog.respError(res);
        }
      },
    },
  );

  const onPress = async () => {
    if (!state?.agreement) {
      setState({
        agreementTipVisible: true,
      });
      return;
    } else {
      setState({
        agreementTipVisible: false,
      });
    }
    setState({loading: true});
    try {
      // const supported = await QQApi.isSupported();
      const supported = true;
      if (supported) {
        const apiRes = await QQApi.auth(null);
        const {access_token, openid} = apiRes || {};
        if (access_token && openid) {
          await loginByQQ(access_token, openid);
        }
      } else {
        Dialog.alert('', '当前设备未安装QQ应用或版本过低');
      }
      setState({loading: false});
    } catch (err) {
      setState({loading: false});
    }
  };

  return <IconButton type="qq" style={styles.socialIcon} onPress={onPress} />;
};
const styles = StyleSheet.create({
  socialIcon: {
    marginHorizontal: 25,
    width: 24,
    height: 24,
  },
});
export default LoginByQQ;
