import {ReactComponent as arrow_down} from '@/assets/icons/arrow_down.svg';
import {ReactComponent as arrow_left} from '@/assets/icons/arrow_left.svg';
import {ReactComponent as arrow_right} from '@/assets/icons/arrow_right.svg';
import {ReactComponent as arrow_up} from '@/assets/icons/arrow_up.svg';
import {ReactComponent as box_colorful} from '@/assets/icons/box_colorful.svg';
import {ReactComponent as checked} from '@/assets/icons/checked.svg';
import {ReactComponent as checked_circle} from '@/assets/icons/checked_circle.svg';
import {ReactComponent as close} from '@/assets/icons/close.svg';
import {ReactComponent as close_fill} from '@/assets/icons/close_fill.svg';
import {ReactComponent as coins} from '@/assets/icons/coins.svg';
import {ReactComponent as coins_colorful} from '@/assets/icons/coins_colorful.svg';
import {ReactComponent as edit} from '@/assets/icons/edit.svg';
import {ReactComponent as ensure_diamond} from '@/assets/icons/ensure/diamond.svg';
import {ReactComponent as ensure_favourite} from '@/assets/icons/ensure/favourite.svg';
import {ReactComponent as ensure_help} from '@/assets/icons/ensure/help.svg';
import {ReactComponent as ensure_transport} from '@/assets/icons/ensure/transport.svg';
import {ReactComponent as func_address} from '@/assets/icons/function/address.svg';
import {ReactComponent as func_coupon} from '@/assets/icons/function/coupon.svg';
import {ReactComponent as func_cupboard} from '@/assets/icons/function/cupboard.svg';
import {ReactComponent as func_customer_service} from '@/assets/icons/function/customer_service.svg';
import {ReactComponent as func_exchange} from '@/assets/icons/function/exchange.svg';
import {ReactComponent as func_favorite} from '@/assets/icons/function/favorite.svg';
import {ReactComponent as func_notice} from '@/assets/icons/function/notice.svg';
import {ReactComponent as func_reward} from '@/assets/icons/function/reward.svg';
import {ReactComponent as func_setting} from '@/assets/icons/function/setting.svg';
import {ReactComponent as heart} from '@/assets/icons/heart.svg';
import {ReactComponent as heart_outline} from '@/assets/icons/heart_outline.svg';
import {ReactComponent as mall} from '@/assets/icons/mall.svg';
import {ReactComponent as mall_text_logo} from '@/assets/icons/mall_text_logo.svg';
import {ReactComponent as minus} from '@/assets/icons/minus.svg';
import {ReactComponent as phone} from '@/assets/icons/phone.svg';
import {ReactComponent as order_dfh} from '@/assets/icons/order/dfh.svg';
import {ReactComponent as order_dfk} from '@/assets/icons/order/dfk.svg';
import {ReactComponent as order_dsh} from '@/assets/icons/order/dsh.svg';
import {ReactComponent as order_thh} from '@/assets/icons/order/thh.svg';
import {ReactComponent as order_ywc} from '@/assets/icons/order/ywc.svg';
import {ReactComponent as alipay} from '@/assets/icons/payment/alipay.svg';
import {ReactComponent as wxpay} from '@/assets/icons/payment/wxpay.svg';
import {ReactComponent as pieces_colorful} from '@/assets/icons/pieces_colorful.svg';
import {ReactComponent as plus} from '@/assets/icons/plus.svg';
import {ReactComponent as recycle} from '@/assets/icons/recycle.svg';
import {ReactComponent as search} from '@/assets/icons/search.svg';
import {ReactComponent as share} from '@/assets/icons/share.svg';
import {ReactComponent as showAll} from '@/assets/icons/show_all.svg';
import {ReactComponent as slide_up} from '@/assets/icons/slide_up.svg';
import {ReactComponent as apple} from '@/assets/icons/social/apple.svg';
import {ReactComponent as qq} from '@/assets/icons/social/qq.svg';
import {ReactComponent as wechat} from '@/assets/icons/social/wechat.svg';
import {ReactComponent as sp_cs} from '@/assets/icons/sp/cs.svg';
import {ReactComponent as sp_gm} from '@/assets/icons/sp/gm.svg';
import {ReactComponent as sp_zs} from '@/assets/icons/sp/zs.svg';
import {ReactComponent as star} from '@/assets/icons/star.svg';
import {ReactComponent as star_outline} from '@/assets/icons/star_outline.svg';
import {ReactComponent as tabbar_box} from '@/assets/icons/tabbar/box.svg';
import {ReactComponent as tabbar_box_active} from '@/assets/icons/tabbar/box_active.svg';
import {ReactComponent as tabbar_cupboard} from '@/assets/icons/tabbar/cupboard.svg';
import {ReactComponent as tabbar_cupboard_active} from '@/assets/icons/tabbar/cupboard_active.svg';
import {ReactComponent as tabbar_mall} from '@/assets/icons/tabbar/mall.svg';
import {ReactComponent as tabbar_mall_active} from '@/assets/icons/tabbar/mall_active.svg';
import {ReactComponent as tabbar_mine} from '@/assets/icons/tabbar/mine.svg';
import {ReactComponent as tabbar_mine_active} from '@/assets/icons/tabbar/mine_active.svg';
import {ReactComponent as tap} from '@/assets/icons/tap.svg';
import {ReactComponent as text_logo} from '@/assets/icons/text_logo.svg';
import {ReactComponent as tip} from '@/assets/icons/tip.svg';
import {ReactComponent as tool_colorful} from '@/assets/icons/tool_colorful.svg';
import {ReactComponent as unchecked} from '@/assets/icons/unchecked.svg';
import {ReactComponent as back_top} from '@/assets/icons/back_top.svg';

export {
  apple,
  qq,
  wechat,
  alipay,
  wxpay,
  mall,
  plus,
  minus,
  phone,
  arrow_right,
  arrow_left,
  arrow_up,
  arrow_down,
  checked,
  checked_circle,
  unchecked,
  close,
  close_fill,
  coins,
  edit,
  recycle,
  text_logo,
  mall_text_logo,
  search,
  showAll,
  heart,
  heart_outline,
  star,
  star_outline,
  share,
  tap,
  slide_up,
  tip,
  ensure_favourite,
  ensure_diamond,
  ensure_transport,
  ensure_help,
  order_dfh,
  order_dfk,
  order_dsh,
  order_thh,
  order_ywc,
  tabbar_mall,
  tabbar_mall_active,
  tabbar_cupboard,
  tabbar_cupboard_active,
  tabbar_box,
  tabbar_box_active,
  tabbar_mine,
  tabbar_mine_active,
  sp_gm,
  sp_cs,
  sp_zs,
  func_address,
  func_coupon,
  func_cupboard,
  func_notice,
  func_customer_service,
  func_reward,
  func_setting,
  func_favorite,
  func_exchange,
  coins_colorful,
  pieces_colorful,
  tool_colorful,
  box_colorful,
  back_top,
};
export default {
  apple,
  qq,
  wechat,
  alipay,
  wxpay,
  mall,
  plus,
  minus,
  phone,
  arrow_right,
  arrow_left,
  arrow_up,
  arrow_down,
  checked,
  checked_circle,
  unchecked,
  close,
  close_fill,
  coins,
  edit,
  recycle,
  text_logo,
  mall_text_logo,
  search,
  showAll,
  heart,
  heart_outline,
  star,
  star_outline,
  share,
  tap,
  slide_up,
  tip,
  ensure_favourite,
  ensure_diamond,
  ensure_transport,
  ensure_help,
  order_dfh,
  order_dfk,
  order_dsh,
  order_thh,
  order_ywc,
  tabbar_mall,
  tabbar_mall_active,
  tabbar_cupboard,
  tabbar_cupboard_active,
  tabbar_box,
  tabbar_box_active,
  tabbar_mine,
  tabbar_mine_active,
  sp_gm,
  sp_cs,
  sp_zs,
  func_address,
  func_coupon,
  func_cupboard,
  func_notice,
  func_customer_service,
  func_reward,
  func_setting,
  func_favorite,
  func_exchange,
  coins_colorful,
  pieces_colorful,
  tool_colorful,
  box_colorful,
  back_top,
};
