import {Center} from '@/components/Flex';
import theme from '@/theme';
import FontWeight from '@/utils/localData/FontWeight';
import {isFunction} from 'lodash';
import React, {useCallback} from 'react';
import {Platform, StyleSheet, Text, View} from 'react-native';

const size = 16;
const styles = StyleSheet.create({
  badge: {
    position: 'absolute',
    top: 0,
    right: 0,
    borderWidth: 1,
    borderColor: theme.fill_base,
    backgroundColor: theme.brand_primary,
  },
  dot: {
    width: size / 2,
    height: size / 2,
    borderRadius: size / 2,
    transform: [{translateX: size / 4}, {translateY: -size / 4}],
  },
  count: {
    width: size,
    height: size,
    borderRadius: size,
    transform: [{translateX: size / 2}, {translateY: -size / 2}],
  },
  innerText: {
    fontWeight: FontWeight.SemiBold,
    color: theme.fill_base,
    fontSize: size / 2,
    lineHeight: size - 2,
    textAlign: 'center',
    ...Platform.select({
      web: {
        fontSize: 12,
        transformOrigin: 'center center',
        transform: [{scale: size / 24}],
      },
    }),
  },
});
const Badge = ({children, overflowCount = 99, count, dot, ...restProps}) => {
  const getBadgeDom = useCallback(() => {
    if (dot) {
      return <View style={[styles.badge, styles.dot]} />;
    }
    let num = parseInt(count);
    if (overflowCount && num > overflowCount) {
      num = `${parseInt(overflowCount)}+`;
    }
    return (
      !!(num && num !== 0) && (
        <Center style={[styles.badge, styles.count]}>
          <Text style={styles.innerText}>{num}</Text>
        </Center>
      )
    );
  }, [dot, overflowCount, count]);

  const badgeDom = getBadgeDom();

  return (
    <View {...restProps}>
      {isFunction(children) ? children() : children}
      {badgeDom}
    </View>
  );
};

export default Badge;
