import PieceExchangeModal from '@/components/_modals/PieceExchangeModal';
import PieceRecoveryModal from '@/components/_modals/PieceRecoveryModal';
import Button from '@/components/Button';
import {Center, Flex} from '@/components/Flex';
import theme from '@/theme';
import useDynamicModal from '@/utils/hooks/useDynamicModal';
import FontWeight from '@/utils/localData/FontWeight';
import {useImageResize} from '@/utils/remoteData/ImageResize';
import React from 'react';
import {Image, StyleSheet, Text, View} from 'react-native';

/**
 * 我的碎片
 * */
const PieceCard = ({data, refreshList, style}) => {
  const openRecoveryModal = useDynamicModal(PieceRecoveryModal, {
    data,
    onSuccess(res) {
      if (res?.success) {
        refreshList();
      }
    },
  });
  const openSendModal = useDynamicModal(PieceExchangeModal, {
    data,
    onSuccess(res) {
      if (res?.success) {
        refreshList();
      }
    },
  });

  const {name, picture, count} = data || {};
  const [, resizeImage] = useImageResize({object: 'piece', size: 'sm'});

  return (
    <View style={[styles.container, style]}>
      <Center>
        <Image source={{uri: resizeImage(picture)}} style={styles.image} resizeMode="cover" />
      </Center>
      <View style={styles.body}>
        <Text style={styles.title}>{name}</Text>
        <Text style={styles.desc}>数量 × {count}</Text>
      </View>
      <Flex style={styles.footer}>
        <Button
          type="primary"
          size="small"
          fontWeight={FontWeight.SemiBold}
          style={styles.footerBtn}
          onPress={openRecoveryModal}>
          出售
        </Button>
        <Button
          type="primary"
          size="small"
          color={theme.brand_secondary}
          isLight={false}
          fontWeight={FontWeight.SemiBold}
          style={styles.footerBtn}
          onPress={openSendModal}>
          赠送
        </Button>
      </Flex>
    </View>
  );
};

export default PieceCard;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    height: 253,
    paddingTop: 12,
    paddingBottom: 17,
    borderWidth: StyleSheet.hairlineWidth,
    borderColor: theme.border_color_base,
    borderRadius: 4,
    backgroundColor: '#fff',
  },

  image: {
    width: 135,
    height: 135,
    marginBottom: 10,
    // backgroundColor: theme.fill_grey,
  },

  body: {
    paddingHorizontal: 16,
    marginBottom: 14,
  },

  title: {
    fontSize: 12,
    marginBottom: 4,
  },
  desc: {
    fontSize: 10,
    color: theme.color_text_secondary,
  },

  footer: {
    paddingHorizontal: 10,
    marginLeft: -6,
  },
  footerBtn: {
    flex: 1,
    marginLeft: 6,
  },
});
