import {isFunction} from 'lodash';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {Image, Pressable} from 'react-native';

export default function AutoHeightImage({style, source, onLayout, defaultHeight, onPress, ...rest}) {
  const [layout, setLayout] = useState(null);
  const [height, setHeight] = useState(defaultHeight ?? 0);

  useEffect(() => {
    if (!layout) {
      return;
    }
    if (source?.hasOwnProperty('uri')) {
      // console.log('网络图片');
      Image.getSize(source.uri, (_width, _height) => {
        const finalHeight = (layout.width * _height) / _width;
        setHeight(finalHeight);
      });
    } else {
      // console.log('本地资源');
      const img = Image.resolveAssetSource(source);
      const finalHeight = (layout.width * img.height) / img.width;
      setHeight(finalHeight);
    }
  }, [layout, source]);

  const img = (
    <Image
      onLayout={e => {
        setLayout(e.nativeEvent.layout);
        isFunction(onLayout) && onLayout(e);
      }}
      style={[style, {height}]}
      source={source}
      {...rest}
    />
  );
  if (isFunction(onPress)) {
    return (
      <Pressable onPress={onPress} style={[style, {height}]}>
        {img}
      </Pressable>
    );
  }
  return img;
}

AutoHeightImage.propTypes = {
  source: PropTypes.any,
  setHeight: PropTypes.func,
};
