import {Button, Dialog, Flex} from '@/components';
import {useDispatch} from '@/utils/dva';
import FontWeight from '@/utils/localData/FontWeight';
import {useRequest} from 'ahooks';
import React from 'react';
import {ScrollView, View, StyleSheet, Text} from 'react-native';

const LogoffPage = ({navigation}) => {
  const dispatch = useDispatch();
  const {run: logoff} = useRequest(() => dispatch({type: 'user/logoff'}), {
    manual: true,
    onSuccess(res) {
      if (res?.success) {
        Dialog.success(res?.msg || '账户注销成功');
        navigation.navigate('Home', {screen: 'MallTab'});
      } else {
        Dialog.respError(res);
      }
    },
  });

  const handleLogoff = logoff;
  const handleBack = () => {
    navigation.canGoBack() ? navigation.goBack() : navigation.replace('Home');
  };
  return (
    <View style={styles.page}>
      <ScrollView contentContainerStyle={styles.container}>
        <Text style={styles.title}>注销账户后，你的账户将被清除</Text>
        <View style={styles.alert}>
          <Text style={styles.alertText}>1，你将无法再登录、使用该账号。</Text>
          <Text style={styles.alertText}>
            2，该手机号码所绑定的所有微信、QQ等第三方账户都将解除绑定，使用相同的手机号码再次注册，将被视作一个全新的用户。
          </Text>
          <Text style={styles.alertText}>
            3，账户下的全部使用记录，包括但不仅限于个人资料、浏览资料、购买记录、提货记录、地址信息、收藏记录等都将被删除且无法被找回。
          </Text>
          <Text style={styles.alertText}>
            4，账号下的所有资产和权益，包括但不仅限于未使用的口袋币、已购买的内容、未提货的商品、优惠券、道具卡等将被清零且无法恢复。
          </Text>
          <Text style={styles.alertText}>5，若有其他任何疑问，可以点击【客服】选择在线人工客服询问详情。</Text>
        </View>
        <Flex style={{marginLeft: -11}}>
          <Button type="primary" fontWeight={FontWeight.SemiBold} style={styles.btn} onPress={handleBack}>
            我再想想
          </Button>
          <Button type="ghost" fontWeight={FontWeight.SemiBold} style={styles.btn} onPress={handleLogoff}>
            确认注销
          </Button>
        </Flex>
      </ScrollView>
    </View>
  );
};
const styles = StyleSheet.create({
  page: {
    flex: 1,
    backgroundColor: '#fff',
  },
  container: {
    paddingHorizontal: 20,
    paddingVertical: 32,
  },
  title: {
    marginBottom: 20,
    fontWeight: FontWeight.Medium,
    fontSize: 14,
  },

  alert: {
    backgroundColor: '#F5F5F5',
    borderRadius: 4,
    padding: 20,
    paddingBottom: 7,
    marginBottom: 20,
  },
  alertText: {
    fontSize: 12,
    lineHeight: 17,
    marginBottom: 17,
    color: '#666',
  },

  btn: {
    flex: 1,
    height: 48,
    marginLeft: 11,
  },
});

LogoffPage.screenName = 'Logoff';
LogoffPage.screenOptions = {
  title: '注销',
};
LogoffPage.linking = 'logoff';

export default LogoffPage;
