import {getCatalogData, getCatalogs} from '@/pages/catalogs/service';

export const namespace = 'catalogs';
const model = {
  namespace,
  state: {
    catalogs: [], //一级分类列表

    // 一级分类下详细的二级分类及活动等数据
    // eg: [`data_${id}`]: {}
  },
  effects: {
    /**
     * 查询一级分类
     * */
    *getCatalogs(__, {call, put}) {
      const res = yield call(getCatalogs);
      if (res?.success) {
        yield put({type: 'saveState', catalogs: res?.data});
      }
      return res;
    },

    /**
     * 查询一级分类下详细的二级分类及活动等数据
     * @actionParam id: 一级分类的id
     * */
    *getCatalogData({id}, {call, put}) {
      const res = yield call(getCatalogData, id);
      if (res?.success) {
        yield put({type: 'saveState', [`data_${id}`]: res?.data});
      }
      return res;
    },
  },
  reducers: {
    saveState(state, action) {
      const {type, ...nextState} = action || {};
      return {...state, ...nextState};
    },
  },
  subscriptions: {},
};

export default model;
