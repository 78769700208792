import {Dialog, Flex} from '@/components';
import CustomerService from '@/components/_modals/CustomerService';
import Button from '@/components/Button';
import {IconButton} from '@/components/Icon';
import theme from '@/theme';
import {useDispatch} from '@/utils/dva';
import useBottomInset from '@/utils/hooks/useBottomInset';
import useDynamicModal from '@/utils/hooks/useDynamicModal';
import OrderStatus from '@/utils/localData/OrderStatus';
import {useRequest} from 'ahooks';
import React from 'react';
import {StyleSheet, View} from 'react-native';

const styles = StyleSheet.create({
  toolbar: {
    borderTopWidth: StyleSheet.hairlineWidth,
    borderTopColor: theme.border_color_base,
    backgroundColor: '#fff',
  },
  container: {
    paddingHorizontal: theme.h_spacing_lg,
    paddingVertical: 12,
  },
  btn: {
    width: 100,
    height: 48,
    marginLeft: 15,
  },
});
const OrderReceiveBar = ({data, mutate}) => {
  const [, , , paddingBottom] = useBottomInset(12);
  const dispatch = useDispatch();
  const {id} = data || {};

  // 确认收货
  const {loading: loading, run: receive} = useRequest(() => dispatch({type: 'order/receive', id}), {
    manual: true,
    onSuccess(res) {
      if (res?.success) {
        Dialog.success(res.msg ?? '操作成功');
        mutate({...data, status: OrderStatus.Completed});
      } else {
        Dialog.respError(res);
      }
    },
  });

  const openCustomerService = useDynamicModal(CustomerService);

  return (
    <View style={[styles.toolbar, {paddingBottom}]}>
      <Flex justify="between" style={styles.container}>
        <IconButton
          type="func_customer_service"
          style={{width: 21, height: 22}}
          onPress={() => openCustomerService()}
        />
        <Flex>
          <Button type="primary" loading={loading} style={styles.btn} onPress={receive}>
            {!loading && '确认收货'}
          </Button>
        </Flex>
      </Flex>
    </View>
  );
};

export default OrderReceiveBar;
