const normal = require('./tag_normal@2x.png');
const hidden = require('./tag_hidden@2x.png');
const rare = require('./tag_rare@2x.png');
const supreme = require('./tag_supreme@2x.png');
const normal_small = require('./tag_normal_small@2x.png');
const hidden_small = require('./tag_hidden_small@2x.png');
const rare_small = require('./tag_rare_small@2x.png');
const supreme_small = require('./tag_supreme_small@2x.png');

export default {normal, hidden, rare, supreme, normal_small, hidden_small, rare_small, supreme_small};
export {normal, hidden, rare, supreme, normal_small, hidden_small, rare_small, supreme_small};
