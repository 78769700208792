import Loading from '@/components/Loading';
import PieceMeta from '@/pages/piece/components/PieceMeta';
import {useDispatch, useSelector} from '@/utils/dva';
import FontWeight from '@/utils/localData/FontWeight';
import {useNavigation} from '@react-navigation/core';
import {useRequest} from 'ahooks';
import React from 'react';
import {FlatList, StyleSheet, Text, TouchableOpacity, View} from 'react-native';

const styles = StyleSheet.create({
  listHead: {
    height: 40,
    paddingTop: 8,
    paddingBottom: 12,
    paddingHorizontal: 10,
  },
  listHeadText: {
    fontSize: 16,
    lineHeight: 20,
    fontWeight: FontWeight.SemiBold,
  },
  pieceCard: {
    height: 108,
    marginHorizontal: 10,
    marginBottom: 8,
  },
});
const PiecesListHead = () => {
  return (
    <View style={styles.listHead}>
      <Text style={styles.listHeadText}>购买碎片</Text>
    </View>
  );
};
const PieceList = ({ListHeaderComponent, ...restProps}) => {
  const navigation = useNavigation();
  const data = useSelector(s => s.piece.data);
  const dispatch = useDispatch();
  const {loading} = useRequest(() => dispatch({type: 'piece/getPieces'}));

  return (
    <FlatList
      {...restProps}
      ListHeaderComponent={ListHeaderComponent ?? PiecesListHead}
      data={data}
      renderItem={({item}) => {
        return (
          <TouchableOpacity
            style={styles.pieceCard}
            onPress={() =>
              navigation.navigate('Piece', {
                screen: 'PieceOrder',
                params: {id: item?.id},
              })
            }>
            <PieceMeta data={item} card={true} />
          </TouchableOpacity>
        );
      }}
      // onEndReached={() => {
      //   !loading && getPagedData();
      // }}
      ListFooterComponent={loading && <Loading />}
    />
  );
};
PieceList.ListHead = PiecesListHead;
export default PieceList;
