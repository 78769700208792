import Icon from '@/components/Icon';
import MallContext from '@/pages/home/mall/components/MallContext';
import MallHeader from '@/pages/home/mall/components/MallHeader';
import MallSearchBar from '@/pages/home/mall/components/MallSearchBar';
import MallTabs from '@/pages/home/mall/components/MallTabs';
import {useSelector} from '@/utils/dva';
import {useFocusCheckActivePopup} from '@/utils/hooks/usePopupManager';
import _ from 'lodash';
import React, {useRef} from 'react';
import {Animated, SafeAreaView, StatusBar, StyleSheet, View} from 'react-native';

const MallPage = ({navigation}) => {
  const tabs = useSelector(s => s.mall?.tabs) ?? [];
  useFocusCheckActivePopup('mall'); // 检查弹窗

  const offsetTop = useRef(new Animated.Value(0)).current;
  const actionRef = useRef({
    show() {
      Animated.timing(offsetTop, {
        toValue: 0,
        duration: 150,
        useNativeDriver: false,
      }).start();
    },
    hide() {
      Animated.timing(offsetTop, {
        toValue: -48,
        duration: 150,
        useNativeDriver: false,
      }).start();
    },
  });

  return (
    <SafeAreaView style={styles.page}>
      <MallContext.Provider value={{actionRef}}>
        {!!tabs?.length && (
          <View style={{flex: 1, overflow: 'hidden'}}>
            <Animated.View style={{marginTop: offsetTop}}>
              <MallHeader key="$header" />
              <MallSearchBar key="$searchBar" />
            </Animated.View>
            <View style={{flex: 1}}>
              <MallTabs key="$tabs" tabs={tabs} />
            </View>
          </View>
        )}
      </MallContext.Provider>
    </SafeAreaView>
  );
};
const styles = StyleSheet.create({
  page: {
    paddingTop: StatusBar.currentHeight,
    flex: 1,
    backgroundColor: '#fff',
  },
});

MallPage.screenName = 'MallTab';
MallPage.screenOptions = {
  tabBarIcon: ({focused, color}) => <Icon type={`tabbar_mall${focused ? '_active' : ''}`} fill={color} />,
  tabBarLabel: '商城',
};
MallPage.linking = {
  path: 'mall',
  get screens() {
    const tabs = global?._getStore?.().getState()?.mall?.tabs;
    return _.chain(tabs)
      .keyBy('value')
      .mapKeys((v, key) => 'Tab_' + key)
      .mapValues(v => v.value)
      .value();
  },
};
export default MallPage;
