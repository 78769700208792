import {SaleCountText} from '@/components';
import {Flex} from '@/components/Flex';
import HotTag from '@/pages/box/components/HotTag';
import theme from '@/theme';
import FontWeight from '@/utils/localData/FontWeight';
import {useImageResize} from '@/utils/remoteData/ImageResize';
import {getDINProBoldStyle} from '@/utils/styleUtils';
import {useLinkTo} from '@react-navigation/native';
import {times} from 'lodash';
import React from 'react';
import {Image, Pressable, StyleSheet, Text, View} from 'react-native';

const BoxCard = ({data, style, onPress}) => {
  const linkTo = useLinkTo();
  const {id, hot, picture, name, detail, saleCount, amount, topFiveProducts} = data || {};
  const [, resizeImage] = useImageResize();

  return (
    <Pressable style={[styles.box, style]} onPress={onPress ?? (e => linkTo(`/box/detail/${id}`))}>
      {hot && <HotTag style={{left: 10, top: 10}} />}
      <Image source={{uri: resizeImage(picture, 'box', 'sm')}} style={styles.boxImage} />
      <Flex direction="column" justify="between" align="start" style={styles.boxContent}>
        <View style={{width: '100%'}}>
          <Text style={styles.boxContentTitle} numberOfLines={1}>
            {name}
          </Text>
          <Text style={styles.boxContentDesc} numberOfLines={1}>
            {detail}
          </Text>
        </View>
        <View style={{width: '100%'}}>
          <Flex justify="between">
            <Text style={styles.boxContentPrice}>¥{amount ?? 0}</Text>
            <Text style={styles.boxSaleCount}>
              <SaleCountText count={saleCount} style={styles.boxSaleCount} />
              人已购买
            </Text>
          </Flex>
          <Flex justify="between" style={styles.boxItemImageRow}>
            {times(5, i => {
              return topFiveProducts[i] ? (
                <Image
                  key={`$image__${i}`}
                  source={{uri: resizeImage(topFiveProducts?.[i], 'prod', 'xxs')}}
                  style={styles.boxItemImage}
                />
              ) : (
                <View key={`$image__${i}`} style={styles.boxItemImage} />
              );
            })}
          </Flex>
        </View>
      </Flex>
    </Pressable>
  );
};
const styles = StyleSheet.create({
  box: {
    height: 155,
    borderWidth: StyleSheet.hairlineWidth,
    borderColor: theme.border_color_base,
    borderRadius: 4,
    backgroundColor: theme.fill_base,
    flexDirection: 'row',
    padding: 10,
  },
  boxImage: {
    width: 135,
    height: 135,
    // backgroundColor: theme.fill_grey,
  },
  boxContent: {
    flex: 1,
    marginTop: 6,
    // marginLeft: 5,
    // marginRight: 10,
    marginLeft: 10,
  },
  boxContentTitle: {
    marginBottom: 3,
    fontWeight: FontWeight.SemiBold,
    lineHeight: 19,
  },
  boxContentDesc: {
    marginBottom: 9,
    fontSize: 12,
    lineHeight: 17,
    color: theme.color_text_secondary,
  },
  boxContentPrice: {
    ...getDINProBoldStyle(16),
    lineHeight: 20,
  },
  boxSaleCount: {
    fontSize: 12,
    color: theme.color_text_secondary,
  },

  boxItemImageRow: {
    marginTop: 3,
    paddingTop: 7,
    borderTopWidth: StyleSheet.hairlineWidth,
    borderColor: theme.border_color_base,
  },
  boxItemImage: {
    width: 34,
    height: 40,
    // backgroundColor: theme.fill_grey,
  },
});

export default BoxCard;
