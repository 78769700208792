import theme from '@/theme';
import {Platform, StyleSheet} from 'react-native';
import './css';

const globalStyle = StyleSheet.create({
  root: {
    ...Platform.select({
      web: {
        fontFamily: '"PingFang SC", Roboto, Arial, sans-serif',
        color: theme.color_text_base,
        fontSize: theme.font_size_base,
      },
    }),
  },
});

export default globalStyle;
