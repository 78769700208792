import {Flex} from '@/components/Flex';
import theme from '@/theme';
import FontWeight from '@/utils/localData/FontWeight';
import {useImageResize} from '@/utils/remoteData/ImageResize';
import {getDINProBoldStyle} from '@/utils/styleUtils';
import {isFunction, isString} from 'lodash';
import React, {isValidElement} from 'react';
import {Image, StyleSheet, Text, View} from 'react-native';

const styles = StyleSheet.create({
  container: {},
  cardContainer: {
    padding: 10,
    backgroundColor: '#fff',
    borderWidth: StyleSheet.hairlineWidth,
    borderRadius: 4,
    borderColor: theme.border_color_base,
  },
  thumb: {
    width: 88,
    height: 88,
    marginRight: 12,
    // backgroundColor: theme.fill_grey,
  },
  right: {
    flex: 1,
    // minHeight: 88,
  },
  rightBody: {
    width: '100%',
    justifyContent: 'space-between',
    marginTop: 6,
  },
  rightFooter: {
    width: '100%',
    justifyContent: 'space-between',
    marginTop: 13,
  },
  title: {
    fontSize: 14,
  },
  brief: {
    fontSize: 12,
    color: theme.color_text_secondary,
    marginTop: 4,
  },
  content: {
    marginTop: 5,
  },
  extra: {
    color: theme.color_text_secondary,
  },
  price: {},
  priceLabel: {
    fontSize: 10,
    lineHeight: 19,
    marginRight: 2,
  },
  priceAmount: {
    ...getDINProBoldStyle(16),
    lineHeight: 20,
  },
  priceUnit: {
    fontWeight: FontWeight.SemiBold,
    fontSize: 14,
    lineHeight: 20,
  },
});
const Thumb = ({data, value, children}) => {
  if (isFunction(value)) {
    return value(data, {style: styles.thumb, styles});
  }
  if (isString(value)) {
    return <Image source={{uri: value}} style={styles.thumb} />;
  }
  if (isValidElement(value)) {
    return value;
  }

  if (isString(children)) {
    return <Image source={{uri: children}} style={styles.thumb} />;
  }
  return children;
};
const Title = ({data, value, children}) => {
  if (isFunction(value)) {
    return value(data, {style: styles.title, styles});
  }
  if (value) {
    return <Text style={styles.title}>{value}</Text>;
  }

  return <Text style={styles.title}>{children}</Text>;
};
const Brief = ({data, value, children}) => {
  if (isFunction(value)) {
    return value(data, {style: styles.brief, styles});
  }
  if (value) {
    return <Text style={styles.brief}>{value}</Text>;
  }

  return <Text style={styles.brief}>{children}</Text>;
};
const Content = ({data, value, children}) => {
  if (isFunction(value)) {
    return value(data, {style: styles.content, styles, defaultDom: children});
  }
  if (isString(value)) {
    return <Text style={styles.content}>{value}</Text>;
  }
  if (isValidElement(value)) {
    return value;
  }
  if (isString(children)) {
    return <Text style={styles.content}>{children}</Text>;
  }
  return children;
};
const Extra = ({data, value, children}) => {
  if (isFunction(value)) {
    return value(data, {style: styles.extra, styles});
  }
  if (value) {
    return <Text style={styles.extra}>{value}</Text>;
  }

  return <Text style={styles.extra}>{children}</Text>;
};
const PieceMeta = ({data, card = true, style, thumb, title, brief, content, extra}) => {
  const {name, picture, score} = data || {};
  const [, resizeImage] = useImageResize({object: 'piece', size: 'xs'});

  return (
    <Flex align="stretch" style={[styles.container, card && styles.cardContainer, style]}>
      <Thumb data={data} value={thumb}>
        <Image source={{uri: resizeImage(picture)}} style={styles.thumb} />
      </Thumb>
      <Flex direction="column" justify="center" align="start" style={styles.right}>
        <Flex justify="between" align="start" style={styles.rightBody}>
          <Flex style={{flex: 1}}>
            <Title data={data} value={title}>
              {name}
            </Title>
            <Brief data={data} value={brief} />
          </Flex>
          <Extra data={data} value={extra} />
        </Flex>
        <Flex justify="between" align="end" style={styles.rightFooter}>
          <Content data={data} value={content}>
            <Flex align="end">
              <Text style={styles.priceLabel}>今日价格：</Text>
              <Flex align="center">
                <Text style={styles.priceAmount}>{score}</Text>
                <View style={{width: 6}} />
                <Text style={styles.priceUnit}>口袋币</Text>
              </Flex>
            </Flex>
          </Content>
        </Flex>
      </Flex>
    </Flex>
  );
};
PieceMeta.Thumb = Thumb;
PieceMeta.Title = Title;
PieceMeta.Brief = Brief;
PieceMeta.Content = Content;
PieceMeta.Extra = Extra;
export default PieceMeta;
