import Button from '@/components/Button';
import Icon from '@/components/Icon';
import {assets_logo_with_text} from '@/pages/home/mall/components/assets';
import {useSelector} from '@/utils/dva';
import FontWeight from '@/utils/localData/FontWeight';
import {useLinkTo} from '@react-navigation/native';
import React from 'react';
import {Animated, ImageBackground, StyleSheet, Text, View} from 'react-native';

const MallHeader = ({style}) => {
  const currentUser = useSelector(s => s.global.currentUser);
  const isLogin = !!currentUser;
  const linkTo = useLinkTo();

  return (
    <Animated.View style={[styles.mallHeader, style]}>
      <View style={styles.logo}>
        <ImageBackground source={assets_logo_with_text} style={styles.logo} />
      </View>
      <View style={styles.actions}>
        {isLogin ? (
          <View style={styles.amount}>
            <Icon type="coins" style={styles.amountIcon} />
            <Text style={styles.amountText}>{currentUser?.score}</Text>
          </View>
        ) : (
          <Button
            size="small"
            style={[styles.actionButton]}
            fontWeight={FontWeight.SemiBold}
            onPress={() => linkTo('/login')}>
            立即登录
          </Button>
        )}
      </View>
    </Animated.View>
  );
};

const styles = StyleSheet.create({
  mallHeader: {
    height: 57,
    marginBottom: -9,
    // paddingTop: 20 + 9,
    paddingTop: 20,
    paddingBottom: 10,
    paddingHorizontal: 20,
    zIndex: 19,
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  logo: {
    width: 60,
    height: 27,
  },
  action: {},
  actionButton: {
    paddingLeft: 11,
    paddingRight: 11,
    borderRadius: 2,
  },
  amount: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  amountIcon: {
    marginRight: 9,
  },
  amountText: {
    fontSize: 16,
    lineHeight: 22,
    fontWeight: FontWeight.SemiBold,
  },
});

export default MallHeader;
