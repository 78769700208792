import {Platform} from 'react-native';

export function getServer() {
  return process.env.REACT_APP_SERVER;
}

export function getOrigin() {
  return process.env.REACT_APP_ORIGIN;
}

export function getCDN() {
  return process.env.REACT_APP_CDN;
}

export function getTicket() {
  return global._getStore?.()?.getState?.()?.global?.currentUser?.ticket;
}

export function getChannel() {
  return global._getStore?.()?.getState?.()?.global?.channel;
}

export function getDeviceIds() {
  return global._getStore?.()?.getState?.()?.global?.deviceIds;
}

export function getUserAgent() {
  return global._getStore?.()?.getState?.()?.global?.userAgent;
}

export function getRequestHeaderParams() {
  const {
    currentUser,
    channel,
    deviceIds,
    x_channelCode,
    x_shareId,
    versionCode,
    versionName,
    deviceModel,
    deviceName,
    userAgent,
  } = global._getStore?.()?.getState?.()?.global || {};
  return {
    ticket: currentUser?.ticket || '',
    channel: channel || '',
    source: x_channelCode || '',
    XinShareId: x_shareId || '',
    ...Platform.select({
      android: {
        IMEI: deviceIds?.IMEI || '',
        ANDROIDID: deviceIds?.ANDROID_ID || '',
        OAID: deviceIds?.OAID || '',
      },
      ios: {
        IDFA: deviceIds?.IDFA || '',
      },
    }),
    versionCode: versionCode || '',
    versionName: versionName || '',
    deviceModel: deviceModel || '',
    deviceName: deviceName || '',
    ...Platform.select({
      native: {
        userAgent,
      },
    }),
  };
}

export function isDev() {
  return !!global.__DEV__;
}

export function isAndroid() {
  return Platform.OS === 'android';
}

export function isIos() {
  return Platform.OS === 'ios';
}

export function isNative() {
  return Platform.OS === 'ios' || Platform.OS === 'android';
}

export function isWeb() {
  return Platform.OS === 'web';
}

export function isIosWeb() {
  return isWeb() && !!navigator?.userAgent?.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
}
