import CouponHistoryPage from '@/pages/coupon/history';
import CouponListPage from '@/pages/coupon/list';
import CouponSelectorPage from '@/pages/coupon/selector';
import {createStackNavigator} from '@/utils/navigatorUtils';
import React from 'react';

export default createStackNavigator([CouponListPage, CouponHistoryPage, CouponSelectorPage], {
  screenName: 'Coupon',
  screenOptions: {
    headerShown: false,
  },
  linking: 'coupon',
});
