import _ from 'lodash';
import React, {useEffect, useMemo, useState} from 'react';

const getKey = item => item?.key ?? item?.id;
const setKey = (item, value) => {
  item.key = value;
  item.id = value;
};

export class NoticesStack {
  constructor(
    initialData,
    {
      dataLimit = 10, // 元数据长度限制，超出后从结尾丢弃
      renderCount = 5, // 一次渲染的条数
      duration = 4000,
    } = {},
  ) {
    this.data = [];
    this.queue = [];
    this.dataLimit = dataLimit > renderCount ? dataLimit : renderCount;
    this.renderCount = renderCount;
    this.duration = duration;
    this.getKey = getKey;
    this.addData(initialData);
    this.pushData();
  }

  pushData = () => {
    if (this.queue.length < this.renderCount) {
      _.times(this.renderCount - this.queue.length, () => this.pushNotice());
    }
    if (this.queue.length > 0) {
      while (this.queue.length < this.renderCount) {
        _.times(this.renderCount - this.queue.length, i => {
          if (this.queue[i]) {
            const copiedNotice = _.clone(this.queue[i].notice);
            setKey(copiedNotice, `copied_${getKey(copiedNotice)}`);
            this.pushNotice(copiedNotice);
          }
        });
      }
    }
  };

  addData = newData => {
    this.data = _.chain(newData).unionBy(this.data, getKey).slice(0, this.dataLimit).value();
    if (!this.queue.length) {
      this.pushData();
    }
  };

  getNextNotice = () => {
    const queue = _.map(this.queue, 'notice');
    if (this._last) {
      const rest = _.differenceBy(this.data, _.differenceBy(queue, [this._last], getKey), getKey);
      const index = _.findIndex(rest, o => getKey(o) === getKey(this._last));
      const next = rest[index + 1];
      if (next) {
        return next;
      }
    }
    const rest = _.differenceBy(this.data, queue, getKey);
    return _.first(_.differenceBy(rest, [this._last], getKey));
  };

  getQueueNotice = notice => {
    const key = getKey(notice);
    return {
      key,
      notice,
      pop: callback => {
        const result = this.popNoticeIfEnable(key);
        _.isFunction(callback) && callback(!!result, this);
      },
    };
  };

  pushNotice = notice => {
    if (!notice) {
      notice = this.getNextNotice();
    }
    if (notice) {
      this.queue = _.concat(this.queue, this.getQueueNotice(notice));
      this._last = notice;
    }
  };

  popNoticeIfEnable = key => {
    // 仅当有备选通知时允许丢弃当前消息
    if (this.queue.length >= this.renderCount) {
      const index = _.findIndex(this.queue, item => item?.key === key);
      const next = this.getNextNotice();
      if (_.some(this.queue, item => item.key === getKey(next))) {
        console.warn('NoticesStack: next is in queue');
      }
      if (next) {
        _.fill(this.queue, this.getQueueNotice(next), index, index + 1);
        this._last = next;
        return this.queue;
      }
    }
  };

  popNotice = () => {
    this.queue = _.tail(this.queue);
  };
}

const useNoticesStatus = (data, options) => {
  const [notices, setNotices] = useState([]);
  const noticesStack = useMemo(() => new NoticesStack(data, options), []);
  // 数据刷新时添加到元数据
  useEffect(() => {
    noticesStack.addData(data);
    setNotices(noticesStack.queue);
  }, [data, noticesStack]);

  return [notices, setNotices, noticesStack];
};

export default useNoticesStatus;
