import {Center} from '@/components/Flex';
import theme from '@/theme';
import React from 'react';
import {ActivityIndicator, StyleSheet, Text} from 'react-native';

const styles = StyleSheet.create({
  loading: {
    height: 30,
    paddingHorizontal: 20,
    paddingVertical: 5,
  },
  loadingText: {
    fontSize: 12,
    color: theme.color_text_secondary,
    lineHeight: 20,
    marginLeft: 8,
  },
});
const Loading = ({text, style}) => {
  return (
    <Center style={[styles.loading, style]}>
      <ActivityIndicator color={theme.brand_primary} />
      <Text style={styles.loadingText}>{text ?? '数据加载中...'}</Text>
    </Center>
  );
};

export default Loading;
