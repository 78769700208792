import Grid from '@/components/Grid';
import List from '@/components/List';
import ProdCardCommon from '@/pages/product/components/ProdCard/ProdCardCommon';
import {useDispatch, useSelector} from '@/utils/dva';
import FontWeight from '@/utils/localData/FontWeight';
import {useRequest} from 'ahooks';
import React, {useEffect, useState} from 'react';
import {StyleSheet, Text, View} from 'react-native';

const styles = StyleSheet.create({
  item: {
    flex: 1,
    height: 252,
    backgroundColor: '#fff',
  },
});

const ProdRecommends = ({style, visible}) => {
  const initialData = useSelector(s => s.favorites.recommendProds);
  const dispatch = useDispatch();
  const {data} = useRequest(() => dispatch({type: 'favorites/getRecommendProds'}), {
    ready: !initialData?.length,
    initialData,
    formatResult: res => res?.data,
  });
  const [_visible, setVisible] = useState(false);
  useEffect(() => {
    if (visible) {
      // 延迟显示，防止页面闪烁
      const id = setTimeout(() => setVisible(true), 500);
      return () => clearTimeout(id);
    } else {
      setVisible(false);
    }
  }, [visible]);

  return (
    _visible &&
    !!data?.length && (
      <View style={style}>
        <List.Item offset={[0, 20]}>
          <Text style={{fontWeight: FontWeight.Bold}}>猜你喜欢</Text>
        </List.Item>
        <Grid
          data={data}
          columnNum={2}
          border={false}
          hasLine={true}
          itemStyle={styles.item}
          renderItem={item => {
            return <ProdCardCommon data={item} style={[styles.item]} />;
          }}
        />
      </View>
    )
  );
};
export default ProdRecommends;
