import React from 'react';
import {Platform, StyleSheet, View} from 'react-native';

const styles = StyleSheet.create({
  HeaderRight: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    ...Platform.select({
      web: {
        marginRight: 20,
      },
      android: {
        marginRight: 10,
      },
    }),
  },
  BackButton: {
    // marginLeft: 0,
  },
});

const HeaderRight = ({tintColor = '#000', children, navigation: _navigation} = {}) => {
  return <View style={styles.HeaderRight}>{children}</View>;
};

export default HeaderRight;
