import * as avatars from '@/assets/avatars';
import theme from '@/theme';
import {isWeb} from '@/utils/utils';
import {isFunction, isNumber, isString, reduce} from 'lodash';
import React, {isValidElement, useEffect, useState} from 'react';
import {ImageBackground, Platform, StyleSheet, Text, View} from 'react-native';

const styles = StyleSheet.create({
  wrap: {
    width: 52,
    height: 52,
    backgroundColor: '#F1F2F4',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
  },
  circleWrap: {
    borderRadius: 52,
  },
  squareWrap: {
    borderRadius: theme.radius_xs,
  },
  image: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    zIndex: 99,
  },
  inside: {
    // position: 'absolute',
    overflow: 'visible',
    ...Platform.select({
      web: {},
      default: {
        width: 200,
      },
    }),
  },
  text: {
    fontSize: 26,
    color: '#D3D6DD',
    ...Platform.select({
      web: {
        whiteSpace: 'nowrap',
      },
    }),
  },
});
const defaultDom = <ImageBackground source={require('@/assets/user.png')} style={styles.image} />;
const defaultProps = {
  gap: 4,
  shape: 'circle', // circle | square
  size: 52,
  src: '',
  onError: undefined,
};
const Avatar = ({style, children, ...restProps}) => {
  const {gap, shape, size, src, onError} = Object.assign({}, defaultProps, restProps);

  let source = src && {uri: src};
  if (src && avatars.hasOwnProperty(src)) {
    source = avatars[src];
  }

  const _gap = gap ?? 4;
  const _style = {};
  if (isNumber(size)) {
    _style.width = size;
    _style.height = size;
    if (restProps.shape === 'circle') {
      _style.borderRadius = size;
    }
  }
  const [loaded, setLoaded] = useState(false);
  const imageDom = !!source && (
    <ImageBackground
      source={source}
      style={[styles.image, !loaded && {opacity: 0}]}
      onLoad={() => setLoaded(true)}
      onError={err => {
        isFunction(onError) && onError(err);
      }}
    />
  );

  const [wrapLayout, setWrapLayout] = useState(null);
  const [insideLayout, setInsideLayout] = useState(null);
  const onWrapLayout = e => setWrapLayout({width: e.nativeEvent.layout.width, height: e.nativeEvent.layout.height});
  const onInsideLayout = e =>
    setInsideLayout({
      width: e.nativeEvent.layout.width,
      height: e.nativeEvent.layout.height,
    });
  const onTextLayout = e =>
    setInsideLayout({
      width: reduce(e.nativeEvent.lines, (r, line) => r + line.width, 0),
      height: e.nativeEvent.lines[0]?.height ?? 1,
    });

  const [otherDomm, setOtherDom] = useState(null);
  useEffect(() => {
    const _insideStyle = {opacity: 0};
    if (wrapLayout && insideLayout) {
      const _wrapWidth = wrapLayout.width - 2 * _gap,
        _wrapHeight = wrapLayout.height - 2 * _gap;
      let _width = insideLayout.width,
        _height = insideLayout.height;
      if (_width > _wrapWidth) {
        _height = (_height * _wrapWidth) / _width;
        _width = _wrapWidth;
      }
      if (_height > _wrapHeight) {
        _width = (_width * _wrapHeight) / _height;
        _height = _wrapHeight;
      }
      _insideStyle.transform = [{scale: _width / insideLayout.width}];
      _insideStyle.opacity = 1;
    }
    if (children) {
      if (isString(children) && !isWeb()) {
        setOtherDom(
          <View style={[styles.inside, _insideStyle, insideLayout]}>
            <Text style={[styles.text]} {...(_insideStyle.transform ? {numberOfLines: 1} : {onTextLayout})}>
              {children}
            </Text>
          </View>,
        );
      } else {
        setOtherDom(
          <View style={[styles.inside, _insideStyle]} onLayout={onInsideLayout}>
            {isFunction(children) ? (
              children()
            ) : isValidElement(children) ? (
              children
            ) : (
              <Text style={[styles.text]}>{children}</Text>
            )}
          </View>,
        );
      }
    }
  }, [_gap, children, insideLayout, wrapLayout]);

  return (
    <View style={[styles.wrap, styles[`${shape}Wrap`], _style, style]} onLayout={onWrapLayout}>
      {imageDom}
      {!loaded && (otherDomm || defaultDom)}
    </View>
  );
};
export default Avatar;
