import {Dialog, LoadingView} from '@/components';
import CommonTradeModal from '@/components/_modals/CommonTradeModal';
import Button from '@/components/Button';
import {Flex} from '@/components/Flex';
import LinearGradient from '@/components/LinearGradient';
import BoxMeta from '@/pages/box/components/BoxMeta';
import theme from '@/theme';
import {useDispatch} from '@/utils/dva';
import useDynamicModal from '@/utils/hooks/useDynamicModal';
import useLoopRequest from '@/utils/hooks/useLoopRequest';
import useLov from '@/utils/hooks/useLov';
import {wakeUpPayApp} from '@/utils/hooks/useWakeUpPayApp';
import FontWeight from '@/utils/localData/FontWeight';
import {getDINProBoldStyle} from '@/utils/styleUtils';
import {useNavigation} from '@react-navigation/core';
import {useRequest} from 'ahooks';
import React, {useCallback, useRef, useState} from 'react';
import {StyleSheet, Text, View} from 'react-native';
import {isAndroid} from '@/utils/utils';

const BoxFooterBar = ({data}) => {
  const {id, singleOnly} = data || {};
  const navigation = useNavigation();
  const dispatch = useDispatch();
  const [orderId, setOrderId] = useState('');
  const {onoff} = useLov('SCORE_TO_RMB_ENABLE');
  const rate = useLov('RMB_SCORE_RATE')?.[1];
  const deductEnable = onoff === 'on' && rate > 0;

  // 订阅盲盒结果
  const {
    loading: paying,
    run: subscribeResult,
    unsubscribe,
  } = useLoopRequest(() => dispatch({type: 'box/getResult', orderId}), {
    isSuccess: res => res?.success && res?.data?.length,
  });

  // 离开时确认订单付款结果
  const {run: confirmResult} = useRequest(paid => dispatch({type: 'order/confirmResult', id: orderId, paid}), {
    ready: !!orderId,
    manual: true,
    onSuccess(res) {
      if (res?.success) {
        // Dialog.success(res.msg || '购买成功');
        navigation.push('Box', {
          screen: 'BoxResultModal',
          params: {id: id, data: res?.data},
        });
      } else {
        Dialog.alert('', res?.msg);
      }
    },
  });

  // 购买盲盒
  const {loading: submitting, run: submit} = useRequest(
    params => {
      // console.log('购买盲盒：', params);
      const {amount, payType, deduct, deductAmount, multiple} = params || {};
      return dispatch({
        type: 'box/buy',
        data: {id, payType, count: multiple ? 5 : 1},
      })
        .then(res => {
          if (res?.success && res?.data?.id) {
            // 订单生成成功
            const orderId = res.data.id;
            setOrderId(orderId);
            // 立即支付订单
            return dispatch({
              type: 'order/pay',
              data: {id: orderId, payType, deduct},
            });
          }
          return res;
        })
        .then(res => {
          if (res?.success) {
            // 支付链接生成成功
            // 唤起支付应用
            wakeUpPayApp(payType, res.data)
              .then(res => {
                if (!res?.paid) {
                  unsubscribe();
                  if (!res.canceled) {
                    Dialog.fail(res.memo || '支付失败');
                  }
                }
              })
              .catch(err => {
                unsubscribe();
                Dialog.fail(err.toString() || '支付失败');
              });
            // 订阅盲盒结果
            return subscribeResult();
          }
          return res;
        });
    },
    {
      manual: true,
      onSuccess(res, [, {close} = {}] = []) {
        if (res?.success) {
          // Dialog.success(res.msg || '购买成功');
          close();
          navigation.push('Box', {
            screen: 'BoxResultModal',
            params: {id: id, data: res?.data},
          });
        } else {
          if (res?.code === 499) {
            // 取消订阅后返回499
          } else {
            Dialog.respError(res);
          }
        }
      },
    },
  );
  const modalActionRef = useRef({});
  modalActionRef.current.onClose = useCallback(() => {
    if (paying) {
      unsubscribe();
      Dialog.confirm('', '正在查询支付状态，确认离开？', [
        {
          text: '支付失败',
          onPress() {
            confirmResult(false);
          },
        },
        {
          text: '已完成支付',
          onPress() {
            confirmResult(true);
          },
        },
      ]);
    }
  }, [confirmResult, paying, unsubscribe]);
  const openModal = useDynamicModal(
    CommonTradeModal,
    {
      renderDisplay(__, {multiple}) {
        return (
          <View style={styles.boxCard}>
            <BoxMeta
              style={{flex: 1}}
              data={data}
              card={false} //
              content={(_data, {styles: _styles}) => (
                <Text style={_styles.priceAmount}>¥{multiple ? _data?.batchAmount : _data?.amount}</Text>
              )}
            />
          </View>
        );
      },
      submit,
      deductEnable,
      onClose() {
        modalActionRef?.current?.onClose?.();
      },
    },
    {
      onBeforeRemove: evt => {
        if (paying) {
          isAndroid() && evt.preventDefault();
          modalActionRef?.current?.onClose?.();
        }
      },
      closeOnBlur: false,
      global: false,
    },
  );

  const {batchAmount, amount} = data || {};

  return (
    <LoadingView layer={true} hideIndicator={paying} style={styles.container}>
      <LinearGradient
        style={styles.bg}
        colors={['rgba(255,255,255,0)', 'rgba(255,255,255,1)']}
        start={{x: 0, y: 0}}
        end={{x: 0, y: 1}}
        backgroundImage={'linear-gradient(to bottom, rgba(255,255,255,0) 0, rgba(255,255,255,1)  100%)'}
      />
      <Flex style={{marginLeft: -11}}>
        <Button
          type="primary"
          fontWeight={FontWeight.SemiBold}
          style={styles.button}
          loading={submitting}
          onPress={() => openModal({params: {multiple: false, amount: amount}})}>
          {textStyle => (
            <>
              {!submitting && (
                <>
                  <Text style={[textStyle, styles.priceText]}>¥{Number(amount).toFixed(1)}</Text>
                  <View style={[styles.divider, {borderColor: StyleSheet.flatten(textStyle)?.color}]} />
                </>
              )}
              <Text style={[textStyle]}>一发入魂</Text>
            </>
          )}
        </Button>
        {!singleOnly && (
          <Button
            type="primary"
            fontWeight={FontWeight.SemiBold}
            color="#00ffbc"
            style={[styles.button]}
            loading={submitting}
            onPress={() => openModal({params: {multiple: true, amount: batchAmount}})}>
            {textStyle => (
              <>
                {!submitting && (
                  <>
                    <Text style={[textStyle, styles.priceText]}>¥{Number(batchAmount).toFixed(1)}</Text>
                    <View style={[styles.divider, {borderColor: StyleSheet.flatten(textStyle)?.color}]} />
                  </>
                )}
                <Text style={[textStyle]}>5开不重</Text>
              </>
            )}
          </Button>
        )}
      </Flex>
    </LoadingView>
  );
};
const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    height: 68,
    padding: theme.h_spacing_lg,
    justifyContent: 'flex-end',
  },
  bg: {
    height: 140,
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
  },
  button: {
    flex: 1,
    height: 48,
    marginLeft: 11,
  },
  priceText: {
    ...getDINProBoldStyle(),
    fontSize: 14,
    lineHeight: 17,
  },
  divider: {
    borderLeftWidth: 1,
    height: 12,
    marginVertical: 3,
    marginHorizontal: 13,
  },

  boxCard: {
    borderWidth: StyleSheet.hairlineWidth,
    borderColor: theme.border_color_base,
    borderRadius: 4,
    padding: theme.h_spacing_md,
    backgroundColor: theme.fill_body,
    height: 155,
  },
});

export default BoxFooterBar;
