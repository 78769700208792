import {LoadingView} from '@/components';
import Empty from '@/components/Empty';
import ToolCard from '@/pages/tools/components/ToolCard';
import {useDispatch, useSelector} from '@/utils/dva';
import {useNavigation} from '@react-navigation/core';
import {useRequest} from 'ahooks';
import {isFunction} from 'lodash';
import React, {isValidElement} from 'react';
import {FlatList, StyleSheet, TouchableOpacity, View} from 'react-native';

const DefaultEmpty = () => {
  const navigation = useNavigation();
  return (
    <Empty
      style={{backgroundColor: 'transparent'}}
      text="暂时还没有道具~去逛逛获取更多"
      actions={[
        {
          text: '去逛逛',
          onPress: () => navigation.navigate('BoxCupboardTab'),
        },
      ]}
    />
  );
};
const ToolList = ({status, current, onPress, contentContainerStyle, EmptyComponent = DefaultEmpty}) => {
  const initialData = useSelector(s => s.tools[status ? `${status}_data` : 'data']);
  const dispatch = useDispatch();
  const {data, loading} = useRequest(
    () =>
      dispatch({
        type: 'tools/getTools', //
        status,
      }),
    {
      initialData,
      formatResult: res => res?.data,
    },
  );
  const Empty = isValidElement(EmptyComponent) ? EmptyComponent : <EmptyComponent />;
  return (
    <LoadingView loading={loading} style={styles.container}>
      {!loading && !data?.length && Empty}
      <FlatList
        contentContainerStyle={[styles.list, contentContainerStyle]}
        initialNumToRender={10}
        data={data}
        numColumns={2}
        renderItem={({item}) => {
          if (isFunction(onPress)) {
            return (
              <TouchableOpacity onPress={() => onPress(item)} style={styles.column}>
                <ToolCard data={item} style={current?.id === item?.id && styles.current} />
              </TouchableOpacity>
            );
          } else {
            return (
              <View style={styles.column}>
                <ToolCard data={item} />
              </View>
            );
          }
        }}
        columnWrapperStyle={styles.row}
        // ListFooterComponent={loading && <Loading />}
      />
    </LoadingView>
  );
};
const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  list: {
    padding: 12,
    paddingBottom: 12 - 5,
  },
  row: {
    marginLeft: -5,
    marginBottom: 5,
  },
  column: {
    flex: 1,
    maxWidth: '50%',
    paddingLeft: 5,
  },
  current: {
    borderWidth: 2,
    borderColor: '#FF3865',
    backgroundColor: 'rgba(255,51,97,0.20)',
  },
});

export default ToolList;
