import BoxList from '@/pages/box/components/BoxList';
import React from 'react';
import {View} from 'react-native';

const BoxListPage = () => {
  return (
    <View style={{flex: 1}}>
      <BoxList loadingView={true} />
    </View>
  );
};
BoxListPage.screenName = 'BoxList';
BoxListPage.screenOptions = {
  title: '口袋盲盒',
};
export default BoxListPage;
