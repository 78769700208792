/**
 *  options: {
 *    mapValues: (o)=>o.value || <string>key || bool
 *  }
 *  */
import FrozenMap from './FrozenMap';

const defaultOptions = {
  mapValues: false,
};
export default function toMap(options) {
  return function (_class) {
    return FrozenMap(_class, {...defaultOptions, ...options});
  };
}
