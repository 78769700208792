import React from 'react';
import {Text} from 'react-native';

const SaleCountText = ({count, style}) => {
  count = parseInt(count);
  let text = 0;
  if (count > 10000) {
    text = Math.floor(count / 10000) + 'w+';
  } else if (count > 1000) {
    text = Math.floor(count / 1000) + '000+';
  } else if (count > 100) {
    text = Math.floor(count / 100) + '00+';
  } else if (count >= 0) {
    text = '100+';
  }
  return <Text style={style}>{text}</Text>;
};

export default React.memo(SaleCountText);
