import List from '@/components/List';
import useGlobalCallback from '@/utils/hooks/useGlobalCallback';
import {isIos} from '@/utils/utils';
import cityData from '@bang88/china-city-data';
import _ from 'lodash';
import React from 'react';
import {ScrollView} from 'react-native';

const ProvincePicker = ({navigation, route}) => {
  const id = route?.params?.id;
  const callbackRef = useGlobalCallback(id);
  const handlePress = item => {
    const isCity = item?.children?.length === 1; // 直辖市
    callbackRef?.current?.callback?.({
      navigation,
      value: {
        province: {
          text: item?.label,
          value: item?.value,
          isCity,
        },
        city: isCity && item.children[0],
      },
    });
  };
  return (
    <ScrollView style={{flex: 1}}>
      {cityData?.length && (
        <List>
          {_.map(cityData, (item, i) => {
            const {value, label} = item || {};
            return (
              <List.Item key={`$item_${value}`} arrow="right" onPress={handlePress.bind({}, item)}>
                {label}
              </List.Item>
            );
          })}
        </List>
      )}
    </ScrollView>
  );
};

ProvincePicker.modalName = 'ProvincePicker';
ProvincePicker.screenOptions = {
  title: '选择省份',
  animation: 'slide_from_right',
  presentation: isIos() ? 'card' : 'fullScreenModal',
};
export default ProvincePicker;
