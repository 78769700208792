import BoxBomb from '@/pages/box/detail/components/BoxBomb';
import BoxReady from '@/pages/box/detail/components/BoxReady';
import BoxResultBar from '@/pages/box/detail/components/BoxResultBar';
import FiveProdsDisplay from '@/pages/box/detail/components/FiveProdsDisplay';
import ProdDisplay from '@/pages/box/detail/components/ProdDisplay';
import useAskTask from '@/pages/box/detail/useAskTask';
import useTranslucentBoxHeader from '@/pages/box/detail/useTranslucentBoxHeader';
import BoxRarity from '@/utils/localData/BoxRarity';
import {isWeb} from '@/utils/utils';
import _ from 'lodash';
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {Animated, ImageBackground, Platform, StyleSheet, View} from 'react-native';

const assets_open_box = require('../assets/open_box.gif');
const getRarity = data => {
  const values = _.map(data, 'style');
  if (_.includes(values, BoxRarity.Supreme)) {
    return BoxRarity.Supreme;
  } else if (_.includes(values, BoxRarity.Rare)) {
    return BoxRarity.Rare;
  } else if (_.includes(values, BoxRarity.Hidden)) {
    return BoxRarity.Hidden;
  }
  return _.first(values);
};
const BoxResultModal = ({navigation, route}) => {
  const {id, demo, data: initialData} = route?.params || {};
  const {run: onShare} = useAskTask(id);
  useTranslucentBoxHeader(navigation, {onShare});
  const [data, mutate] = useState(initialData);
  const [opened, setOpened] = useState(false);
  const [visible, setVisible] = useState(false);
  const contentOpacity = useRef(new Animated.Value(0)).current;
  const openBoxGif = useMemo(() => {
    if (isWeb()) {
      // todo: web上因缓存的问题是否需要降级处理
      return assets_open_box + `?${Date.now()}`;
    }
    return assets_open_box;
  }, []);

  const showResult = useCallback(() => {
    setVisible(true);
    Animated.timing(contentOpacity, {
      toValue: 1,
      duration: 1000,
      useNativeDriver: !isWeb(),
    }).start();
  }, [contentOpacity]);

  useEffect(() => {
    if (opened) {
      const id = setTimeout(() => {
        showResult();
      }, 3000 - 320); // 减去8帧让动画衔接流畅些
      return () => clearTimeout(id);
    }
  }, [opened, showResult]);

  const open = () => {
    setOpened(true);
  };

  return (
    <View style={[styles.page]}>
      {!opened ? (
        <BoxReady open={open} />
      ) : (
        <>
          {!visible ? (
            <ImageBackground source={openBoxGif} resizeMode="contain" style={[StyleSheet.absoluteFill]} />
          ) : (
            <BoxBomb rarity={getRarity(data)} visible={visible} style={{flex: 1}}>
              <Animated.ScrollView style={{opacity: contentOpacity}}>
                <View style={{height: 608}}>
                  {data?.length === 1 && (
                    <ProdDisplay
                      data={_.first(data)}
                      navigation={navigation}
                      showPrice={false}
                      imageBgStyle={{
                        marginTop: 162,
                        marginBottom: 40,
                      }}
                    />
                  )}
                  {data?.length === 5 && <FiveProdsDisplay data={data} />}
                </View>
                <BoxResultBar navigation={navigation} demo={demo} data={data} mutate={mutate} />
              </Animated.ScrollView>
            </BoxBomb>
          )}
        </>
      )}
    </View>
  );
};
const styles = StyleSheet.create({
  page: {
    flex: 1,
    backgroundColor: '#030406',
    ...Platform.select({
      web: {
        marginTop: -28,
      },
    }),
  },
});

BoxResultModal.modalName = 'BoxResultModal';
BoxResultModal.screenOptions = {
  title: '',
  headerShown: false,
  presentation: 'containedTransparentModal',
  animation: 'fade',
};
BoxResultModal.linking = {
  path: 'result/:id',
  parse: {
    id: Number,
  },
};
export default BoxResultModal;
