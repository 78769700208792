import {useRequest} from 'ahooks';
import _ from 'lodash';
import React, {useCallback, useState} from 'react';

const usePagedRequest = (service, options) => {
  const {
    pageSize: _pageSize,
    defaultParams,
    formatData,
    onSuccess,
    onBeforeRefresh,
    uniqueKey = 'id',
    ...restOptions
  } = options || {};
  const [data, setData] = useState();
  const {run, pagination, loading, ...rest} = useRequest(service, {
    ...restOptions,
    defaultParams: _.concat([{current: 1, pageSize: _pageSize ?? 10}], defaultParams),
    paginated: true,
    formatResult: res => {
      return {
        // ...res?.data, // {list, total}
        ...(_.isFunction(formatData) ? formatData(res?.data) : res?.data),
        ..._.pick(res, ['success', 'msg', 'code']),
      };
    },
    onSuccess: function (res, [params] = []) {
      const {success, list, total, current} = res || {};
      if (success) {
        const newData = current === 1 ? list ?? [] : _.unionBy(data ?? [], list ?? [], uniqueKey);
        setData(newData);
      }
      _.isFunction(onSuccess) && onSuccess.apply(this, arguments);
    },
  });
  const {current, pageSize: __pageSize, totalPage} = pagination || {};
  const pageSize = _pageSize ?? __pageSize;
  const hasMore = current < totalPage;
  const load = useCallback((...args) => run({current: 1, pageSize}, ...args), [pageSize, run]);
  const loadMore = useCallback(
    async (...args) => {
      if (!hasMore) {
        return false;
      }
      return run({current: current + 1, pageSize}, ...args);
    },
    [current, hasMore, pageSize, run],
  );
  const [refreshing, setRefreshing] = useState(false);
  const refresh = useCallback(
    async (...args) => {
      setRefreshing(true);
      try {
        if (_.isFunction(onBeforeRefresh)) {
          await onBeforeRefresh();
        }
        const res = await load(...args);
        setRefreshing(false);
        return res;
      } catch (err) {
        setRefreshing(false);
      }
    },
    [load, onBeforeRefresh, setRefreshing],
  );

  return {
    ...rest,
    data,
    mutate: setData,
    pagination,
    hasMore,
    load,
    loading: current <= 1 && loading && !refreshing,
    loadMore,
    loadingMore: current > 1 && loading,
    refresh,
    refreshing,
  };
};

export default usePagedRequest;
