import {useNavigation} from '@react-navigation/core';
import React from 'react';

const useMergedNavigation = navigation => {
  const _navigation = useNavigation();
  navigation = navigation ?? _navigation;
  return navigation;
};

export default useMergedNavigation;
