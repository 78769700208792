import {LoadingView} from '@/components';
import Empty from '@/components/Empty';
import Loading from '@/components/Loading';
import BoxProdCard from '@/pages/box/detail/components/BoxProdCard';
import {useDispatch} from '@/utils/dva';
import {useRequest} from 'ahooks';
import _ from 'lodash';
import React, {useEffect, useRef} from 'react';
import {FlatList, Pressable, StyleSheet} from 'react-native';

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  list: {
    padding: 12,
    paddingBottom: 12 - 5,
  },
  row: {marginLeft: -5, marginBottom: 5},
  column: {
    flex: 1,
    maxWidth: '50%',
    paddingLeft: 5,
  },
  card: {
    flex: 1,
  },
});

const BoxProdList = ({ListHeaderComponent, data: initialData, id, rarity, onPressBoxProd}) => {
  const dispatch = useDispatch();
  const {data, loading} = useRequest(() => dispatch({type: 'box/getBoxProds', id, rarity}), {
    initialData,
    ready: !!id,
    refreshDeps: [id, rarity],
    formatResult: res => res?.data,
  });

  const listRef = useRef();

  useEffect(() => {
    listRef?.current?.scrollToOffset?.({offset: 0, animated: false});
  }, [rarity]);

  const _data = !rarity ? data : _.filter(data, item => item?.style === rarity);
  return (
    <LoadingView loading={loading} layer={!!data?.length} style={styles.container}>
      {!loading && !data?.length && <Empty style={{backgroundColor: 'transparent'}} />}
      <FlatList
        ref={listRef}
        contentContainerStyle={styles.list}
        ListHeaderComponent={ListHeaderComponent}
        initialNumToRender={10}
        data={_data}
        numColumns={2}
        renderItem={({item}) => (
          <Pressable style={styles.column} onPress={() => onPressBoxProd(item)}>
            <BoxProdCard data={item} style={styles.card} />
          </Pressable>
        )}
        columnWrapperStyle={styles.row}
        ListFooterComponent={loading && <Loading />}
      />
    </LoadingView>
  );
};

export default BoxProdList;
