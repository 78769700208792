import {create} from 'dva-core';
import {connect, Provider, useDispatch, useSelector, useStore} from 'react-redux';
import {persistReducer, persistStore} from 'redux-persist';
import storage from './storage';

export {connect, Provider, useDispatch, useSelector, useStore};

let dva;
const persistConfig = {
  key: 'persist',
  storage: storage,
  whitelist: ['global', 'report', 'activity_popup', 'search'],
  version: 4,
};
const persistEnhancer = () => createStore => (reducer, initialState, enhancer) => {
  const store = createStore(persistReducer(persistConfig, reducer), initialState, enhancer);
  const persist = persistStore(store);
  return {...store, persist};
};

function createDvaApp(options) {
  if (!dva) {
    dva = create({
      onError(e) {
        console.warn('dva: ', e);
      },
    });

    dva.use({
      extraEnhancers: [persistEnhancer()],
    });

    if (!global.registered) {
      const {models} = options || {};
      models?.forEach(model => dva.model(model));
    }

    global.registered = true;

    dva.start();

    const store = dva._store;

    dva.start = container => () => ({container});

    dva.getStore = () => store;
  }
  return dva;
}

const getStore = () => dva?.getStore?.();
const getDispatch = () => dva?.getStore?.()?.dispatch;

export {getStore, getDispatch};
export default createDvaApp;

global._getStore = getStore;
