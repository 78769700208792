import createMaterialTopTabNavigator from '@/components/material-top-tabs/navigators/createMaterialTopTabNavigator';
import HistoryList from '@/pages/coupon/components/HistoryList';
import theme from '@/theme';
import useNestingBackButton from '@/utils/hooks/useNestingBackButton';
import _ from 'lodash';
import React from 'react';
import {StyleSheet} from 'react-native';

const Tab = createMaterialTopTabNavigator();
const tabComponents = {};
const getTabComponent = (key, _props) => {
  if (!tabComponents[key]) {
    tabComponents[key] = React.memo(props => <HistoryList {...props} {..._props} status={key} />);
  }
  return tabComponents[key];
};
const tabs = [
  {text: '已使用', value: 'used'},
  {text: '已失效', value: 'expire'},
];

const styles = StyleSheet.create({
  sceneContainerStyle: {
    justifyContent: 'center',
  },
  tabBarIndicatorContainerStyle: {
    transform: [{translateX: -130}],
    left: '50%',
  },
  tabBarIndicatorStyle: {
    backgroundColor: theme.brand_primary,
    width: 42,
    marginHorizontal: (130 - 42) / 2,
  },
  tabBarContentContainerStyle: {
    justifyContent: 'center',
  },
  tabBarItemStyle: {
    width: 130,
    minHeight: 46,
    padding: 0,
  },
});
const CouponHistoryPage = ({navigation, route}) => {
  useNestingBackButton();
  return (
    <Tab.Navigator
      backBehavior="back"
      sceneContainerStyle={styles.sceneContainerStyle}
      screenOptions={{
        // tabBarScrollEnabled: true,
        tabBarIndicatorContainerStyle: styles.tabBarIndicatorContainerStyle,
        tabBarIndicatorStyle: styles.tabBarIndicatorStyle,
        tabBarContentContainerStyle: styles.tabBarContentContainerStyle,
        tabBarItemStyle: styles.tabBarItemStyle,
        tabBarInactiveTintColor: theme.color_text_secondary,
        lazy: true,
        lazyPreloadDistance: 0,
      }}>
      {_.map(tabs, (tab, i) => {
        const {text, value} = tab || {};
        return (
          <Tab.Screen
            key={`$toolsTab_${value}`}
            name={value}
            options={{
              title: '历史优惠券',
              tabBarLabel: text,
            }}
            component={getTabComponent(value)}
          />
        );
      })}
    </Tab.Navigator>
  );
};

CouponHistoryPage.screenName = 'CouponHistory';
CouponHistoryPage.screenOptions = {
  title: '历史优惠券',
};
CouponHistoryPage.linking = {
  path: 'history',
  screens: _.chain(tabs)
    .keyBy('value')
    .mapValues((v, key) => key)
    .value(),
};
export default CouponHistoryPage;
