export default class FontWeight {
  static Thin = '100';
  static ExtraLight = '200';
  static Light = '300';
  static Regular = '400';
  static Medium = '500';
  static SemiBold = '600';
  static Bold = '700';
  static ExtraBold = '800';
  static Black = '900';
}
