import {getTools} from './service';
import _ from 'lodash';

export const namespace = 'tools';
const initialState = {
  data: [],
};
const model = {
  namespace,
  state: initialState,
  effects: {
    // /**
    //  * 道具分页查询
    //  * @actionParam status: 道具状态
    //  * @actionParam lastId
    //  * */
    // *getPagedTools({status, lastId}, {call, put}) {
    //   const res = yield call(getPagedTools, {status, lastId});
    //   if (res?.success) {
    //     yield put({type: 'addData', status, data: res?.data});
    //   }
    //   return res;
    // },

    *getTools({status}, {call, put}) {
      const res = yield call(getTools, status);
      if (res?.success) {
        if (status) {
          yield put({type: 'saveState', [status + '_data']: res?.data});
        } else {
          yield put({type: 'saveState', data: res?.data});
        }
      }
      return res;
    },
  },
  reducers: {
    saveState(state, action) {
      const {type, ...nextState} = action || {};
      return {...state, ...nextState};
    },
    clear(state, action) {
      return _.clone(initialState);
    },
  },
};

export default model;
