import request, {getPagedParams} from '@/utils/request';
import _ from 'lodash';

/**
 * 获取商城首页标签列表
 * @resp data: [{text, value}, ...]
 * */
export function getMallTabs() {
  return request('/server/shopping/getBigCategoryListLimit', {
    method: 'GET',
  }).then(res => {
    if (res?.success) {
      res.data = _.map(res.data, ({id, name}) => ({value: id, text: name}));
    }
    return res;
  });
}

/**
 * 查询商城首页单个tab页内数据
 * @param tabId
 * @resp: 返回单个大类下配置的banner, 承诺信息，子类列表，第二个banner, 第一页的商品
 */
export function getMallTabData(tabId) {
  return request('/server/shopping/index', {
    method: 'GET',
    params: {
      bigCategoryId: tabId,
    },
  }).then(res => {
    if (res?.success) {
      res.data = _.pick(res.data, ['productBanner', 'smallCategoryList', 'boxBanner', 'productBoxes']);
      if (res.data?.productBoxes?.records) {
        res.data.productBoxes = res.data.productBoxes.records;
      }
    }
    return res;
  });
}

/**
 * 分页获取商城商品
 * @param params: {
 *   current,
 *   pageSize,
 *   tab
 * }
 * */
export function getPagedProds(params) {
  return request('/server/shopping/page', {
    method: 'GET',
    params: getPagedParams({
      ...params,
      bigCategoryId: params?.tab,
    }),
  });
}
