import Swiper from '@/components/Swiper';
import theme from '@/theme';
import {useImageResize} from '@/utils/remoteData/ImageResize';
import {isWeb} from '@/utils/utils';
import {useLinkTo} from '@react-navigation/native';
import {assign, isArray, merge} from 'lodash';
import React from 'react';
import {Image, Linking, StyleSheet, View, Dimensions, useWindowDimensions} from 'react-native';

// const gutter = theme.h_spacing_lg - 8;
const gutter = 21;
const styles = StyleSheet.create({
  container: {
    paddingHorizontal: theme.h_spacing_lg - gutter / 2,
    // overflow: 'visible',
  },
  slide: {
    paddingHorizontal: gutter / 2,
  },
  slideImage: {
    flex: 1,
    width: '100%',
    borderRadius: 2,
    backgroundColor: theme.fill_grey,
  },
});

const Component = ({section, data}) => {
  const linkTo = useLinkTo();
  const {width} = useWindowDimensions();
  const heightStyle = section?.height && {height: section.height * ((width - 40) / (375 - 40))};
  const [, resizeImage] = useImageResize({object: 'banner', size: 'lg'});

  const onPressItem = item => {
    try {
      const {jumpUrl} = item || {};
      if (jumpUrl) {
        if (String(jumpUrl).toLowerCase().startsWith('http')) {
          Linking.openURL(jumpUrl);
        } else {
          linkTo(jumpUrl);
        }
      }
    } catch (err) {
      console.warn(err);
    }
  };

  /*
   * web 上因滑动事件冲突问题，阻止了子元素对触摸事件的响应
   * 将Swiper.Item的点击事件移到Swiper上，通过activeIndex获取对应的dataItem
   * */
  const onPress = ({activeIndex} = {}) => {
    if (isWeb()) {
      onPressItem(data?.[activeIndex]);
    }
  };

  return (
    <View style={[styles.container, section.style]}>
      <View style={[heightStyle]}>
        {data?.length && (
          <Swiper {...section.swiperProps} onPress={onPress}>
            {isArray(data) &&
              data.map((item, i) => {
                const {url} = item || {};
                const image = (url && resizeImage(url)) || '';
                return (
                  <Swiper.Item key={`$slide__${i}`} style={styles.slide} onPress={() => onPressItem(item)}>
                    <Image source={{uri: image}} style={styles.slideImage} />
                  </Swiper.Item>
                );
              })}
          </Swiper>
        )}
      </View>
    </View>
  );
};

export default class BannerSection {
  static type = 'banner';
  static component = React.memo(Component);

  constructor(config) {
    const {swiperProps, ...rest} = config || {};
    this.swiperProps = merge(this.swiperProps, swiperProps);
    assign(this, rest);
  }

  get type() {
    return this?.constructor?.type;
  }

  style = {};
  height = 90;
  swiperProps = {
    autoplay: true,
    loop: true,
    indicatorProps: {},
  };
}
