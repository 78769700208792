export default [
  {icon: 'func_favorite', text: '收藏', key: 'sk', link: '/favorites'},
  {icon: 'func_address', text: '地址管理', key: 'dzgl', link: '/address'},
  {icon: 'func_notice', text: '通知', key: 'tz', link: '/notice'},
  {icon: 'func_customer_service', text: '客服', key: 'kf'},
  {icon: 'func_cupboard', text: '盲盒盒柜', key: 'mhhg'},
  {icon: 'func_exchange', text: '口令兑换', key: 'kldh'},
  // {icon: 'func_reward', text: '拉新奖励', key: 'lxjl'},
  // {icon: 'func_coupon', text: '优惠券', key: 'yhq'},
  {icon: 'func_setting', text: '设置', key: 'sz', link: '/setting'},
];
