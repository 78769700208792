import {Dialog, Flex, LoadingView} from '@/components';
import OrderPayBar from '@/pages/box/pickUp/components/OrderPayBar';
import OrderContext from '@/pages/order/detail/components/OrderContext';
import OrderSections from '@/pages/order/detail/components/OrderSections';
import AddressSection from '@/pages/order/detail/components/OrderSections/AddressSection';
import ItemsSection from '@/pages/order/detail/components/OrderSections/ItemsSection';
import RemarkSection from '@/pages/order/detail/components/OrderSections/RemarkSection';
import ProdMeta from '@/pages/product/components/ProdMeta';
import theme from '@/theme';
import {useDispatch} from '@/utils/dva';
import useOrderPay from '@/utils/hooks/useOrderPay';
import OrderStatus from '@/utils/localData/OrderStatus';
import {getOrderPrice, getPrice} from '@/utils/priceUtils';
import {useRequest, useSetState} from 'ahooks';
import _ from 'lodash';
import React from 'react';
import {ScrollView, StyleSheet, Text} from 'react-native';

const sections = [
  new AddressSection({
    mode: 'input',
  }),
  new ItemsSection({
    formatData: data => data?.orderItemDtoList,
    renderItem: item => {
      const {id, itemName, standard, quantity} = item || {};
      const [, price] = getPrice(item) || {};
      return (
        <ProdMeta
          key={`$order_item_${id}`}
          card={false}
          data={item}
          title={itemName}
          brief={`${standard} 数量 × ${quantity}`}
          content={(__, {styles: _styles}) => (
            <Flex justify="end" style={{flex: 1}}>
              <Text style={_styles.brief}>
                需要补差价<Text style={{color: theme.brand_warning}}>{price.amount}</Text>元
              </Text>
            </Flex>
          )}
        />
      );
    },
    itemStyle: {
      paddingVertical: 24,
    },
  }),
  new RemarkSection({
    mode: 'input',
    rows: 4,
  }),
];

const BoxPickUpPage = ({navigation, route}) => {
  const {id, data: initialData, callback} = route?.params || {};
  const [state, setState] = useSetState({
    address: null,
    remark: '',
    itemDoms: null,
  });
  const dispatch = useDispatch();
  const {data, loading} = useRequest(() => dispatch({type: 'order/getOrder', id}), {
    ready: !!id,
    initialData,
    formatResult: res => res?.data,
  });

  const price = getOrderPrice(data) || {};
  const payParams = {
    addressId: state?.address?.id,
    remark: state?.remark,
  };
  const [_submit, {submitting}] = useOrderPay(data, {
    defaultParams: payParams,
    itemDoms: state?.itemDoms,
    onSuccess(res) {
      if (res?.success) {
        _.isFunction(callback) && callback();
        navigation.replace('Order', {
          screen: 'OrderList',
          params: {
            screen: OrderStatus._Paid.key,
          },
        });
      }
    },
  });
  const submit = async () => {
    if (!state.address?.id) {
      return Dialog.info('请选择收货地址');
    }
    const res = await _submit(payParams);
    return res;
  };

  return (
    <LoadingView loading={loading} layer={!!data?.id} style={styles.page}>
      <ScrollView style={{flex: 1}}>
        <OrderContext.Provider value={{state, setState}}>
          <OrderSections data={data} sections={sections} />
        </OrderContext.Provider>
      </ScrollView>
      <OrderPayBar
        price={price} //
        submitting={submitting}
        submit={submit}
      />
    </LoadingView>
  );
};
const styles = StyleSheet.create({
  page: {
    flex: 1,
  },

  itemCard: {
    borderRadius: 4,
    backgroundColor: theme.fill_body,
    borderWidth: StyleSheet.hairlineWidth,
    borderColor: theme.border_color_base,
    paddingVertical: 10,
    paddingHorizontal: 10,
  },
});

BoxPickUpPage.screenName = 'BoxPickUp';
BoxPickUpPage.screenOptions = {
  title: '信息确认',
};
BoxPickUpPage.linking = 'pickup/:id';
export default BoxPickUpPage;
