'use strict';

Object.defineProperty(exports, '__esModule', {
  value: true,
});

var _reactNative = require('react-native');

exports.default = function (theme) {
  return _reactNative.StyleSheet.create({
    container: {
      height: theme.list_item_height + theme.border_width_sm,
      borderBottomWidth: _reactNative.StyleSheet.hairlineWidth,
      borderBottomColor: theme.border_color_base,
      marginLeft: theme.h_spacing_lg,
      paddingRight: theme.h_spacing_lg,
      marginTop: 0,
      marginBottom: 0,
      flexDirection: 'row',
      alignItems: 'center',
    },
    multilineContainer: {
      height: 'auto',
      paddingVertical: (theme.list_item_height - 20) / 2,
      alignItems: 'flex-start',
    },
    text: {
      marginRight: theme.h_spacing_sm,
      textAlignVertical: 'center',
      fontSize: theme.font_size_heading,
      color: theme.color_text_base,
    },
    input: {
      flex: 1,
      // height: styles.list_item_height,
      backgroundColor: 'transparent',
      fontSize: theme.input_font_size,
      color: theme.color_text_base,
    },
    multilineInput: {
      paddingVertical: 0,
      height: 'auto',
      textAlignVertical: 'top',
      paddingTop: 0,
      paddingBottom: 0,
    },
    inputDisabled: {
      backgroundColor: theme.fill_disabled,
      color: theme.color_text_disabled,
    },
    inputErrorColor: {
      color: '#f50',
    },
    clear: {
      backgroundColor: theme.color_icon_base,
      borderRadius: 15,
      padding: 2,
    },
    extra: {
      marginLeft: theme.h_spacing_sm,
      fontSize: theme.font_size_subhead,
      color: theme.color_text_caption,
    },
    errorIcon: {
      marginLeft: theme.h_spacing_sm,
      width: theme.icon_size_sm,
      height: theme.icon_size_sm,
    },
  });
};

module.exports = exports.default;
