import {Flex} from '@/components/Flex';
import {getPrice} from '@/utils/priceUtils';
import theme from '@/theme';
import FontWeight from '@/utils/localData/FontWeight';
import {useImageResize} from '@/utils/remoteData/ImageResize';
import {getDINProBoldStyle} from '@/utils/styleUtils';
import {isFunction, isString} from 'lodash';
import React, {isValidElement} from 'react';
import {Image, StyleSheet, Text, View} from 'react-native';

const styles = StyleSheet.create({
  container: {},
  cardContainer: {
    paddingVertical: 16,
    paddingHorizontal: theme.h_spacing_lg,
    backgroundColor: '#fff',
  },
  thumb: {
    width: 135,
    height: 135,
    // borderWidth: StyleSheet.hairlineWidth,
    // borderColor: theme.border_color_base,
    borderRadius: 2,
    marginRight: 15,
    // backgroundColor: theme.fill_grey,
  },
  right: {
    flex: 1,
    // minHeight: 88,
  },
  rightBody: {
    width: '100%',
    justifyContent: 'space-between',
  },
  rightFooter: {
    width: '100%',
    justifyContent: 'space-between',
    marginTop: 13,
  },
  title: {
    fontSize: 16,
    fontWeight: FontWeight.SemiBold,
  },
  brief: {
    fontSize: 12,
    color: theme.color_text_secondary,
    marginTop: 9,
  },
  content: {},
  extra: {
    color: theme.color_text_secondary,
  },
  price: {
    fontWeight: FontWeight.Bold,
    fontSize: 16,
  },
  priceAmount: {
    ...getDINProBoldStyle(20),
    lineHeight: 25,
  },
  priceUnit: {
    fontSize: 18,
    fontWeight: FontWeight.SemiBold,
  },
});
const Thumb = ({data, value, children}) => {
  if (isFunction(value)) {
    return value(data, {style: styles.thumb, styles});
  }
  if (isString(value)) {
    return <Image source={{uri: value}} style={styles.thumb} />;
  }
  if (isValidElement(value)) {
    return value;
  }

  if (isString(children)) {
    return <Image source={{uri: children}} style={styles.thumb} />;
  }
  return children;
};
const Title = ({data, value, children}) => {
  if (isFunction(value)) {
    return value(data, {style: styles.title, styles});
  }
  if (value) {
    return (
      <Text numberOfLines={2} style={styles.title}>
        {value}
      </Text>
    );
  }
  return (
    <Text numberOfLines={2} style={styles.title}>
      {children}
    </Text>
  );
};
const Brief = ({data, value, children}) => {
  if (isFunction(value)) {
    return value(data, {style: styles.brief, styles});
  }
  if (value) {
    return (
      <Text numberOfLines={2} style={styles.brief}>
        {value}
      </Text>
    );
  }
  return (
    <Text numberOfLines={2} style={styles.brief}>
      {children}
    </Text>
  );
};
const Content = ({data, value, children}) => {
  if (isFunction(value)) {
    return value(data, {style: styles.content, styles, defaultDom: children});
  }
  if (isString(value)) {
    return <Text style={styles.content}>{value}</Text>;
  }
  if (isValidElement(value)) {
    return value;
  }
  if (isString(children)) {
    return <Text style={styles.content}>{children}</Text>;
  }
  return children;
};
const Extra = ({data, value, children}) => {
  if (isFunction(value)) {
    return value(data, {style: styles.extra, styles});
  }
  if (value) {
    return <Text style={styles.extra}>{value}</Text>;
  }

  return <Text style={styles.extra}>{children}</Text>;
};
const BoxMeta = ({data, card = true, style, thumb, title, brief, content, extra}) => {
  const {name, detail, picture} = data || {};
  const [, rmbPrice] = getPrice(data);
  const [, resizeImage] = useImageResize({object: 'box', size: 'sm'});

  return (
    <Flex align="stretch" style={[styles.container, card && styles.cardContainer, style]}>
      <Thumb data={data} value={thumb}>
        <Image source={{uri: resizeImage(picture)}} style={styles.thumb} />
      </Thumb>
      <Flex direction="column" justify="between" align="start" style={styles.right}>
        <View style={styles.rightBody}>
          <Title data={data} value={title}>
            {name}
          </Title>
          <Brief data={data} value={brief}>
            {detail}
          </Brief>
        </View>
        <Flex justify="between" align="end" style={styles.rightFooter}>
          <Content data={data} value={content}>
            <Text style={styles.price}>
              <Text style={styles.priceAmount}>{rmbPrice.text()}</Text>
            </Text>
          </Content>
          <Extra data={data} value={extra} />
        </Flex>
      </Flex>
    </Flex>
  );
};
BoxMeta.Thumb = Thumb;
BoxMeta.Title = Title;
BoxMeta.Brief = Brief;
BoxMeta.Content = Content;
BoxMeta.Extra = Extra;
export default BoxMeta;
