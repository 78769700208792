import React from 'react';
import {StatusBar} from 'react-native';

export const DefaultStatusBar = props => {
  return <StatusBar backgroundColor="#ffffff" translucent={false} animated={true} barStyle="dark-content" {...props} />;
};

export const TranslucentStatusBar = props => {
  return (
    <StatusBar backgroundColor="transparent" translucent={true} animated={true} barStyle="dark-content" {...props} />
  );
};
