import {LoadingView} from '@/components';
import BoxFooterBar from '@/pages/box/detail/components/BoxFooterBar';
import BoxRecommends from '@/pages/box/detail/components/BoxRecommends';
import BoxSections from '@/pages/box/detail/components/BoxSections';
import useAskTask from '@/pages/box/detail/useAskTask';
import useTranslucentBoxHeader from '@/pages/box/detail/useTranslucentBoxHeader';
import theme from '@/theme';
import {useDispatch} from '@/utils/dva';
import useBottomInset from '@/utils/hooks/useBottomInset';
import {getImageResize} from '@/utils/remoteData/ImageResize';
import {useRequest} from 'ahooks';
import _ from 'lodash';
import React, {useCallback, useState} from 'react';
import {Image, Platform, StyleSheet, View} from 'react-native';
import BoxPageLayoutContext from './components/BoxPageLayoutContext';

const BoxDetailPage = ({navigation, route}) => {
  const id = route?.params?.id;
  const {run: onShare} = useAskTask(id);
  const {onScroll} = useTranslucentBoxHeader(navigation, {onShare});
  const [layout, setLayout] = useState(null);
  const [, , , redress] = useBottomInset(20);

  const dispatch = useDispatch();
  const {data, loading} = useRequest(
    () =>
      dispatch({type: 'box/getBox', id}).then(res => {
        // 预加载第一张展示图
        try {
          const size = getImageResize()?.box_image_lg?.value;
          if (res?.success && res?.data?.boxProductList?.length && size) {
            const {boxPicture: firstImage} = _.first(res.data.boxProductList) || {};
            return Image.prefetch(firstImage + '?' + size).then(() => res);
          }
        } catch (e) {}
        return res;
      }),
    {
      formatResult: res => res?.data,
    },
  );

  const ListHeaderComponent = useCallback(
    () => [<BoxSections key="$sections" data={data} />, <BoxRecommends.ListHead key="$listHead" />],
    [data],
  );

  return (
    <LoadingView loading={loading} style={[styles.page]} onLayout={evt => setLayout(evt.nativeEvent.layout)}>
      <View style={[styles.container, {marginBottom: redress}]}>
        <BoxPageLayoutContext.Provider value={layout}>
          <BoxRecommends
            boxId={id}
            ListHeaderComponent={ListHeaderComponent}
            onScroll={onScroll}
            scrollEventThrottle={16}
          />
          <BoxFooterBar data={data} />
        </BoxPageLayoutContext.Provider>
      </View>
    </LoadingView>
  );
};
BoxDetailPage.screenName = 'BoxDetail';
BoxDetailPage.screenOptions = {
  title: '口袋盲盒',
  headerShown: false,
};
BoxDetailPage.linking = {
  path: 'detail/:id',
  parse: {
    id: Number,
  },
  screens: {
    BoxResultModal: 'result',
  },
};
export default BoxDetailPage;

const styles = StyleSheet.create({
  page: {
    flex: 1,
    // backgroundColor: '#030406',
    backgroundColor: '#fff',
    ...Platform.select({
      web: {
        marginTop: -28,
      },
    }),
  },
  container: {
    flex: 1,
    backgroundColor: theme.fill_body,
  },
});
