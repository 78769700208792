import toMap from '@/utils/FrozenMap/toMap';
import {addToStateData} from '@/utils/remoteData';
import {isWeb} from '@/utils/utils';

@addToStateData('RmbPayType')
@toMap({mapValues: v => v?.value})
export default class RmbPayType {
  static Alipay = {key: 'alipay', text: '支付宝', value: isWeb() ? 'zfb_h5' : 'zfb', icon: 'alipay'};
  static Wxpay = {key: 'wxpay', text: '微信支付', value: isWeb() ? 'wx_h5' : 'wx', icon: 'wxpay'};
}
