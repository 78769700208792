import theme from '@/theme';
import useEasingInOut from '@/utils/hooks/useEasingInOut';
import {useMount} from 'ahooks';
import {isFunction} from 'lodash';
import React, {useEffect, useState} from 'react';
import {Animated, Easing, PanResponder, Pressable, StyleSheet, View} from 'react-native';

const defaultProps = {
  checked: undefined,
  disabled: false,
  onChange: () => {},
  color: theme.brand_primary,
  onPress: () => {},
};

const Switch = props => {
  const {checked, disabled, onChange, color, onPress, style} = Object.assign({}, defaultProps, props);
  const [_checked, setChecked] = useState(checked ?? false);
  const [mounted, setMounted] = useState(false);
  const [animValue, on, off] = useEasingInOut(0, 16);
  const slideX = animValue.interpolate({
    inputRange: [-1, 0, 16, 17],
    outputRange: [0, 0, 16, 16],
  });
  const bgColor = slideX.interpolate({
    inputRange: [0, 16],
    outputRange: [StyleSheet.flatten(styles.bg).backgroundColor ?? '#999', color ?? theme.brand_primary],
  });

  const [panResponder, setPanResponder] = useState();
  useEffect(() => {
    setPanResponder(
      PanResponder.create({
        // onStartShouldSetPanResponder: () => true,
        // onStartShouldSetPanResponderCapture: () => true,
        onMoveShouldSetPanResponder: () => true,
        // onMoveShouldSetPanResponderCapture: () => true,
        onPanResponderGrant: () => {
          animValue.setOffset(animValue._value);
        },
        onPanResponderMove: Animated.event([null, {dx: animValue}], {useNativeDriver: false}),
        onPanResponderRelease: (e, gesture) => {
          animValue.flattenOffset();
          const __checked = slideX.__getValue() > 8;
          setChecked(__checked);
          __checked
            ? on(true, null, {duration: 100, easing: Easing.ease})
            : off(true, null, {duration: 100, easing: Easing.ease});
        },
      }),
    );
  }, [animValue]);

  useMount(() => {
    _checked ? on(false) : off(false);
    setMounted(true);
  });

  useEffect(() => {
    if (mounted) {
      _checked ? on() : off();
    }
    isFunction(onChange) && onChange(_checked);
  }, [_checked]);

  const handlePress = () => {
    isFunction(onPress) && onPress(_checked);
    if (disabled) {
      return;
    }
    setChecked(!_checked);
  };

  const bgColorStyle = _checked ? {backgroundColor: color} : {};

  return (
    <View style={[styles.container, style]} {...panResponder?.panHandlers}>
      <Pressable onPress={handlePress}>
        <Animated.View style={[styles.bg, bgColorStyle, {backgroundColor: bgColor}]}>
          <Animated.View style={[styles.slide, {left: slideX}]} />
        </Animated.View>
      </Pressable>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: 40,
    height: 24,
  },
  bg: {
    width: 40,
    height: 24,
    borderRadius: 20,
    backgroundColor: '#F1F2F4',
    padding: 2,
  },
  slide: {
    width: 20,
    height: 20,
    borderRadius: 20,
    backgroundColor: '#fff',
    // position: 'absolute',
    // left: 0,
    // top: 0,
  },
});

export default Switch;
