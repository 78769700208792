'use strict';

var _interopRequireDefault = require('@babel/runtime/helpers/interopRequireDefault');

Object.defineProperty(exports, '__esModule', {
  value: true,
});
Object.defineProperty(exports, 'default', {
  enumerable: true,
  get: function get() {
    return _DatePicker.default;
  },
});

var _DatePicker = _interopRequireDefault(require('./DatePicker'));
