import {copy, Dialog, LoadingView} from '@/components';
// import ShareModal from '@/components/_modals/ShareModal';
import {IconButton} from '@/components/Icon';
import Loading from '@/components/Loading';
import SwipeAction from '@/components/SwipeAction';
import XInstall from '@/NativeModules/XInstall';
import ProdRecommends from '@/pages/favorites/components/ProdRecommends';
import ProdMeta from '@/pages/product/components/ProdMeta';
import {useDispatch, useSelector} from '@/utils/dva';
import useLov from '@/utils/hooks/useLov';
// import useDynamicModal from '@/utils/hooks/useDynamicModal';
import usePagedRequest from '@/utils/hooks/usePagedRequest';
import {getOrigin} from '@/utils/utils';
import {useLinkTo} from '@react-navigation/native';
import {useRequest} from 'ahooks';
import _ from 'lodash';
import React from 'react';
import {Pressable, Share, StyleSheet, View, VirtualizedList} from 'react-native';
import {GestureHandlerRootView} from 'react-native-gesture-handler';

const FavoritesPage = () => {
  const linkTo = useLinkTo();
  const dispatch = useDispatch();
  // const openShareModal = useDynamicModal(ShareModal);
  const {currentUser, x_channelCode, x_shareId} = useSelector(s => s.global);

  const {data, loading, hasMore, loadMore, loadingMore, mutate} = usePagedRequest(({current, pageSize}) =>
    dispatch({
      type: 'favorites/getPagedFavorites',
      params: {
        current,
        pageSize,
      },
    }),
  );
  const {run: remove} = useRequest(id => dispatch({type: 'product/unlike', id}), {
    manual: true,
    onSuccess(res, [id]) {
      if (res?.success) {
        Dialog.success(res?.msg ?? '移除成功');
        mutate(_.filter(data, item => item?.id !== id));
      } else {
        Dialog.respError(res);
      }
    },
  });

  let {url: shareLink} = useLov('APP_SHARE');
  const handleShare = item => {
    shareLink = shareLink || `${getOrigin()}/product/detail/${item.id}`;
    shareLink += `?XShareId=${currentUser?.uid}`;
    shareLink += `&linkTo=${encodeURIComponent('/product/detail/' + item.id)}`;
    // openShareModal({
    //   shareLink,
    // });
    copy(shareLink, () => {
      if (_.isFunction(Share?.share)) {
        Share.share({message: shareLink});
      } else {
        Dialog.info('分享链接已复制');
      }
      XInstall.reportShareByXinShareId(currentUser?.uid + '');
    });
  };

  const prodRecommends = <ProdRecommends visible={!loading && !hasMore} />;
  return (
    <LoadingView loading={loading} style={styles.page}>
      <VirtualizedList
        style={{flex: 1}}
        data={data}
        initialNumToRender={10}
        renderItem={({item, index}) => {
          const right = [
            {
              text: '删除',
              backgroundColor: 'red',
              color: '#fff',
              onPress: () => remove(item?.id),
            },
          ];
          return (
            <GestureHandlerRootView>
              <View key={`$prod_${item?.id}`} style={{marginBottom: 10}}>
                <SwipeAction autoClose right={right}>
                  <Pressable onPress={() => item?.id && linkTo(`/product/detail/${item.id}`)}>
                    <ProdMeta
                      data={item}
                      content={(__, {defaultDom}) => defaultDom}
                      extra={<IconButton type="share" fill="#000" onPress={() => handleShare(item)} />}
                    />
                  </Pressable>
                </SwipeAction>
              </View>
            </GestureHandlerRootView>
          );
        }}
        getItemCount={data => data?.length}
        getItem={(data, index) => data[index]}
        onEndReached={() => {
          hasMore && !loadingMore && loadMore();
        }}
        ListFooterComponent={
          <>
            {loadingMore && <Loading />}
            {prodRecommends}
          </>
        }
      />
    </LoadingView>
  );
};
const styles = StyleSheet.create({
  page: {
    flex: 1,
  },
});

FavoritesPage.screenName = 'Favorites';
FavoritesPage.screenOptions = {
  title: '收藏',
};
FavoritesPage.linking = 'favorites';
export default FavoritesPage;
