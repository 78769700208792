import {Flex} from '@/components/Flex';
import {IconButton} from '@/components/Icon';
import Modal from '@/components/Modal';
import BoxProdList from '@/pages/box/detail/components/BoxProdList';
import BoxRarityBar from '@/pages/box/components/BoxRarityBar';
import theme from '@/theme';
import useControlledModalProps from '@/utils/hooks/useControlledModalProps';
import React, {useEffect, useState} from 'react';
import {StyleSheet, Text, View} from 'react-native';
import {useSafeAreaInsets} from 'react-native-safe-area-context';

const BoxProdListModal = ({data, height, onPressBoxProd, ...rest}) => {
  const {visible, close} = useControlledModalProps(rest);
  const [rarity, setRarity] = useState('');
  const {top} = useSafeAreaInsets();

  const {boxProductList, probabilityNormal, probabilityHidden, probabilityRare, probabilitySupreme} = data || {};
  const probabilities = {
    normal: probabilityNormal,
    hidden: probabilityHidden,
    rare: probabilityRare,
    supreme: probabilitySupreme,
  };

  useEffect(() => {
    !visible && setRarity('');
  }, [visible]);
  return (
    <Modal
      popup
      visible={visible}
      animationType="slide-up"
      maskClosable={false} // onClose={() => setVisible(false)}
      style={[styles.modal]}>
      <View style={[styles.container, {height: (height ?? 0) - top - theme.header_height}]}>
        <Flex justify="center" style={styles.header}>
          <Text style={styles.title}>全部商品详情</Text>
          <IconButton type="close" size={12} onPress={close} style={styles.close} />
        </Flex>
        <View style={styles.boxRarityBar}>
          <BoxRarityBar data={probabilities} current={rarity} onChange={v => setRarity(v)} />
        </View>
        <View style={{flex: 1}}>
          <BoxProdList data={boxProductList} rarity={rarity} onPressBoxProd={onPressBoxProd} />
        </View>
      </View>
    </Modal>
  );
};

export default BoxProdListModal;

const styles = StyleSheet.create({
  modal: {
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
    overflow: 'hidden',
  },
  container: {
    backgroundColor: theme.fill_body,
  },
  close: {
    position: 'absolute',
    top: theme.h_spacing_lg,
    right: theme.h_spacing_lg - 4,
    zIndex: 99,
  },
  header: {
    paddingVertical: 20,
    paddingHorizontal: theme.h_spacing_lg,
  },
  title: {},

  boxRarityBar: {
    paddingTop: 5,
    paddingBottom: 4,
    paddingHorizontal: 12,
  },
});
