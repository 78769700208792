import * as avatars from '@/assets/avatars';
import {Dialog, Grid, RatioView} from '@/components';
import Button from '@/components/Button';
import Icon from '@/components/Icon';
import theme from '@/theme';
import {useDispatch} from '@/utils/dva';
import useNestingBackButton from '@/utils/hooks/useNestingBackButton';
import {useRequest} from 'ahooks';
import _ from 'lodash';
import React, {useState} from 'react';
import {Image, Pressable, ScrollView, StyleSheet, View} from 'react-native';
import useBottomInset from '@/utils/hooks/useBottomInset';

const data = _.toPairs(avatars);
const EditAvatarPage = ({currentUser}) => {
  const navigation = useNestingBackButton();
  const [current, setCurrent] = useState('');
  const dispatch = useDispatch();
  const {loading, run: update} = useRequest(
    () =>
      dispatch({
        type: 'user/updateProfile',
        data: {headImg: current},
      }),
    {
      manual: true,
      onSuccess(res) {
        if (res?.success) {
          navigation.goBack();
        } else {
          Dialog.respError(res);
        }
      },
    },
  );

  const submit = () => {
    if (!current) {
      return Dialog.info('请选择头像');
    }
    update();
  };

  // ios：底部安全区域
  const [, paddingBottom] = useBottomInset(12);
  return (
    <View style={styles.page}>
      <ScrollView style={{flex: 1}} contentContainerStyle={styles.container}>
        <Grid
          border={false}
          hasLine={false}
          data={data}
          columnNum={3}
          itemStyle={styles.item}
          renderItem={(it, i) => {
            const checked = it[0] === current;
            return (
              <RatioView ratio={1} defaultHeight={92}>
                <Pressable onPress={() => setCurrent(it[0])} style={StyleSheet.absoluteFill}>
                  <Image source={it[1]} key={`$item__${i}`} style={styles.image} />
                  <Icon type="checked" size={28} style={styles.checkbox} fill={checked ? '#000' : '#cbcbcb'} />
                </Pressable>
              </RatioView>
            );
          }}
        />
      </ScrollView>
      <View style={[styles.footer, {paddingBottom}]}>
        <Button type="primary" onPress={submit} loading={loading} style={styles.footerButton}>
          确定
        </Button>
      </View>
    </View>
  );
};
const styles = StyleSheet.create({
  page: {
    flex: 1,
  },
  container: {
    paddingHorizontal: 14,
    paddingVertical: 10,
    flex: 1,
  },
  item: {
    paddingHorizontal: 12,
    paddingVertical: 14,
    height: 'auto',
  },
  image: {
    width: '100%',
    height: '100%',
  },
  checkbox: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    backgroundColor: '#fff',
    borderRadius: 28,
  },
  footer: {
    paddingHorizontal: theme.v_spacing_lg,
    paddingVertical: 12,
    backgroundColor: '#fff',
    borderTopWidth: StyleSheet.hairlineWidth,
    borderTopColor: theme.border_color_base,
  },
  footerButton: {
    height: 48,
  },
});

EditAvatarPage.screenName = 'EditAvatar';
EditAvatarPage.screenOptions = {
  title: '选择头像',
};
EditAvatarPage.linking = 'edit_avatar';
export default EditAvatarPage;
