import {getDocument} from '@/pages/document/service';

export const namespace = 'document';
const model = {
  namespace,
  state: {},
  effects: {
    /**
     * 获取文档
     * @actionParam docType: 文档类型
     * */
    *getDocument({docType}, {call, put}) {
      const res = yield call(getDocument, docType);
      if (res?.success) {
        yield put({type: 'saveState', [docType]: res.data});
      }
      return res;
    },
  },
  reducers: {
    saveState(state, action) {
      const {type, ...nextState} = action || {};
      return {...state, ...nextState};
    },
  },
};

export default model;
