import {Flex} from '@/components/Flex';
import RarityTag from '@/pages/box/components/RarityTag';
import theme from '@/theme';
import FontWeight from '@/utils/localData/FontWeight';
import {getPrice} from '@/utils/priceUtils';
import {useImageResize} from '@/utils/remoteData/ImageResize';
import React from 'react';
import {Image, Platform, StyleSheet, Text, View} from 'react-native';

const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: 253,
    paddingTop: 32,
    paddingBottom: 13,
    paddingHorizontal: 16,
    backgroundColor: '#fff',
    borderWidth: StyleSheet.hairlineWidth,
    borderColor: theme.border_color_base,
    borderRadius: 4,
  },
  image: {
    width: '100%',
    height: 135,
    marginBottom: 8,
  },
  tag: {
    top: 8,
    left: 8,
  },
  title: {
    fontSize: 12,
    fontWeight: FontWeight.Light,
    lineHeight: 17,
    marginBottom: 10,
  },
  price: {
    fontSize: 14,
    lineHeight: 20,
    fontWeight: FontWeight.SemiBold,
  },

  extra: {
    color: theme.color_text_secondary,

    ...Platform.select({
      web: {
        fontSize: 12,
        lineHeight: 14,
        fontWeight: FontWeight.Light,
        transformOrigin: 'right center',
        transform: [{scale: 0.833}],
      },
      default: {
        fontSize: 10,
        lineHeight: 14,
        fontWeight: FontWeight.ExtraLight,
      },
    }),
  },
});

const BoxProdCard = ({data, style, recovery = false}) => {
  const {productName, itemName, boxPicture, picture: itemPicture, style: rarity, recycleRate} = data || {};
  const [scorePrice, rmbPrice] = getPrice(data);

  const name = recovery ? itemName : productName;
  const picture = recovery ? itemPicture : boxPicture;
  const price = recovery ? scorePrice : rmbPrice;
  const [, resizeImage] = useImageResize({object: 'prod', size: 'sm'});

  return (
    <View style={[styles.container, style]}>
      <Image style={styles.image} resizeMode="contain" source={{uri: resizeImage(picture)}} />
      <RarityTag rarity={rarity} style={styles.tag} />
      <Flex style={{flex: 1}} direction="column" align="stretch" justify="between">
        <Text style={styles.title} numberOfLines={2}>
          {name}
        </Text>
        <Flex justify="between">
          <Text style={styles.price}>{price.text()}</Text>
          <Text style={styles.extra}>回收返还 {parseFloat(recycleRate).mul(100)}%</Text>
        </Flex>
      </Flex>
    </View>
  );
};

export default BoxProdCard;
