// import ShareModal from '@/components/_modals/ShareModal';
import {Dialog} from '@/components';
import TaskModal from '@/components/_modals/TaskModal';
import XInstall from '@/NativeModules/XInstall';
import {useDispatch, useSelector} from '@/utils/dva';
import useDynamicModal from '@/utils/hooks/useDynamicModal';
import {useRequest} from 'ahooks';
import _ from 'lodash';
import React from 'react';
import {Share} from 'react-native';

const useAskTask = triggerId => {
  const currentUser = useSelector(s => s.global.currentUser);
  const dispatch = useDispatch();
  const openTaskModal = useDynamicModal(TaskModal);
  // const openShareModal = useDynamicModal(ShareModal);

  const {loading, run} = useRequest(() => dispatch({type: 'global/askTask', trigger: 'box', triggerId}), {
    ready: !!triggerId,
    manual: true,
    onSuccess(res) {
      if (res?.success) {
        // todo: if res?.data?.type === 'share' { ... } else ...
        openTaskModal({
          data: res?.data,
          callback: data => {
            // openShareModal({
            //   shareLink: data?.shareLink,
            // });
            if (data?.shareLink) {
              if (_.isFunction(Share?.share)) {
                Share.share({message: data.shareLink});
              } else {
                Dialog.info('分享链接已复制');
              }
              XInstall.reportShareByXinShareId(currentUser?.uid + '');
            }
          },
        });
      }
    },
  });
  return {loading, run};
};

export default useAskTask;
