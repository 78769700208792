import {buy, collect, getProd, getRecommendProds, like, uncollect, unlike} from './service';

export const namespace = 'product';
const model = {
  namespace,
  state: {
    recommendProds: [],
  },
  effects: {
    /**
     * 商品信息查询
     * @actionParam id: 商品的id
     * */
    *getProd({id}, {call, put}) {
      return yield call(getProd, id);
    },

    /**
     * 喜欢
     * @actionParam id: 商品的id
     * */
    *like({id}, {call}) {
      return yield call(like, id);
    },
    /**
     * 取消喜欢
     * @actionParam id: 商品的id
     * */
    *unlike({id}, {call}) {
      return yield call(unlike, id);
    },
    /**
     * 收藏
     * @actionParam id: 商品的id
     * */
    *collect({id}, {call}) {
      return yield call(collect, id);
    },
    /**
     * 取消收藏
     * @actionParam id: 商品的id
     * */
    *uncollect({id}, {call}) {
      return yield call(uncollect, id);
    },

    /**
     * 购买商品
     * @actionParam params: {
     *   skuId
     *   count: 数量
     *   payType: 支付方式
     * }
     * */
    *buy({data}, {call}) {
      return yield call(buy, data);
    },

    /**
     * 查询推荐商品（猜你喜欢）
     * */
    *getRecommendProds(__, {call, put}) {
      const res = yield call(getRecommendProds);
      if (res?.success) {
        yield put({type: 'saveState', recommendProds: res.data});
      }
      return res;
    },
  },
  reducers: {
    saveState(state, action) {
      const {type, ...nextState} = action || {};
      return {...state, ...nextState};
    },
  },
};

export default model;
