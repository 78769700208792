import Deduction from '@/components/_modals/CommonTradeModal/Deduction';
import Checkbox from '@/components/Checkbox';
import {Flex} from '@/components/Flex';
import Link from '@/components/Link';
import useFadeInOut from '@/pages/login/components/useFadeInOut';
import theme from '@/theme';
import useLov from '@/utils/hooks/useLov';
import _ from 'lodash';
import React, {useCallback} from 'react';
import {ActivityIndicator, Animated, StyleSheet, Text, View} from 'react-native';
import {isWeb} from '@/utils/utils';
import {IconButton} from '@/components/Icon';
import {Popable} from '@/components';

const Step0 = ({renderDisplay, params, state, setState, shouldShow, deductEnable}) => {
  const docs = useLov('DOCUMENT_TYPE');
  const {percent: deductPercent, title: deductTitle} = useLov('SCORE_TO_RMB_ENABLE');
  const animStyle = useFadeInOut(shouldShow);
  const onChangeDeductAmount = useCallback(deductAmount => setState({deductAmount}), [setState]);
  return (
    <Animated.View style={[{width: '100%', height: '100%', justifyContent: 'space-between'}, animStyle]}>
      <View>
        {_.isFunction(renderDisplay) &&
          renderDisplay(state, {
            ...params,
          })}
      </View>
      <View>
        {!!deductEnable && (
          <Deduction amount={state.amount} onChange={onChangeDeductAmount}>
            {(value, {loading}) => {
              return (
                <Flex justify="between" style={styles.item}>
                  <Flex onPress={() => !loading && setState({deduct: true})} style={{flex: 1}}>
                    <Text style={styles.itemText}>使用口袋币余额抵扣 </Text>
                    {loading ? <ActivityIndicator size={'small'} /> : <Text style={styles.itemText}>{value}</Text>}
                    <Text style={styles.itemText}> 元</Text>
                    {deductTitle && (
                      <Popable
                        content={
                          <Text
                            style={{
                              paddingVertical: 5,
                              paddingHorizontal: 5,
                              color: '#fff',
                              fontSize: 10,
                              textAlign: 'center',
                            }}>
                            {deductTitle}
                          </Text>
                        }
                        contentStyle={{borderRadius: 0}}
                        caretStyle={{
                          borderRadius: 0,
                          width: 8,
                          height: 8,
                          transform: [{translateY: -1}, {rotate: '45deg'}],
                        }}
                        position="bottom"
                        statusBarTranslucent
                        style={{
                          width: 170,
                          opacity: isWeb() ? 0.8 : 1,
                          textAlign: 'center',
                        }}>
                        <IconButton type="tip" size={11} />
                      </Popable>
                    )}
                  </Flex>
                  <Checkbox
                    size={17}
                    checked={state.deduct}
                    disabled={loading}
                    onChange={checked => !loading && setState({deduct: checked})}
                  />
                </Flex>
              );
            }}
          </Deduction>
        )}
        <Flex justify="between" style={[styles.item, styles.lastItem]} onPress={() => setState({agreement: true})}>
          <Flex>
            <Text style={[styles.itemText, styles.greyText]}>同意</Text>
            <Link to="/document/gmxz">《{docs?.gmxz ?? '口袋盲盒买家须知'}》</Link>
          </Flex>
          <Checkbox
            size={17}
            checked={state.agreement}
            onChange={checked => {
              setState({agreement: !!checked});
            }}
          />
        </Flex>
      </View>
    </Animated.View>
  );
};

const styles = StyleSheet.create({
  item: {
    paddingVertical: 18,
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderColor: theme.border_color_base,
  },
  lastItem: {
    borderBottomWidth: 0,
  },
  itemText: {
    fontSize: 14,
    lineHeight: 20,
  },

  greyText: {
    color: theme.color_text_secondary,
  },
});

export default Step0;
