import {isWeb} from '@/utils/utils';
import {isNumber, isUndefined} from 'lodash';
import React, {useRef, useState} from 'react';
import {Animated} from 'react-native';

const RatioView = ({ratio, defaultHeight, animated = false, style, children, ...restProps}) => {
  const [height, setHeight] = useState(defaultHeight);
  const opacity = useRef(new Animated.Value(animated ? 0 : 1)).current;
  const onLayout = evt => {
    if (isNumber(ratio) && ratio > 0) {
      const {width} = evt.nativeEvent.layout;
      setHeight(width * ratio);
    }
    if (opacity._value === 0) {
      animated
        ? Animated.timing(opacity, {
            toValue: 1,
            duration: 200,
            useNativeDriver: !isWeb(),
          }).start()
        : opacity.setValue(1);
    }
  };
  return (
    <Animated.View onLayout={onLayout} style={[style, !isUndefined(height) && {height}, {opacity}]} {...restProps}>
      {children}
    </Animated.View>
  );
};

export default RatioView;
