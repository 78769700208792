import {getMallTabData, getMallTabs, getPagedProds} from '@/pages/home/mall/service';
import _, {isUndefined} from 'lodash';

export const namespace = 'mall';
const model = {
  namespace,
  state: {
    tabs: [],
    // `data_${tab}`: [], // 对应tab页内数据
  },
  effects: {
    /**
     * 初始化商城数据
     * */
    *getInitialState(__, {call, put, all}) {
      const _getTabs = yield put({type: 'getTabs'});
      const res = yield call(() => _getTabs);
      if (res?.success) {
        // 获取初始页数据
        const {value: firstTab} = _.first(res?.data) || {};
        if (!isUndefined(firstTab)) {
          const _getFirstTabData = yield put({type: 'getTabData', tab: firstTab});
          yield call(() => _getFirstTabData);
        }
      }
      return res;
    },
    /**
     * 获取商城首页标签列表
     * */
    *getTabs(__, {call, put}) {
      const res = yield call(getMallTabs);
      if (res?.success) {
        yield put({type: 'saveState', tabs: res.data});
      }
      return res;
    },
    /**
     * 获取标签页内数据
     * @actionParam tab
     * */
    *getTabData({tab}, {call, put}) {
      const res = yield call(getMallTabData, tab);
      if (res?.success) {
        yield put({type: 'saveData', tab, data: res.data});
      }
      return res;
    },

    /**
     * 分页查询商城产品
     * @actionParam params: {
     *   current,
     *   pageSize,
     *   tab
     * }
     * */
    *getPagedProds({params}, {call}) {
      return yield call(getPagedProds, params);
    },
  },
  reducers: {
    saveState(state, action) {
      const {type, ...nextState} = action || {};
      return {...state, ...nextState};
    },
    setCurrentTab(state, action) {
      return {...state, currentTab: action.currentTab};
    },
    saveData(state, action) {
      const {tab, data} = action || {};
      if (tab) {
        return {...state, [`data_${tab}`]: data ?? {}};
      }
      return state;
    },
  },
};

export default model;
