import {Flex, Icon, LoadingView} from '@/components';
import Loading from '@/components/Loading';
import MallContext from '@/pages/home/mall/components/MallContext';
import ProdCard from '@/pages/product/components/ProdCard';
import theme from '@/theme';
import {useDispatch, useSelector} from '@/utils/dva';
import usePagedRequest from '@/utils/hooks/usePagedRequest';
import {useRequest} from 'ahooks';
import _ from 'lodash';
import React, {useContext, useMemo, useRef, useState} from 'react';
import {Dimensions, Platform, StyleSheet, TouchableOpacity, View, VirtualizedList} from 'react-native';
import MallSections from './MallSections';

const WindowHeight = Dimensions.get('window').height;
const MallTabContent = ({tab}) => {
  const {actionRef} = useContext(MallContext);
  const dispatch = useDispatch();
  const initialData = useSelector(s => s.mall[`data_${tab}`]);
  const {
    data,
    loading,
    run: getData,
  } = useRequest(() => dispatch({type: 'mall/getTabData', tab}), {
    // ready: !initialData,
    initialData,
    formatResult: res => res?.data,
  });

  const {
    data: prods,
    hasMore,
    loadMore,
    loadingMore,
    refresh,
    refreshing,
  } = usePagedRequest(params => dispatch({type: 'mall/getPagedProds', params: {...params, tab}}), {
    ready: !!data,
    initialData: data?.productBoxes,
    uniqueKey: null,
    pageSize: 20,
    onBeforeRefresh: getData,
  });

  const scroll = useRef({dragStart: 0, momentumStart: 0}).current;
  const header = <MallSections data={data} />;
  const vData = useMemo(() => _.chunk(prods, 2), [prods]);
  const [backTopShown, setBackTopShown] = useState(false);
  const scrollView = useRef(null);
  return (
    <LoadingView loading={loading} layer={!!data} hideIndicator={refreshing} style={{flex: 1}}>
      <VirtualizedList
        ref={scrollView}
        onScroll={evt => {
          const y = evt.nativeEvent.contentOffset.y;
          setBackTopShown(y > WindowHeight);
        }}
        scrollEventThrottle={16}
        //start
        onScrollBeginDrag={evt => {
          scroll.dragStart = evt.nativeEvent.contentOffset.y;
        }}
        onScrollEndDrag={evt => {
          const y = evt.nativeEvent.contentOffset.y;
          const s = evt.nativeEvent.velocity.y;
          if (y - scroll.dragStart >= 48) {
            actionRef?.current?.hide?.();
          } else {
            let show = Platform.select({
              android: y === 0 && s > 3,
              ios: y < 0 && s < -1.5,
            });
            if (show) {
              actionRef?.current?.show?.();
            }
          }
        }}
        onMomentumScrollBegin={evt => {
          scroll.momentumStart = evt.nativeEvent.contentOffset.y;
        }}
        onMomentumScrollEnd={evt => {
          const y = evt.nativeEvent.contentOffset.y;
          // const s = evt.nativeEvent.velocity.y;
          if (y < scroll.momentumStart && y === 0) {
            actionRef?.current?.show?.();
          }
        }}
        //end
        ListHeaderComponent={header}
        data={vData}
        initialNumToRender={10}
        renderItem={({item, index}) => {
          return (
            <Flex style={[styles.row, index === vData?.length - 1 && styles.lastRow]}>
              {_.map(item, (it, j) => (
                <View key={`$item_${it?.id}__${j}`} style={[styles.column, j === 1 && styles.lastColumn]}>
                  <ProdCard data={it} style={{flex: 1}} />
                </View>
              ))}
            </Flex>
          );
        }}
        keyExtractor={(item, index) => `$row_${item[0]?.id}_${item[1]?.id}__${index}`}
        getItemCount={data => data?.length}
        getItem={(data, index) => data[index]}
        onEndReached={() => {
          hasMore && !loadingMore && loadMore();
        }}
        onRefresh={refresh}
        refreshing={refreshing}
        ListFooterComponent={loadingMore && <Loading />}
      />
      {!!backTopShown && (
        <TouchableOpacity
          style={styles.backTopBtn}
          onPress={() => {
            scrollView?.current?.getScrollRef?.()?.scrollTo?.({y: 0});
          }}>
          <Icon type="back_top" size={20} fill="#fff" />
        </TouchableOpacity>
      )}
    </LoadingView>
  );
};
const styles = StyleSheet.create({
  row: {
    borderTopWidth: StyleSheet.hairlineWidth,
    borderColor: theme.border_color_base,
  },
  lastRow: {
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderColor: theme.border_color_base,
  },
  column: {
    borderRightWidth: StyleSheet.hairlineWidth,
    borderColor: theme.border_color_base,
    flexBasis: '50%',
    width: '50%',
  },
  lastColumn: {
    borderRightWidth: 0,
  },

  backTopBtn: {
    position: 'absolute',
    right: 20,
    bottom: 20,
    padding: 10,
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    borderRadius: 40,
    opacity: 0.5,
  },
});

export default React.memo(MallTabContent);
