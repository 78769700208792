import {useDispatch, useSelector} from '@/utils/dva';
import useGlobalCallback from '@/utils/hooks/useGlobalCallback';
import {useNavigation, useRoute} from '@react-navigation/core';
import {useRequest} from 'ahooks';
import _, {isFunction, isUndefined} from 'lodash';
import React, {isValidElement, useCallback, useEffect, useState} from 'react';
import {Pressable} from 'react-native';

const AddressPicker = React.memo(({style, children, value, onChange}) => {
  const navigation = useNavigation();
  const route = useRoute();
  const [_address, setAddress] = useState(value);
  const address = isUndefined(value) ? _address : value;

  const handleChange = useCallback(
    value => {
      setAddress(value);
      isFunction(onChange) && onChange(value);
    },
    [onChange],
  );

  // 注册选择地址的回调函数
  const addressPickerRef = useGlobalCallback(({navigation, value}) => {
    handleChange(value);
    navigation.navigate(route);
  });

  // 跳转到地址页面（附回调id）
  const handlePress = () => {
    addressPickerRef?.current?.id && navigation.push('AddressPicker', {id: addressPickerRef.current.id});
  };

  // 自动加载我的地址
  const dispatch = useDispatch();
  const currentUser = useSelector(s => s.global.currentUser);
  const data = useSelector(s => s.address.data);
  useRequest(() => dispatch({type: 'address/getAddresses'}), {
    ready: !!(currentUser && !data?.length),
  });

  // 自动设置默认地址
  useEffect(() => {
    if (!address) {
      handleChange(_.find(data, item => item.defaultFlag));
    }
  }, [address, data, handleChange]);

  return (
    <Pressable onPress={handlePress} style={style}>
      {isFunction(children) && children(address)}
      {isValidElement(children) && children}
    </Pressable>
  );
});

export default AddressPicker;
