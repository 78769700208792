import toMap from '@/utils/FrozenMap/toMap';
import {addToStateData} from '@/utils/remoteData';

@addToStateData('OrderStatus')
@toMap({mapValues: v => v?.value})
export default class OrderStatus {
  static All = {key: 'all', text: '全部', value: '', link: '/order/list/all'};
  static Unpaid = {key: 'unpaid', text: '待付款', value: '待付款', icon: 'order_dfk', link: '/order/list/unpaid'};
  static Paid = {key: 'paid', text: '待发货', value: '待发货', icon: 'order_dfh', link: '/order/list/paid'};
  static Delivered = {
    key: 'delivered',
    text: '待收货',
    value: '待收货',
    icon: 'order_dsh',
    link: '/order/list/delivered',
  };
  static Completed = {
    key: 'completed',
    text: '已完成',
    value: '已完成',
    icon: 'order_ywc',
    link: '/order/list/completed',
  };
  static AfterSale = {
    key: 'after_sale',
    text: '退货/售后',
    value: '退货/售后',
    icon: 'order_thh',
    link: '/order/list/after_sale',
  };
  static Canceled = {key: 'canceled', text: '已取消', value: 'cancel'};
}
