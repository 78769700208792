import {Flex} from '@/components/Flex';
import List from '@/components/List';
import Modal from '@/components/Modal';
import AppInfo from '@/NativeModules/AppInfo';
import SecurityPage from '@/pages/setting/security';
import theme from '@/theme';
import {useDispatch} from '@/utils/dva';
import authedPage from '@/utils/hooks/authedPage';
import useLov from '@/utils/hooks/useLov';
import {createStackNavigator} from '@/utils/navigatorUtils';
import {isWeb} from '@/utils/utils';
import {useLinkTo} from '@react-navigation/native';
import {useRequest} from 'ahooks';
import React from 'react';
import {ScrollView, Text} from 'react-native';

const {Item} = List;
const itemProps = {
  offset: [0, 16],
  arrow: 'right',
};

const SettingPage = ({navigation}) => {
  const linkTo = useLinkTo();
  const docs = useLov('DOCUMENT_TYPE');
  const dispatch = useDispatch();
  const {run: logout} = useRequest(() => dispatch({type: 'user/logout'}), {
    manual: true,
    onSuccess(res) {
      if (res?.success) {
        navigation.navigate('Home', {screen: 'MallTab'});
      }
    },
  });
  return (
    <ScrollView>
      <List border={false}>
        <Item {...itemProps} onPress={() => linkTo('/setting/security')}>
          账户与安全
        </Item>
        {/*<Item {...itemProps}>给好评</Item>*/}
        <Item {...itemProps} onPress={() => linkTo('/document/fwxy')}>
          {docs?.fwxy ?? '用户服务协议'}
        </Item>
        <Item {...itemProps} onPress={() => linkTo('/document/yscl')}>
          {docs?.yscl ?? '隐私政策'}
        </Item>
        {!isWeb() && (
          <Item
            {...itemProps}
            onPress={() => {
              AppInfo.getVersionName().then(version => {
                Modal.alert('', '当前版本号: ' + version);
              });
            }}>
            版本
          </Item>
        )}
        <Item {...itemProps} onPress={() => linkTo('/about_us')}>
          关于我们
        </Item>
      </List>

      <List border={false} style={{marginTop: 10}}>
        <Item last={true} onPress={logout}>
          <Flex align="center" justify="center">
            <Text style={{color: theme.color_text_caption}}>退出登录</Text>
          </Flex>
        </Item>
      </List>
    </ScrollView>
  );
};
SettingPage.screenName = 'SettingIndex';
SettingPage.screenOptions = {
  title: '设置',
};
SettingPage.linking = '';

export default createStackNavigator(
  [SettingPage, SecurityPage],
  {
    screenName: 'Setting',
    screenOptions: {
      headerShown: false,
    },
    linking: 'setting',
  },
  [],
  [authedPage],
);
