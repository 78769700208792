import Modal from '@/components/Modal';
import Toast from '@/components/Toast';
import _, {isArray, isFunction, isObject} from 'lodash';

Toast.config({mask: false, duration: 2});

function alert({title, content, callbackOrActions, onBackHandler, okText} = {}) {
  if (!isObject(arguments[0])) {
    title = arguments[0];
    content = arguments[1];
    callbackOrActions = arguments[2];
    onBackHandler = arguments[3];
  }
  return Modal.alert(
    title,
    content,
    isArray(callbackOrActions)
      ? callbackOrActions
      : [
          {
            text: okText ?? '确定',
            onPress: () => {
              isFunction(callbackOrActions) && callbackOrActions();
            },
          },
        ],
  );
}

function confirm({title, content, callbackOrActions, onBackHandler, okText, cancelText} = {}) {
  if (!isObject(arguments[0])) {
    title = arguments[0];
    content = arguments[1];
    callbackOrActions = arguments[2];
    onBackHandler = arguments[3];
  }
  return Modal.alert(
    title,
    content,
    isArray(callbackOrActions)
      ? callbackOrActions
      : [
          {
            text: cancelText ?? '取消',
            onPress: () => {
              isFunction(callbackOrActions) && callbackOrActions(false);
            },
            style: 'cancel',
          },
          {
            text: okText ?? '确定',
            onPress: () => {
              isFunction(callbackOrActions) && callbackOrActions(true);
            },
          },
        ],
    onBackHandler,
  );
}

function operation(actions) {
  return Modal.operation(actions);
}

function prompt({title, content, callback, actions, type = 'default', defaultValue, placeholders, onBackHandler} = {}) {
  return Modal.prompt(title, content, callback ?? actions, type, defaultValue, placeholders, onBackHandler);
}

function getProps(args, defaultProps) {
  if (!isObject(args[0])) {
    const content = args[0];
    const duration = args[1];
    const onClose = args[2];
    const mask = args[3];
    const stackable = args[4];
    return _.omitBy(_.defaults({}, defaultProps, {content, duration, onClose, mask, stackable}), _.isUndefined);
  }
  return _.omitBy(_.defaults({}, defaultProps, args[0]), _.isUndefined);
}

function info(...args) {
  const key = Toast.info(getProps(args));
  return () => Toast.remove(key);
}

function success(...args) {
  const key = Toast.success(getProps(args));
  return () => Toast.remove(key);
}

function fail(...args) {
  const key = Toast.fail(getProps(args));
  return () => Toast.remove(key);
}

function loading(...args) {
  const key = Toast.loading(
    getProps(args, {
      duration: 0,
      mask: true,
    }),
  );
  return () => Toast.remove(key);
}

function offline(...args) {
  const key = Toast.offline(getProps(args));
  return () => Toast.remove(key);
}

function respError(res, defaultMsg = '请求出错了~') {
  const {success, code, msg} = res || {};
  if (!success) {
    if (code >= 400) {
      return offline(msg ?? defaultMsg);
    } else {
      return fail(msg ?? defaultMsg);
    }
  }
}

const Dialog = {
  alert,
  confirm,
  operation,
  prompt,
  info,
  success,
  fail,
  loading,
  offline,
  respError,
};

export default Dialog;
