import {Dialog} from '@/components';
import BoxRecoveryModal from '@/components/_modals/BoxRecoveryModal';
import Button from '@/components/Button';
import {Center} from '@/components/Flex';
import Icon from '@/components/Icon';
import Link from '@/components/Link';
import useDynamicModal from '@/utils/hooks/useDynamicModal';
import useMergedNavigation from '@/utils/hooks/useMergedNavigation';
import FontWeight from '@/utils/localData/FontWeight';
import _ from 'lodash';
import React from 'react';
import {StyleSheet, Text, View} from 'react-native';

const BoxResultBar = ({navigation: _navigation, demo, data, mutate}) => {
  const navigation = useMergedNavigation(_navigation);
  const goBack = () => navigation.goBack(); //() => navigation.navigate('BoxDetail', {id: 99})
  const validData = _.filter(data, it => it.disabled !== true);
  const openModal = useDynamicModal(BoxRecoveryModal, {
    multiply: data?.length > 1,
    data: validData,
    onSuccess(res, [selectProdKeys] = []) {
      if (res?.success) {
        _.each(data, it => {
          if (_.includes(selectProdKeys, it.id)) {
            it.disabled = true;
          }
        });
        mutate(_.clone(data));
      }
    },
  });

  const saleOut = () => {
    if (!validData?.length) {
      return Dialog.info('已回收完毕');
    }
    openModal();
  };
  return (
    <View style={styles.container}>
      <Center style={{marginLeft: -11}}>
        {demo ? (
          <Button
            type="primary"
            fontWeight={FontWeight.SemiBold}
            color="#00ffbc"
            style={[styles.button]}
            onPress={goBack}>
            去玩把真的 <Icon type="arrow_right" fill="#000" style={{marginLeft: 5}} />
          </Button>
        ) : (
          [
            <Button
              key="$btn_1"
              fontWeight={FontWeight.SemiBold}
              style={[styles.button]}
              disabled={!validData?.length} //
              onPress={saleOut}>
              {!validData?.length ? '回收完毕' : '不喜欢，出售'}
            </Button>,
            <Button
              key="$btn_2"
              type="primary"
              fontWeight={FontWeight.SemiBold}
              color="#00ffbc"
              style={[styles.button]}
              onPress={goBack}>
              再来一发
            </Button>,
          ]
        )}
      </Center>
      <Center>
        {demo ? (
          <Text style={styles.alert}>试玩结果仅供展示，赶紧来把真的吧~</Text>
        ) : (
          <Link
            style={styles.alert}
            onPress={() => {
              navigation.replace('Box', {screen: 'BoxRecords'});
            }}>
            前往盒柜查看提货 <Icon type="arrow_right" fill="#fff" style={{marginLeft: 5}} />
          </Link>
        )}
      </Center>
    </View>
  );
};
const styles = StyleSheet.create({
  container: {
    marginVertical: 20,
    paddingHorizontal: 20,
  },
  button: {
    flex: 1,
    maxWidth: '50%',
    height: 48,
    marginLeft: 11,
  },
  alert: {
    color: '#fff',
    fontSize: 12,
    fontWeight: FontWeight.Medium,
    marginVertical: 26,
  },
});

export default BoxResultBar;
