import {Dialog, LoadingView} from '@/components';
import useOrderPay from '@/utils/hooks/useOrderPay';
import Empty from '@/components/Empty';
import Loading from '@/components/Loading';
import OrderCard from '@/pages/order/list/components/OrderCard';
import theme from '@/theme';
import {useDispatch} from '@/utils/dva';
import usePagedRequest from '@/utils/hooks/usePagedRequest';
import OrderStatus from '@/utils/localData/OrderStatus';
import {useNavigation} from '@react-navigation/core';
import {useRequest} from 'ahooks';
import _ from 'lodash';
import React, {useCallback} from 'react';
import {StyleSheet, VirtualizedList} from 'react-native';

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  list: {
    flex: 1,
  },
  contentContainerStyle: {
    paddingTop: theme.v_spacing_md,
    minHeight: '100%',
  },
});
const OrderList = ({status}) => {
  const navigation = useNavigation();
  const dispatch = useDispatch();

  // 加载订单
  const {data, mutate, loading, hasMore, loadMore, loadingMore, refresh, refreshing} = usePagedRequest(
    ({current, pageSize}) => {
      return dispatch({
        type: 'order/getPagedOrders',
        params: {
          status,
          current,
          pageSize,
        },
      });
    },
    {
      defaultParams: [{status}],
    },
  );
  const refreshItem = useCallback(
    item => {
      const finded = _.find(data, it => it?.id === item?.id);
      if (finded) {
        _.extend(finded, item);
        mutate(_.clone(data));
      }
    },
    [data, mutate],
  );

  // 订单支付
  const [submit, {submitting}] = useOrderPay(null, {
    onSuccess(res, [params] = []) {
      if (res?.success) {
        refreshItem({
          id: params?.id,
          status: OrderStatus.Paid,
        });
      }
    },
  });

  // 确认收货
  const {loading: receiving, run: receive} = useRequest(id => dispatch({type: 'order/receive', id}), {
    manual: true,
    onSuccess(res, [id] = []) {
      if (res?.success) {
        refreshItem({
          id,
          status: OrderStatus.Completed,
        });
        Dialog.success(res.msg ?? '操作成功');
      } else {
        Dialog.respError(res);
      }
    },
  });

  const renderItem = useCallback(
    ({item}) => <OrderCard data={item} submit={submit} receive={receive} refreshData={refreshItem} />,
    [receive, refreshItem, submit],
  );

  return (
    <LoadingView
      loading={loading || submitting || receiving}
      layer={submitting || receiving}
      hideIndicator={submitting}
      style={styles.container}>
      <VirtualizedList
        style={styles.list}
        contentContainerStyle={styles.contentContainerStyle}
        data={data}
        initialNumToRender={10}
        renderItem={renderItem}
        getItemCount={data => data?.length}
        getItem={(data, index) => data[index]}
        onEndReached={() => {
          hasMore && !loadingMore && loadMore();
        }}
        onRefresh={refresh}
        refreshing={refreshing}
        ListEmptyComponent={
          !loading &&
          !data?.length && (
            <Empty
              style={{backgroundColor: 'transparent'}}
              text="暂时还没有订单~去逛逛"
              actions={[
                {
                  text: '去逛逛',
                  onPress: () => navigation.navigate('Home', {screen: 'MallTab'}),
                },
              ]}
            />
          )
        }
        ListFooterComponent={loadingMore && <Loading />}
      />
    </LoadingView>
  );
};

export default OrderList;
