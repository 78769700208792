import * as _ from 'lodash';

/**
 *  options: {
 *    mapValues: (o)=>o.value || <string>key || bool
 *  }
 *  */
class _FrozenMap extends Map {
  constructor(obj, options) {
    super();
    const {mapValues} = options || {};
    _.map(obj, (value, key) => {
      this.set(key, value);
      if (mapValues) {
        _.extend(this, {
          get [key]() {
            return _.isFunction(mapValues)
              ? mapValues.call(this, value)
              : _.isString(mapValues)
              ? value && value[mapValues]
              : value;
          },
          get ['_' + key]() {
            return value;
          },
        });
      }
    });

    this.values();
    this.keys();
    Object.freeze(this);
  }

  get length() {
    return this.size;
  }

  toArray() {
    return Array.from(this);
  }

  values() {
    if (!this.__values) {
      this.__values = this.toArray().map(item => item[1]);
    }
    return this.__values;
  }

  keys() {
    if (!this.__keys) {
      this.__keys = this.toArray().map(item => item[0]);
    }
    return this.__keys;
  }

  map(iteratee) {
    return _.map(this.values(), iteratee);
  }

  filter(predicate) {
    return _.filter(this.values(), predicate);
  }

  getByKey(key) {
    return _.find(this.values(), item => item === key || item?.key === key);
  }

  getByValue(value) {
    return _.find(this.values(), item => item === value || item?.value === value);
  }
}

let caches = {};
export default function FrozenMap(obj, options) {
  const {cacheId, ...restOpts} = options || {};
  if (cacheId) {
    if (!caches[cacheId]) {
      caches[cacheId] = new _FrozenMap(obj, restOpts);
    }
    return caches[cacheId];
  }
  return new _FrozenMap(obj, restOpts);
}
