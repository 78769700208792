import createMaterialTopTabNavigator from '@/components/material-top-tabs/navigators/createMaterialTopTabNavigator';
import ToolList from '@/pages/tools/components/ToolList';
import theme from '@/theme';
import authedPage from '@/utils/hooks/authedPage';
import ToolStatus from '@/utils/localData/ToolStatus';
import _ from 'lodash';
import React from 'react';
import {StyleSheet} from 'react-native';

const Tab = createMaterialTopTabNavigator();
const tabComponents = {};
const getTabComponent = (key, value, _props) => {
  if (!tabComponents[key]) {
    tabComponents[key] = React.memo(props => <ToolList {...props} {..._props} status={value} />);
  }
  return tabComponents[key];
};
const ToolsPage = authedPage(() => {
  return (
    <Tab.Navigator
      backBehavior="back"
      screenOptions={{
        // tabBarScrollEnabled: true,
        tabBarIndicatorContainerStyle: styles.tabBarIndicatorContainerStyle,
        tabBarIndicatorStyle: styles.tabBarIndicatorStyle,
        tabBarContentContainerStyle: styles.tabBarContentContainerStyle,
        tabBarItemStyle: styles.tabBarItemStyle,
        tabBarInactiveTintColor: theme.color_text_secondary,
        lazy: true,
        lazyPreloadDistance: 0,
      }}>
      {_.map(
        ToolStatus.filter(it => it?.link),
        (tab, i) => {
          const {key, text, value} = tab || {};
          return (
            <Tab.Screen
              key={`$toolsTab_${key}`}
              name={key}
              options={{
                title: '道具卡',
                tabBarLabel: text,
              }}
              component={getTabComponent(key, value)}
            />
          );
        },
      )}
    </Tab.Navigator>
  );
});
const styles = StyleSheet.create({
  tabBarIndicatorContainerStyle: {
    transform: [{translateX: -130}],
    left: '50%',
  },
  tabBarIndicatorStyle: {
    backgroundColor: theme.brand_primary,
    width: 42,
    marginHorizontal: (130 - 42) / 2,
  },
  tabBarContentContainerStyle: {
    justifyContent: 'center',
  },
  tabBarItemStyle: {
    width: 130,
    minHeight: 46,
    padding: 0,
  },
});

ToolsPage.screenName = 'Tools';
ToolsPage.screenOptions = {
  title: '道具卡',
};
ToolsPage.linking = {
  path: 'tools',
  screens: _.chain(ToolStatus.filter(it => it?.link))
    .keyBy('key')
    .mapValues((v, key) => key)
    .value(),
};
export default ToolsPage;
