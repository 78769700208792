import HeaderLeft from '@/components/Header/HeaderLeft';
import HeaderRight from '@/components/Header/HeaderTitle';
import HeaderSearch from '@/components/Header/HeaderTitle';
import HeaderTitle from '@/components/Header/HeaderTitle';
import ShadowIcon from '@/components/Header/ShadowIcon';

import theme from '@/theme';
import React from 'react';
import {View} from 'react-native';

export const defaultHeaderConfig = {
  headerTintColor: '#000',
  headerStyle: {height: theme.header_height, borderBottomWidth: 0},
  headerTitleAlign: 'center',
  headerShadowVisible: false,
  // headerLeft: ({tintColor}) => <HeaderLeft>,
  // headerBackImageSource: require('@/assets/back.png'),
  headerBackVisible: false,
  headerBackTitleVisible: false,
  headerTitle: props => <HeaderTitle {...props} />,
  headerLeft: props => <HeaderLeft {...props} />,
};

const DefaultHeader = ({
  headerLeft = defaultHeaderConfig.headerLeft,
  headerRight,
  title,
  style,
  titleStyle,
  leftStyle,
  rightStyle,
  tintColor,
} = {}) => {
  return (
    <View
      style={[
        {
          height: theme.header_height,
          backgroundColor: '#fff',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        },
        style,
      ]}>
      <View style={[{flex: 1}, leftStyle]}>{headerLeft && headerLeft()}</View>
      <View style={[{marginHorizontal: 15}, titleStyle]}>
        <HeaderTitle tintColor={tintColor}>{title}</HeaderTitle>
      </View>
      <View style={[{flex: 1}, rightStyle]}>{headerRight && headerRight()}</View>
    </View>
  );
};
DefaultHeader.HeaderTitle = HeaderTitle;
DefaultHeader.HeaderRight = HeaderRight;
DefaultHeader.HeaderLeft = HeaderLeft;
DefaultHeader.HeaderSearch = HeaderSearch;
DefaultHeader.ShadowIcon = ShadowIcon;
DefaultHeader.defaultHeaderConfig = defaultHeaderConfig;
export default DefaultHeader;
export {HeaderTitle, HeaderRight, HeaderLeft, HeaderSearch, ShadowIcon};
