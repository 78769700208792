import request, {getPagedParams} from '@/utils/request';

/**
 * 搜索接口
 * @param params: {
 *   current: 页码
 *   pageSize,
 *   text: 输入文字
 *   orderBy: 排序
 *   brandId: 品牌id
 *   categoryId: 品类id
 * }
 * */
export function search(params) {
  const {text, categoryId, ...rest} = getPagedParams(params);
  return request('/server/product/page', {
    method: 'GET',
    params: {
      name: text,
      smallCategoryId: categoryId,
      ...rest,
    },
  }).then(res => {
    res.options = {...params};
    return res;
  });
}

/**
 * 查询热搜
 * */
export function getHots() {
  return request('/server/product/hotSearch', {
    method: 'GET',
  });
}
