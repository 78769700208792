import {Flex} from '@/components';
import Empty from '@/components/Empty';
import Loading from '@/components/Loading';
import ProdCardCommon from '@/pages/product/components/ProdCard/ProdCardCommon';
import SearchOrderByBar from '@/pages/search/conpoments/SearchOrderByBar';
import theme from '@/theme';
import _ from 'lodash';
import React, {useCallback} from 'react';
import {StyleSheet, View, VirtualizedList} from 'react-native';

const SearchResult = ({state, search, data, hasMore, loadMore, loadingMore}) => {
  const getData = useCallback(() => {
    return _.chunk(data ?? [], 2);
  }, [data]);

  if (!data?.length) {
    return <Empty />;
  }

  const _data = getData();
  return (
    <View style={styles.container}>
      <SearchOrderByBar orderBy={state?.orderBy} search={search} />
      <VirtualizedList
        data={_data}
        initialNumToRender={10}
        renderItem={({item, index}) => {
          return (
            <Flex style={[styles.row, index === _data.length - 1 && styles.lastRow]}>
              {_.map(item, (prod, i) => (
                <ProdCardCommon key={i} data={prod} style={[styles.column, i === 1 && styles.lastColumn]} />
              ))}
            </Flex>
          );
        }}
        keyExtractor={(item, index) => {
          return `$row_${_.map(item, sub => sub?.id).join('_')}` ?? `$row__${index}`;
        }}
        getItemCount={data => data?.length}
        getItem={(data, index) => data[index]}
        onEndReached={() => {
          hasMore && !loadingMore && loadMore();
        }}
        ListFooterComponent={loadingMore && <Loading />}
      />
    </View>
  );
};

export default SearchResult;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  row: {
    borderTopWidth: StyleSheet.hairlineWidth,
    borderColor: theme.border_color_base,
  },
  lastRow: {
    borderBottomWidth: StyleSheet.hairlineWidth,
  },
  column: {
    height: 258,
    width: '50%',
    maxWidth: '50%',
    borderRightWidth: StyleSheet.hairlineWidth,
    borderColor: theme.border_color_base,
  },
  lastColumn: {
    borderRightWidth: 0,
  },
});
