import Button from '@/components/Button';
import Card from '@/components/Card';
import {Flex} from '@/components/Flex';
import PieceMeta from '@/pages/piece/components/PieceMeta';
import ProdMeta from '@/pages/product/components/ProdMeta';
import theme from '@/theme';
import OrderStatus from '@/utils/localData/OrderStatus';
import OrderSubType from '@/utils/localData/OrderSubType';
import {getOrderPrice} from '@/utils/priceUtils';
import {useImageResize} from '@/utils/remoteData/ImageResize';
import {useNavigation} from '@react-navigation/core';
import _ from 'lodash';
import React from 'react';
import {Pressable, StyleSheet, Text} from 'react-native';

const OrderCard = ({data, submit, receive, refreshData}) => {
  const navigation = useNavigation();
  const {id, status, orderItemDtoList, subType} = data || {};
  const item = _.first(orderItemDtoList);
  const price = getOrderPrice(data) || {};
  const [, resizeImage] = useImageResize({object: 'prod', size: 'xs'});
  const prodMeta =
    !!item &&
    (subType === OrderSubType.Piece ? (
      <PieceMeta
        card={false}
        data={item}
        thumb={resizeImage(item.picture)}
        title={item.itemName}
        content={(__, {styles}) => (
          <Flex align="end" style={styles.price}>
            <Text style={styles.priceAmount}>
              {price?.prefix}
              {price?.amount}
            </Text>
            <Text style={styles.priceUnit}> {price?.unit}</Text>
          </Flex>
        )}
      />
    ) : (
      <ProdMeta
        card={false}
        data={item}
        thumb={resizeImage(item.picture)}
        title={item.itemName}
        brief={`${item.standard}  数量 × ${item.quantity}`}
        content={(__, {styles}) => (
          <Flex align="end" style={styles.price}>
            <Text style={styles.priceAmount}>
              {price?.prefix}
              {price?.amount}
            </Text>
            <Text style={styles.priceUnit}> {price?.unit}</Text>
          </Flex>
        )}
      />
    ));

  let action;
  if (status === OrderStatus.Unpaid) {
    action = {
      text: '立即付款',
      onPress: () => submit({...data, itemDoms: [prodMeta]}),
    };
  }
  if (status === OrderStatus.Delivered) {
    action = {
      text: '确认收货',
      onPress: () => {
        receive(id);
      },
    };
  }

  const gotoDetail = () => {
    // linkTo(`/order/detail/${id}`)
    navigation.push('OrderDetail', {
      id,
      refreshData,
    });
  };

  return (
    <Pressable onPress={gotoDetail} style={{marginBottom: theme.v_spacing_md}}>
      <Card style={styles.card} full>
        <Card.Header title={<Text style={styles.orderStatus}>{OrderStatus.getByValue(status)?.text ?? status}</Text>} />
        <Card.Body style={styles.body}>{prodMeta}</Card.Body>
        {action ? (
          <Card.Footer
            extra={
              <Flex justify="end">
                <Button size="small" type="primary" style={{width: 72}} onPress={action.onPress}>
                  {action.text}
                </Button>
              </Flex>
            }
          />
        ) : (
          <></>
        )}
      </Card>
    </Pressable>
  );
};
const styles = StyleSheet.create({
  card: {
    minHeight: 157,
  },
  body: {
    paddingVertical: 16,
    paddingHorizontal: theme.h_spacing_lg,
  },
  itemCard: {
    borderRadius: 4,
    backgroundColor: theme.fill_body,
    borderWidth: StyleSheet.hairlineWidth,
    borderColor: theme.border_color_base,
    paddingVertical: 10,
    paddingHorizontal: 10,
  },
});

export default OrderCard;
