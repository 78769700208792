import useMergedNavigation from '@/utils/hooks/useMergedNavigation';
import {isAndroid} from '@/utils/utils';
import _Portal from '@ant-design/react-native/lib/portal';
import _ from 'lodash';
import React, {useCallback, useEffect, useRef} from 'react';
import _Portal2 from './Portal';

export const getModalProps = props => {
  return _.pick(props, [
    'visible',
    'closable',
    'maskClosable',
    'style',
    'headStyle',
    'bodyStyle',
    'animationType',
    'onClose',
    'footer',
    'transparent',
    'popup',
    'animateAppear',
    'operation',
    'onAnimationEnd',
    'Portal',
  ]);
};
const useDynamicModal = (
  Modal,
  props,
  {
    navigation: _navigation, //
    onBeforeRemove = () => {},
    closeBeforeRemove = true,
    closeOnBlur = true,
    global = true,
  } = {},
) => {
  const navigation = useMergedNavigation(_navigation);
  const keys = useRef([]).current;
  // 是否使用全家弹出（部分场景需要将Modal渲染在页面内，如：从modal内查看文档并返回的场景）
  const Portal = global ? _Portal : _Portal2;

  const remove = useCallback(
    key => {
      Portal.remove(key);
      _.pull(keys, key);
    },
    [Portal, keys],
  );
  useEffect(() => {
    const unsubscribe = navigation.addListener('beforeRemove', evt => {
      if (_.isFunction(onBeforeRemove)) {
        const res = onBeforeRemove(evt);
        if (res === false) {
          return;
        }
      }
      if (closeBeforeRemove && keys?.length) {
        _.each(_.clone(keys), remove);
        isAndroid() && evt.preventDefault();
      }
    });
    const unsubscribe2 = navigation.addListener('blur', evt => {
      if (closeOnBlur && keys?.length) {
        _.each(_.clone(keys), remove);
      }
    });
    return () => {
      unsubscribe();
      unsubscribe2();
    };
  }, [closeBeforeRemove, closeOnBlur, keys, navigation, onBeforeRemove, remove]);

  const open = params => {
    const {onAnimationEnd, ...modalProps} = _.extend({}, props, params);
    const key = Portal.add(
      React.createElement(Modal, {
        navigation,
        defaultVisible: true,
        Portal,
        ...modalProps,
        onAnimationEnd: visible => {
          if (!visible) {
            remove(key);
          }
          onAnimationEnd?.(visible);
        },
      }),
    );
    keys.push(key);
    return key;
  };
  open.closeAll = () => _.each(_.clone(keys), remove);
  return open;
};

export default useDynamicModal;
