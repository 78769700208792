import _, {find, isFunction} from 'lodash';
import React from 'react';
import BoxSection from './BoxSection';
import DetailSection from './DetailSection';
import HeadSection from './HeadSection';
import OfferSection from './OfferSection';
import VariantSection from './VariantSection';

const allSections = [HeadSection, OfferSection, VariantSection, BoxSection, DetailSection];
const defaultConfigs = [
  new HeadSection({
    style: {marginBottom: 0},
  }),
  new OfferSection({
    formatData: data => data?.saleSlogans,
  }),
  new VariantSection({
    style: {marginBottom: 0},
  }),
  new BoxSection({
    formatData: data => ({title: data?.saleSlogan, list: data?.productBoxes}),
  }),
  new DetailSection({
    formatData: data => _.compact(data?.descriptionList),
  }),
];

const getSectionComponent = type => {
  return find(allSections, c => c.type === type)?.component;
};

const ProductSections = ({data, mutate, sections = defaultConfigs}) => {
  return (
    <>
      {sections.map((section, i) => {
        const {type, formatData} = section || {};
        section.data = isFunction(formatData) ? formatData(data, section) : data;
        const sectionProps = {
          key: `$section_${type}_${i}`,
          section,
          data,
          mutate,
        };
        const sectionComponent = getSectionComponent(type);
        if (sectionComponent) {
          return React.createElement(sectionComponent, sectionProps);
        }
      })}
    </>
  );
};

export default ProductSections;
