import {getCoupons} from '@/pages/coupon/service';

export const namespace = 'coupon';
const model = {
  namespace,
  state: {
    all: [],
    // [status]: [], // 对应道具状态的道具数据
  },
  effects: {
    /**
     * 获取优惠券
     * @actionParam status: 优惠券状态
     * */
    *getCoupons({status, lastId}, {call, put}) {
      const res = yield call(getCoupons, status, lastId);
      if (res?.success) {
        if (status) {
          // 保存不同状态的数据
          yield put({type: 'addData', status, data: res.data});
        } else {
          yield put({type: 'addData', data: res.data});
        }
      }
      return res;
    },
  },
  reducers: {
    saveState(state, action) {
      const {type, ...nextState} = action || {};
      return {...state, ...nextState};
    },

    addData(state, action) {
      const {status, data} = action || {};
      const oldData = state?.[status];
      return {...state, [status]: _.concat([], oldData ?? [], data ?? [])};
    },
  },
};

export default model;
