import Button from '@/components/Button';
import {Flex} from '@/components/Flex';
import Icon from '@/components/Icon';
import {fill_base} from '@/theme';
import FontWeight from '@/utils/localData/FontWeight';
import {getDINProBoldStyle} from '@/utils/styleUtils';
import React, {useState} from 'react';
import {StyleSheet, Text, TouchableOpacity, View} from 'react-native';

const styles = StyleSheet.create({
  couponContainer: {
    height: 103,
    backgroundColor: fill_base,
    position: 'relative',
  },
  leftContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 114,
    height: '100%',
  },
  rightContainer: {
    flex: 1,
    height: '100%',
    paddingVertical: 12,
    paddingHorizontal: 16,
  },
  pricePrefix: {
    fontSize: 16,
    position: 'relative',
    bottom: 10,
  },
  priceNum: {
    color: '#212121',
    ...getDINProBoldStyle(40),
    lineHeight: 52,
  },
  priceLimit: {
    fontSize: 12,
    lineHeight: 17,
    fontWeight: FontWeight.Regular,
  },
  couponTitle: {
    fontSize: 14,
    lineHeight: 20,
    color: '#212121',
    fontWeight: FontWeight.Regular,
  },
  couponDate: {
    fontSize: 10,
    color: '#999',
    lineHeight: 14,
    marginTop: 4,
  },
  btn: {
    width: 64,
    height: 28,
    borderColor: '#000',
    borderRadius: 14,
  },
  detailBtn: {
    fontSize: 12,
    lineHeight: 14,
    color: '#999',
    marginRight: 4,
  },
  detailContainer: {
    height: 30,
    backgroundColor: '#DBDADF',
  },
  detail: {
    fontSize: 10,
    lineHeight: 30,
    color: '#212121',
    marginLeft: 10,
  },
  statusImage: {
    width: 75,
    height: 70,
    position: 'absolute',
    right: -10,
    bottom: -15,
  },
});

const CouponItem = () => {
  const [show, setShow] = useState(false);

  return (
    <View style={[{marginTop: 10, paddingHorizontal: 20, flex: 1}]}>
      <Flex style={[styles.couponContainer]}>
        <View style={[styles.leftContainer]}>
          <View>
            <Flex align="end">
              <Text style={styles.pricePrefix}>¥</Text>
              <Text style={[styles.priceNum]}>30</Text>
            </Flex>
            <Text style={[styles.priceLimit]}>满199元可用</Text>
          </View>
        </View>
        <Flex direction="column" align="start" justify="between" style={[styles.rightContainer]}>
          <View>
            <Text>夏日清凉礼包</Text>
            <Text style={[styles.couponDate]}>2021.09.01 08:20 - 2021.09.20 08:20</Text>
          </View>
          <Flex justify="between" style={{width: '100%'}}>
            <Flex>
              <TouchableOpacity onPress={e => setShow(!show)}>
                <Text style={styles.detailBtn}>使用说明</Text>
              </TouchableOpacity>
              <Icon type={`${show ? 'arrow_up' : 'arrow_down'}`} />
            </Flex>
            <Button style={styles.btn} size={'small'}>
              去使用
            </Button>
            {/*<ImageBackground source={require('@/assets/disbable.svg')} style={styles.statusImage} />*/}
          </Flex>
        </Flex>
      </Flex>
      <View style={[styles.detailContainer, {display: show ? '' : 'none'}]}>
        <Text style={styles.detail}>· 新人专享，仅现货可用</Text>
      </View>
    </View>
  );
};

export default CouponItem;
