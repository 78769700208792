import {IconButton} from '@/components/Icon';
import theme from '@/theme';
import {getDINProBoldStyle} from '@/utils/styleUtils';
import {isFunction} from 'lodash';
import React, {useState} from 'react';
import {StyleSheet, TextInput, View} from 'react-native';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
  },

  input: {
    width: 32,
    height: 20,
    paddingVertical: 3,
    backgroundColor: theme.fill_body,
    borderRadius: 2,
    marginHorizontal: 3,
    padding: 0,
    textAlign: 'center',
    textAlignVertical: 'center',
    color: theme.brand_primary,
    ...getDINProBoldStyle(12),
    lineHeight: 14,
  },
  btn: {},
  btnDisabled: {},
});

export default function Stepper(props) {
  const {style, inputStyle, value, defaultValue, step, min, max, disabled, readOnly, onChange} = Object.assign(
    {
      min: 0,
      max: Infinity,
      step: 1,
      disabled: false,
      readOnly: true,
      onChange: null,
    },
    props,
  );

  const [_value, setValue] = useState(value ?? defaultValue ?? min ?? 0);

  const handleChange = text => {
    let v = parseFloat(text);
    if (v > max) {
      v = max;
    }
    if (v < min) {
      v = min;
    }
    setValue(isNaN(v) ? '' : v);
    isFunction(onChange) && onChange(isNaN(v) ? '' : v);
  };

  const minus = () => {
    if (disabled) {
      return;
    }
    handleChange(_value - step);
  };
  const plus = () => {
    if (disabled) {
      return;
    }
    handleChange(_value + step);
  };
  const onBlur = evt => {
    const text = evt.nativeEvent.text;
    let v = parseFloat(text);
    if (isNaN(v)) {
      v = min ?? 0;
    }
    handleChange(v);
  };
  return (
    <View style={[styles.container, style]}>
      <IconButton
        type="minus"
        onPress={minus}
        style={[styles.btn]}
        fill={_value <= min ? theme.fill_disabled : theme.color_text_base}
      />
      <TextInput
        keyboardType="numeric"
        style={[styles.input, inputStyle]}
        value={String(value ?? _value)}
        defaultValue={defaultValue}
        onChangeText={handleChange}
        onBlur={onBlur}
        disabled={disabled}
        editable={!readOnly}
        selectionColor={theme.brand_primary}
      />
      <IconButton
        type="plus"
        onPress={plus}
        style={[styles.btn]}
        fill={_value >= max ? theme.fill_disabled : theme.color_text_base}
      />
    </View>
  );
}
