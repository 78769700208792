import {Center, Dialog} from '@/components';
import AppUpdateModal from '@/components/_modals/AppUpdateModal';
import List from '@/components/List';
import AppInfo from '@/NativeModules/AppInfo';
import {assets_logo} from '@/pages/aboutUs/assets';
import theme from '@/theme';
import {useDispatch, useSelector} from '@/utils/dva';
import useDynamicModal from '@/utils/hooks/useDynamicModal';
import useLov from '@/utils/hooks/useLov';
import {isNative, isWeb} from '@/utils/utils';
import {useLinkTo} from '@react-navigation/native';
import {useMount, useRequest, useSetState} from 'ahooks';
import React from 'react';
import {Image, ScrollView, StyleSheet, Text, View} from 'react-native';

const {Item} = List;
const itemProps = {
  offset: [0, 16],
  arrow: 'right',
  lineStyle: {
    paddingVertical: 20,
  },
};
const AboutUsPage = () => {
  const linkTo = useLinkTo();
  const {version, copyright, copyright_time} = useLov('APP_INFO');
  const lastVersion = useSelector(s => s.global.lastVersion);
  const docs = useLov('DOCUMENT_TYPE');

  const openUpdateModal = useDynamicModal(
    AppUpdateModal,
    {},
    {
      onBeforeRemove: () => true,
    },
  );

  const dispatch = useDispatch();
  const {run: checkVersion} = useRequest(
    () => {
      dispatch({type: 'global/clearLastVersion'});
      return dispatch({type: 'global/checkVersion'});
    },
    {
      manual: true,
      onSuccess(res) {
        if (res?.success) {
          if (res?.data?.versionCode) {
            const {downloadUrl, versionCode, versionName, title, content} = res.data || {};
            openUpdateModal({
              downloadUrl,
              versionCode,
              versionName,
              title,
              content,
              mandatory: false,
            });
          } else {
            isNative() && Dialog.info('已是最新版本');
          }
        } else {
          Dialog.respError(res);
        }
      },
    },
  );

  const [state, setState] = useSetState({
    versionCode: 0,
    versionName: version,
    appChannel: 'koudaimoo',
  });

  useMount(() => {
    if (isNative()) {
      // 获取app版本名称
      AppInfo.getVersionCode().then(versionCode => setState({versionCode}));
      AppInfo.getVersionName().then(versionName => setState({versionName}));
      AppInfo.getAppChannel().then(appChannel => setState({appChannel}));
    }
    if (isWeb() && lastVersion?.versionName) {
      setState({versionName: lastVersion.versionName});
    }
  });

  const versionName = state.versionName || version;
  return (
    <View style={{flex: 1}}>
      <ScrollView style={{flex: 1}}>
        <Center direction="column" style={styles.head}>
          <Image source={assets_logo} style={styles.logo} />
          <Text style={styles.version}>Version: {versionName}</Text>
        </Center>
        <List border={false} style={{marginTop: 10}}>
          <Item {...itemProps} onPress={checkVersion}>
            {isWeb() ? '下载APP' : '检查更新'}
          </Item>
          <Item {...itemProps} onPress={() => linkTo('/document/fwxy')}>
            {docs?.fwxy ?? '用户服务协议'}
          </Item>
          <Item {...itemProps} onPress={() => linkTo('/document/yscl')}>
            {docs?.yscl ?? '隐私政策'}
          </Item>
        </List>
      </ScrollView>

      <Center direction="column" style={styles.foot}>
        <Text style={styles.copyright}>{copyright}</Text>
        <Text style={styles.copyright}>{copyright_time}</Text>
      </Center>
    </View>
  );
};
const styles = StyleSheet.create({
  head: {
    paddingVertical: 36,
  },
  logo: {
    width: 72,
    height: 72,
    marginBottom: 10,
  },
  version: {
    fontSize: 14,
    lineHeight: 20,
  },

  foot: {
    paddingVertical: 32,
    paddingHorizontal: 20,
  },

  copyright: {
    fontSize: 12,
    lineHeight: 17,
    color: theme.color_text_secondary,
  },
});

AboutUsPage.screenName = 'AboutUs';
AboutUsPage.screenOptions = {
  title: '关于我们',
};
AboutUsPage.linking = 'about_us';

export default AboutUsPage;
