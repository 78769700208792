import Grid from '@/components/Grid';
import Icon from '@/components/Icon';
import theme from '@/theme';
import {assign} from 'lodash';
import React from 'react';
import {StyleSheet, Text, View} from 'react-native';

const styles = StyleSheet.create({
  container: {},
  gridItem: {
    paddingVertical: theme.v_spacing_sm,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    marginRight: 2,
  },
  text: {
    fontSize: 9,
    lineHeight: 13,
    color: '#212121',
  },
});

const Component = ({section, data}) => {
  return (
    <View style={[styles.container, section.style]}>
      {data?.length && (
        <Grid
          {...section.gridProps}
          data={data}
          renderItem={(item, i) => {
            const {icon, text} = item || {};
            return (
              <View style={[styles.gridItem]}>
                <Icon type={icon} style={[styles.icon]} />
                <Text style={[styles.text]}>{text}</Text>
              </View>
            );
          }}
        />
      )}
    </View>
  );
};

export default class EnsureSection {
  static type = 'ensure';
  static component = React.memo(Component);
  style = {};
  gridProps = {
    itemStyle: {
      height: 'auto',
    },
    columnNum: 4,
    hasLine: false,
  };

  constructor(config) {
    const {...rest} = config || {};
    assign(this, rest);
  }

  get type() {
    return this?.constructor?.type;
  }
}
