import icons from '@/assets/icons';
import {isFunction} from 'lodash';
import React from 'react';
import {StyleSheet, TouchableOpacity, View} from 'react-native';

const Icon = ({type, size, color, ...props}) => {
  if (props.style) {
    props.style = StyleSheet.flatten(props.style);
  }
  if (size) {
    props.width = props.width ?? size;
    props.height = props.height ?? size;
  }
  if (color) {
    props.fill = color;
  }
  if (icons[type]) {
    return React.createElement(icons[type], props);
  }
  return null;
};

export default Icon;

const iconBtnStyles = StyleSheet.create({
  btn: {
    width: 20,
    height: 20,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export function IconButton({onPress, style, iconStyle, ...iconProps}) {
  return isFunction(onPress) ? (
    <TouchableOpacity onPress={onPress} style={[iconBtnStyles.btn, style]}>
      <Icon {...iconProps} style={iconStyle} />
    </TouchableOpacity>
  ) : (
    <View style={[iconBtnStyles.btn, style]}>
      <Icon {...iconProps} style={iconStyle} />
    </View>
  );
}
