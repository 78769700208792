import {StyleSheet} from 'react-native';

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    left: 0,
    right: 0,
    height: 26,
  },

  notice: {
    width: 170,
    height: 26,
    borderRadius: 26,
    position: 'absolute',
    overflow: 'hidden',
  },

  userName: {
    flexShrink: 1,
    height: 22,
    overflow: 'hidden',
  },
  noticeText: {
    flexShrink: 0,
    fontSize: 10,
    lineHeight: 22,
    color: '#fff',
    height: 22,
  },
});
export default styles;
