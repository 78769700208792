import {LoadingView, RenderHtml} from '@/components';
import {useDispatch, useSelector} from '@/utils/dva';
import useLov from '@/utils/hooks/useLov';
import {useFocusEffect} from '@react-navigation/native';
import {useRequest} from 'ahooks';
import React from 'react';
import {ScrollView, StyleSheet, useWindowDimensions} from 'react-native';

const styles = StyleSheet.create({
  page: {
    flex: 1,
  },

  content: {
    paddingHorizontal: 14,
    paddingVertical: 0,
  },
});
const DocumentPage = ({navigation, route}) => {
  const type = route?.params?.type;
  const docs = useLov('DOCUMENT_TYPE');
  const title = docs?.[type] ?? '';

  useFocusEffect(() => {
    navigation.setOptions({title: title ?? ''});
  });

  const {[title]: doc} = useSelector(s => s.document) || {};
  const dispatch = useDispatch();
  const {data, loading} = useRequest(() => dispatch({type: 'document/getDocument', docType: type}), {
    initialData: doc,
    ready: title && !doc,
    formatResult: res => res?.success && res.data,
    onSuccess: dt => {
      if (dt?.name) {
        navigation.setOptions({title: dt.name});
      }
    },
  });

  const {width} = useWindowDimensions();
  return (
    <LoadingView loading={loading} style={styles.page}>
      <ScrollView contentContainerStyle={styles.content}>
        {!!data?.content && (
          <RenderHtml
            contentWidth={width}
            source={{
              html: data.content,
            }}
            enableExperimentalMarginCollapsing={true}
          />
        )}
      </ScrollView>
    </LoadingView>
  );
};

DocumentPage.screenName = 'Document';
DocumentPage.screenOptions = {
  title: '',
};
DocumentPage.linking = 'document/:type';
export default DocumentPage;
