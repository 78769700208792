import {getPagedPointBillItems} from './service';

export const namespace = 'point';
const model = {
  namespace,
  state: {
    billItems: [], // 交易记录
  },
  effects: {
    /**
     * 交易记录分页查询
     * @actionParam params: {
     *   current,
     *   pageSize,
     * }
     * */
    *getPagedPointBillItems({params}, {call, put}) {
      const res = yield call(getPagedPointBillItems, params);
      if (res?.success) {
        yield put({type: 'addBillItems', billItems: res?.data});
      }
      return res;
    },
  },
  reducers: {
    saveState(state, action) {
      const {type, ...nextState} = action || {};
      return {...state, ...nextState};
    },

    addBillItems(state, action) {
      const {billItems} = action || {};
      const oldData = state.billItems;
      return {...state, billItems: _.concat([], oldData ?? [], billItems ?? [])};
    },

    clearBillItems(state, action) {
      return {...state, billItems: []};
    },
  },
};

export default model;
