import Header from '@/components/Header';
import Icon from '@/components/Icon';
import RecordsSection from '@/pages/home/boxCupboard/components/RecordsSection';
import StatisticSection from '@/pages/home/boxCupboard/components/StatisticSection';
import useAuthedTab from '@/pages/home/useAuthedTab';
import PieceList from '@/pages/piece/components/PieceList';
import theme from '@/theme';
import {useDispatch} from '@/utils/dva';
import authedComponent from '@/utils/hooks/authedComponent';
import {useFocusEffect} from '@react-navigation/native';
import {useRequest} from 'ahooks';
import React, {useCallback} from 'react';
import {SafeAreaView, StatusBar, StyleSheet, View} from 'react-native';

const ListHeaderComponent = React.memo(() => [
  <StatisticSection key="$statisticSection" />,
  <RecordsSection key="$recordsSection" />,
  <PieceList.ListHead key="$listHead" />,
]);
const _BoxCupboardPage = authedComponent(({navigation, route}) => {
  const dispatch = useDispatch();

  const {run} = useRequest(() => dispatch({type: 'global/getUserBoxStatistics'}), {
    manual: true,
  });

  useFocusEffect(
    useCallback(() => {
      run();
    }, [run]),
  );

  return (
    <SafeAreaView style={styles.page}>
      <View style={styles.container}>
        <Header key="$header" title="盒柜" headerLeft={() => null} />
        <PieceList navigation={navigation} ListHeaderComponent={ListHeaderComponent} />
      </View>
    </SafeAreaView>
  );
});
const styles = StyleSheet.create({
  page: {
    flex: 1,
    paddingTop: StatusBar.currentHeight,
    backgroundColor: '#fff',
  },
  container: {
    flex: 1,
    backgroundColor: theme.fill_body,
  },
  section: {
    marginBottom: 10,
    backgroundColor: '#fff',
  },
});

const BoxCupboardPage = props => {
  useAuthedTab(props);
  return React.createElement(_BoxCupboardPage, props);
};
BoxCupboardPage.screenName = 'BoxCupboardTab';
BoxCupboardPage.screenOptions = {
  lazy: false,
  tabBarIcon: ({focused, color}) => <Icon type={`tabbar_cupboard${focused ? '_active' : ''}`} fill={color} />,
  tabBarLabel: '盒柜',
};
BoxCupboardPage.linking = 'box_cupboard';
export default BoxCupboardPage;
