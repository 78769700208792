import {deleteAddress, getAddresses, saveAddress} from '@/pages/address/service';
import _ from 'lodash';

export const namespace = 'address';
const initialState = {
  data: [],
};
const model = {
  namespace,
  state: initialState,
  effects: {
    /**
     * 获取全部地址
     * */
    *getAddresses(__, {call, put}) {
      const res = yield call(getAddresses);
      if (res?.success) {
        yield put({type: 'saveState', data: res.data});
      }
      return res;
    },

    // /**
    //  * 获取单个地址
    //  * @actionParam id: 地址ID
    //  * */
    // *getAddress({id}, {call, put}) {
    //   return yield call(getAddress, id);
    // },

    /**
     * 保存地址
     * @actionParam data: 地址数据
     * */
    *saveAddress({data}, {call, put, select}) {
      const res = yield call(saveAddress, data);
      if (res?.success) {
        let list = yield select(s => s[namespace].data);
        if (data?.defaultFlag) {
          _.forEach(list, item => {
            item.defaultFlag = false;
          });
        }
        const finded = _.find(list, item => item?.id === data?.id);
        if (finded) {
          _.extend(finded, res?.data ?? data);
        } else {
          if (!_.isArray(list)) {
            list = [];
          }
          list.push(res?.data ?? data);
        }
        yield put({
          type: 'saveState',
          data: _.clone(list),
        });
      }
      return res;
    },

    /**
     * 删除地址
     * @actionParam id
     * */
    *deleteAddress({id}, {call, put, select}) {
      const res = yield call(deleteAddress, id);
      if (res?.success) {
        const list = yield select(s => s[namespace].data);
        if (_.some(list, item => item?.id === id)) {
          yield put({
            type: 'saveState',
            data: _.filter(list, item => item?.id !== id),
          });
        }
      }
      return res;
    },
  },
  reducers: {
    saveState(state, action) {
      const {type, ...nextState} = action || {};
      return {...state, ...nextState};
    },
    clear(state, action) {
      return _.clone(initialState);
    },
  },
};

export default model;
