import RatioView from '@/components/CustomizedView/RatioView';
import Icon from '@/components/Icon';
import RarityTag from '@/pages/box/components/RarityTag';
import BoxPageLayoutContext from '@/pages/box/detail/components/BoxPageLayoutContext';
import BoxProdDetailModal from '@/pages/box/detail/components/BoxProdDetailModal';
import BoxProdListModal from '@/pages/box/detail/components/BoxProdListModal';
import useAnimation from '@/pages/box/detail/components/BoxSections/ProdSection/useAnimation';
import theme from '@/theme';
import {useImageResize} from '@/utils/remoteData/ImageResize';
import {isAndroid, isWeb} from '@/utils/utils';
import {useNavigation} from '@react-navigation/core';
import {useFocusEffect} from '@react-navigation/native';
import {useTimeout} from 'ahooks';
import {assign} from 'lodash';
import React, {useContext, useState} from 'react';
import {Image, Pressable, StyleSheet, Text, View} from 'react-native';

const Component = ({section, data}) => {
  const [listVisible, setListVisible] = useState(false);
  const [detailVisible, setDetailVisible] = useState(false);
  const layout = useContext(BoxPageLayoutContext);
  const navigation = useNavigation();

  useFocusEffect(() => {
    return navigation.addListener('beforeRemove', evt => {
      if (detailVisible) {
        setDetailVisible(false);
        isAndroid() && evt.preventDefault();
        return;
      }
      if (listVisible) {
        setListVisible(false);
        isAndroid() && evt.preventDefault();
        return;
      }
    });
  });

  const openListModal = () => setListVisible(true);
  const closeListModal = () => setListVisible(false);
  const openDetailModal = () => setDetailVisible(true);
  const closeDetailModal = () => setDetailVisible(false);

  const [currentBoxProd, setCurrentBoxProd] = useState(null);
  const onPressBoxProd = boxProd => {
    setCurrentBoxProd(boxProd);
    openDetailModal();
  };

  const gotoMall = () => {
    closeListModal();
    closeDetailModal();
    navigation.push('Product', {
      screen: 'ProductDetail',
      params: {id: currentBoxProd.productId},
    });
  };

  // 为了更快加载盲盒内展示图片，延迟加载一部分小图
  const [ready, setReady] = useState(false);
  useTimeout(() => {
    setReady(true);
  }, 800);
  const [, resizeImage] = useImageResize({object: 'prod', size: 'xs'});
  const animatedContentDom = useAnimation({
    data: section?.data,
    displayCount: 4,
    containerPadding: 10,
    containerStyle: styles.prodsContainer,
    renderItem: (item, i, {width}) => (
      <View key={i} style={{padding: 5, width}}>
        <RatioView ratio={1} defaultHeight={width - 10} style={[styles.prod]}>
          {(i <= 10 || ready) && (
            <Image source={{uri: resizeImage(item?.boxPicture)}} style={StyleSheet.absoluteFill} />
          )}
          <RarityTag rarity={item?.style} small />
        </RatioView>
      </View>
    ),
    onPress: openListModal,
  });
  return (
    <>
      <Pressable style={[styles.container, section.style]} onPress={openListModal}>
        <View style={styles.slideUpHandle}>
          <View style={styles.slideUpHandleBg} />
          <Pressable style={{alignItems: 'center'}} onPress={openListModal}>
            <Icon type="slide_up" />
            <Text style={styles.slideUpHandleText}>查看全部商品</Text>
          </Pressable>
        </View>

        {animatedContentDom}
      </Pressable>
      <BoxProdListModal
        data={data}
        visible={listVisible}
        onClose={closeListModal}
        navigation={navigation}
        height={isWeb() ? layout?.height - 28 : layout?.height}
        onPressBoxProd={onPressBoxProd}
      />
      <BoxProdDetailModal
        id={currentBoxProd?.productId}
        visible={detailVisible}
        gotoMall={gotoMall}
        onClose={closeDetailModal}
        navigation={navigation}
        height={isWeb() ? layout?.height - 28 : layout?.height}
      />
    </>
  );
};

export default class Index {
  static type = 'prod';
  static component = Component;
  style = {};

  constructor(config) {
    const {swiperProps, ...rest} = config || {};
    assign(this, rest);
  }

  get type() {
    return this?.constructor?.type;
  }
}

const styles = StyleSheet.create({
  container: {
    marginTop: -50,
    backgroundColor: '#fff',
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
    minHeight: 140,
    paddingTop: 36,
  },
  slideUpHandle: {
    width: 200,
    height: 50,
    position: 'absolute',
    left: '50%',
    top: -13,
    transform: [{translateX: -100}],
    paddingTop: 6,
    justifyContent: 'flex-start',
    alignItems: 'center',
    overflow: 'hidden',
  },
  slideUpHandleBg: {
    width: 200,
    height: 200,
    borderRadius: 200,
    backgroundColor: '#fff',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: -1,
  },

  slideUpHandleText: {
    textAlign: 'center',
    marginTop: 8,
    fontSize: 12,
  },
  prodsContainer: {
    // paddingHorizontal: 12,
    paddingHorizontal: 10,
    paddingBottom: 12,
  },
  prod: {
    borderWidth: StyleSheet.hairlineWidth,
    borderColor: theme.border_color_base,
    borderRadius: 1,
    // backgroundColor: theme.fill_grey,
  },
});
