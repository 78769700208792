import {LoadingView} from '@/components';
import Empty from '@/components/Empty';
import Loading from '@/components/Loading';
import BoxRecordsContext from '@/pages/box/records/components/BoxRecordsContext';
import OpenedBoxFooterBar from '@/pages/box/records/components/OpenedBoxFooterBar';
import OpenedBoxListCard from '@/pages/box/records/components/OpenedBoxListCard';
import theme from '@/theme';
import {useDispatch} from '@/utils/dva';
import usePagedRequest from '@/utils/hooks/usePagedRequest';
import BoxRecordStatus from '@/utils/localData/BoxRecordStatus';
import {useNavigation} from '@react-navigation/core';
import _ from 'lodash';
import React, {useContext, useEffect, useRef, useState} from 'react';
import {StyleSheet, View, VirtualizedList} from 'react-native';

const OpenedBoxList = ({status}) => {
  const navigation = useNavigation();
  const dispatch = useDispatch();
  const {rarity} = useContext(BoxRecordsContext);
  const listRef = useRef();

  const {data, load, loading, hasMore, loadingMore, loadMore, mutate, refresh, refreshing} = usePagedRequest(
    ({current, pageSize}) =>
      dispatch({
        type: 'box/getPagedOpendBoxes',
        params: {
          current,
          pageSize,
          status,
          rarity,
        },
      }),
    {
      ready: !!status,
      formatData: data => {
        const {list} = data || {};
        _.each(list, it => {
          if (it?.skus) {
            it.currentSkuId = _.minBy(it.skus, 'amount')?.id;
          }
        });
        return data;
      },
      refreshDeps: [rarity, status],
    },
  );

  const pending = status === BoxRecordStatus.Paid;
  const [selectedKeys, setSelectedKeys] = useState([]);
  useEffect(() => {
    setSelectedKeys([]);
  }, [rarity, status]);

  const allKeys = _.map(data, 'id');
  const onCheckedAll = checked => {
    setSelectedKeys(checked ? allKeys : []);
  };
  const onChecked = (checked, item) => {
    if (checked) {
      setSelectedKeys(_.concat(selectedKeys, item?.id));
    } else {
      setSelectedKeys(_.without(selectedKeys, item?.id));
    }
  };
  const onChangeVariant = (currentSkuId, item) => {
    const index = _.findIndex(data, item);
    mutate(_.clone(_.fill(data, _.extend({}, item, {currentSkuId: currentSkuId}), index, index + 1)));
  };

  return (
    <LoadingView loading={loading} style={[styles.container]}>
      <View style={styles.list}>
        <VirtualizedList
          ref={listRef}
          style={styles.list}
          contentContainerStyle={styles.contentContainerStyle}
          data={data}
          initialNumToRender={10}
          renderItem={({item}) => (
            <OpenedBoxListCard
              status={status}
              pending={pending}
              checked={_.some(selectedKeys, key => key === item?.id)}
              onChecked={checked => onChecked(checked, item)}
              onChangeVariant={currentSkuId => onChangeVariant(currentSkuId, item)}
              data={item}
              style={styles.item}
            />
          )}
          getItemCount={data => data?.length}
          getItem={(data, index) => data[index]}
          onEndReached={() => {
            hasMore && !loadingMore && loadMore();
          }}
          onRefresh={refresh}
          refreshing={refreshing}
          ListEmptyComponent={
            !loading &&
            !data?.length && (
              <Empty
                style={{backgroundColor: 'transparent'}}
                text="暂时还没有记录~去逛逛获取更多"
                actions={[
                  {
                    text: '去逛逛',
                    onPress: () => navigation.navigate('Home', {screen: 'BoxTab'}),
                  },
                ]}
              />
            )
          }
          ListFooterComponent={loadingMore && <Loading />}
        />
      </View>

      {pending && (
        <OpenedBoxFooterBar
          listRef={listRef}
          data={data}
          selectedKeys={selectedKeys}
          onCheckedAll={onCheckedAll}
          refreshList={() => load()}
          onPicked
        />
      )}
    </LoadingView>
  );
};
const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  containerWithFooter: {
    paddingBottom: 70,
  },
  list: {
    flex: 1,
  },
  contentContainerStyle: {
    minHeight: '100%',
  },
  item: {
    marginBottom: 8,
    marginHorizontal: 10,
  },
  footer: {
    height: 70,
    paddingHorizontal: 20,
    paddingVertical: 11,
    backgroundColor: '#fff',
    borderTopWidth: StyleSheet.hairlineWidth,
    borderColor: theme.border_color_base,
  },
  footerBtn: {
    width: 120,
    height: 48,
    marginLeft: 10,
  },
});

export default OpenedBoxList;
