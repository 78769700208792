import {Dialog, LoadingView} from '@/components';
import Button from '@/components/Button';
import CrystalBall from '@/components/CrystalBall';
import {Center, Flex} from '@/components/Flex';
import Icon, {IconButton} from '@/components/Icon';
import Modal from '@/components/Modal';
import Stepper from '@/components/Stepper';
import theme from '@/theme';
import {useDispatch, useSelector} from '@/utils/dva';
import useBottomInset from '@/utils/hooks/useBottomInset';
import useControlledModalProps from '@/utils/hooks/useControlledModalProps';
import useLov from '@/utils/hooks/useLov';
import FontWeight from '@/utils/localData/FontWeight';
import {getDINProBoldStyle, getShadowButton} from '@/utils/styleUtils';
import {useRequest} from 'ahooks';
import _ from 'lodash';
import React, {useMemo, useState} from 'react';
import {StyleSheet, Text, View} from 'react-native';

const PieceRecoveryModal = ({data, onSuccess, ...rest}) => {
  const [, , , redress] = useBottomInset(10);
  const {visible, close, modalProps} = useControlledModalProps(rest);
  const currentUser = useSelector(s => s.global.currentUser);
  const {fragmentId, count: total} = data || {};
  const [count, setCount] = useState(total);
  const {percent} = useLov('FRAGMENT_RECYCLE_PERCENT') || {};
  const dispatch = useDispatch();

  const {
    data: result,
    submitting,
    run: submit,
  } = useRequest(
    () =>
      dispatch({
        type: 'piece/recovery',
        data: {id: fragmentId, count},
      }),
    {
      ready: !!fragmentId,
      manual: true,
      onSuccess(res) {
        _.isFunction(onSuccess) && onSuccess(res);
        if (res?.success) {
          // 回收成功后
          const {orderId, userScore, recycleScore} = res.data || {};
          //  更新显示的回收价格
          if (recycleScore) {
            Dialog.success('+' + recycleScore + '口袋币');
          }
          if (userScore) {
            // todo: 余额变化的动效
            // 更新用户口袋币余额
            dispatch({type: 'global/saveState', currentUser: {...currentUser, score: userScore}});
            // 提示并跳转到回收订单
            _.delay(close, 1000);
          }
        } else {
          Dialog.respError(res);
        }
      },
    },
  );

  const score = useMemo(() => {
    const _score = data?.score ?? 0;
    return _.floor(parseFloat(_score).mul(percent).mul(count));
  }, [data?.score, percent, count]);

  const handleSubmit = () => {
    if (!count > 0) {
      return Dialog.info({content: '请输入回收数量', position: 'bottom'});
    }
    submit();
  };
  return (
    <Modal
      popup
      visible={visible}
      animationType="slide-up"
      maskClosable={false} // onClose={() => setVisible(false)}
      {...modalProps}
      style={[styles.modal, {paddingBottom: redress}]}>
      <LoadingView loading={submitting} layer={true} style={styles.container}>
        <IconButton type="close" size={12} onPress={close} style={styles.close} />
        <View style={styles.content}>
          <Center style={{marginTop: 50}}>
            {visible && <CrystalBall amount={score} resultAmount={result?.data?.recycleScore} />}
          </Center>

          <Flex justify="between" style={{height: 46}}>
            <Text style={{fontSize: 12}}>回收碎片</Text>
            <Flex align="baseline" justify="end" style={{flex: 0}}>
              <Stepper readOnly={false} min={1} max={total} value={count} onChange={setCount} />
            </Flex>
          </Flex>
          <View style={styles.divider} />
          <Flex justify="between" style={{height: 46}}>
            <Flex>
              <Icon type="coins_colorful" style={{marginRight: 5}} />
              <Text style={{fontSize: 12}}>口袋币余额</Text>
            </Flex>
            <Text style={[styles.priceText, {fontSize: 12, color: theme.color_text_secondary}]}>
              {currentUser?.score ?? 0}
            </Text>
          </Flex>
        </View>
        <Flex style={[styles.toolbar, {marginLeft: -11}]}>
          <Button
            type="primary"
            fontWeight={FontWeight.SemiBold}
            style={[styles.toolbarButton, styles.btnBlackShadow]}
            onPress={close}>
            我再想想
          </Button>
          <Button
            type="primary"
            fontWeight={FontWeight.SemiBold}
            color={theme.brand_secondary}
            isLight={false}
            style={[styles.toolbarButton, styles.btnRedShadow]}
            onPress={handleSubmit}>
            确认回收
          </Button>
        </Flex>
      </LoadingView>
    </Modal>
  );
};
const styles = StyleSheet.create({
  modal: {
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
    overflow: 'hidden',
  },
  container: {
    height: 436,
    backgroundColor: '#fff',
    paddingBottom: 70,
  },
  close: {
    position: 'absolute',
    top: theme.h_spacing_lg - 4,
    right: theme.h_spacing_lg - 4,
    zIndex: 99,
  },
  header: {
    paddingVertical: 16,
    paddingHorizontal: theme.h_spacing_lg,
  },
  title: {},
  content: {
    paddingHorizontal: theme.h_spacing_lg,
    width: '100%',
    flex: 1,
  },
  toolbar: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: '#fff',
    paddingHorizontal: theme.h_spacing_lg,
    paddingVertical: theme.v_spacing_md,
    marginBottom: 10,
  },
  toolbarButton: {
    height: 48,
    marginLeft: 11,
    flex: 1,
  },
  btnBlackShadow: getShadowButton('#000'),
  btnRedShadow: getShadowButton(theme.brand_secondary),

  priceText: {
    ...getDINProBoldStyle(12),
    lineHeight: 15,
  },

  divider: {
    borderTopWidth: StyleSheet.hairlineWidth,
    borderColor: theme.border_color_base,
    marginTop: 7,
    marginBottom: 5,
  },
});

export default PieceRecoveryModal;
