import PointBillPage from '@/pages/point/bill';
import authedPage from '@/utils/hooks/authedPage';
import {createStackNavigator} from '@/utils/navigatorUtils';
import React from 'react';

export default createStackNavigator(
  [PointBillPage],
  {
    screenName: 'Point',
    screenOptions: {
      headerShown: false,
    },
    linking: 'point',
  },
  [],
  [authedPage],
);
