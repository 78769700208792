import React from 'react';
import {ImageBackground, StyleSheet} from 'react-native';
import assets_hot from '../assets/_hot';

const styles = StyleSheet.create({
  hotTag: {
    width: 36,
    height: 16,
    position: 'absolute',
    left: 0,
    top: 0,
    zIndex: 99,
  },
});
const HotTag = ({style}) => {
  return <ImageBackground source={assets_hot} resizeMode="cover" style={[styles.hotTag, style]} />;
};

export default HotTag;
