import Icon from '@/components/Icon';
import FontWeight from '@/utils/localData/FontWeight';
import {isFunction} from 'lodash';
import React from 'react';
import {StyleSheet, Text} from 'react-native';

const styles = StyleSheet.create({
  text: {
    fontSize: 16,
    fontWeight: FontWeight.Medium,
  },
});
const HeaderTitle = ({children, tintColor = '#000'} = {}) => {
  if (children === 'logo') {
    return <Icon type="text_logo" />;
  }
  return React.isValidElement(children) ? (
    children
  ) : isFunction(children) ? (
    children(tintColor)
  ) : (
    <Text style={[styles.text, tintColor && {color: tintColor}]}>{children}</Text>
  );
};

export default HeaderTitle;
