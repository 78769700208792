import Icon from '@/components/Icon';
import {TranslucentStatusBar} from '@/components/StatusBar';
import BoxList from '@/pages/home/box/components/BoxList';
import Head from '@/pages/home/box/components/Head';
import StickyHeader from '@/pages/home/box/components/StickyHeader';
import useFocusedStatusBar from '@/utils/hooks/useFocusedStatusBar';
import {useFocusCheckActivePopup} from '@/utils/hooks/usePopupManager';
import {isWeb} from '@/utils/utils';
import React, {useRef, useState} from 'react';
import {Animated, Dimensions, Platform, ScrollView, StatusBar, StyleSheet, View} from 'react-native';
import {useSafeAreaInsets} from 'react-native-safe-area-context';

const WindowHeight = Dimensions.get('window').height;
const NoticesWarpHeight = 42;
const TopTabBarHeight = 54;
const offsetTop = 225;
const styles = StyleSheet.create({
  page: {
    backgroundColor: '#020305',
    flex: 1,
  },
  offset: {
    height: offsetTop,
    ...Platform.select({
      web: {
        marginTop: -28,
      },
    }),
  },
  statusBarOffset: {
    height: StatusBar.currentHeight,
  },
  list: {
    minHeight: WindowHeight - NoticesWarpHeight - TopTabBarHeight,
  },
});
const statusBarDom = <TranslucentStatusBar barStyle="light-content" />;
const BoxPage = ({navigation}) => {
  const statusBar = useFocusedStatusBar(navigation, statusBarDom);
  useFocusCheckActivePopup('box'); // 检查弹窗

  const [currentType, setCurrentType] = useState('');
  const scrollView = useRef(null);

  const animValue = useRef(new Animated.Value(1)).current;
  const opacity = animValue.interpolate({
    inputRange: [0, 160, 226],
    outputRange: [1, 0, 0],
  });

  const [layout, setLayout] = useState({height: WindowHeight});
  const {top: topInset} = useSafeAreaInsets();
  const scrollViewMinHeight = layout.height - NoticesWarpHeight - TopTabBarHeight - topInset;

  return (
    <View
      style={styles.page}
      onLayout={evt => {
        setLayout(evt.nativeEvent.layout);
      }}>
      {statusBar}
      <ScrollView
        stickyHeaderIndices={[1]}
        nestedScrollEnabled={true}
        ref={scrollView}
        onScroll={Animated.event([{nativeEvent: {contentOffset: {y: animValue}}}], {
          useNativeDriver: false,
          listener: evt => {
            if (scrollView?.current) {
              scrollView.current.scrollTop = evt.nativeEvent.contentOffset.y;
              scrollView.current.jumpToScrollTop = isWeb() ? offsetTop - 28 : offsetTop;
            }
          },
        })}
        scrollEventThrottle={16}>
        <View style={[styles.offset, {marginBottom: -topInset}]} />
        <StickyHeader
          navigation={navigation}
          currentType={currentType}
          setCurrentType={setCurrentType}
          scrollView={scrollView}
        />
        <Head navigation={navigation} style={{opacity: opacity}} />
        <BoxList
          style={[styles.list, {minHeight: scrollViewMinHeight}]}
          contentContainerStyle={{paddingTop: 16}}
          type={currentType}
        />
      </ScrollView>
    </View>
  );
};

BoxPage.screenName = 'BoxTab';
BoxPage.screenOptions = {
  tabBarIcon: ({focused, color}) => <Icon type={`tabbar_box${focused ? '_active' : ''}`} fill={color} />,
  tabBarLabel: '口袋盲盒',
};
BoxPage.linking = 'box';
export default BoxPage;
