import {Dialog} from '@/components';
import {IconButton} from '@/components/Icon';
import WechatApi from '@/NativeModules/Wechat';
import {useDispatch} from '@/utils/dva';
import LoginType from '@/utils/localData/LoginType';
import {useRequest} from 'ahooks';
import {isFunction} from 'lodash';
import React from 'react';
import {StyleSheet} from 'react-native';

const LoginByWechat = ({state, setState, onSuccess, onChangeLoginType}) => {
  const dispatch = useDispatch();
  const {run: loginByWechat} = useRequest(code => dispatch({type: 'user/loginByWechat', code}), {
    manual: true,
    onSuccess(res) {
      isFunction(onSuccess) && onSuccess(res);
      if (res?.success) {
        // Dialog.success(res.msg || '登录成功');
      } else if (res?.code == 401 && res?.data) {
        // 绑定手机号
        setState({token: res.data});
        onChangeLoginType(LoginType.BindPhone);
      } else {
        Dialog.respError(res);
      }
    },
  });

  const onPress = async () => {
    if (!state?.agreement) {
      setState({
        agreementTipVisible: true,
      });
      return;
    } else {
      setState({
        agreementTipVisible: false,
      });
    }
    setState({loading: true});
    try {
      const supported = await WechatApi.isSupported();
      if (supported) {
        const apiRes = await WechatApi.auth(null);
        const {code} = apiRes || {};
        if (code) {
          await loginByWechat(code);
        }
      } else {
        Dialog.alert('', '请先安装微信APP');
      }
      setState({loading: false});
    } catch (err) {
      setState({loading: false});
    }
  };
  return <IconButton type="wechat" style={styles.socialIcon} onPress={onPress} />;
};
const styles = StyleSheet.create({
  socialIcon: {
    marginHorizontal: 25,
    width: 24,
    height: 24,
  },
});
export default LoginByWechat;
