import {defaultHeaderConfig} from '@/components/Header';
import theme from '@/theme';
import errorBoundary from '@/utils/hooks/errorBoundary';
import {createNativeStackNavigator} from '@react-navigation/native-stack';
import _ from 'lodash';
import React from 'react';

export function getLinkings(screens) {
  return _.chain(screens)
    .filter(s => s.screenName && s.linking !== undefined)
    .keyBy('screenName')
    .mapValues(s => s.linking)
    .value();
}

export function getScreenElements(screens, Screen, getName = s => s?.screenName || '', defaultOptions, hooks) {
  return _.chain(screens)
    .filter(getName)
    .map((screen, i) => {
      // if (!screen.ComponentWithStatusBar) {
      //   screen.ComponentWithStatusBar = props => {
      //     return (
      //       <>
      //         <DefaultStatusBar />
      //         {React.createElement(screen, props)}
      //       </>
      //     );
      //   };
      // }
      return (
        <Screen
          key={`$screen_${getName(screen)}_${i}`}
          name={getName(screen)}
          // component={screen?.ComponentWithStatusBar ?? screen}
          component={errorBoundary(screen, undefined, hooks)}
          options={{...defaultOptions, ...screen.screenOptions}}
        />
      );
    })
    .value();
}

export function createStackNavigator(screens, defines, modals, hooks) {
  const {screenOptions, screenName, linking, ...rest} = defines || {};
  const Stack = createNativeStackNavigator();

  const Navigator = () => {
    return (
      <Stack.Navigator screenOptions={{...defaultHeaderConfig, contentStyle: {backgroundColor: theme.fill_body}}}>
        <Stack.Group>{getScreenElements(screens, Stack.Screen, undefined, {}, hooks)}</Stack.Group>
        {!!modals?.length && (
          <Stack.Group>{getScreenElements(modals, Stack.Screen, s => s?.modalName, {}, hooks)}</Stack.Group>
        )}
      </Stack.Navigator>
    );
  };
  Navigator.screenName = screenName;
  Navigator.screenOptions = screenOptions;
  Navigator.linking = _.isString(linking)
    ? {
        path: linking,
        screens: getLinkings(screens),
      }
    : _.assign({}, linking, {
        screens: getLinkings(screens),
      });
  _.assign(Navigator, rest);
  return Navigator;
}
