import {Dialog} from '@/components';
import {IconButton} from '@/components/Icon';
import AppleApi from '@/NativeModules/Apple';
import {useDispatch} from '@/utils/dva';
import LoginType from '@/utils/localData/LoginType';
import {useRequest} from 'ahooks';
import {isFunction} from 'lodash';
import React from 'react';
import {StyleSheet} from 'react-native';

const LoginByApple = ({state, setState, onSuccess, onChangeLoginType}) => {
  const dispatch = useDispatch();
  const {run: loginByApple} = useRequest(
    (identityToken, userID) => dispatch({type: 'user/loginByApple', identityToken, userID}),
    {
      manual: true,
      onSuccess(res) {
        isFunction(onSuccess) && onSuccess(res);
        if (res?.success) {
          // Dialog.success(res.msg || '登录成功');
        } else if (res?.code == 401 && res?.data) {
          // 绑定手机号
          setState({token: res.data});
          onChangeLoginType(LoginType.BindPhone);
        } else {
          Dialog.respError(res);
        }
      },
    },
  );

  const onPress = async () => {
    if (!state?.agreement) {
      setState({
        agreementTipVisible: true,
      });
      return;
    } else {
      setState({
        agreementTipVisible: false,
      });
    }
    setState({loading: true});
    try {
      const supported = await AppleApi.isSupported();
      if (supported) {
        const apiRes = await AppleApi.auth();
        const {identityToken, userID} = apiRes || {};
        if (identityToken && userID) {
          await loginByApple(identityToken, userID);
        }
      } else {
        Dialog.alert('', '不支持当前系统版本');
      }
      setState({loading: false});
    } catch (err) {
      setState({loading: false});
    }
  };

  return <IconButton type="apple" style={styles.socialIcon} onPress={onPress} />;
};
const styles = StyleSheet.create({
  socialIcon: {
    marginHorizontal: 25,
    width: 24,
    height: 24,
  },
});
export default LoginByApple;
