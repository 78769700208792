import {Flex} from '@/components/Flex';
import Tag from '@/components/Tag';
import FontWeight from '@/utils/localData/FontWeight';
import _, {assign} from 'lodash';
import React from 'react';
import {StyleSheet, Text} from 'react-native';
import _styles from './style';

const Component = ({section}) => {
  const offers = section.data;
  const {label, content} = _.first(offers) || {};

  return (
    !!offers?.length && (
      <Flex style={[_styles.section, styles.container, section.style]}>
        <Text style={styles.label}>促销</Text>
        <Flex style={[styles.content]}>
          <Tag small color="#FF053D">
            {label}
          </Tag>
          <Text style={styles.text}>{content}</Text>
        </Flex>
      </Flex>
    )
  );
};
const styles = StyleSheet.create({
  container: {},
  label: {
    fontWeight: FontWeight.SemiBold,
    lineHeight: 30,
    minWidth: 46,
  },
  content: {
    flex: 1,
  },
  text: {
    marginLeft: 8,
    fontSize: 12,
    flex: 1,
  },
});

export default class OfferSection {
  static type = 'offer';
  static component = Component;
  style = {};

  constructor(config) {
    assign(this, config);
  }

  get type() {
    return this?.constructor?.type;
  }
}
