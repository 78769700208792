import theme from '@/theme';
import React from 'react';
import {StyleSheet, View} from 'react-native';

const styles = StyleSheet.create({
  container: {
    marginVertical: theme.v_spacing_lg,
    height: StyleSheet.hairlineWidth,
    overflow: 'hidden',
  },
  line: {
    borderWidth: StyleSheet.hairlineWidth,
    borderStyle: 'dashed',
    borderColor: theme.border_color_base,
    borderRadius: 0.1,
  },
});
const Devider = ({style, borderStyle = 'solid', borderWidth = StyleSheet.hairlineWidth}) => {
  return (
    <View style={[styles.container, {height: borderWidth}, style]}>
      <View style={[styles.line, {borderStyle, borderWidth}]} />
    </View>
  );
};

export default Devider;
