import Button from '@/components/Button';
import {Flex} from '@/components/Flex';
import Icon from '@/components/Icon';
import ProductContext from '@/pages/product/detail/components/ProductContext';
import ProdStatus from '@/utils/localData/ProdStatus';
import {getPrice} from '@/utils/priceUtils';
import theme from '@/theme';
import {useDispatch, useSelector} from '@/utils/dva';
import useBottomInset from '@/utils/hooks/useBottomInset';
import FontWeight from '@/utils/localData/FontWeight';
import PayType from '@/utils/localData/PayType';
import {useRequest} from 'ahooks';
import React, {useContext} from 'react';
import {ActivityIndicator, StyleSheet, Text, View} from 'react-native';

const styles = StyleSheet.create({
  toolbar: {
    borderTopWidth: StyleSheet.hairlineWidth,
    borderTopColor: theme.border_color_base,
    backgroundColor: '#fff',
  },
  container: {
    paddingHorizontal: theme.h_spacing_lg,
    paddingVertical: theme.v_spacing_md,
  },
  amount: {},
  amountLabel: {
    fontSize: 12,
    lineHeight: 16,
    marginBottom: 5,
    fontWeight: FontWeight.SemiBold,
  },
  amountValue: {
    fontSize: 12,
    lineHeight: 16,
    fontWeight: FontWeight.Bold,
  },
  buyBtn: {
    width: 140,
    height: 40,
    marginLeft: 15,
  },
  action: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  actionText: {
    fontSize: 10,
    fontWeight: FontWeight.SemiBold,
    lineHeight: 20,
  },
});
const ProductFooterBar = ({data}) => {
  const currentUser = useSelector(s => s.global.currentUser);
  const [, , , paddingBottom] = useBottomInset(10);
  const {submitting, selectVariant} = useContext(ProductContext);
  const [price] = getPrice(data);
  const {fragmentId, fragmentFlag, status} = data || {};

  const dispatch = useDispatch();
  const {data: pieceCount, loading} = useRequest(
    () =>
      dispatch({
        type: 'piece/getPieceCount',
        pieceId: fragmentId,
      }),
    {
      ready: !!(fragmentFlag && fragmentId && currentUser),
      formatResult: res => res?.data,
      refreshDeps: [currentUser],
    },
  );

  return (
    <View style={[styles.toolbar, {paddingBottom}]}>
      <Flex justify="between" style={[styles.container]}>
        <Flex style={[styles.left]}>
          {loading ? (
            <ActivityIndicator color={theme.brand_primary} />
          ) : (
            <View style={styles.amount}>
              <Text style={styles.amountLabel}>{fragmentFlag ? `已有${price?.unit}` : `${price?.unit}余额`} </Text>
              {currentUser ? (
                fragmentFlag ? (
                  <Flex>
                    <View style={{marginRight: 5}}>
                      <Icon type={PayType.get('Piece').icon} size={12} />
                    </View>
                    <Text style={styles.amountValue}>{pieceCount}</Text>
                  </Flex>
                ) : (
                  <Flex>
                    <View style={{marginRight: 5}}>
                      <Icon type={PayType.get('Score').icon} size={12} />
                    </View>
                    <Text style={styles.amountValue}>{currentUser?.score}</Text>
                  </Flex> // 口袋币余额
                )
              ) : (
                <Text style={styles.amountValue}>未登录</Text>
              )}
            </View>
          )}
        </Flex>
        <Flex style={[styles.right]}>
          {/*<TouchableOpacity style={styles.action}>
            <Icon type="star_outline" size={20} />
            <Text style={styles.actionText}>收藏</Text>
          </TouchableOpacity>*/}
          {status === ProdStatus.SALE ? (
            <Button type="primary" loading={submitting} style={styles.buyBtn} onPress={selectVariant}>
              立即购买
            </Button>
          ) : (
            <Button type="primary" style={styles.buyBtn} disabled={true}>
              已下架
            </Button>
          )}
        </Flex>
      </Flex>
    </View>
  );
};

export default ProductFooterBar;
