import {Flex} from '@/components/Flex';
import OrderContext from '@/pages/order/detail/components/OrderContext';
import PieceMeta from '@/pages/piece/components/PieceMeta';
import ProdMeta from '@/pages/product/components/ProdMeta';
import {getOrderPrice, getPrice} from '@/utils/priceUtils';
import theme from '@/theme';
import OrderSubType from '@/utils/localData/OrderSubType';
import OrderType from '@/utils/localData/OrderType';
import {useImageResize} from '@/utils/remoteData/ImageResize';
import _, {assign} from 'lodash';
import React, {useContext, useEffect, useMemo} from 'react';
import {StyleSheet, Text, View} from 'react-native';

const Component = ({section, data}) => {
  const {state, setState} = useContext(OrderContext);
  const items = section.data;
  const {type, subType} = data || {};
  const {renderItem} = section || {};
  const [, resizeImage] = useImageResize({object: 'prod', size: 'xs'});

  const itemDoms = useMemo(() => {
    return _.map(items || [], (item, i) => {
      if (_.isFunction(renderItem)) {
        return renderItem(item, i);
      }
      const {id, picture, itemName, standard, quantity} = item || {};
      const price = getOrderPrice({...item, type, subType}) || {};

      if (type === OrderType.PickUp) {
        return (
          <ProdMeta
            key={`$order_item_${id}`}
            card={false}
            data={item}
            thumb={resizeImage(picture)}
            title={itemName}
            brief={`${standard} 数量 × ${quantity}`}
            content={(__, {styles: _styles}) => (
              <Flex justify="end" style={{flex: 1}}>
                <Text style={_styles.brief}>
                  补差价<Text style={{color: theme.brand_warning}}>{price?.amount}</Text>元
                </Text>
              </Flex>
            )}
          />
        );
      } else {
        return subType === OrderSubType.Piece ? (
          <PieceMeta
            key={`$order_item_${id}`}
            card={false} //
            data={item}
            title={itemName}
            content={(__, {styles: _styles}) => (
              <Flex align="center">
                <Text style={[_styles.priceAmount, {fontSize: 14}]}>
                  {price?.prefix}
                  {price?.amount}
                </Text>
                <Text style={[_styles.priceUnit, {fontSize: 12}]}> {price?.unit}</Text>
              </Flex>
            )}
          />
        ) : (
          <ProdMeta
            key={`$order_item_${id}`}
            card={false}
            data={item}
            thumb={resizeImage(picture)}
            title={itemName}
            brief={`${standard}  数量 × ${quantity}`}
            content={(__, {styles: _styles}) => (
              <Flex align="center">
                <Text style={[_styles.priceAmount, {fontSize: 14}]}>
                  {price?.prefix}
                  {price?.amount}
                </Text>
                <Text style={[_styles.priceUnit, {fontSize: 12}]}> {price?.unit}</Text>
              </Flex>
            )}
          />
        );
      }
    });
  }, [items, renderItem, resizeImage, subType, type]);

  useEffect(() => {
    if (itemDoms !== state?.itemDoms) {
      setState({itemDoms});
    }
  }, [itemDoms, setState, state?.itemDoms]);

  return (
    <View style={[styles.container, section?.style]}>
      {_.map(itemDoms, (dom, i) => (
        <View key={`$order_item__${i}`} style={[styles.item, section?.itemStyle]}>
          {dom}
        </View>
      ))}
    </View>
  );
};
const styles = StyleSheet.create({
  container: {},
  item: {
    marginBottom: 10,
    backgroundColor: '#fff',
    paddingVertical: 16,
    paddingHorizontal: theme.h_spacing_lg,
  },
});

export default class ItemsSection {
  static type = 'items';
  static component = Component;

  constructor(config) {
    assign(this, config);
  }

  get type() {
    return this?.constructor?.type;
  }

  style = {};
  itemStyle = {};
}
