import toMap from '@/utils/FrozenMap/toMap';
import {addToStateData} from '@/utils/remoteData';
import { isWeb } from "@/utils/utils";

@addToStateData('PayType')
@toMap({mapValues: v => v?.value})
export default class PayType {
  static Alipay = {key: 'alipay', text: '支付宝', value: isWeb() ? 'zfb_h5' : 'zfb', icon: 'alipay'};
  static Wxpay = {key: 'wxpay', text: '微信支付', value: isWeb() ? 'wx_h5' : 'wx', icon: 'wxpay'};
  static Score = {key: 'score', text: '口袋币', value: 'score', icon: 'coins_colorful'};
  static Piece = {key: 'piece', text: '碎片', value: 'fragment', icon: 'pieces_colorful'};
}
