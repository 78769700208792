import {Flex} from '@/components';
import {isWeb} from '@/utils/utils';
import _ from 'lodash';
import React, {useEffect, useRef} from 'react';
import {Animated, Easing, useWindowDimensions} from 'react-native';

const useAnimation = ({data, containerPadding: p = 10, containerStyle, displayCount = 4, renderItem} = {}) => {
  const total = data?.length ?? 0;
  const time = useRef(new Animated.Value(0)).current;
  const {width} = useWindowDimensions();
  const itemWidth = (width - p * 2) / displayCount;
  const w = itemWidth * total + p * 2;

  useEffect(() => {
    if (total >= displayCount) {
      const animtion = Animated.timing(time, {
        toValue: total,
        duration: 1000 * total,
        easing: Easing.linear,
        useNativeDriver: !isWeb(),
      });
      Animated.loop(animtion).start();
    }
  }, [displayCount, time, total]);

  const start = p,
    end = w - p;
  const translateX = time.interpolate({
    inputRange: [0, total, total],
    outputRange: [-start, -end, -start],
  });
  if (total <= displayCount) {
    return <Flex style={containerStyle}>{_.map(data, (item, i) => renderItem(item, i, {width: itemWidth}))}</Flex>;
  }
  return (
    <Animated.View style={{transform: [{translateX}]}}>
      <Flex style={containerStyle}>
        {_.map(_.concat(data, _.slice(data, 0, displayCount + 1)), (item, i) =>
          renderItem(item, i, {width: itemWidth}),
        )}
      </Flex>
    </Animated.View>
  );
};

export default useAnimation;
