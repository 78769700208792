import {Dialog} from '@/components';
import RmbPayType from '@/utils/localData/RmbPayType';
import {isIosWeb} from '@/utils/utils';

export default (data, resolve, reject) => {
  try {
    const openPayPage = () => {
      window.open(data, '_blank');

      resolve(
        Promise.resolve({
          paid: true,
          canceled: false,
          memo: '',
        }),
      );
    };
    const cancel = () => {
      resolve(
        Promise.resolve({
          paid: false,
          canceled: true,
          memo: '',
        }),
      );
    };
    if (isIosWeb()) {
      Dialog.confirm(RmbPayType._Wxpay.text, '立即跳转支付页面？', ok => {
        if (ok) {
          openPayPage();
        } else {
          cancel();
        }
      });
    } else {
      openPayPage();
    }
  } catch (err) {
    reject(err);
  }
};
