import {Dialog, LoadingView, TranslucentStatusBar} from '@/components';
import PickVariantModal from '@/components/_modals/PickVariantModal';
import ProductContext from '@/pages/product/detail/components/ProductContext';
import ProductFooterBar from '@/pages/product/detail/components/ProductFooterBar';
import ProductSections from '@/pages/product/detail/components/ProductSections';
import useTranslucentProdHeader from '@/pages/product/detail/useTranslucentProdHeader';
import theme from '@/theme';
import {useDispatch} from '@/utils/dva';
import useDynamicModal from '@/utils/hooks/useDynamicModal';
import PayType from '@/utils/localData/PayType';
import ProdStatus from '@/utils/localData/ProdStatus';
import RmbPayType from '@/utils/localData/RmbPayType';
import {useRequest, useSetState} from 'ahooks';
import _ from 'lodash';
import React from 'react';
import {Image, ScrollView, StyleSheet} from 'react-native';

const getImageRatio = src =>
  new Promise(resolve => {
    try {
      Image.getSize(src, (width, height) => {
        resolve(height / width);
      });
    } catch (err) {
      resolve(1);
    }
  });

const ProductDetailPage = ({navigation, route}) => {
  const {onScroll} = useTranslucentProdHeader(navigation);
  const id = route?.params?.id;
  const [state, setState] = useSetState({skuId: undefined, count: 1});
  const dispatch = useDispatch();
  const {data, loading, mutate} = useRequest(
    () =>
      dispatch({type: 'product/getProd', id}).then(async res => {
        if (res?.success && res?.data?.pictureList?.length) {
          const ratio = await getImageRatio(_.first(res.data.pictureList));
          res.data.pictureRatio = ratio || 1;
        }
        return res;
      }),
    {
      ready: !!id,
      formatResult: res => res?.data,
    },
  );
  const {loading: submitting, run: submit} = useRequest(
    params => dispatch({type: 'product/buy', data: {...params, ...state}}),
    {
      manual: true,
      onSuccess(res) {
        if (res?.success) {
          if (res?.data?.id) {
            navigation.push('Product', {
              screen: 'ProductOrder',
              params: {
                id: res?.data?.id,
                data: res?.data,
              },
            });
          }
        } else {
          Dialog.respError(res);
        }
      },
    },
  );
  const handleSubmit = async ({payType}) => {
    if (submitting) {
      return;
    }
    if (!state.skuId) {
      return Dialog.info(`请选择${data?.standardName ?? '规格'}`);
    } else if (!state.count >= 1) {
      return Dialog.info('请选择数量');
    }
    return await submit({payType});
  };

  const {fragmentFlag, scoreOnly, status} = data || {};
  const onSale = status === ProdStatus.SALE;
  const selectVariant = useDynamicModal(PickVariantModal, {
    data,
    defaultCurrent: state?.skuId,
    onChange: skuId => setState({skuId}),
    defaultCount: state?.count,
    onChangeCount: onSale && (count => setState({count})), // 支持选择数量
    actions: onSale && [
      !scoreOnly && {
        text: '人民币支付',
        type: 'ghost',
        onPress: async (__, {close, setLoading}) => {
          setLoading(true);
          const res = await handleSubmit({payType: RmbPayType.Alipay});
          setLoading(false);
          res?.success && close();
        },
      },
      {
        text: fragmentFlag ? '碎片兑换' : '口袋币支付',
        type: 'primary',
        onPress: async (__, {close, setLoading}) => {
          setLoading(true);
          const res = await handleSubmit({payType: fragmentFlag ? PayType.Piece : PayType.Score});
          setLoading(false);
          res?.success && close();
        },
      },
    ],
  });

  return (
    <ProductContext.Provider value={{state, setState, submitting, submit: handleSubmit, selectVariant}}>
      <TranslucentStatusBar barStyle="dark-content" />
      <LoadingView loading={loading} style={[styles.page]}>
        <ScrollView onScroll={onScroll} scrollEventThrottle={16}>
          <ProductSections data={data} mutate={mutate} />
        </ScrollView>
        <ProductFooterBar data={data} selectVariant={selectVariant} />
      </LoadingView>
    </ProductContext.Provider>
  );
};
const styles = StyleSheet.create({
  page: {
    backgroundColor: theme.fill_body,
    flex: 1,
  },
});

ProductDetailPage.screenName = 'ProductDetail';
ProductDetailPage.screenOptions = {
  headerTransparent: true,
  title: '',
};
ProductDetailPage.linking = {
  path: 'detail/:id',
  parse: {
    id: Number,
  },
};
export default ProductDetailPage;
