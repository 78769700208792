import {Flex} from '@/components/Flex';
import Icon, {IconButton} from '@/components/Icon';
import List from '@/components/List';
import {Popable} from '@/components/react-native-popable';
import theme from '@/theme';
import {useSelector} from '@/utils/dva';
import {getDINProBoldStyle} from '@/utils/styleUtils';
import {isWeb} from '@/utils/utils';
import {useLinkTo} from '@react-navigation/native';
import React from 'react';
import {StyleSheet, Text, View} from 'react-native';

const StatisticSection = () => {
  const linkTo = useLinkTo();
  const currentUser = useSelector(s => s.global.currentUser);
  const {score, fragmentCount} = currentUser || {};

  return (
    <View style={[styles.section, styles.statisticSection]}>
      <Flex style={styles.statisticSectionBody}>
        <Flex direction="column" justify="center" align="stretch" style={styles.statisticSectionStatistic}>
          <Flex align="center" style={{zIndex: 9}}>
            <Icon type="coins_colorful" style={{marginRight: 6}} />
            <Text>我的口袋币</Text>
            <Popable
              content="抽盒商品官方回收获得，可用于商城购物或碎片购买。"
              position="bottom"
              statusBarTranslucent
              style={{width: 130, opacity: isWeb() ? 0.8 : 1}}>
              <IconButton type="tip" />
            </Popable>
          </Flex>
          <Flex justify="between" align="center" style={{marginTop: 8}}>
            <Text style={styles.statisticSectionStatisticValue}>{score ?? 0}</Text>
            <Flex align="center" onPress={() => linkTo('/point/bill')}>
              <Text style={styles.linkText}>交易记录</Text>
              <Icon type="arrow_right" style={{marginLeft: 8}} />
            </Flex>
          </Flex>
        </Flex>

        <Flex
          direction="column"
          justify="center"
          align="stretch"
          style={[styles.statisticSectionStatistic, {borderLeftWidth: StyleSheet.hairlineWidth}]}>
          <Flex>
            <Icon type="pieces_colorful" style={{marginRight: 6}} />
            <Text>碎片仓</Text>
          </Flex>
          <Flex justify="between" align="center" style={{marginTop: 8}}>
            <Text style={styles.statisticSectionStatisticValue}>{fragmentCount ?? 0}</Text>
            <Flex onPress={() => linkTo('/piece/my_pieces')}>
              <Text style={styles.linkText}>立即查看</Text>
              <Icon type="arrow_right" style={{marginLeft: 8}} />
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <List.Item
        arrow="right"
        last={true}
        offset={20}
        style={styles.statisticSectionFooter}
        // lineStyle={{minHeight: 32}}
        onPress={() => linkTo('/tools')}>
        道具卡
      </List.Item>
    </View>
  );
};
const styles = StyleSheet.create({
  section: {
    marginBottom: 10,
    backgroundColor: '#fff',
  },
  statisticSection: {
    marginTop: 10,
    marginBottom: 12,
    // height: 98,
  },
  statisticSectionBody: {
    height: 66,
    alignItems: 'stretch',
    zIndex: 1,
  },
  statisticSectionFooter: {
    height: 48,
    borderColor: theme.border_color_base,
    borderTopWidth: StyleSheet.hairlineWidth,
  },
  statisticSectionStatistic: {
    flex: 1,
    borderColor: theme.border_color_base,
    paddingHorizontal: 20,
    paddingVertical: 12,
  },
  statisticSectionStatisticValue: {
    ...getDINProBoldStyle(16),
    fontSize: 16,
    lineHeight: 20,
  },
  linkText: {
    fontSize: 12,
    lineHeight: 16,
    color: theme.color_text_secondary,
  },
});

export default StatisticSection;
