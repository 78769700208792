import {Clipboard, Dialog} from '@/components';
import Button from '@/components/Button';
import {Center, Flex} from '@/components/Flex';
import Modal from '@/components/Modal';
import Stepper from '@/components/Stepper';
import theme from '@/theme';
import {useDispatch} from '@/utils/dva';
import useControlledModalProps from '@/utils/hooks/useControlledModalProps';
import FontWeight from '@/utils/localData/FontWeight';
import {useRequest} from 'ahooks';
import _ from 'lodash';
import React, {useState} from 'react';
import {Animated, StyleSheet, Text, View} from 'react-native';

const Step0 = ({total = 5, count, onChange, submit, loading}) => {
  return (
    <Animated.View style={styles.step}>
      <Center style={{flex: 1, paddingBottom: 30}}>
        <Stepper value={count} min={1} max={total} onChange={onChange} readOnly={false} disabled={loading} />
      </Center>
      <Center>
        <Button
          type="primary"
          style={styles.btn}
          fontWeight={FontWeight.SemiBold}
          onPress={submit}
          disabled={loading}
          loading={loading}>
          生成口令
        </Button>
      </Center>
    </Animated.View>
  );
};
const Step1 = ({token, copy}) => {
  return (
    <Animated.View style={styles.step}>
      <Flex direction="column" align="center">
        <Text style={styles.messageText}>把口令复制给好友</Text>
        <Text style={styles.messageText}>好友进APP内登录后领取</Text>
        <View style={styles.token}>
          <Text style={styles.tokenText}>{token}</Text>
        </View>
      </Flex>
      <Center direction="column">
        <Button type="primary" style={styles.btn} fontWeight={FontWeight.SemiBold} onPress={copy}>
          复制口令
        </Button>
        <Text style={styles.tipText}>
          该口令将于<Text style={styles.warningText}>12小时</Text>后自动失效
        </Text>
      </Center>
    </Animated.View>
  );
};

/**
 * 我的碎片转换成赠送口令
 * */
const PieceExchangeModal = ({data, onSuccess, ...rest}) => {
  const {visible, close, modalProps} = useControlledModalProps(rest);
  const [step, setStep] = useState(0);
  const {fragmentId, count: total} = data || {};
  const [count, setCount] = useState(1);
  const [token, setToken] = useState('');

  const dispatch = useDispatch();
  const {loading, run: submit} = useRequest(
    () =>
      dispatch({
        type: 'piece/exchange',
        data: {id: fragmentId, count},
      }),
    {
      ready: !!fragmentId,
      manual: true,
      onSuccess(res) {
        _.isFunction(onSuccess) && onSuccess(res);
        if (res?.success) {
          setToken(res.data);
          setStep(1);
        } else {
          Dialog.respError(res);
        }
      },
    },
  );

  const copy = () => {
    const callback = () => {
      Dialog.info('口令已复制');
      close();
    };
    const success = Clipboard.setString(token);
    if (success) {
      callback();
    } else {
      Clipboard.getString().then(content => {
        content === token && callback();
      });
    }
  };

  const handleSubmit = () => {
    if (!count > 0) {
      return Dialog.info({content: '请输入回收数量', position: 'bottom'});
    }
    submit();
  };
  return (
    <Modal
      visible={visible}
      onClose={close}
      title={step === 0 ? '调整赠送额度' : '领取口令'}
      closable={true}
      transparent={true}
      maskClosable={true}
      {...modalProps}
      style={styles.container}
      bodyStyle={styles.bodyStyle}>
      {step === 0 && <Step0 total={total} count={count} onChange={setCount} submit={handleSubmit} loading={loading} />}
      {step === 1 && <Step1 token={token} copy={copy} />}
    </Modal>
  );
};

export default PieceExchangeModal;

const styles = StyleSheet.create({
  container: {
    paddingTop: 30,
  },
  bodyStyle: {
    marginTop: 12,
    paddingBottom: 28,
  },
  messageText: {
    fontSize: 14,
  },
  token: {
    width: 250,
    height: 70,
    backgroundColor: theme.fill_body,
    borderRadius: 6,
    marginTop: 11,
    marginBottom: 26,
    paddingVertical: 12,
    paddingHorizontal: 20,
  },
  tokenText: {
    fontSize: 14,
    lineHeight: 20,
    color: theme.color_text_secondary,
  },
  tipText: {
    color: theme.color_text_secondary,
    fontSize: 12,
    marginTop: 8,
  },
  warningText: {
    color: theme.brand_warning,
  },
  btn: {
    width: 162,
    height: 48,
  },
  step: {
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'stretch',
    height: 215,
  },
});
