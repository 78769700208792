import {useSetState} from 'ahooks';
import _ from 'lodash';
import {useEffect, useRef} from 'react';
import {Dimensions, StyleSheet} from 'react-native';
import styles from './styles';

const WINDOW_WIDTH = Dimensions.get('window').width;
export default function useRollingAnim({KEY, animValue, setNotices, duration, renderCount, pop, index: i}) {
  const width = StyleSheet.flatten(styles.notice)?.width ?? 170,
    gutter = 10,
    x0 = 0 - width - gutter,
    x1 = WINDOW_WIDTH + gutter,
    density = (x1 - x0 + gutter) / (width + gutter);

  const to = i + density;
  let inputRange = [i];
  let outputRange = [x0];
  if (to <= renderCount) {
    inputRange.push(to);
    outputRange.push(x1);
    inputRange.push(to);
    outputRange.push(x0);
  } else {
    inputRange.push(renderCount);
    outputRange.push(((x1 - x0) * (renderCount - i)) / density + x0);
    const preInout = [0];
    const preOutput = [((x1 - x0) * (renderCount - i)) / density + x0];
    preInout.push(to - renderCount);
    preOutput.push(x1);
    preInout.push(to - renderCount);
    preOutput.push(x0);
    inputRange = _.concat(preInout, inputRange);
    outputRange = _.concat(preOutput, outputRange);
  }
  const right = useRef(
    animValue.interpolate({
      inputRange,
      outputRange: outputRange.map(v => -v),
    }),
  ).current;

  const [state, setState] = useSetState({ready: false, poping: false});
  useEffect(() => {
    const _onReady = _.debounce(
      () => {
        if (!state.ready) {
          setState({ready: true});
        }
      },
      duration,
      {leading: true},
    );
    const _onFinish = _.debounce(
      () => {
        if (state.ready && state.poping !== true) {
          state.poping = true;
          pop((success, {queue}) => {
            if (success) {
              setNotices(_.clone(queue));
            }
            state.poping = false;
          });
        }
      },
      duration,
      {leading: true},
    );
    const listenerId = right?.addListener(({value}) => {
      if (value > width) {
        _onReady();
      }
      if (value < -WINDOW_WIDTH) {
        _onFinish();
      }
    });
    return () => {
      right?.removeListener(listenerId);
    };
  }, [right, state]);

  return {right: 0, transform: [{translateX: right}], opacity: state.ready ? 1 : 0};
}
