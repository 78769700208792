import {Dialog} from '@/components';
import CommonTradeModal from '@/components/_modals/CommonTradeModal';
import theme from '@/theme';
import {useDispatch} from '@/utils/dva';
import useDynamicModal from '@/utils/hooks/useDynamicModal';
import useLoopRequest from '@/utils/hooks/useLoopRequest';
import {getOrderPrice} from '@/utils/priceUtils';
import {useRequest} from 'ahooks';
import _ from 'lodash';
import React, {useCallback, useRef} from 'react';
import {StyleSheet, Text, View} from 'react-native';
import {wakeUpPayApp} from './useWakeUpPayApp';
import {isAndroid} from '@/utils/utils';

const useOrderPay = (
  order,
  {
    actionRef: _actionRef,
    renderDisplay, //
    defaultParams,
    itemDoms,
    onSuccess,
    askBeforeRemove = true,
  } = {},
) => {
  const dispatch = useDispatch();
  const __actionRef = useRef();
  const actionRef = _actionRef ?? __actionRef;
  if (!actionRef?.current) {
    actionRef.current = {};
  }
  const {id, amount} = order || {};

  // 订阅支付结果
  const {
    loading: paying,
    run: subscribeResult,
    unsubscribe,
  } = useLoopRequest(_id => dispatch({type: 'order/getResult', id: _id ?? id}), {
    isSuccess: res => res?.success,
  });

  // 支付
  const {loading: submitting, run: submit} = useRequest(
    params =>
      dispatch({
        type: 'order/pay',
        data: {
          id,
          ...defaultParams,
          ..._.pick(params, ['id', 'amount', 'payType', 'addressId', 'remark']),
        },
      }).then(res => {
        if (res?.success && params?.amount > 0) {
          // 提交成功后唤起支付应用
          wakeUpPayApp(params.payType, res.data)
            .then(res => {
              if (!res?.paid) {
                unsubscribe();
                if (!res.canceled) {
                  Dialog.fail(res.memo || '支付失败');
                }
              }
            })
            .catch(err => {
              unsubscribe();
              Dialog.fail(err.toString() || '支付失败');
            });
          // 订阅支付结果
          return subscribeResult(params?.id ?? id);
        }
        return res;
      }),
    {
      manual: true,
      onSuccess(res, ...rest) {
        if (res?.success) {
          actionRef?.current?.close?.(); // 关闭支付弹出（仅payRmb）
          // payRmb.closeAll();
          Dialog.success(res.msg ?? '支付成功');
        } else {
          if (res?.code === 499) {
            // 订阅取消时返回499
          } else {
            Dialog.respError(res);
          }
        }
        _.isFunction(onSuccess) && onSuccess(res, ...rest);
      },
    },
  );

  // > 0元
  const modalActionRef = useRef({});
  modalActionRef.current.onClose = useCallback(() => {
    if (paying) {
      unsubscribe();
    }
  }, [paying, unsubscribe]);
  const payRmb = useDynamicModal(
    CommonTradeModal,
    {
      renderDisplay(...args) {
        if (_.isFunction(renderDisplay)) {
          return renderDisplay(...args);
        } else {
          const {itemDoms: items} = args[0] || {};
          if (items?.length) {
            return (
              <View>
                <View style={styles.displayCard}>{_.first(items)}</View>
                {items?.length > 1 && <Text style={styles.displayDesc}>等{items.length}件商品</Text>}
              </View>
            );
          }
        }
        return null;
      },
      params: {
        id,
        amount,
        itemDoms,
        agreement: true,
      },
      defaultStep: 1,
      submit: params => submit(params),
      onClose() {
        modalActionRef?.current?.onClose?.();
      },
    },
    {
      onBeforeRemove: evt => {
        if (paying) {
          if (askBeforeRemove) {
            isAndroid() && evt.preventDefault();
            Dialog.confirm('', '是否已完成付款？', [
              {
                text: '放弃支付',
                onPress() {
                  unsubscribe();
                },
              },
              {
                text: '已完成支付',
                onPress() {
                  Dialog.info('正在查询支付状态，请稍后');
                },
              },
            ]);
            return false;
          } else {
            unsubscribe();
          }
        }
      },
    },
  );

  _.extend(actionRef.current, {
    close: () => payRmb.closeAll(),
  });

  const finialSubmit = _order => {
    const {id, amount, itemDoms: _itemDoms} = order || _order || {};
    const price = getOrderPrice(order || _order);
    if (!price.isRmb()) {
      return submit({id, payType: price.payType});
    } else if (amount <= 0) {
      return submit({id, amount, payType: price.payType});
    } else {
      return payRmb({
        params: {id, amount, itemDoms: itemDoms ?? _itemDoms},
      });
    }
  };
  return [finialSubmit, {submitting, paying, unsubscribe, actionRef}];
};

export default useOrderPay;

const styles = StyleSheet.create({
  displayCard: {
    borderRadius: 4,
    backgroundColor: theme.fill_body,
    borderWidth: StyleSheet.hairlineWidth,
    borderColor: theme.border_color_base,
    paddingVertical: 10,
    paddingHorizontal: 10,
  },
  displayDesc: {
    fontSize: 12,
    color: theme.color_text_secondary,
    marginTop: 5,
  },
});
