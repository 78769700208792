import useNestingBackButton from '@/utils/hooks/useNestingBackButton';
import React from 'react';
import {View} from 'react-native';

const CouponSelectorPage = () => {
  useNestingBackButton();
  return <View />;
};

CouponSelectorPage.screenName = 'CouponSelector';
CouponSelectorPage.screenOptions = {
  title: '选择优惠券',
};
CouponSelectorPage.linking = 'selector';
export default CouponSelectorPage;
