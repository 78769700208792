import {Dialog, LoadingView} from '@/components';
import PickProdsModal from '@/components/_modals/PickProdsModal';
import PickToolModal from '@/components/_modals/PickToolModal';
import Button from '@/components/Button';
import CrystalBall from '@/components/CrystalBall';
import {Center, Flex} from '@/components/Flex';
import Icon, {IconButton} from '@/components/Icon';
import Modal from '@/components/Modal';
import theme from '@/theme';
import {useDispatch, useSelector} from '@/utils/dva';
import useBottomInset from '@/utils/hooks/useBottomInset';
import useControlledModalProps from '@/utils/hooks/useControlledModalProps';
import useDynamicModal from '@/utils/hooks/useDynamicModal';
import FontWeight from '@/utils/localData/FontWeight';
import {getDINProBoldStyle, getShadowButton} from '@/utils/styleUtils';
import {useRequest} from 'ahooks';
import _ from 'lodash';
import React, {useMemo, useState} from 'react';
import {StyleSheet, Text, TouchableOpacity, View} from 'react-native';

const BoxRecoveryModal = ({navigation, data, selectable = true, multiply = false, onSuccess, ...rest}) => {
  const [, , , redress] = useBottomInset(10);
  const {visible, close, modalProps} = useControlledModalProps(rest);
  const currentUser = useSelector(s => s.global.currentUser);
  const [selectProdKeys, setSelectProdKeys] = useState(_.map(data, 'id'));
  const [currentTool, setCurrentTool] = useState(null);
  const dispatch = useDispatch();

  const openPickProdsModal = useDynamicModal(
    PickProdsModal,
    {
      data,
      defaultSelectKeys: selectProdKeys,
      onChange: setSelectProdKeys,
    },
    {navigation},
  );
  const openPickToolModal = useDynamicModal(
    PickToolModal,
    {
      defaultCurrent: currentTool,
      onChange: setCurrentTool,
    },
    {navigation},
  );

  const {
    data: result,
    loading: submitting,
    run: submit,
  } = useRequest(() => dispatch({type: 'box/recovery', data: {ids: selectProdKeys, toolId: currentTool?.id}}), {
    manual: true,
    onSuccess(res, params) {
      _.isFunction(onSuccess) && onSuccess(res, params);
      if (res?.success) {
        // 回收成功后
        const {orderId, userScore, recycleScore} = res.data || {};
        //  更新显示的回收价格
        if (recycleScore) {
          Dialog.success('+' + recycleScore + '口袋币');
        }
        if (userScore) {
          // todo: 余额变化的动效
          // 更新用户口袋币余额
          dispatch({type: 'global/saveState', currentUser: {...currentUser, score: userScore}});
          // 提示并跳转到回收订单
          _.delay(close, 0);
        }
      } else {
        Dialog.respError(res);
      }
    },
  });
  const handleSubmit = () => {
    if (submitting) {
      return;
    }
    if (!selectProdKeys?.length) {
      return Dialog.info({
        content: '请选择回收商品', //
        bottom: 85,
      });
    }
    return submit(selectProdKeys);
  };

  const score = useMemo(() => {
    const selectProd = _.filter(data, p => _.includes(selectProdKeys, p.id));

    if (currentTool?.id) {
      return _.reduce(selectProd, (total, it) => total + it?.score || 0, 0);
    }
    return _.reduce(
      selectProd,
      (total, it) => {
        const {score, recycleRate} = it || {};
        if (score && recycleRate) {
          return total + _.floor(parseFloat(score).mul(recycleRate));
        }
        return total;
      },
      0,
    );
  }, [currentTool?.id, data, selectProdKeys]);

  return (
    <Modal
      popup
      visible={visible}
      animationType="slide-up"
      maskClosable={false} // onClose={() => setVisible(false)}
      {...modalProps}
      style={[styles.modal, {paddingBottom: redress}]}>
      <LoadingView loading={submitting} layer={submitting} style={styles.container}>
        <IconButton type="close" size={12} onPress={close} style={styles.close} />
        <View style={styles.content}>
          <Center style={{marginTop: 50}}>
            <CrystalBall amount={score} resultAmount={result?.data?.recycleScore} />
          </Center>

          <Flex justify="between" style={{height: 46}}>
            <Text style={{fontSize: 12}}>回收商品</Text>
            {/*{selectable && (data.length > 1 || multiply) ? (*/}
            {selectable ? (
              <TouchableOpacity onPress={openPickProdsModal} style={{flex: 0}}>
                <Flex justify="end" align="end">
                  <Text style={styles.countText}>{selectProdKeys?.length}</Text>
                  <Flex>
                    <Text style={styles.countUnit}>件</Text>
                    <View style={{marginLeft: 3}}>
                      <Icon type="arrow_right" />
                    </View>
                  </Flex>
                </Flex>
              </TouchableOpacity>
            ) : (
              <Flex justify="end" align="end" style={{flex: 0}}>
                <Text style={styles.countText}>{selectProdKeys?.length}</Text>
                <Text style={styles.countUnit}>件</Text>
              </Flex>
            )}
          </Flex>
          <View style={styles.divider} />
          {selectProdKeys?.length === 1 && (
            <Flex justify="between" style={{height: 46}}>
              <Flex align="center">
                <Icon type="tool_colorful" style={{marginRight: 5}} />
                <Text style={{fontSize: 12}}>使用道具</Text>
              </Flex>
              <TouchableOpacity onPress={openPickToolModal}>
                {currentTool ? (
                  <Text style={{fontSize: 12}}>
                    {currentTool.name} <Icon type="arrow_right" />
                  </Text>
                ) : (
                  <Text style={{fontSize: 12, color: theme.color_text_secondary}}>
                    使用道具 <Icon type="arrow_right" />
                  </Text>
                )}
              </TouchableOpacity>
            </Flex>
          )}
          <Flex justify="between" style={{height: 46}}>
            <Flex>
              <Icon type="coins_colorful" style={{marginRight: 6.5}} />
              <Text style={{fontSize: 12}}>口袋币余额</Text>
            </Flex>
            <Text style={[styles.priceText, {fontSize: 12, color: theme.color_text_secondary}]}>
              {currentUser?.score ?? 0}
            </Text>
          </Flex>
        </View>
        <Flex style={[styles.toolbar, {marginLeft: -11}]}>
          <Button
            type="primary"
            fontWeight={FontWeight.SemiBold}
            style={[styles.toolbarButton, styles.btnBlackShadow]}
            onPress={close}>
            我再想想
          </Button>
          <Button
            type="primary"
            fontWeight={FontWeight.SemiBold}
            color={theme.brand_secondary}
            disabled={!!result?.success}
            isLight={false}
            style={[styles.toolbarButton, styles.btnRedShadow]}
            onPress={handleSubmit}>
            确认回收
          </Button>
        </Flex>
      </LoadingView>
    </Modal>
  );
};
const styles = StyleSheet.create({
  modal: {
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
    overflow: 'hidden',
  },
  container: {
    height: 436,
    backgroundColor: '#fff',
    paddingBottom: 70,
  },
  close: {
    position: 'absolute',
    top: theme.h_spacing_lg - 4,
    right: theme.h_spacing_lg - 4,
    zIndex: 99,
  },
  header: {
    paddingVertical: 16,
    paddingHorizontal: theme.h_spacing_lg,
  },
  title: {},
  content: {
    paddingHorizontal: theme.h_spacing_lg,
    width: '100%',
    flex: 1,
  },
  toolbar: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    // borderTopWidth: StyleSheet.hairlineWidth,
    // borderTopColor: theme.border_color_base,
    backgroundColor: '#fff',
    paddingHorizontal: theme.h_spacing_lg,
    paddingVertical: theme.v_spacing_md,
    marginBottom: 10,
  },
  toolbarButton: {
    height: 48,
    marginLeft: 11,
    flex: 1,
  },
  btnBlackShadow: getShadowButton('#000'),
  btnRedShadow: getShadowButton(theme.brand_secondary),

  countText: {
    ...getDINProBoldStyle(20),
    lineHeight: 25,
  },
  countUnit: {
    fontSize: 12,
    lineHeight: 19,
    marginLeft: 4,
  },
  priceText: {
    ...getDINProBoldStyle(12),
    lineHeight: 15,
  },

  divider: {
    borderTopWidth: StyleSheet.hairlineWidth,
    borderColor: theme.border_color_base,
    marginTop: 7,
    marginBottom: 5,
  },
});

export default BoxRecoveryModal;
