import List from '@/components/List';
import useGlobalCallback from '@/utils/hooks/useGlobalCallback';
import {useIntlCodes} from '@/utils/remoteData/IntlCodes';
import {isIos} from '@/utils/utils';
import _ from 'lodash';
import React from 'react';
import {ScrollView} from 'react-native';

const IntlCodePicker = ({navigation, route}) => {
  const {id} = route?.params || {};
  const callbackRef = useGlobalCallback(id);
  const handlePress = item => {
    callbackRef?.current?.callback?.({
      navigation,
      value: item,
    });
  };
  const data = useIntlCodes() || [
    {text: '中国', value: '+86'},
    {text: '中国中国', value: '+8686'},
    {text: '中国中国中国', value: '+868686'},
  ];
  return (
    <ScrollView style={{flex: 1}}>
      {data?.length && (
        <List>
          {_.map(data, (item, i) => {
            const {value, text} = item || {};
            return (
              <List.Item key={`$item_${value}`} extra={value} arrow="right" onPress={handlePress.bind({}, item)}>
                {text}
              </List.Item>
            );
          })}
        </List>
      )}
    </ScrollView>
  );
};

IntlCodePicker.modalName = 'IntlCodePicker';
IntlCodePicker.screenOptions = {
  title: '选择国家/地区',
  animation: isIos() ? 'default' : 'default',
  presentation: 'modal',
};
export default IntlCodePicker;
