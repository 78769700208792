import {isIos} from '@/utils/utils';

export * from './global';
import {Empty, LoadingView} from '@/components';
import {DefaultStatusBar} from '@/components/StatusBar';
import createDvaApp, {Provider as DvaProvider} from '@/utils/dva';
import errorBoundary from '@/utils/hooks/errorBoundary';
import {useRequest} from 'ahooks';
import * as React from 'react';
import {useEffect, useReducer, useState} from 'react';
import {SafeAreaProvider} from 'react-native-safe-area-context';
import SplashScreen from 'react-native-splash-screen';
import {useDispatch} from 'react-redux';
import models from './models';
import RootNavigator from './RootNavigator';
import XInstall from '@/NativeModules/XInstall';

const davApp = createDvaApp({models});

const Error = ({refresh, errMsg}) => (
  <Empty image={Empty.IMAGES.Error} text={errMsg} actions={[{text: '刷新试试', onPress: refresh}]} />
);

const AppInit = errorBoundary(({children}) => {
  const [ready, setReady] = useState(false);
  const [error, setError] = useState(false);
  const [times, forceUpdate] = useReducer(x => x + 1, 0);

  const dispatch = useDispatch();
  const {data: globalState, run: getInitialState} = useRequest(() => dispatch({type: 'global/getInitialState'}), {
    manual: true,
    onSuccess: res => {
      setReady(true);
      setTimeout(() => {
        SplashScreen?.hide();
        isIos() && XInstall?.initSdk();
      }, 500);
    },
    onError: err => {
      console.warn('onError', err);
      setError(err);
      // 尝试重新获取初始数据
      setTimeout(forceUpdate, 2000);
    },
  });

  useEffect(() => {
    if (times < 5) {
      getInitialState();
    } else {
      SplashScreen?.hide();
      throw error;
    }
  }, [error, getInitialState, times]);

  return (
    <LoadingView delay={0} style={{flex: 1}} loading={!ready}>
      {ready && children}
    </LoadingView>
  );
}, Error);

function App() {
  return (
    <DvaProvider store={davApp.getStore()}>
      <SafeAreaProvider>
        <DefaultStatusBar />
        <AppInit onError>
          <RootNavigator />
        </AppInit>
      </SafeAreaProvider>
    </DvaProvider>
  );
}

export default errorBoundary(App, Error);
