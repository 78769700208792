import PickVariantModal from '@/components/_modals/PickVariantModal';
import Checkbox from '@/components/Checkbox';
import {Flex} from '@/components/Flex';
import Icon from '@/components/Icon';
import BoxProdDetailModal from '@/pages/box/detail/components/BoxProdDetailModal';
import BoxRecordsContext from '@/pages/box/records/components/BoxRecordsContext';
import ProdMeta from '@/pages/product/components/ProdMeta';
import theme from '@/theme';
import useDynamicModal from '@/utils/hooks/useDynamicModal';
import BoxRecordStatus from '@/utils/localData/BoxRecordStatus';
import {getPrice} from '@/utils/priceUtils';
import {getDINProBoldStyle} from '@/utils/styleUtils';
import {useNavigation} from '@react-navigation/core';
import _ from 'lodash';
import React, {useContext} from 'react';
import {Pressable, StyleSheet, Text} from 'react-native';
import {useSafeAreaInsets} from 'react-native-safe-area-context';

const OpenedBoxListCard = ({status, pending, checked, onChecked, onChangeVariant, data, style}) => {
  const pickVariant = useDynamicModal(PickVariantModal, {
    data,
    defaultCurrent: data?.currentSkuId,
    actions: [
      {
        text: '选好了',
        onPress({skuId, count}, {close}) {
          onChangeVariant?.(skuId);
          close();
        },
      },
    ],
  });
  const {productId, itemName, skus, currentSkuId, recycleTime, recycleRate, amount} = data || {};
  const currentSku = _.find(skus, sku => sku?.id === currentSkuId);
  const [, rmbPrice] = getPrice(currentSku ?? data);

  const renderDesc = () => {
    if (pending) {
      return `${recycleTime} 自动按${parseFloat(recycleRate).mul(100)}%回收`;
    } else if (status === BoxRecordStatus.Recycled) {
      return `${recycleTime} 已回收`;
    }
  };
  const renderAmountDesc = () => {
    if (pending && currentSku) {
      const _amount = parseFloat(currentSku.amount).add(parseFloat(amount).mul(-1));
      return (
        <Text style={styles.amountDesc}>
          提货需补差价<Text style={styles.redText}>{_amount}</Text>元
        </Text>
      );
    }
  };
  const navigation = useNavigation();
  const gotoMall = () => {
    navigation.push('Product', {
      screen: 'ProductDetail',
      params: {id: productId},
    });
  };
  const {containerHeight} = useContext(BoxRecordsContext);
  const {top} = useSafeAreaInsets();
  const openProdDetailModal = useDynamicModal(BoxProdDetailModal, {
    id: productId,
    gotoMall,
    height: containerHeight + top + theme.header_height,
  });
  const onPress = () => {
    // navigation.push('Product', {
    //   screen: 'ProductDetail',
    //   params: {id: productId},
    // });
    openProdDetailModal();
  };
  return (
    <Flex style={[styles.card, style]}>
      {pending && <Checkbox checked={checked} onChange={onChecked} size={18} style={{marginRight: 10}} />}
      <Pressable style={{flex: 1}} onPress={onPress}>
        <ProdMeta
          style={{flex: 1}}
          data={data}
          card={false}
          title={() => <Text numberOfLines={pending ? 1 : 2}>{itemName}</Text>}
          brief={(__, {style}) => <Text style={[style, styles.desc]}>{renderDesc()}</Text>}
          content={
            <Flex direction="column" align="start" style={{marginTop: -9, width: '100%'}}>
              {pending && (
                <Flex onPress={pickVariant} style={[styles.selectVariant, checked && styles.highlightVariant]}>
                  <Text numberOfLines={1} style={[styles.selectVariantText, checked && styles.highlightVariantText]}>
                    {currentSku ? currentSku.standard : '请选择规格'}{' '}
                  </Text>
                  <Icon type="arrow_right" size={7} fill={checked ? theme.brand_warning : theme.color_text_secondary} />
                </Flex>
              )}
              <Flex justify="between" style={{width: '100%'}}>
                <Text style={getDINProBoldStyle(18)}>{rmbPrice.text()}</Text>
                {renderAmountDesc()}
              </Flex>
            </Flex>
          }
        />
      </Pressable>
    </Flex>
  );
};

const styles = StyleSheet.create({
  card: {
    height: 108,
    borderRadius: 4,
    borderWidth: StyleSheet.hairlineWidth,
    borderColor: theme.border_color_base,
    backgroundColor: '#fff',
    padding: 10,
  },

  selectVariant: {
    paddingVertical: 2,
    paddingHorizontal: 7,
    borderWidth: StyleSheet.hairlineWidth,
    borderColor: theme.border_color_base,
    backgroundColor: theme.fill_body,
    marginBottom: 4,
    textAlign: 'center',
    maxWidth: '100%',
  },
  highlightVariant: {
    borderColor: theme.brand_warning,
    backgroundColor: '#FFE0E7',
  },
  selectVariantText: {
    fontSize: 10,
    lineHeight: 14,
    color: theme.color_text_secondary,
  },
  highlightVariantText: {
    color: theme.brand_warning,
  },

  desc: {
    fontSize: 10,
    lineHeight: 14,
    marginTop: 2,
  },
  amountDesc: {
    fontSize: 10,
    lineHeight: 14,
    color: theme.color_text_secondary,
  },
  redText: {
    color: theme.brand_secondary,
  },
});

export default React.memo(OpenedBoxListCard);
