import getBombAsset from '@/pages/box/assets/_bomb';
import BoxRarity from '@/utils/localData/BoxRarity';
import {isWeb} from '@/utils/utils';
import React, {useMemo} from 'react';
import {ImageBackground} from 'react-native';

const BoxBomb = ({rarity, visible, style, children, ...rest}) => {
  const bombGif = useMemo(() => {
    if (isWeb()) {
      // todo: web上因缓存的问题是否需要降级处理
      return getBombAsset(BoxRarity.getByValue(rarity)?.key) + `?${Date.now()}`;
    }
    return getBombAsset(BoxRarity.getByValue(rarity)?.key);
  }, [rarity]);

  return (
    <ImageBackground source={visible && bombGif} {...rest} style={[visible && {opacity: visible ? 1 : 0}, style]}>
      {children}
    </ImageBackground>
  );
};

export default BoxBomb;
