import {useNavigationState} from '@react-navigation/native';
import {useEffect, useState} from 'react';

export default function useFocusedStatusBar(navigation, StatusBarElement) {
  const [statusBar, setStatusBar] = useState(null);
  const state = useNavigationState(state => state);
  useEffect(() => {
    if (navigation.isFocused()) {
      setStatusBar(StatusBarElement);
    } else {
      setStatusBar(null);
    }
  }, [StatusBarElement, navigation, state]);
  return statusBar;
}
