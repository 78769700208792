import {Center} from '@/components';
import {isArray, reduce} from 'lodash';
import React from 'react';
import {Text} from 'react-native';
import Empty from '../../components/Empty';

const defaultError = ({refresh, errMsg}) => <Empty image={Empty.IMAGES.Error} text={errMsg} />;
export default function errorBoundary(Component, Error = defaultError, hooks) {
  if (Component.ErrorBoundary) {
    return Component.ErrorBoundary;
  }

  class ErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
      this.state = {error: false};
      if (isArray(hooks) && hooks.length > 0) {
        this.Component = reduce(hooks, (result, hook) => hook(result), Component);
      } else {
        this.Component = Component;
      }
    }

    static getDerivedStateFromError(error) {
      // 更新 state 使下一次渲染能够显示降级后的 UI
      return {error: error};
    }

    componentDidCatch(error, errorInfo) {
      // 你同样可以将错误日志上报给服务器
      // logErrorToMyService(error, errorInfo);
      console.log(error);
    }
    refresh = () => {
      this.setState({error: false});
    };
    render() {
      if (this.state.error) {
        const errMsg = this.state.error?.toString?.() || '出错了~';
        // 你可以自定义降级后的 UI 并渲染
        return Error ? (
          <Error refresh={() => this.refresh()} errMsg={errMsg} />
        ) : (
          <Center style={{flex: 1}}>
            <Text numberOfLines={4}>{errMsg}</Text>
          </Center>
        );
      }
      return React.createElement(this.Component, this.props);
    }
  }

  Component.ErrorBoundary = ErrorBoundary;

  return Component.ErrorBoundary;
}
