import {LoadingView} from '@/components';
import {Flex} from '@/components/Flex';
import CatalogContent from '@/pages/catalogs/components/CatalogContent';
import theme from '@/theme';
import {useDispatch, useSelector} from '@/utils/dva';
import useHeaderSearchBar from '@/utils/hooks/useHeaderSearchBar';
import FontWeight from '@/utils/localData/FontWeight';
import {useRequest} from 'ahooks';
import _ from 'lodash';
import React, {useEffect, useRef, useState} from 'react';
import {Platform, ScrollView, StyleSheet, Text, View} from 'react-native';

const CatalogsPage = ({navigation}) => {
  useHeaderSearchBar(
    Platform.select({
      ios: {
        editable: false,
        onPressIn: () => navigation.push('Search'),
      },
      default: {
        editable: false,
        onPress: () => navigation.push('Search'),
      },
    }),
  );

  const initialData = useSelector(s => s.catalogs.catalogs);
  const [current, setCurrent] = useState(_.first(initialData)?.id);
  const dispatch = useDispatch();
  const {data: catalogs, loading} = useRequest(() => dispatch({type: 'catalogs/getCatalogs'}), {
    initialData,
    ready: !initialData?.length,
    formatResult: res => res?.data,
    onSuccess: _data => _data?.length && setCurrent(_.first(_data)?.id),
  });

  const contentScrollViewRef = useRef();
  useEffect(() => {
    contentScrollViewRef?.current?.scrollTo?.({y: 0, animated: false});
  }, [current]);

  return (
    <LoadingView loading={loading} delay={300} style={styles.page}>
      <Flex align="stretch" style={{flex: 1}}>
        <View style={styles.tabBar}>
          <ScrollView style={styles.tabBarScrollView}>
            {_.map(catalogs, (item, i) => {
              const isActive = current === item?.id;
              return (
                <Flex
                  align="center"
                  key={`$catalog_${item?.id}`}
                  style={[styles.tab, isActive && styles.activeTab]}
                  onPress={() => setCurrent(item?.id)}>
                  <Text style={[styles.tabText, isActive && styles.activeTabText]}>{item?.name}</Text>
                </Flex>
              );
            })}
          </ScrollView>
        </View>
        <View style={styles.tabContent}>
          <CatalogContent key={current} catalogId={current} scrollViewRef={contentScrollViewRef} />
        </View>
      </Flex>
    </LoadingView>
  );
};

CatalogsPage.screenName = 'Catalogs';
CatalogsPage.screenOptions = {
  title: '目录',
  // headerShown: false,
};
CatalogsPage.linking = 'catalogs';
export default CatalogsPage;

const styles = StyleSheet.create({
  page: {
    flex: 1,
    backgroundColor: '#fff',
  },
  tabBar: {
    width: 80,
    backgroundColor: theme.fill_body,
  },
  tabBarScrollView: {
    flex: 1,
  },
  tab: {
    width: 80,
    height: 58,
    paddingVertical: 20,
    paddingLeft: 14,
    paddingRight: 10,
  },
  tabText: {
    fontSize: 13,
    lineHeight: 18,
  },
  activeTab: {
    backgroundColor: '#fff',
  },
  activeTabText: {
    color: theme.brand_secondary,
    fontWeight: FontWeight.SemiBold,
  },
  tabContent: {
    flex: 1,
    backgroundColor: '#fff',
  },
});
