import {AddressPicker, Flex} from '@/components';
import ColorfulBorder from '@/components/ColorfulBorder';
import Icon from '@/components/Icon';
import OrderContext from '@/pages/order/detail/components/OrderContext';
import theme from '@/theme';
import FontWeight from '@/utils/localData/FontWeight';
import {assign} from 'lodash';
import React, {useCallback, useContext} from 'react';
import {StyleSheet, Text, View} from 'react-native';

const displayStyles = StyleSheet.create({
  container: {
    paddingHorizontal: 20,
    paddingVertical: 16,
    backgroundColor: '#fff',
    marginBottom: 10,
  },
  address: {
    fontSize: 14,
    fontWeight: FontWeight.Medium,
  },
  name: {
    fontSize: 12,
    color: '#333333',
  },
  phone: {
    fontSize: 12,
    color: '#333333',
    marginLeft: 8,
  },
});
export const DisplayAddress = ({current, onChange, editable, displayAddress, style}) => {
  const renderDom = data => {
    const {province, city, area, address, name, mobile} = data || {};
    return (
      <Flex align="start">
        <View style={{width: 24}}>
          <Icon type="func_address" width={12} height={15.6} style={{top: 2}} />
        </View>
        {address ? (
          <View style={{height: 42}}>
            <Text style={displayStyles.address}>
              {province}
              {city}
              {area}
              {address}
            </Text>
            <Flex align="center" style={{marginTop: 5}}>
              <Text style={displayStyles.name}>{name}</Text>
              <Text style={displayStyles.phone}>{mobile}</Text>
            </Flex>
          </View>
        ) : (
          <Text style={{color: theme.color_text_secondary}}>{editable ? '请选择收货地址' : '未选择收货地址'} </Text>
        )}
      </Flex>
    );
  };

  return (
    <View style={[displayStyles.container, style]}>
      {editable ? (
        <AddressPicker value={current} onChange={onChange}>
          {renderDom(current)}
        </AddressPicker>
      ) : (
        renderDom(current ?? displayAddress)
      )}
    </View>
  );
};
const inputStyles = StyleSheet.create({
  container: {
    backgroundColor: '#fff',
    minHeight: 73,
    paddingHorizontal: 20,
    marginBottom: 10,
  },
  content: {
    flex: 1,
    paddingRight: 20,
    paddingTop: 15,
    paddingBottom: 18,
  },
  address: {
    fontSize: 14,
    fontWeight: FontWeight.Medium,
    lineHeight: 19,
  },
  name: {
    fontSize: 12,
    color: '#333',
    lineHeight: 16,
  },
  phone: {
    fontSize: 12,
    color: '#333',
    lineHeight: 16,
    marginLeft: 8,
  },
});
export const InputAddress = ({current, onChange, style}) => {
  return (
    <AddressPicker onChange={onChange}>
      <Flex style={[inputStyles.container, style]}>
        <View style={inputStyles.content}>
          {current ? (
            <>
              <Text style={inputStyles.address}>
                {current.province}
                {current.city}
                {current.area}
                {current.address}
              </Text>
              <Flex align="center" style={{marginTop: 5}}>
                <Text style={inputStyles.name}>{current.name}</Text>
                <Text style={inputStyles.phone}>{current.mobile}</Text>
              </Flex>
            </>
          ) : (
            <Text style={{color: theme.color_text_secondary}}>请选择收货地址</Text>
          )}
        </View>
        <Icon type="arrow_right" />
        <ColorfulBorder />
      </Flex>
    </AddressPicker>
  );
};

const Component = ({section}) => {
  const {state, setState} = useContext(OrderContext);
  const onChange = useCallback(address => setState({address}), [setState]);

  if (section?.mode === 'display') {
    const {visible = true, editable, address: displayAddress} = section.data || {}; // for display mode only
    return visible ? (
      <DisplayAddress
        current={state?.address}
        onChange={onChange}
        editable={editable}
        displayAddress={displayAddress} // 订单没有完整的地址对象，需要formatData提供供显示的地址
        style={section?.style}
      />
    ) : null;
  }
  if (section?.mode === 'input') {
    return (
      <InputAddress
        current={state?.address} //
        onChange={onChange}
        style={section?.style}
      />
    );
  }
};

export default class AddressSection {
  static type = 'address';
  static component = Component;

  constructor(config) {
    assign(this, config);
  }

  get type() {
    return this?.constructor?.type;
  }

  style = {};
  mode = 'display'; // display or input
}
