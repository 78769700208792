'use strict';

Object.defineProperty(exports, '__esModule', {
  value: true,
});
Object.defineProperty(exports, 'default', {
  enumerable: true,
  get: function get() {
    return _picker.Picker;
  },
});

var _picker = require('@react-native-picker/picker');
