import {Dialog, LoadingView} from '@/components';
import OrderContext from '@/pages/order/detail/components/OrderContext';
import OrderSections from '@/pages/order/detail/components/OrderSections';
import AddressSection from '@/pages/order/detail/components/OrderSections/AddressSection';
import BillSection from '@/pages/order/detail/components/OrderSections/BillSection';
import ItemsSection from '@/pages/order/detail/components/OrderSections/ItemsSection';
import RemarkSection from '@/pages/order/detail/components/OrderSections/RemarkSection';
import {useDispatch} from '@/utils/dva';
import authedPage from '@/utils/hooks/authedPage';
import useOrderPay from '@/utils/hooks/useOrderPay';
import {getOrderPrice} from '@/utils/priceUtils';
import {useRequest, useSetState} from 'ahooks';
import React from 'react';
import {ScrollView, StyleSheet} from 'react-native';
import OrderPayBar from './components/OrderPayBar';

const sections = [
  new AddressSection({
    mode: 'input',
  }),
  new ItemsSection({
    formatData: data => data?.orderItemDtoList,
  }),
  new BillSection(),
  new RemarkSection({
    mode: 'input',
    rows: 4,
  }),
];
const ProductOrderPage = authedPage(({navigation, route}) => {
  const {id, data: initialData} = route?.params || {};
  const [state, setState] = useSetState({
    address: null,
    remark: '',
    itemDoms: null,
  });
  const dispatch = useDispatch();
  const {data, loading} = useRequest(() => dispatch({type: 'order/getOrder', id}), {
    ready: !!id,
    initialData,
    formatResult: res => res?.data,
  });

  const payParams = {
    addressId: state?.address?.id,
    remark: state?.remark,
  };
  const [_submit, {submitting}] = useOrderPay(data, {
    defaultParams: payParams,
    itemDoms: state.itemDoms,
    onSuccess(res) {
      if (res?.success) {
        navigation.replace('Order', {screen: 'OrderDetail', params: {id}});
      }
    },
  });

  const submit = async () => {
    if (!state.address?.id) {
      return Dialog.info('请选择收货地址');
    }
    const res = await _submit(payParams);
    return res;
  };

  const price = getOrderPrice(data);
  return (
    <LoadingView loading={loading} layer={!!data?.id} style={styles.page}>
      <ScrollView style={{flex: 1}}>
        <OrderContext.Provider value={{state, setState}}>
          <OrderSections data={data} sections={sections} />
        </OrderContext.Provider>
      </ScrollView>
      <OrderPayBar
        price={price} //
        submitting={submitting}
        submit={submit}
      />
    </LoadingView>
  );
});
const styles = StyleSheet.create({
  page: {
    flex: 1,
    zIndex: 0,
  },
});

ProductOrderPage.screenName = 'ProductOrder';
ProductOrderPage.screenOptions = {
  title: '确认支付',
};
ProductOrderPage.linking = {
  path: 'order/:id',
  parse: {
    id: Number,
  },
};
export default ProductOrderPage;
