import React from 'react';
import {ImageBackground, StyleSheet} from 'react-native';
import assets_colorful_border from './_colorful_border';

const styles = StyleSheet.create({
  wrap: {
    height: 3,
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 1,
  },
});
const ColorfulBorder = ({style}) => (
  <ImageBackground
    source={assets_colorful_border}
    style={[styles.wrap, style]}
    resizeMethod="resize"
    resizeMode="repeat"
    imageStyle={{resizeMode: 'repeat'}}
  />
);
export default ColorfulBorder;
