import BoxRarity from '@/utils/localData/BoxRarity';
import React from 'react';
import {ImageBackground} from 'react-native';
import assets_rarities from '../assets/_rarities';

const RarityImage = ({style, rarity}) => {
  const Rarity = BoxRarity.getByValue(rarity);
  const asset = assets_rarities[Rarity?.key];
  return <ImageBackground source={asset} style={[{width: 128, height: 30}, style]} />;
};

export default RarityImage;
