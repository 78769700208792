import BoxDetailPage from '@/pages/box/detail';
import BoxResultModal from '@/pages/box/detail/result.js';
import BoxListPage from '@/pages/box/list';
import BoxPickUpPage from '@/pages/box/pickUp';
import BoxRecordsPage from '@/pages/box/records';
import {createStackNavigator} from '@/utils/navigatorUtils';
import React from 'react';

export default createStackNavigator(
  [BoxListPage, BoxDetailPage, BoxRecordsPage, BoxPickUpPage],
  {
    screenName: 'Box',
    screenOptions: {
      headerShown: false,
    },
    linking: 'box',
  },
  [BoxResultModal],
);
