import request from '@/utils/request';
import _ from 'lodash';

/**
 * 商品信息查询
 * @param id: 商品的id
 * @resp: 返回商品详情信息，包括商品变体列表，所属的盲盒列表，商品详情
 * */
export function getProd(id) {
  return request(`/server/product/${id}`, {
    method: 'GET',
  });
}

/**
 * 喜欢
 * @param id: 商品的id
 * */
export function like(id) {
  return request('/server/product/like', {
    method: 'POST',
    data: {
      id,
    },
  });
}

/**
 * 取消喜欢
 * @param id: 商品的id
 * */
export function unlike(id) {
  return request('/server/product/unLike', {
    method: 'POST',
    data: {
      id,
    },
  });
}

/**
 * 收藏
 * @param id: 商品的id
 * */
export function collect(id) {
  return request('/server/prod/collect', {
    method: 'POST',
    data: {
      id,
    },
  });
}

/**
 * 取消收藏
 * @param id: 商品的id
 * */
export function uncollect(id) {
  return request('/server/prod/uncollect', {
    method: 'POST',
    data: {
      id,
    },
  });
}

/**
 * 购买接口
 * @param data: {
 *   skuId: 规格
 *   count: 数量
 *   payType: 支付方式
 * }
 * @resp: order
 * */
export function buy(data) {
  return request('/server/order/product', {
    method: 'POST',
    data: {
      items: [_.pick(data, ['skuId', 'count'])],
      orderPayType: data?.payType,
    },
  });
}

/**
 * 查询推荐商品
 * */
export function getRecommendProds() {
  return request('/server/product/recommend/page', {
    method: 'GET',
    params: {
      current: 1,
      size: 10,
    },
  });
}
