import Button from '@/components/Button';
import {Flex} from '@/components/Flex';
import Icon from '@/components/Icon';
import HotTag from '@/pages/box/components/HotTag';
import theme from '@/theme';
import FontWeight from '@/utils/localData/FontWeight';
import {useImageResize} from '@/utils/remoteData/ImageResize';
import {getDINProBoldStyle} from '@/utils/styleUtils';
import {useNavigation} from '@react-navigation/core';
import {assign} from 'lodash';
import React from 'react';
import {Dimensions, Image, Pressable, StyleSheet, Text, TouchableOpacity, View, Platform} from 'react-native';
import _styles from './style';

const Component = ({section}) => {
  const navigation = useNavigation();
  const {title, list} = section.data || {};
  const [, resizeImage] = useImageResize({object: 'box', size: 'sm'});
  return (
    (!!list?.length && (
      <View style={[_styles.section, styles.container, section.style]}>
        <Flex style={[styles.header]}>
          <Text style={styles.title}>{title}</Text>
          <TouchableOpacity style={[styles.extra]} onPress={() => navigation.navigate('Box', {screen: 'BoxList'})}>
            <Flex style={{flex: 1}} justify="end">
              <Text style={styles.extraText}>去逛逛</Text>
              <View style={styles.extraTextIconWrap}>
                <Icon type="arrow_right" />
              </View>
            </Flex>
          </TouchableOpacity>
        </Flex>
        <View style={styles.content}>
          {list.map((item, i) => {
            const {id, name, detail, hot, picture, amount} = item || {};
            return (
              <Pressable
                key={`$box_${id}`}
                style={[styles.box]}
                onPress={() => navigation.push('Box', {screen: 'BoxDetail', params: {id}})}>
                <Flex>
                  {hot && <HotTag />}
                  <Image source={{uri: resizeImage(picture)}} style={styles.boxImage} />
                  <View style={styles.boxContent}>
                    <Text style={styles.boxContentTitle} numberOfLines={1}>
                      {name}
                    </Text>
                    <Text style={styles.boxContentDesc} numberOfLines={1}>
                      {detail}
                    </Text>
                    <Flex justify="between" align="center">
                      <View>
                        <Text style={styles.boxContentPrice}>¥{amount}</Text>
                      </View>
                      <Button
                        type="primary"
                        round
                        size="small"
                        style={{width: 64}}
                        onPress={() => navigation.push('Box', {screen: 'BoxDetail', params: {id}})}>
                        超值抽
                      </Button>
                    </Flex>
                  </View>
                </Flex>
              </Pressable>
            );
          })}
        </View>
      </View>
    )) || <View style={[{marginBottom: 10}, section.style]} />
  );
};
const styles = StyleSheet.create({
  container: {},
  header: {},
  title: {
    flex: 1,
    fontSize: 16,
    lineHeight: 30,
    ...Platform.select({
      ios: {
        fontFamily: 'HYYakuHei',
      },
      default: {
        fontFamily: 'HYYKH85W',
      },
    }),
  },
  extra: {
    flexBasis: 60,
    justifyContent: 'flex-end',
  },
  extraText: {
    fontSize: 10,
    fontWeight: FontWeight.SemiBold,
    color: theme.color_text_secondary,
  },
  extraTextIconWrap: {
    width: 20,
    height: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginHorizontal: -5,
    marginVertical: 5,
  },

  content: {
    paddingTop: 10,
  },
  box: {
    height: 106,
    marginBottom: 6,
    marginTop: 2,
    backgroundColor: theme.fill_body,
    position: 'relative',
    padding: 8,
  },

  boxImage: {
    width: 90,
    height: 90,
    backgroundColor: '#fff',
  },
  boxContent: {
    flex: 1,
    marginLeft: 10,
    marginRight: 12,
    marginVertical: 7,
    maxWidth: Dimensions.get('window').width - 90 - 20 - 20 - 20 - 10 - 8,
  },
  boxContentTitle: {
    marginBottom: 3,
    fontWeight: FontWeight.SemiBold,
    lineHeight: 19,
  },
  boxContentDesc: {
    marginBottom: 9,
    fontSize: 12,
    lineHeight: 17,
    color: theme.color_text_secondary,
  },
  boxContentPrice: {
    marginTop: 3,
    ...getDINProBoldStyle(20),
    lineHeight: 25,
  },
});

export default class BoxSection {
  static type = 'box';
  static component = Component;
  style = {};

  constructor(config) {
    assign(this, config);
  }

  get type() {
    return this?.constructor?.type;
  }
}
