import {Flex, LoadingView} from '@/components';
import AreaPicker from '@/components/AreaPicker';
import Button from '@/components/Button';
import Dialog from '@/components/Dialog';
import HeaderRight from '@/components/Header/HeaderRight';
import Icon from '@/components/Icon';
import InputItem from '@/components/InputItem';
import IntlCodeSelector from '@/components/IntlCodeSelector';
import List from '@/components/List';
import Switch from '@/components/Switch';
import theme, {fill_base} from '@/theme';
import {useDispatch, useSelector} from '@/utils/dva';
import useNestingBackButton from '@/utils/hooks/useNestingBackButton';
import {useFocusEffect} from '@react-navigation/native';
import {useRequest} from 'ahooks';
import _ from 'lodash';
import React, {useState} from 'react';
import {ScrollView, StyleSheet, Text, TouchableOpacity, View} from 'react-native';
import useBottomInset from '@/utils/hooks/useBottomInset';

const EditAddressPage = ({navigation, route}) => {
  useNestingBackButton();
  const isNew = String(route.params?.id).toLowerCase() === 'new';
  const id = isNew ? undefined : Number(route.params?.id);
  const initialData = useSelector(s => _.find(s.address.data, item => item?.id === id));
  const [data, setData] = useState(_.clone(initialData));
  const dispatch = useDispatch();

  const {loading} = useRequest(() => dispatch({type: 'address/getAddresses'}), {
    ready: !!(id && !initialData),
    onSuccess(res) {
      res?.success && setData(_.find(res.data, item => item?.id === id));
    },
  });

  const {loading: submitting, run: submit} = useRequest(() => dispatch({type: 'address/saveAddress', data}), {
    manual: true,
    onSuccess(res) {
      if (res?.success) {
        Dialog.success(res?.msg ?? '保存成功');
        navigation.canGoBack() ? navigation.goBack() : navigation.navigate('Home');
      } else {
        Dialog.respError(res);
      }
    },
  });

  const {loading: deleting, run: doDelete} = useRequest(() => dispatch({type: 'address/deleteAddress', id}), {
    manual: true,
    ready: !!id,
    onSuccess(res) {
      if (res?.success) {
        Dialog.success(res?.msg ?? '删除成功');
        navigation.canGoBack() ? navigation.goBack() : navigation.navigate('Home');
      } else {
        Dialog.respError(res);
      }
    },
  });

  useFocusEffect(() => {
    navigation.setOptions({
      title: id ? '编辑地址' : '新增地址',
      headerRight: props =>
        !!id && (
          <HeaderRight {...props} navigation={navigation}>
            <TouchableOpacity onPress={!deleting ? doDelete : _.noop}>
              <Text>删除</Text>
            </TouchableOpacity>
          </HeaderRight>
        ),
    });
  });

  const handleChange = values => {
    setData({...data, ...values});
  };

  // ios：底部安全区域
  const [, paddingBottom] = useBottomInset(11);
  return (
    <LoadingView loading={loading || submitting || deleting} layer={submitting || deleting} style={styles.page}>
      <ScrollView style={styles.container}>
        <List border={false}>
          <InputItem placeholder="请填写收件人姓名" value={data?.name} onChange={name => handleChange({name})}>
            姓名
          </InputItem>
          <InputItem
            type="phone"
            placeholder="请填写收件人手机号"
            value={data?.mobile}
            onChange={mobile => handleChange({mobile})}
            renderInput={defaultDom => (
              <Flex style={{flex: 1}}>
                <IntlCodeSelector
                  defaultValue={'+86'}
                  value={data?.intlCode}
                  onChange={intlCode => handleChange({intlCode})}>
                  {code => (
                    <Flex justify="start" style={{marginRight: 10}}>
                      <Text style={styles.intlCodeLabel}>{code}</Text>
                      <Icon style={{marginLeft: 3}} type="arrow_down" size={6} fill={'#BDBDBD'} />
                    </Flex>
                  )}
                </IntlCodeSelector>
                {defaultDom}
              </Flex>
            )}>
            手机号
          </InputItem>
          <AreaPicker
            onChange={result => {
              const {
                area: {label: area},
                city: {label: city},
                province: {label: province},
              } = result || {};
              handleChange({area, city, province});
            }}>
            <InputItem
              pointerEvents={'none'}
              value={`${data?.province ?? ''}${data?.city ?? ''}${data?.area ?? ''}`}
              extra={<Icon type="arrow_right" />}
              placeholder="请选择收件地址（省/市/区）"
              editable={false}>
              选择地区
            </InputItem>
          </AreaPicker>
          <InputItem
            type="textarea"
            onChange={address => handleChange({address})}
            value={data?.address}
            placeholder="例如街道、门牌号、小区、楼栋号等"
            multiline={true}
            numberOfLines={2}
            last={true}>
            详细地址
          </InputItem>
        </List>
        <View style={styles.defaultContainer}>
          <InputItem
            extra={<Switch checked={data?.defaultFlag} onChange={defaultFlag => handleChange({defaultFlag})} />}
            placeholder=""
            editable={false}
            last={true}>
            设为默认地址
          </InputItem>
        </View>
      </ScrollView>
      <View style={[styles.footer, {paddingBottom}]}>
        <Button type="primary" onPress={submit} loading={submitting} style={styles.footerButton}>
          保存
        </Button>
      </View>
    </LoadingView>
  );
};
const styles = StyleSheet.create({
  page: {
    flex: 1,
  },
  container: {
    flex: 1,
  },
  intlCodeLabel: {
    fontSize: 12,
    color: '#BDBDBD',
  },
  defaultContainer: {
    backgroundColor: fill_base,
    marginVertical: 10,
  },
  footer: {
    paddingHorizontal: theme.v_spacing_lg,
    paddingVertical: 11,
    backgroundColor: '#fff',
    borderTopWidth: StyleSheet.hairlineWidth,
    borderTopColor: theme.border_color_base,
  },
  footerButton: {
    height: 48,
  },
});

EditAddressPage.screenName = 'EditAddress';
EditAddressPage.screenOptions = {
  title: '',
};
EditAddressPage.linking = 'edit/:id';
export default EditAddressPage;
