import {Button} from '@/components';
import FontWeight from '@/utils/localData/FontWeight';
import {isWeb} from '@/utils/utils';
import React, {useEffect, useRef} from 'react';
import {Animated, ImageBackground, Pressable, StyleSheet, View} from 'react-native';
import {assets_box, assets_line} from './assets';

/**
 * 盲盒等待开启
 * */
const BoxReady = ({open}) => {
  const animValue = useRef(new Animated.Value(0)).current;
  const animation = useRef(
    Animated.timing(animValue, {
      toValue: 4,
      duration: 2000,
      isInteraction: false,
      useNativeDriver: !isWeb(),
    }),
  ).current;

  const translateY = animValue.interpolate({
    inputRange: [0, 1, 2, 3, 4],
    outputRange: [4, 0, -4, 0, 4],
  });

  useEffect(() => {
    Animated.loop(animation).start();
  }, []);

  return (
    <View style={styles.container}>
      <ImageBackground source={assets_line} style={styles.bg}>
        <Pressable onPress={open}>
          <Animated.Image source={assets_box} style={[styles.box, {transform: [{translateY}]}]} />
        </Pressable>
      </ImageBackground>
      <Button type="primary" color={'#fff'} fontWeight={FontWeight.SemiBold} style={styles.btn} onPress={open}>
        点击开启
      </Button>
    </View>
  );
};

export default BoxReady;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
  },
  bg: {
    width: 361,
    height: 380,
    marginTop: 166,
    marginBottom: 59,
    justifyContent: 'flex-start',
    alignItems: 'center',
  },

  box: {
    width: 179,
    height: 167,
    marginTop: 80,
  },

  btn: {
    borderRadius: 4,
    width: 162,
    height: 48,
  },
});
