import HeaderSearch from '@/components/Header/HeaderSearch';
import {useSelector} from '@/utils/dva';
import {useNavigation} from '@react-navigation/core';
import React from 'react';
import {useWindowDimensions} from 'react-native';

export default function useHeaderSearchBar(searchProps) {
  const defaultText = useSelector(s => s.search.defaultText);
  const navigation = useNavigation();
  const {width} = useWindowDimensions();
  React.useEffect(() => {
    navigation.setOptions({
      headerTitle: () => (
        <HeaderSearch
          width={width}
          {...{
            autoCapitalize: 'none',
            placeholder: defaultText,
            autoFocus: true,
            blurOnSubmit: true,
            clearable: true,
            ...searchProps,
          }}
        />
      ),
    });
  }, [navigation, width, searchProps, defaultText]);
}
