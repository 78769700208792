import toMap from '@/utils/FrozenMap/toMap';
import {addToStateData} from '@/utils/remoteData';

@addToStateData('OrderSubType')
@toMap({mapValues: v => v?.value})
export default class OrderSubType {
  static Prod = {key: 'prod', text: '商品', value: '商品'};
  static Piece = {key: 'piece', text: '碎片', value: '碎片'};
  static Box = {key: 'box', text: '盲盒', value: '盲盒'};
}
