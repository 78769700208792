import theme from '@/theme';
import FontWeight from '@/utils/localData/FontWeight';
import {getDINProBoldStyle} from '@/utils/styleUtils';
import {isWeb} from '@/utils/utils';
import React, {useEffect, useMemo, useState} from 'react';
import {Image, ImageBackground, StyleSheet, Text, View} from 'react-native';

const _wave = require('./wave.gif');
const _wave_rise = require('./wave_rise.gif');

const CrystalBall = ({style, amount, resultAmount}) => {
  const [source1, setSource1] = useState();
  const [source2, setSource2] = useState();

  const [displayAmount, setDisplayAmount] = useState(amount);

  useEffect(() => {
    if (resultAmount > 0) {
      setDisplayAmount(resultAmount);
    } else if (amount >= 0) {
      setDisplayAmount(amount);
    }
  }, [amount, displayAmount, resultAmount]);

  const {wave_rise, wave} = useMemo(() => {
    if (isWeb()) {
      return {
        wave_rise: _wave_rise + `?${Date.now()}`,
        wave: _wave + `?${Date.now()}`,
      };
    }
    // todo: web上因缓存的问题是否需要降级处理
    return {wave_rise: _wave_rise, wave: _wave};
  }, []);

  useEffect(() => {
    const id1 = setTimeout(() => setSource1(wave_rise), 250);
    const id2 = setTimeout(() => {
      setSource2(wave);
    }, 2121 + 250);
    return () => {
      clearTimeout(id1);
      clearTimeout(id2);
    };
  }, [wave, wave_rise]);
  return (
    <ImageBackground source={source1} style={[styles.container, style]}>
      {source2 && <Image source={source2} style={[StyleSheet.absoluteFill, {zIndex: 1, width: 155, height: 155}]} />}
      <View style={[StyleSheet.absoluteFill, {zIndex: 2, alignItems: 'center'}]}>
        <Text style={styles.title}>出售价格</Text>
        <Text style={styles.price}>+{displayAmount}</Text>
        <Text style={styles.unit}>口袋币</Text>
      </View>
    </ImageBackground>
  );
};

export default CrystalBall;

const styles = StyleSheet.create({
  container: {
    width: 155,
    height: 155,
    borderRadius: 155,
    // borderWidth: StyleSheet.hairlineWidth,
    // borderColor: theme.border_color_base,
    backgroundColor: theme.fill_body,
    alignItems: 'center',
    overflow: 'hidden',
  },
  bg: {zIndex: -1},
  title: {
    fontSize: 12,
    marginTop: 13,
  },
  price: {
    ...getDINProBoldStyle(20),
    lineHeight: 28,
    marginTop: 42,
  },
  unit: {
    fontWeight: FontWeight.SemiBold,
  },
});
