import request from '@/utils/request';

/**
 * 分页查询道具
 * @param params: {
 *   status: '道具状态'
 *   lastId
 * }
 * */
export function getPagedTools11(params) {
  return request('/server/order/getPagedTools', {
    method: 'GET',
    params,
  });
}

/**
 * 分页查询道具
 * @param status
 * */
export function getTools(status) {
  return request('/server/coupon/getUserCoupons', {
    method: 'GET',
    params: {status},
  });
}

export async function getPagedTools({status, lastId}) {
  return await (() => {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve({
          success: true,
          data: _.range((lastId ?? 0) + 1, (lastId ?? 0) + 11).map(id => ({
            id,
            name: '海绵宝宝粉丝福利合集',
            desc: '三抽必中主图款，冲！',
            price: '900',
            image: 'https://images.shobserver.com/news/690_390/2021/9/10/d911633aab5943c690efe995cc98aa09.jpg',
          })),
        });
      }, 300);
    });
  })();
}
