import BoxRollingNotices from '@/pages/box/components/BoxRolingNotices';
import theme from '@/theme';
import {useBoxType} from '@/utils/remoteData/BoxType';
import _ from 'lodash';
import React, {useMemo} from 'react';
import {Platform, ScrollView, StatusBar, StyleSheet, Text, TouchableOpacity, View} from 'react-native';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import {Button, LinearGradient} from '@/components';

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#030303',
    paddingTop: StatusBar.currentHeight,
  },
  notices: {
    height: 26,
    marginVertical: 8,
  },
  tabBar: {
    height: 54,
    backgroundColor: theme.fill_body,
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
  },
  tabsContainer: {
    paddingHorizontal: 10,
    paddingTop: 8,
    borderBottomWidth: 1,
    borderColor: theme.border_color_base,
  },
  tab: {
    padding: 10,
    position: 'relative',
  },
  tabText: {
    lineHeight: 20,
  },
  tabIndicator: {
    width: 22,
    height: 3,
    backgroundColor: theme.brand_primary,
    position: 'absolute',
    left: '50%',
    bottom: 0,
    ...Platform.select({
      web: {
        marginLeft: -11,
      },
    }),
  },
  helpBtnContainer: {
    position: 'absolute',
    left: 0,
    paddingLeft: 20,
    // backgroundColor: '#030303',
    width: 125,
  },
  helpBtn: {
    height: 26,
    width: 85,
    // marginTop: 8,
  },
});
const StickyHeader = ({navigation, currentType, setCurrentType, scrollView}) => {
  const types = useBoxType();
  const tabs = useMemo(() => {
    return _.concat([{key: 'all', text: '全部', value: ''}], types);
  }, [types]);

  const onPress = type => {
    setCurrentType(type);
    if (scrollView?.current) {
      const {scrollTop, jumpToScrollTop, scrollTo} = scrollView.current || {};
      if (scrollTop > jumpToScrollTop && _.isFunction(scrollTo)) {
        scrollTo({y: jumpToScrollTop, animated: false});
      }
    }
  };
  const {top} = useSafeAreaInsets();
  return (
    <View style={[styles.container, {paddingTop: top}]}>
      <View style={styles.notices}>
        <BoxRollingNotices navigation={navigation} />
        <LinearGradient
          style={styles.helpBtnContainer}
          colors={['#030303', '#030303', 'rgba(0,0,0,0)']}
          locations={[0, 0.84, 1]}
          start={{x: 0, y: 0}}
          end={{x: 1, y: 0}}
          backgroundImage={'linear-gradient(to right, #030303 0, #030303 105px, rgba(255,255,255,0)  100%)'}>
          <Button
            type="primary"
            size="small"
            color="#00ffbc"
            isLight={true}
            round
            style={styles.helpBtn}
            onPress={() => navigation.navigate('Help')}>
            新手教学
          </Button>
        </LinearGradient>
      </View>
      <View style={styles.tabBar}>
        <ScrollView horizontal={true} style={styles.tabsContainer}>
          {_.map(tabs, (tab, index) => {
            const {key, text, value} = tab || {};
            const isActive = currentType === value;
            return (
              <TouchableOpacity key={`$box_tab__${key ?? index}`} style={styles.tab} onPress={() => onPress(value)}>
                <Text style={styles.tabText}>{text}</Text>
                {isActive && <View style={styles.tabIndicator} />}
              </TouchableOpacity>
            );
          })}
        </ScrollView>
      </View>
    </View>
  );
};

export default StickyHeader;
