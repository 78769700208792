import OrderType from '@/utils/localData/OrderType';
import PayType from '@/utils/localData/PayType';
import _ from 'lodash';

class Price {
  constructor({prefix, amount, unit, payType, ...rest} = {}) {
    _.extend(this, {available: true, prefix, amount, unit, payType}, rest);
  }

  isAvailable() {
    return !!this.available;
  }

  text(digits) {
    if (!this.isRmb()) {
      digits = 0;
    }
    let {prefix, amount, unit} = this;

    if (_.isNumber(digits) && digits >= 0) {
      amount = parseFloat(amount).toFixed(digits);
    } else {
      amount = (_.isNumber(amount) || _.isString(amount)) && String(amount);
    }

    return `${prefix ?? ''}${amount ?? ''}${(unit && amount && ' ') || ''}${unit ?? ''}`;
  }

  isScore() {
    return this.payType === PayType.Score;
  }

  isPiece() {
    return this.payType === PayType.Piece;
  }

  isRmb() {
    return !this.isScore() && !this.isPiece();
  }
}

export function getPrice(prod) {
  const {score, amount, fragmentFlag, fragmentAmount, fragmentName, scoreOnly} = prod || {};
  return [
    fragmentFlag
      ? new Price({prefix: '', amount: fragmentAmount ?? 0, unit: fragmentName ?? '碎片', payType: PayType.Piece})
      : new Price({prefix: '', amount: score ?? 0, unit: '口袋币', payType: PayType.Score}),
    new Price({prefix: '¥', amount: amount ?? 0, unit: '', payType: PayType.Alipay, available: !scoreOnly}),
  ];
}

export function getOrderPrice(order) {
  const {score, amount, fragmentAmount, fragmentName, type} = order || {};
  if (type === OrderType.PickUp) {
    return new Price({prefix: '¥', amount, unit: '', payType: PayType.Alipay});
  }
  if (score) {
    return new Price({prefix: '', amount: score, unit: '口袋币', payType: PayType.Score});
  } else if (fragmentAmount) {
    return new Price({prefix: '', amount: fragmentAmount, unit: fragmentName ?? '碎片', payType: PayType.Piece});
  }
  return new Price({prefix: '¥', amount, unit: '', payType: PayType.Alipay});
}
