import {Center} from '@/components/Flex';
import Loading from '@/components/Loading';
import PieceProdCard from '@/pages/piece/components/PieceProdCard';
import {useDispatch} from '@/utils/dva';
import {useNavigation} from '@react-navigation/core';
import {useRequest} from 'ahooks';
import React from 'react';
import {FlatList, ImageBackground, Pressable, StyleSheet} from 'react-native';
import assets_title from '../assets/_title';

const styles = StyleSheet.create({
  listHead: {
    height: 25,
    marginTop: 10,
    marginBottom: 11,
  },
  listHeadImage: {
    width: 98,
    height: 17,
  },
  prodCard: {
    flex: 1,
    height: 223,
    paddingLeft: 9,
    maxWidth: '50%',
  },
});
const ListHead = () => {
  return (
    <Center style={styles.listHead}>
      <ImageBackground source={assets_title} style={styles.listHeadImage} />
    </Center>
  );
};
const PieceProdList = ({ListHeaderComponent, data: initialData, pieceId}) => {
  const navigation = useNavigation();
  const dispatch = useDispatch();
  const {data, loading} = useRequest(() => dispatch({type: 'piece/getRecommendProds', pieceId}), {
    ready: !!(pieceId && !initialData?.length),
    initialData,
    formatResult: res => res?.data,
  });

  return (
    <FlatList
      ListHeaderComponent={ListHeaderComponent}
      data={data}
      numColumns={2}
      renderItem={({item}) => {
        return (
          <Pressable
            style={styles.prodCard}
            onPress={() =>
              navigation.push('Product', {
                screen: 'ProductDetail',
                params: {id: item?.id},
              })
            }>
            <PieceProdCard data={item} />
          </Pressable>
        );
      }}
      columnWrapperStyle={{marginLeft: -9, marginBottom: 9, paddingHorizontal: 10}}
      ListFooterComponent={loading && <Loading />}
    />
  );
};
PieceProdList.ListHead = ListHead;
export default PieceProdList;
