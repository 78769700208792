import request, {getPagedParams} from '@/utils/request';

/**
 * 分页查询订单
 * @param params: {
 *   current,
 *   pageSize,
 *   status: 订单状态
 * }
 * */
export function getPagedOrders(params) {
  return request('/server/order/page', {
    method: 'GET',
    params: getPagedParams(params),
  });
}

/**
 * 商品确认订单查询
 * @param id: 订单id
 * */
export function getOrder(id) {
  return request(`/server/order/${id}`, {
    method: 'GET',
  });
}

/**
 * 支付（订单确认）接口
 * @param data: {
 *   id: 订单id
 *   addressId: 地址
 *   remark: 订单备注
 *   payType: 支付方式
 *   deduct: 口袋币余额抵扣人民币
 * }
 * */
export function pay(data) {
  return request('/server/order/pay', {
    method: 'POST',
    data: {
      orderId: data?.id,
      addressId: data?.addressId,
      remark: data?.remark,
      orderPayType: data?.payType,
      deduct: !!data?.deduct,
    },
  });
}

/**
 * 支付结果查询（需要轮询直到返回成功）
 * @param id: 订单ID
 * */
export function getResult(id) {
  return request(`/server/order/status/${id}`, {
    method: 'GET',
  });
}

/**
 * 支付结果查询（需要轮询直到返回成功）
 * @param id: 订单ID
 * @param paid: 是否支付
 * */
export function confirmResult(id, paid) {
  return request('/server/order/getOrderPayResult', {
    method: 'GET',
    params: {
      id,
      paySuccess: !!paid,
    },
  });
}

/**
 * 确认收货接口
 * @param id
 * */
export function receive(id) {
  return request('/server/order/complete', {
    method: 'POST',
    data: {id},
  });
}

/**
 * 取消订单接口
 * @param id
 * */
export function cancel(id) {
  return request('/server/order/cancel', {
    method: 'POST',
    data: {id},
  });
}
