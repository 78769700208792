import LoginPage from '@/pages/login';
import useAuthed from '@/utils/hooks/useAuthed';
import useGlobalCallback from '@/utils/hooks/useGlobalCallback';
import {useIsFocused} from '@react-navigation/native';
import {useEffect} from 'react';
import {isNative} from '@/utils/utils';

const useAuthedTab = ({navigation, route}) => {
  const focused = useIsFocused();
  const [authed] = useAuthed();
  const loginCallback = useGlobalCallback(({navigation}) => {
    navigation.navigate(route);
  });
  useEffect(() => {
    if (!focused) {
      const unsubscribe = navigation.addListener('tabPress', e => {
        if (authed) {
          return;
        }
        if (isNative() && global.quickLogin) {
          global.quickLogin({
            passPrefetch: true,
            otherLogin: () => {
              navigation.navigate(LoginPage.screenName, {id: loginCallback?.current?.id});
            },
            onSuccess: res => {
              if (res?.success) {
                loginCallback?.current?.callback({navigation});
              }
            },
          });
        } else {
          navigation.navigate(LoginPage.screenName, {id: loginCallback?.current?.id});
        }
        e.preventDefault();
      });
      return unsubscribe;
    }
  }, [navigation, focused, authed, loginCallback]);
  return authed;
};

export default useAuthedTab;
