import {RatioView} from '@/components';
import Grid from '@/components/Grid';
import theme from '@/theme';
import FontWeight from '@/utils/localData/FontWeight';
import {useImageResize} from '@/utils/remoteData/ImageResize';
import {useLinkTo} from '@react-navigation/native';
import {assign} from 'lodash';
import React from 'react';
import {Image, StyleSheet, Text, TouchableOpacity, View} from 'react-native';

const Component = ({section, data}) => {
  const linkTo = useLinkTo();
  const [, resizeImage] = useImageResize({object: 'prod', size: 'xxs'});

  return (
    <View style={[styles.container, section.style]}>
      <View style={[styles.containerInside]}>
        {data?.length ? (
          <Grid
            {...section.gridProps}
            data={data}
            renderItem={(item, i) => {
              const {id, name, picture} = item || {};
              return (
                <TouchableOpacity
                  style={[styles.gridItem]}
                  onPress={() => id && linkTo(`/product/list?categoryId=${id}&title=${name}`)}>
                  <View style={[styles.imageContainer]} s>
                    <RatioView ratio={2 / 3} defaultHeight={33.3}>
                      <Image source={{uri: resizeImage(picture)}} resizeMode="cover" style={[styles.image]} />
                    </RatioView>
                  </View>
                  <Text style={[styles.text]}>{name}</Text>
                </TouchableOpacity>
              );
            }}
          />
        ) : null}
      </View>
    </View>
  );
};
const gutter = [20, 15];
const styles = StyleSheet.create({
  container: {
    paddingHorizontal: theme.h_spacing_lg,
    marginVertical: theme.v_spacing_md,
  },
  containerInside: {
    marginHorizontal: -gutter[0] / 2,
    marginBottom: -gutter[1],
  },
  gridItem: {
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: gutter[1],
  },
  imageContainer: {
    width: '100%',
    paddingHorizontal: gutter[0] / 2,
    marginTop: 1,
    marginBottom: 3,
  },
  image: {
    width: '100%',
    height: '100%',
    backgroundColor: theme.fill_grey,
  },
  text: {
    fontSize: 10,
    lineHeight: 14,
    fontWeight: FontWeight.SemiBold,
  },
});

export default class ActivitySection {
  static type = 'activity';
  static component = React.memo(Component);
  style = {};
  gridProps = {
    itemStyle: {
      height: 'auto',
    },
    columnNum: 5,
    hasLine: false,
  };

  constructor(config) {
    const {...rest} = config || {};
    assign(this, rest);
  }

  get type() {
    return this?.constructor?.type;
  }
}
