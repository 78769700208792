import address from '@/pages/address/model';
import box from '@/pages/box/model';
import catalogs from '@/pages/catalogs/model';
import coupon from '@/pages/coupon/model';
import document from '@/pages/document/model';
import favorites from '@/pages/favorites/model';
import mall from '@/pages/home/mall/model';
import notice from '@/pages/notice/model';
import order from '@/pages/order/model';
import piece from '@/pages/piece/model';
import point from '@/pages/point/model';
import product from '@/pages/product/model';
import search from '@/pages/search/model';
import tools from '@/pages/tools/model';
import activity_popup from './activity_popup';
import global from './global';
import lov from './lov';
import report from './report';
import user from './user';

export default [
  global,
  lov,
  user,
  report,
  activity_popup,
  mall,
  catalogs,
  search,
  product,
  box,
  point,
  piece,
  order,
  address,
  coupon,
  tools,
  favorites,
  notice,
  document,
];
