import AppLaunched from '@/AppLaunched';
import {defaultHeaderConfig} from '@/components/Header';
import AddressPicker from '@/pages/_modal_pickers/addressPicker';
import AreaPicker from '@/pages/_modal_pickers/areaPicker';
import CityPicker from '@/pages/_modal_pickers/cityPicker';
import IntlCodePicker from '@/pages/_modal_pickers/IntlCodePicker';
import ProvincePicker from '@/pages/_modal_pickers/provincePicker';
import AboutUsPage from '@/pages/aboutUs';
import AddressPage from '@/pages/address';
import BoxPage from '@/pages/box';
import CatalogsPage from '@/pages/catalogs';
import CouponPage from '@/pages/coupon';
import DocumentPage from '@/pages/document';
import FavoritesPage from '@/pages/favorites';
import HomePage from '@/pages/home';
import LoginPage from '@/pages/login';
import LogoffPage from '@/pages/logoff';
import NoticePage from '@/pages/notice';
import OrderPage from '@/pages/order';
import PiecePage from '@/pages/piece';
import PointPage from '@/pages/point';
import ProductPage from '@/pages/product';
import MineProfilePage from '@/pages/profile';
import SearchPage from '@/pages/search';
import SettingPage from '@/pages/setting';
import ReadmePage from '@/pages/readme';
import ToolsPage from '@/pages/tools';
import HelpPage from '@/pages/help';
import globalStyle from '@/styles';
import {useSelector} from '@/utils/dva';
import {widthProvider} from '@/utils/hooks/useDynamicModal';
import {getLinkings, getScreenElements} from '@/utils/navigatorUtils';
import {isNative} from '@/utils/utils';
import AntdProvider from '@ant-design/react-native/lib/provider';
import {NavigationContainer, useNavigationContainerRef} from '@react-navigation/native';
import {createNativeStackNavigator} from '@react-navigation/native-stack';
import React, {useEffect} from 'react';
import {View} from 'react-native';
import theme from './theme';

const screens = [
  HomePage, // keep first
  LoginPage,
  LogoffPage,
  CatalogsPage,
  SearchPage,
  ProductPage,
  BoxPage,
  PointPage,
  PiecePage,
  OrderPage,
  MineProfilePage,
  SettingPage,
  AboutUsPage,
  DocumentPage,
  AddressPage,
  CouponPage,
  NoticePage,
  ToolsPage,
  FavoritesPage,
  ReadmePage,
  HelpPage,
];

const modals = [
  ProvincePicker, //
  CityPicker,
  AreaPicker,
  IntlCodePicker,
  AddressPicker,
];

const linking = {
  // prefixes: ['https://koudai.com', 'koudai://'],
  config: {
    screens: getLinkings(screens),
  },
};
const {Navigator, Screen, Group} = createNativeStackNavigator();

function RootNavigator() {
  const navigationRef = useNavigationContainerRef();
  const agreement = useSelector(s => s.global.agreement);

  useEffect(() => {
    global._navigation = navigationRef;
  }, [navigationRef]);

  return (
    <View style={[globalStyle.root, {flex: 1}]} edges={['bottom']}>
      <NavigationContainer
        ref={navigationRef}
        linking={linking}
        // fallback={<SplashScreen />}
      >
        <AntdProvider>
          <AppLaunched>
            <Navigator
              initialRouteName={isNative() && !agreement ? ReadmePage.screenName : HomePage.screenName}
              screenOptions={{
                ...defaultHeaderConfig,
                contentStyle: {backgroundColor: theme.fill_body},
              }}>
              <Group>{getScreenElements(screens, Screen, undefined, {}, [widthProvider])}</Group>
              <Group screenOptions={{presentation: 'modal', animation: 'slide_from_right'}}>
                {getScreenElements(modals, Screen, s => s?.modalName || '')}
              </Group>
            </Navigator>
          </AppLaunched>
        </AntdProvider>
      </NavigationContainer>
    </View>
  );
}

export default React.memo(RootNavigator);
