import {Dialog, Popover} from '@/components';
import Button from '@/components/Button';
import Checkbox from '@/components/Checkbox';
import {Flex} from '@/components/Flex';
import Icon from '@/components/Icon';
import IntlCodeSelector from '@/components/IntlCodeSelector';
import Link from '@/components/Link';
import styles from '@/pages/login/components/styles';
import {useDispatch} from '@/utils/dva';
import {isValidPhone} from '@/utils/formUtils';
import useLov from '@/utils/hooks/useLov';
import LoginType from '@/utils/localData/LoginType';
import {useCountDown, useRequest} from 'ahooks';
import {isFunction} from 'lodash';
import React, {useCallback, useEffect} from 'react';
import {Animated, Pressable, Text, View} from 'react-native';
import InputItem from './InputItem';
import useFadeInOut from './useFadeInOut';

const LoginBySMS = ({loginType, state, setState, onSuccess}) => {
  const docs = useLov('DOCUMENT_TYPE');
  // const style = useFadeInOut(loginType === LoginType.Phone);
  const style = useFadeInOut(true);

  const showArgTop = useCallback(() => {
    setState({
      agreementTipVisible: true,
    });
  }, [setState]);
  const hideArgTop = useCallback(() => {
    setState({
      agreementTipVisible: false,
    });
  }, [setState]);

  useEffect(() => {
    if (state?.agreementTipVisible) {
      const id = setTimeout(hideArgTop, 3000);
      return () => clearTimeout(id);
    }
  }, [state?.agreementTipVisible, hideArgTop]);

  const [countdown, setTargetDate] = useCountDown();
  const dispatch = useDispatch();
  const {loading, run: getCaptcha} = useRequest(
    () =>
      dispatch({
        type: 'global/getCaptcha',
        params: {
          intlCode: state?.intlCode,
          phone: state?.phone,
        },
      }),
    {
      manual: true,
      loadingDelay: 300,
      onSuccess(res) {
        if (res?.success) {
          setTargetDate(Date.now() + 60000);
        } else {
          Dialog.respError(res);
        }
      },
    },
  );

  const {loading: submitting, run: submit} = useRequest(() => dispatch({type: 'user/login', data: state}), {
    manual: true,
    onSuccess(res) {
      isFunction(onSuccess) && onSuccess(res);
      if (res?.success) {
        // Dialog.success(res.msg || '登录成功');
      } else {
        Dialog.respError(res);
      }
    },
  });

  const handleSubmit = () => {
    const {phone, validateCode, agreement} = state || {};
    if (!isValidPhone(phone)) {
      return Dialog.info('请输入手机号码');
    } else if (!(validateCode && validateCode?.length >= 4)) {
      return Dialog.info('请输入验证码');
    } else if (!agreement) {
      return showArgTop();
    }
    submit();
  };

  useEffect(() => {
    if (loading) {
      return Dialog.loading('加载中...');
    }
  }, [loading]);

  return (
    <Animated.View style={[styles.form, style]}>
      <View style={{marginBottom: 36}}>
        <InputItem
          value={state?.phone}
          onChange={phone => {
            setState({phone});
          }}
          placeholder="输入手机号">
          <IntlCodeSelector
            defaultValue={'+86'}
            onChange={intlCode => {
              setState({intlCode});
            }}>
            {code => (
              <Flex>
                <Text style={styles.formItemLabel}>{code}</Text>
                <Icon style={{marginLeft: 3}} type="arrow_down" size={6} fill={'#000'} />
              </Flex>
            )}
          </IntlCodeSelector>
        </InputItem>
        <Text style={styles.alertText}>未注册的手机号验证后自动创建账户</Text>
      </View>
      <View style={{marginBottom: 27}}>
        <InputItem
          value={state?.validateCode}
          onChange={validateCode => {
            setState({validateCode});
          }}
          extra={
            <Button
              style={{height: 21}}
              type={isValidPhone(state?.phone) ? 'primary' : 'default'}
              size="small"
              disabled={loading || !(isValidPhone(state?.phone) && countdown === 0)}
              onPress={getCaptcha}>
              {countdown === 0 ? '发送验证码' : `已发送 ${Math.round(countdown / 1000)}s`}
            </Button>
          }
          placeholder="输入您的验证码">
          <Text style={styles.formItemLabel}>验证码</Text>
        </InputItem>
      </View>
      <Flex align="start" style={{marginBottom: 27}}>
        <Checkbox
          style={{height: 24}}
          checked={!!state?.agreement}
          onChange={agreement => {
            setState({agreement});
          }}
          hitSlop={{bottom: 20, left: 20, right: 20, top: 20}}>
          <Popover
            content="请阅读并勾选同意用户协议"
            position="top"
            caretPosition="left"
            caretStyle={{left: 10}}
            visible={loginType === LoginType.Phone && state?.agreementTipVisible}
            style={{
              minWidth: 162,
              transform: [{translateX: -10}, {translateY: -25}],
              position: 'absolute',
            }}>
            <Text style={{paddingVertical: 5, paddingHorizontal: 8, color: '#fff', fontSize: 12}}>
              请阅读并勾选同意用户协议
            </Text>
          </Popover>
        </Checkbox>
        <Pressable onPress={() => setState({agreement: true})}>
          <Text style={[styles.agreement, {textAlign: 'left'}]}>
            <Text style={[styles.privacyText, styles.secondaryColor]}>登录即代表阅读并同意</Text>
            <Link to="/document/fwxy" style={styles.privacyText}>
              {docs?.fwxy ?? '用户服务协议'}
            </Link>
            <Text style={[styles.privacyText, styles.secondaryColor]}>与</Text>
            <Link to="/document/yscl" style={styles.privacyText}>
              {docs?.yscl ?? '隐私政策'}
            </Link>
          </Text>
        </Pressable>
      </Flex>
      <View style={{marginBottom: 26}}>
        <Button
          type="primary"
          disabled={!(isValidPhone(state?.phone) && state?.validateCode)}
          loading={submitting}
          size="large"
          onPress={handleSubmit}>
          登录
        </Button>
      </View>
      <Flex justify="end" style={{marginBottom: 26}}>
        {/*<Link to={'FindPassword'} style={styles.privacyText}>
          找回密码
        </Link>*/}
        {/*<Link style={styles.privacyText} onPress={onChangeLoginType.bind({}, LoginType.Password)}>
          密码登录
        </Link>*/}
      </Flex>
    </Animated.View>
  );
};

export default LoginBySMS;
