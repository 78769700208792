import ProdType from '@/utils/localData/ProdType';
import React from 'react';
import {StyleSheet, View} from 'react-native';
import ProdCardCommon from './ProdCardCommon';
import ProdCardForBox from './ProdCardForBox';

const styles = StyleSheet.create({
  card: {
    width: '100%',
    height: 258,
  },
});
const ProdCard = ({style, data}) => {
  const {type} = data || {};

  return (
    <View style={[styles.card, style]}>
      {type === ProdType.Box ? <ProdCardForBox data={data} /> : <ProdCardCommon data={data} />}
    </View>
  );
};

export default ProdCard;
