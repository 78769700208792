import React from 'react';
import {ScrollView, StyleSheet, View} from 'react-native';
import {assets_guid_image_1, assets_guid_image_2, assets_guid_image_3} from './assets';
import Image from './Image';

const HelpPage = () => {
  return (
    <View style={styles.page}>
      <ScrollView>
        <Image source={assets_guid_image_1} />
        <Image source={assets_guid_image_2} />
        <Image source={assets_guid_image_3} />
      </ScrollView>
    </View>
  );
};
HelpPage.screenName = 'Help';
HelpPage.screenOptions = {
  title: '口袋盲盒新手教学',
};
const styles = StyleSheet.create({
  page: {
    flex: 1,
    backgroundColor: '#b5fec0',
  },
  imageContainer: {
    width: '100%',
  },
});
HelpPage.linking = 'help';
export default HelpPage;
