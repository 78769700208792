import Grid from '@/components/Grid';
import LinearGradient from '@/components/LinearGradient';
import LinearTextGradient from '@/components/LinearTextGradient';
import theme from '@/theme';
import BoxRarity from '@/utils/localData/BoxRarity';
import FontWeight from '@/utils/localData/FontWeight';
import _ from 'lodash';
import React from 'react';
import {Platform, StyleSheet, Text, TouchableOpacity, View} from 'react-native';

const BoxRarityBar = ({data, current, onChange}) => {
  return (
    <View style={{marginLeft: -6}}>
      <Grid
        data={BoxRarity.values()}
        columnNum={BoxRarity.size}
        itemStyle={styles.itemStyle}
        renderItem={item => {
          const {key, value, text, colors, bgColor} = item || {};
          const checked = current === value;
          const probability = data[key];
          return (
            <TouchableOpacity onPress={() => onChange?.(value)}>
              <LinearGradient
                style={[styles.container, checked && styles.checked, checked && {borderColor: colors[0]}]}
                colors={[bgColor, 'rgba(255,255,255,0)']}
                start={{x: 0, y: 0}}
                end={{x: 0, y: 1}}
                backgroundImage={`linear-gradient(to bottom, ${bgColor} 0, rgba(255,255,255,0)  100%)`}>
                <LinearTextGradient
                  style={[styles.title, {color: colors[1]}]}
                  colors={colors}
                  start={{x: 0, y: 0}}
                  end={{x: 1, y: 0}}
                  backgroundImage={`linear-gradient(to right, ${colors[0]} 0, ${colors[1]}  100%)`}>
                  {text}
                </LinearTextGradient>
                <Text style={styles.desc} numberOfLines={1}>
                  概率{_.floor(parseFloat(probability).mul(100), 4)}%
                </Text>
              </LinearGradient>
            </TouchableOpacity>
          );
        }}
      />
    </View>
  );
};
const styles = StyleSheet.create({
  itemStyle: {
    height: 48,
    marginLeft: 6,
  },
  container: {
    borderWidth: 1,
    borderColor: '#fff',
    borderRadius: 4,
    height: 48,
    paddingVertical: 7,
    paddingHorizontal: 10,
  },
  checked: {
    borderWidth: 1,
    borderColor: '#FF3865',
  },
  title: {
    color: '#D8D8D8',
    fontWeight: FontWeight.SemiBold,
    lineHeight: 20,
  },
  desc: {
    fontSize: 10,
    lineHeight: 14,
    color: theme.color_text_secondary,

    ...Platform.select({
      web: {
        transformOrigin: 'left center',
        // minWidth: '120%',
        // transform: [{scale: 0.833}],
        minWidth: '140%',
        transform: [{scale: 0.833}],
      },
      default: {
        minWidth: '120%',
      },
    }),
  },
});

export default BoxRarityBar;
