import {useDispatch, useSelector} from '@/utils/dva';
import useLov from '@/utils/hooks/useLov';
import {openLogin} from '@/utils/request';
import {useRequest} from 'ahooks';
import {isFunction} from 'lodash';
import React, {useEffect, useState} from 'react';
import _ from 'lodash';

const Deduction = ({amount, children, onChange}) => {
  const score = useSelector(s => s.global?.currentUser?.score) || 0;
  const dispatch = useDispatch();
  const {onoff, percent: deductPercent} = useLov('SCORE_TO_RMB_ENABLE');
  const rate = useLov('RMB_SCORE_RATE')?.[1];
  const enable = onoff === 'on';
  const [deductAmount, setDeductAmount] = useState(0);
  const {loading} = useRequest(() => dispatch({type: 'global/getCurrentUser'}), {
    ready: !!(enable && rate > 0),
    onSuccess(res) {
      if (!res?.success) {
        openLogin();
      }
    },
  });

  useEffect(() => {
    if (enable && rate > 0 && score >= 0 && deductPercent >= 0) {
      const value1 = _.floor(parseFloat(amount).mul(deductPercent).div(100), 2);
      const value2 = _.floor(parseInt(score).div(rate).mul(deductPercent).div(100), 2);
      setDeductAmount(Math.min(parseFloat(amount), value1, value2));
    }
  }, [amount, deductPercent, enable, rate, score]);

  useEffect(() => {
    isFunction(onChange) && onChange(deductAmount);
  }, [deductAmount, onChange]);

  // if (Number(deductAmount) <= 0) {
  //   return null;
  // }
  if (isFunction(children)) {
    return children(deductAmount, {loading});
  }
  return children;
};

export default React.memo(Deduction);
