import {Center} from '@/components/Flex';
import Modal from '@/components/Modal';
import theme from '@/theme';
import useControlledModalProps from '@/utils/hooks/useControlledModalProps';
import useLov from '@/utils/hooks/useLov';
import React from 'react';
import {Image, StyleSheet, Text} from 'react-native';

/**
 * 联系客服
 * */

const CustomerService = ({onSuccess, ...rest}) => {
  const {visible, close, modalProps} = useControlledModalProps(rest);
  const {qrcode, work_time} = useLov('APP_CUSTOMER_SERVICE') || {};

  return (
    <Modal
      visible={visible}
      onClose={close}
      title="联系客服"
      closable={true}
      transparent={true}
      maskClosable={true}
      {...modalProps}
      style={styles.modal}
      bodyStyle={styles.bodyStyle}>
      <Center direction="column">
        <Image source={{uri: qrcode}} style={styles.qrcode} />
        <Text style={styles.desc}>保存图片至相册，扫码即可添加</Text>
        <Text style={styles.text}>人工客服时间：</Text>
        <Text style={styles.text}>{work_time}</Text>
      </Center>
    </Modal>
  );
};

export default CustomerService;

const styles = StyleSheet.create({
  modal: {
    paddingTop: 50,
  },
  bodyStyle: {
    marginTop: 30,
    paddingBottom: 52,
    borderBottomWidth: 0,
  },

  qrcode: {
    width: 150,
    height: 150,
    marginBottom: 14,
  },
  desc: {
    color: theme.color_text_secondary,
    fontSize: 12,
    lineHeight: 17,
    marginBottom: 20,
  },
  text: {
    fontSize: 12,
    lineHeight: 17,
  },
});
