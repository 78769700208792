import {Center, Dialog, Flex, LoadingView, Popover} from '@/components';
import Button from '@/components/Button';
import Checkbox from '@/components/Checkbox';
import Icon from '@/components/Icon';
import Link from '@/components/Link';
import QuickLoginApi from '@/NativeModules/QuickLogin';
import LoginByQQ from '@/pages/login/components/LoginByQQ';
import LoginByWechat from '@/pages/login/components/LoginByWechat';
import {useDispatch} from '@/utils/dva';
import useLov from '@/utils/hooks/useLov';
import FontWeight from '@/utils/localData/FontWeight';
import LoginType from '@/utils/localData/LoginType';
import {useRequest} from 'ahooks';
import {isFunction} from 'lodash';
import React, {useCallback, useEffect} from 'react';
import {Pressable, StyleSheet, Text, View} from 'react-native';
import _styles from './styles';

const QuickLogin = ({navigation, loginType, onChangeLoginType, state, setState, onSuccess, hasCallback}) => {
  const docs = useLov('DOCUMENT_TYPE');
  const showArgTop = useCallback(() => {
    setState({
      agreementTipVisible: true,
    });
  }, [setState]);
  const hideArgTop = useCallback(() => {
    setState({
      agreementTipVisible: false,
    });
  }, [setState]);

  useEffect(() => {
    if (state?.agreementTipVisible) {
      const id = setTimeout(hideArgTop, 3000);
      return () => clearTimeout(id);
    }
  }, [state?.agreementTipVisible, hideArgTop]);

  useEffect(() => {
    navigation.setOptions({
      headerTitle: () => null,
    });
    const unsubscribe = navigation.addListener('beforeRemove', e => {
      // isAndroid() && e.preventDefault();
      setTimeout(() => {
        onChangeLoginType(LoginType.Phone);
      }, 200);
      navigation.setOptions({
        headerTitle: () => <Icon type="text_logo" />,
      });
    });
    return unsubscribe;
  }, []);

  const dispatch = useDispatch();
  const {loading: submitting, run: submit} = useRequest(
    (token, accessToken) =>
      dispatch({
        type: 'user/loginByYD',
        token,
        accessToken,
      }),
    {
      manual: true,
      onSuccess(res) {
        isFunction(onSuccess) && onSuccess(res);
        if (res?.success) {
          // Dialog.success(res.msg || '登录成功');
        } else {
          Dialog.respError(res);
        }
      },
    },
  );

  const handleSubmit = () => {
    const {agreement} = state || {};
    if (!agreement) {
      return showArgTop();
    }
    QuickLoginApi.onePass({})
      .then(apiRes => {
        console.log(apiRes);
        const {token, accessToken} = apiRes || {};
        if (token && accessToken) {
          submit(token, accessToken);
        }
      })
      .catch(e => {
        console.log('QuickLoginApi error', e);
        Dialog.info(e.toString());
      });
  };

  return (
    <LoadingView loading={submitting} layer={true} style={styles.page}>
      <View style={styles.container}>
        <Center style={styles.header}>{!!hasCallback && <Text style={styles.title}>登录后继续操作</Text>}</Center>
        <View style={styles.body}>
          <Center style={{marginBottom: 31}}>
            <Text style={styles.mobileNumber}>{state?.mobileNumber}</Text>
          </Center>
          <Flex align="start" justify="center" style={{marginBottom: 20, paddingLeft: 20, paddingRight: 2}}>
            <Checkbox
              style={{height: 24, zIndex: 1}}
              checked={!!state?.agreement}
              onChange={agreement => {
                setState({agreement});
              }}
              hitSlop={{bottom: 20, left: 20, right: 20, top: 20}}>
              <Popover
                content="请阅读并勾选同意用户协议"
                position="top"
                caretPosition="left"
                caretStyle={{left: 10}}
                visible={loginType === LoginType.QuickLogin && state?.agreementTipVisible}
                style={{
                  minWidth: 162,
                  transform: [{translateX: -10}, {translateY: -25}],
                  position: 'absolute',
                }}>
                <Text style={{paddingVertical: 5, paddingHorizontal: 8, color: '#fff', fontSize: 12}}>
                  请阅读并勾选同意用户协议
                </Text>
              </Popover>
            </Checkbox>
            <Pressable onPress={() => setState({agreement: true})}>
              <Text style={[_styles.agreement, {textAlign: 'center', left: -18}]}>
                <Text style={[_styles.privacyText, _styles.secondaryColor]}>&#12288;&ensp;登录即代表阅读并同意</Text>
                <Link to="/document/fwxy" style={_styles.privacyText}>
                  {docs?.fwxy ?? '用户服务协议'}
                </Link>
                <Text style={[_styles.privacyText, _styles.secondaryColor]}>与</Text>
                <Link to="/document/yscl" style={_styles.privacyText}>
                  {docs?.yscl ?? '隐私政策'}
                </Link>
                <Text style={[_styles.privacyText, _styles.secondaryColor]}>与</Text>
                <Link to="/document/yscl" style={_styles.privacyText}>
                  {docs?.yscl ?? '隐私政策'}
                </Link>
              </Text>
            </Pressable>
          </Flex>
          <View style={{marginBottom: 14}}>
            <Button type="primary" loading={submitting} size="large" onPress={handleSubmit}>
              本机号码一键登录
            </Button>
          </View>
          <View style={{marginBottom: 14}}>
            <Button type="ghost" size="large" onPress={() => onChangeLoginType(LoginType.Phone)}>
              其他手机号码登录
            </Button>
          </View>
        </View>

        <View style={styles.footer}>
          <Flex justify="center" style={styles.othersContent}>
            <LoginByWechat
              state={state}
              setState={setState}
              onSuccess={onSuccess}
              onChangeLoginType={onChangeLoginType}
            />
            <LoginByQQ state={state} setState={setState} onSuccess={onSuccess} onChangeLoginType={onChangeLoginType} />
          </Flex>
        </View>
      </View>
    </LoadingView>
  );
};

export default QuickLogin;

const styles = StyleSheet.create({
  page: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    overflow: 'hidden',
  },
  container: {
    marginTop: 69,
    width: 300,
  },
  header: {
    marginBottom: 32,
    minHeight: 32,
  },
  title: {
    lineHeight: 32,
    fontSize: 24,
    fontWeight: FontWeight.Light,
  },
  body: {
    paddingTop: 50 - 32,
    width: 300,
    height: 350,
  },
  mobileNumber: {
    lineHeight: 33,
    fontSize: 24,
    fontWeight: FontWeight.SemiBold,
  },
  othersContent: {
    marginVertical: 28,
  },
});
