'use strict';

Object.defineProperty(exports, '__esModule', {
  value: true,
});
exports.default = void 0;

var _reactNative = require('react-native');

var _default = function _default(theme) {
  return _reactNative.StyleSheet.create({
    card: {
      // borderWidth: theme.border_width_md,
      borderWidth: _reactNative.StyleSheet.hairlineWidth,
      borderColor: theme.border_color_base,
      borderRadius: theme.radius_md,
      // paddingBottom: styles.v_spacing_sm,
      flexDirection: 'column',
      backgroundColor: theme.fill_base,
    },
    full: {
      borderRadius: 0,
      borderLeftWidth: 0,
      borderRightWidth: 0,
    },
    headerWrap: {
      flexDirection: 'row',
      paddingVertical: theme.v_spacing_md,
      paddingRight: theme.h_spacing_lg,
      marginLeft: theme.h_spacing_lg,
      alignItems: 'center',
      // borderBottomWidth: theme.border_width_md,
      borderBottomWidth: _reactNative.StyleSheet.hairlineWidth,
      borderColor: theme.border_color_base,
    },
    headerTitle: {
      flex: 1,
      flexDirection: 'row',
      alignItems: 'center',
    },
    headerImage: {
      marginRight: theme.h_spacing_sm,
    },
    headerContentWrap: {
      flex: 1,
    },
    headerContent: {
      color: theme.color_text_base,
      fontSize: theme.font_size_heading,
      flex: 1,
    },
    headerExtraWrap: {
      flex: 1,
    },
    headerExtra: {
      flex: 1,
      fontSize: theme.font_size_heading,
      color: theme.color_text_caption,
      textAlign: 'right',
    },
    body: {
      flexGrow: 1,
      paddingVertical: theme.v_spacing_xl,
      minHeight: 48,
    },
    footerWrap: {
      flexDirection: 'row',
      // paddingVertical: styles.v_spacing_md,
      paddingVertical: 12,
      paddingRight: theme.h_spacing_lg,
      marginLeft: theme.h_spacing_lg,
      // borderTopWidth: theme.border_width_md,
      borderTopWidth: _reactNative.StyleSheet.hairlineWidth,
      borderColor: theme.border_color_base,
    },
    footerContent: {
      flex: 1,
      fontSize: theme.font_size_base,
      color: theme.color_text_caption,
    },
    footerExtra: {
      textAlign: 'right',
      fontSize: theme.font_size_base,
      color: theme.color_text_caption,
    },
  });
};

exports.default = _default;
