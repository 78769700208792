import toMap from '@/utils/FrozenMap/toMap';
import {addToStateData} from '@/utils/remoteData';

@addToStateData('ToolStatus')
@toMap({mapValues: v => v?.value})
export default class ToolStatus {
  static All = {key: 'all', text: '全部', value: '', link: '/tools/all'};
  static Used = {key: 'used', text: '已使用', value: '已使用', link: '/tools/used'};
  static Valid = {key: 'valid', text: '有效的', value: '有效的'};
}
