import EditAddressPage from '@/pages/address/edit';
import AddressListPage from '@/pages/address/list';
import authedPage from '@/utils/hooks/authedPage';
import {createStackNavigator} from '@/utils/navigatorUtils';
import React from 'react';

export default createStackNavigator(
  [AddressListPage, EditAddressPage],
  {
    screenName: 'Address',
    screenOptions: {
      headerShown: false,
    },
    linking: 'address',
  },
  [],
  [authedPage],
);
