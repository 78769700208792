import HeaderLeft from '@/components/Header/HeaderLeft';
import HeaderRight from '@/components/Header/HeaderRight';
import ShadowIcon from '@/components/Header/ShadowIcon';
import {IconButton} from '@/components/Icon';
import {TranslucentStatusBar} from '@/components/StatusBar';
import {useFocusEffect} from '@react-navigation/native';
import React, {useRef} from 'react';

const useTranslucentBoxHeader = (navigation, {onShare} = {}) => {
  const translucentHeader = useRef({
    headerShown: true,
    headerTransparent: true,
    headerTintColor: '#fff',
    headerStyle: {
      backgroundColor: 'transparent',
    },
    headerLeft: props => <HeaderLeft {...props} navigation={navigation} showBtnShadow={true} />,
    headerRight: props => (
      <HeaderRight {...props}>
        <TranslucentStatusBar barStyle="light-content" />
        <ShadowIcon type="share" onPress={() => onShare?.()} />
      </HeaderRight>
    ),
  }).current;
  const fixedHeader = useRef({
    headerShown: true,
    headerTransparent: true,
    headerTintColor: '#000',
    headerStyle: {
      backgroundColor: '#fff',
    },
    headerLeft: props => <HeaderLeft {...props} navigation={navigation} />,
    headerRight: props => (
      <HeaderRight {...props}>
        <TranslucentStatusBar barStyle="dark-content" />
        <IconButton type="share" fill="#000" onPress={() => onShare?.()} />
      </HeaderRight>
    ),
  }).current;
  const scrollView = useRef({scrollTop: 0});
  useFocusEffect(() => {
    if (scrollView?.current?.scrollTop > 400) {
      navigation.setOptions(fixedHeader);
    } else {
      navigation.setOptions(translucentHeader);
    }
  });
  const onScroll = evt => {
    scrollView.current.scrollTop = evt.nativeEvent.contentOffset.y;
    if (evt.nativeEvent.contentOffset.y > 400) {
      navigation.setOptions(fixedHeader);
    } else {
      navigation.setOptions(translucentHeader);
    }
  };
  return {onScroll};
};

export default useTranslucentBoxHeader;
