import CustomerService from '@/components/_modals/CustomerService';
import PieceConvertModal from '@/components/_modals/PieceConvertModal';
import Avatar from '@/components/Avatar';
import Badge from '@/components/Badge';
import {Flex} from '@/components/Flex';
import Grid from '@/components/Grid';
import Header from '@/components/Header';
import Icon from '@/components/Icon';
import List from '@/components/List';
// import spConfigs from './_configs/spConfigs';
import Toast from '@/components/Toast';
import funcConfigs from '@/pages/home/mine/_configs/funcConfigs';
import assets__statistic_bg from '@/pages/home/mine/assets/_statistic_bg';
import useAuthedTab from '@/pages/home/useAuthedTab';
import theme from '@/theme';
import {useDispatch} from '@/utils/dva';
import authedComponent from '@/utils/hooks/authedComponent';
import useDynamicModal from '@/utils/hooks/useDynamicModal';
import FontWeight from '@/utils/localData/FontWeight';
import OrderStatus from '@/utils/localData/OrderStatus';
import {getDINProBoldStyle} from '@/utils/styleUtils';
import {useFocusEffect, useLinkTo} from '@react-navigation/native';
import {useRequest} from 'ahooks';
import React, {useCallback} from 'react';
import {ImageBackground, ScrollView, StatusBar, StyleSheet, Text, TouchableOpacity, View} from 'react-native';
import {assets_my_top} from '@/pages/home/mine/assets';
import useFocusedStatusBar from '@/utils/hooks/useFocusedStatusBar';
import {TranslucentStatusBar} from '@/components';

const statusBarDom = <TranslucentStatusBar barStyle="light-content" />;
const _MinePage = authedComponent(({navigation, currentUser}) => {
  const statusBar = useFocusedStatusBar(navigation, statusBarDom);
  const linkTo = useLinkTo();
  const dispatch = useDispatch();

  const {run: refresh} = useRequest(() => dispatch({type: 'global/getUserOrderStatistics'}), {
    manual: true,
  });

  useFocusEffect(
    useCallback(() => {
      refresh();
    }, [refresh]),
  );

  const openPieceReceiveModal = useDynamicModal(PieceConvertModal);
  const openCustomerService = useDynamicModal(
    CustomerService,
    {},
    {
      navigation,
    },
  );

  const onPressFuncItems = item => {
    const {key, link} = item || {};
    if (link) {
      return linkTo(link);
    }
    switch (key) {
      case 'kf':
        return openCustomerService();
      case 'kldh':
        return openPieceReceiveModal();
      case 'mhhg':
        return navigation.jumpTo('BoxCupboardTab');
      default:
        Toast.info('功能暂未开放！');
    }
  };

  const {headImg, name, nickName, introduction, score, fragmentCount, orderCounts} = currentUser || {};

  return (
    <View style={styles.page}>
      {statusBar}
      <ScrollView style={styles.container}>
        <ImageBackground style={[styles.section, styles.headerSection]} source={assets_my_top}>
          <Header title="我的" tintColor="#fff" headerLeft={() => null} style={styles.headerSectionTransparent} />
          <View style={styles.headerSectionContent}>
            <List.Item
              last={true}
              thumb={<Avatar src={headImg} />}
              arrow="right"
              arrowColor="#fff"
              onPress={() => linkTo('/profile')}
              style={styles.headerSectionTransparent}>
              <Text style={styles.userName}>{nickName || name}</Text>
              <List.Item.Brief style={styles.userIntro}>{introduction || '您还未设置个性签名'}</List.Item.Brief>
            </List.Item>
          </View>
        </ImageBackground>

        <View style={[styles.section, styles.orderSection]}>
          <List.Item last={true} arrow="right" onPress={() => linkTo('/order/list')}>
            订单
          </List.Item>
          <View style={styles.orderSectionContent}>
            <Grid
              hasLine={false}
              data={OrderStatus.filter(it => it?.icon && it?.link)}
              columnNum={5}
              itemStyle={{height: 'auto'}}
              renderItem={({icon, text, value, link}, i) => (
                <TouchableOpacity onPress={() => linkTo(link)}>
                  <Flex direction="column">
                    <Badge count={orderCounts?.[value] ?? 0}>
                      <Icon type={icon} />
                    </Badge>
                    <Text style={{fontSize: 11, marginTop: 9}}>{text}</Text>
                  </Flex>
                </TouchableOpacity>
              )}
            />
          </View>
        </View>

        {/*<View style={[styles.section]}>
          <Grid
            border={false}
            data={spConfigs}
            columnNum={3}
            itemStyle={styles.spSectionItem}
            renderItem={({image, link}, i) => (
              <TouchableOpacity>
                <ImageBackground source={image} style={styles.spSectionItemImage} />
              </TouchableOpacity>
            )}
          />
        </View>*/}

        <View style={[styles.section, styles.statisticSection]}>
          <Flex style={styles.statisticSectionBody}>
            <TouchableOpacity style={{flex: 1}} onPress={() => linkTo('/point/bill')}>
              <Flex justify="between" style={styles.statisticSectionStatistic}>
                <Flex>
                  <Icon type="coins_colorful" style={{marginRight: 6}} />
                  <Text>口袋币</Text>
                </Flex>
                <Flex>
                  <Text style={styles.statisticSectionStatisticValue}>{score ?? 0}</Text>
                  <Icon type="arrow_right" style={{marginLeft: 8}} />
                </Flex>
                <ImageBackground
                  source={assets__statistic_bg}
                  style={[StyleSheet.absoluteFill, {width: 107, height: 57}]}
                />
              </Flex>
            </TouchableOpacity>
            <TouchableOpacity style={{flex: 1}} onPress={() => linkTo('/piece')}>
              <Flex
                justify="between"
                style={[styles.statisticSectionStatistic, {borderLeftWidth: StyleSheet.hairlineWidth}]}>
                <Flex>
                  <Icon type="pieces_colorful" style={{marginRight: 6}} />
                  <Text>碎片</Text>
                </Flex>
                <Flex>
                  <Text style={styles.statisticSectionStatisticValue}>{fragmentCount ?? 0}</Text>
                  <Icon type="arrow_right" style={{marginLeft: 8}} />
                </Flex>
                <ImageBackground
                  source={assets__statistic_bg}
                  style={[StyleSheet.absoluteFill, {width: 107, height: 57}]}
                />
              </Flex>
            </TouchableOpacity>
          </Flex>
        </View>

        <View style={[styles.section, styles.funcSection]}>
          <Grid
            hasLine={false}
            data={funcConfigs}
            columnNum={4}
            itemStyle={styles.funcSectionItem}
            renderItem={(item, i) => {
              const {icon, text} = item;
              return (
                <TouchableOpacity onPress={() => onPressFuncItems(item)}>
                  <Flex direction="column">
                    <Icon type={icon} />
                    <Text style={{fontSize: 11, marginTop: 6}}>{text}</Text>
                  </Flex>
                </TouchableOpacity>
              );
            }}
          />
        </View>
      </ScrollView>
    </View>
  );
});
const styles = StyleSheet.create({
  page: {
    // paddingTop: StatusBar.currentHeight,
    backgroundColor: '#fff',
    flex: 1,
  },
  container: {
    backgroundColor: theme.fill_body,
  },
  section: {
    marginBottom: 10,
    backgroundColor: '#fff',
  },
  headerSection: {
    paddingTop: StatusBar.currentHeight,
  },
  headerSectionTransparent: {
    backgroundColor: 'transparent',
  },
  headerSectionContent: {
    paddingVertical: theme.v_spacing_xl - 6,
    backgroundColor: 'transparent',
  },
  userName: {
    color: '#fff',
    fontSize: 20,
    lineHeight: 27,
    fontWeight: FontWeight.SemiBold,
  },
  userIntro: {
    color: '#fff',
    opacity: 0.6,
  },
  orderSection: {},
  orderSectionContent: {
    paddingHorizontal: 16,
    paddingTop: theme.v_spacing_md,
    paddingBottom: theme.v_spacing_xl,
  },

  spSection: {},
  spSectionItem: {
    height: 56,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  spSectionItemText: {},
  spSectionItemImage: {
    width: 84,
    height: 20,
  },

  statisticSection: {
    height: 56,
  },
  statisticSectionBody: {
    flex: 1,
    alignItems: 'stretch',
  },
  statisticSectionFooter: {
    height: 32,
    borderColor: theme.border_color_base,
    borderTopWidth: StyleSheet.hairlineWidth,
  },
  statisticSectionStatistic: {
    flex: 1,
    borderColor: theme.border_color_base,
    paddingHorizontal: theme.h_spacing_lg,
  },
  statisticSectionStatisticValue: {
    ...getDINProBoldStyle(),
    fontSize: 16,
    lineHeight: 20,
  },

  funcSection: {
    paddingHorizontal: 7,
    paddingVertical: 12,
  },
  funcSectionItem: {
    height: 70,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

const MinePage = props => {
  useAuthedTab(props);
  return React.createElement(_MinePage, props);
};
MinePage.screenName = 'MineTab';
MinePage.screenOptions = {
  lazy: false,
  tabBarIcon: ({focused, color}) => <Icon type={`tabbar_mine${focused ? '_active' : ''}`} fill={color} />,
  tabBarLabel: '我的',
  title: '我的',
};
MinePage.linking = 'mine';
export default MinePage;
