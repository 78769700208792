import {copy} from '@/components';
import Button from '@/components/Button';
import {Center} from '@/components/Flex';
import Modal from '@/components/Modal/Modal';
import RenderHtml from '@/components/RenderHtml';
import useControlledModalProps from '@/utils/hooks/useControlledModalProps';
import {isFunction} from 'lodash';
import React from 'react';
import {Image, ImageBackground, StyleSheet} from 'react-native';
import assets_title from './assets/_title';

const TaskModal = ({data, callback, ...rest}) => {
  const {visible, close, modalProps} = useControlledModalProps(rest);
  const {image, desc, shareLink} = data || {};

  const handlePress = () => {
    copy(shareLink, () => {
      close();
      isFunction(callback) && callback(data);
    });
  };

  return (
    <Modal
      visible={visible}
      onClose={close}
      transparent={true}
      closable={true}
      maskClosable={true}
      {...modalProps}
      style={styles.modal}
      bodyStyle={styles.bodyStyle}>
      <Center style={styles.title}>
        <ImageBackground source={assets_title} style={styles.titleImage} />
      </Center>
      <Center>
        <Image source={{uri: image}} style={styles.toolImage} />
      </Center>
      <Center style={styles.content}>
        {/*<Text style={styles.contentText}>分享给新人，新人成功开盒后 您可获得 100% 回收卡</Text>*/}
        <RenderHtml
          contentWidth={200}
          source={{
            html: desc ?? '',
          }}
          enableExperimentalMarginCollapsing={true}
        />
      </Center>
      <Center>
        <Button style={styles.btn} type="primary" onPress={handlePress}>
          立即复制
        </Button>
      </Center>
    </Modal>
  );
};

export default TaskModal;

const styles = StyleSheet.create({
  modal: {
    width: 285,
    height: 330,
    paddingTop: 0,
  },
  bodyStyle: {
    paddingHorizontal: 20,
    paddingTop: 18,
    paddingBottom: 22,
  },
  title: {
    height: 32,
    marginBottom: 26,
  },
  titleImage: {
    width: 94,
    height: 23,
  },
  toolImage: {
    width: 165,
    height: 109,
    marginBottom: 7,
  },
  content: {
    height: 40,
    overflow: 'hidden',
    marginBottom: 34,
  },
  contentText: {
    lineHeight: 20,
    fontSize: 14,
    textAlign: 'center',
  },
  btn: {
    width: 128,
    height: 42,
    borderRadius: 4,
  },
});
