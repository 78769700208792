import Avatar from '@/components/Avatar';
import {Flex} from '@/components/Flex';
import LinearGradient from '@/components/LinearGradient';
import {useImageResize} from '@/utils/remoteData/ImageResize';
import React, {useState} from 'react';
import {Animated, Image, Text} from 'react-native';
import styles from './styles';

export default function Notice(props) {
  const {style, notice, animHook} = props;
  const [isBubbled, setIsBubbled] = useState(false);
  let animStyle = animHook(props, {
    onBubbled: setIsBubbled,
  });
  const {userHeadImg, userName, picture, style: name} = notice || {};
  const [, resize] = useImageResize({object: 'prod', size: 'xxs'});

  return (
    <Animated.View style={[styles.notice, style, animStyle]}>
      <LinearGradient
        colors={
          isBubbled
            ? ['#FF063E', '#FF063E', 'rgba(255,6,62,0)']
            : ['rgba(255,255,255,0.2)', 'rgba(255,255,255,0.2)', 'rgba(255,255,255,0)']
        }
        locations={isBubbled ? [0, 0.178, 1] : [0, 0.785, 1]}
        start={{x: 0, y: 0}}
        end={{x: 1, y: 0}}
        backgroundImage={
          isBubbled
            ? 'linear-gradient(to right, #FF063E 0, #FF063E 26px, rgba(255,255,255,0))'
            : 'linear-gradient(to right, rgba(255,255,255,0.2) 0, rgba(255,255,255,0.2) 125px, rgba(255,255,255,0))'
        }
        style={{padding: 2}}>
        <Flex align="center">
          <Avatar src={userHeadImg} size={22} style={{marginRight: 10}} />
          <Flex align="center" style={{flex: 1}}>
            <Text
              style={[styles.noticeText, styles.userName]}
              numberOfLines={1}
              // ellipsizeMode="middle"
            >
              {userName}
            </Text>
            <Text style={styles.noticeText}>购买了{name}</Text>
            <Image
              source={{uri: resize(picture)}}
              resizeMode="contain"
              style={{width: 17, height: 17, marginLeft: 5, flexBasis: 17}}
            />
          </Flex>
        </Flex>
      </LinearGradient>
    </Animated.View>
  );
}
