import {Flex} from '@/components/Flex';
import Grid from '@/components/Grid';
import List from '@/components/List';
import theme from '@/theme';
import {useSelector} from '@/utils/dva';
import BoxRecordsStatus from '@/utils/localData/BoxRecordStatus';
import {getDINProBoldStyle} from '@/utils/styleUtils';
import {useLinkTo} from '@react-navigation/native';
import React from 'react';
import {StyleSheet, Text, TouchableOpacity, View} from 'react-native';

const RecordsSection = () => {
  const linkTo = useLinkTo();
  const currentUser = useSelector(s => s.global.currentUser);
  const {boxCounts} = currentUser || {};

  return (
    <View style={[styles.section, styles.recordsSection]}>
      <List.Item last={true} arrow="right" onPress={() => linkTo('/box/records')}>
        开盒记录
      </List.Item>
      <View style={styles.recordsSectionContent}>
        <Grid
          hasLine={false}
          data={BoxRecordsStatus.values()}
          columnNum={4}
          itemStyle={{height: 88}}
          renderItem={({text, value, link}, i) => (
            <TouchableOpacity onPress={() => linkTo(link)}>
              <Flex direction="column">
                <Text style={getDINProBoldStyle(24)}>{boxCounts?.[value] ?? 0}</Text>
                <Text style={{fontSize: 12, marginTop: 9}}>{text}</Text>
              </Flex>
            </TouchableOpacity>
          )}
        />
      </View>
    </View>
  );
};
const styles = StyleSheet.create({
  section: {
    marginBottom: 10,
    backgroundColor: '#fff',
  },
  recordsSection: {},
  recordsSectionContent: {
    paddingHorizontal: 16,
    paddingTop: theme.v_spacing_sm,
    paddingBottom: theme.v_spacing_xl,
    height: 88,
  },
});

export default RecordsSection;
