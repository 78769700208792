import {defaultHeaderConfig} from '@/components/Header';
import {TranslucentStatusBar} from '@/components/StatusBar';
import BoxPage from '@/pages/home/box';
import BoxCupboardPage from '@/pages/home/boxCupboard';
import MallPage from '@/pages/home/mall';
import MinePage from '@/pages/home/mine';
import theme from '@/theme';
import useBottomInset from '@/utils/hooks/useBottomInset';
import {getScreenElements} from '@/utils/navigatorUtils';
import {createBottomTabNavigator} from '@react-navigation/bottom-tabs';
import _ from 'lodash';
import React from 'react';
import {StyleSheet} from 'react-native';

const tabs = [BoxPage, MallPage, BoxCupboardPage, MinePage];
const Tab = createBottomTabNavigator();
const HomePage = ({navigation}) => {
  const [, paddingBottom, , redress] = useBottomInset(7);

  return (
    <>
      <TranslucentStatusBar />
      <Tab.Navigator
        initialRouteName={BoxPage.screenName}
        screenOptions={({route}) => ({
          ...defaultHeaderConfig,
          tabBarStyle: {
            // paddingVertical: 7,
            paddingTop: 7,
            paddingBottom: paddingBottom,
            height: 60 + redress,
            borderTopWidth: StyleSheet.hairlineWidth,
            borderTopColor: theme.border_color_base,
          },
          tabBarIconStyle: {
            width: 28,
            height: 28,
          },
          tabBarLabelStyle: {
            marginTop: 4,
            fontSize: 10,
            lineHeight: 14,
          },
          tabBarActiveTintColor: theme.color_text_base,
          tabBarInactiveTintColor: '#909090',
          title: '口袋盲盒',
        })}>
        {getScreenElements(tabs, Tab.Screen, undefined, {
          headerShown: false,
        })}
      </Tab.Navigator>
    </>
  );
};

HomePage.screenName = 'Home';
HomePage.screenOptions = {headerShown: false};
HomePage.linking = {
  path: 'home',
  screens: {
    ..._.chain(tabs)
      .filter(page => page.screenName && page.linking !== undefined)
      .keyBy('screenName')
      .mapValues(page => page.linking)
      .value(),
  },
};
export default HomePage;
