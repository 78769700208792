import {Dialog} from '@/components';
import {IconButton} from '@/components/Icon';
import QuickLoginApi from '@/NativeModules/QuickLogin';
import {useDispatch} from '@/utils/dva';
import {useRequest} from 'ahooks';
import {isFunction} from 'lodash';
import React from 'react';
import {StyleSheet} from 'react-native';

const LoginByYD = ({loginType, onChangeLoginType, state, setState, onSuccess}) => {
  const dispatch = useDispatch();
  const {run: loginByYD} = useRequest(
    (token, accessToken) =>
      dispatch({
        type: 'user/loginByYD',
        token,
        accessToken,
      }),
    {
      manual: true,
      onSuccess(res) {
        isFunction(onSuccess) && onSuccess(res);
        if (res?.success) {
          // Dialog.success(res.msg || '登录成功');
        } else {
          Dialog.respError(res);
        }
      },
    },
  );

  const onePass = async mobileNumber => {
    // if (!state?.agreement) {
    //   setState({
    //     agreementTipVisible: true,
    //   });
    //   return;
    // } else {
    //   setState({
    //     agreementTipVisible: false,
    //   });
    // }
    try {
      setState({loading: true});
      mobileNumber = mobileNumber ?? state?.mobileNumber;
      if (!mobileNumber) {
        mobileNumber = await QuickLoginApi.prefetchMobileNumber();
      }
      if (mobileNumber) {
        const apiRes = await QuickLoginApi.onePass({});
        const {token, accessToken} = apiRes || {};
        console.log('onePass res:', apiRes);

        if (token && accessToken) {
          await loginByYD(token, accessToken);
        }
      }
      setState({mobileNumber, loading: false});
    } catch (e) {
      Dialog.info(e.toString());
      setState({mobileNumber: null, loading: false});
    }
  };

  return <IconButton type="phone" style={styles.socialIcon} onPress={() => onePass()} />;
};
const styles = StyleSheet.create({
  socialIcon: {
    marginHorizontal: 25,
    width: 24,
    height: 24,
  },
});
export default LoginByYD;
