import FontWeight from '@/utils/localData/FontWeight';
import {StyleSheet} from 'react-native';

const styles = StyleSheet.create({
  form: {
    position: 'absolute',
    top: 0,
    width: 300,
  },
  formItem: {},
  formItemLabel: {
    fontSize: 12,
    fontWeight: FontWeight.SemiBold,
  },
  inputItem: {
    marginLeft: 0,
    paddingRight: 0,
    height: 38,
  },
  inputStyle: {
    fontSize: 12,
    fontWeight: FontWeight.Regular,
  },
  inputFillStyle: {
    fontSize: 18,
    fontWeight: FontWeight.SemiBold,
    width: 1,
  },

  alertText: {
    fontSize: 12,
    color: '#A6AAB5',
  },

  agreement: {
    flexDirection: 'row',
    // marginLeft: 7,
  },

  privacyText: {
    fontSize: 12,
    lineHeight: 24,
  },
  secondaryColor: {
    color: '#abaaba',
  },

  socialIcon: {
    marginHorizontal: 25,
    width: 24,
    height: 24,
  },
});

export default styles;
