import {Flex} from '@/components/Flex';
import Icon from '@/components/Icon';
import theme from '@/theme';
import SearchOrderBy from '@/utils/localData/SearchOrderBy';
import _ from 'lodash';
import React from 'react';
import {Pressable, StyleSheet, Text, View} from 'react-native';

const SearchOrderByBar = ({orderBy, search}) => {
  const changeTo = value => {
    if (value !== orderBy) {
      search({orderBy: value});
    }
  };
  return (
    <View style={styles.container}>
      <Flex justify="between">
        {SearchOrderBy.map(({text, value}, i) => {
          const isActive = _.isArray(value) ? _.some(value, v => v === orderBy) : value === orderBy;
          const activeIndex = _.isArray(value) ? _.findIndex(value, v => v === orderBy) : -1;
          const onPress = () => {
            if (_.isArray(value)) {
              if (activeIndex >= 0) {
                if (activeIndex + 1 >= value.length) {
                  changeTo(value[0]);
                } else {
                  changeTo(value[activeIndex + 1]);
                }
              } else {
                changeTo(value[0]);
              }
            } else {
              changeTo(value);
            }
          };
          return (
            <Pressable key={i} onPress={onPress}>
              <Flex style={styles.itemContainer}>
                <Text style={[styles.tabOrderBy, isActive && styles.tabOrderByActive]}>{text}</Text>
                {_.isArray(value) && value.length == 2 && (
                  <View style={{marginLeft: 3}}>
                    <View style={{marginVertical: 0.5}}>
                      <Icon type="arrow_up" fill={activeIndex === 0 ? theme.brand_secondary : theme.color_text_base} />
                    </View>
                    <View style={{marginVertical: 0.5}}>
                      <Icon
                        type="arrow_down"
                        fill={activeIndex === 1 ? theme.brand_secondary : theme.color_text_base}
                      />
                    </View>
                  </View>
                )}
              </Flex>
            </Pressable>
          );
        })}
      </Flex>
    </View>
  );
};
const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 56,
  },
  itemContainer: {
    paddingVertical: 12,
    paddingHorizontal: 12,
  },
  tabOrderBy: {
    fontSize: 13,
    lineHeight: 18,
  },
  tabOrderByActive: {
    color: theme.brand_secondary,
  },
});

export default SearchOrderByBar;
