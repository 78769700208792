import request, {getPagedParams} from '@/utils/request';

/**
 * 分页查询通知
 * @param params: {
 *   current,
 *   pageSize,
 * }
 * */
export function getPagedNotices(params) {
  return request('/server/open/notice/page', {
    method: 'GET',
    params: getPagedParams(params),
  });
}
