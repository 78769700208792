import AppInfo from '@/NativeModules/AppInfo';
import XInstall from '@/NativeModules/XInstall';
import {
  askTask,
  fileUpload,
  getCaptcha,
  getChannelVersion,
  getCurrentUser,
  getUserBoxStatistics,
  getUserOrderStatistics,
} from '@/services/global';
import {isAndroid, isIos, isNative, isWeb} from '@/utils/utils';
import dayjs from 'dayjs';
import _ from 'lodash';
import {NativeEventEmitter, Platform} from 'react-native';
import DeviceInfo from 'react-native-device-info';
import QuickLogin from '@/NativeModules/QuickLogin';

export const namespace = 'global';
const model = {
  namespace,
  state: {
    agreement: isWeb(),
    currentUser: null, // 当前用户
    lastVersion: null, // 最后检查的版本
    // 应用信息
    channel: '', // app渠道
    versionCode: 0, // app版本号
    versionName: '1.0', // app版本名称
    deviceIds: null, // app设备id， { IMEI, OAID, ANDROID_ID, IDFA}
    deviceModel: '',
    userAgent: '',
    // XInstall
    x_channelCode: '', // XInstall渠道码
    x_shareId: '', // XInstall分享ID
    x_uo: {}, // 落地页url参数
    x_co: {}, // 落地页按钮参数
    // YD一键登录
    mobileNumber: '', // 预取号
  },
  effects: {
    /**
     * 获取应用信息
     * */
    *getAppInfo(__, {call, put}) {
      try {
        const appChannel = yield call(AppInfo.getAppChannel);
        const versionName = yield call(AppInfo.getVersionName);
        const versionCode = yield call(AppInfo.getVersionCode);
        const deviceIds = yield call(AppInfo.getDeviceIds);
        const deviceModel = yield call(DeviceInfo.getModel);
        const deviceName = yield call(DeviceInfo.getDeviceName);
        const userAgent = yield call(DeviceInfo.getUserAgent);
        yield put({
          type: 'saveState',
          channel: isWeb() ? appChannel + '_h5' : appChannel,
          versionCode,
          versionName,
          deviceIds,
          deviceModel,
          deviceName,
          userAgent,
        });
      } catch (err) {
        console.warn('getAppInfo', err);
      }
    },

    /**
     * 获取XInstall安装/唤醒参数
     * */
    *getXInstallData(__, {call, put}) {
      if (isNative()) {
        try {
          const x_channelCode = yield call(XInstall.getChannelCode); // XInstall渠道
          const x_extraData = yield call(XInstall.getExtraData);
          let x_uo, x_co;
          if (isIos()) {
            x_uo = x_extraData?.uo || {};
            x_co = x_extraData?.co || {};
          } else {
            x_uo = JSON.parse(x_extraData?.uo || '{}');
            x_co = JSON.parse(x_extraData?.co || '{}');
          }
          yield put({
            type: 'saveXInstallData',
            x_channelCode,
            x_uo,
            x_co,
          });
        } catch (err) {}
      }
    },

    /**
     * 网易易盾预取号
     * */
    *prefetchMobileNumber(__, {call, put, select}) {
      console.log('global/prefetchMobileNumber');
      let mobileNumber = '';
      const isYDEnable = yield select(s => {
        const lov = _.find(s['lov']['APP_LOGIN_TYPE'] || [], it => it?.type === 'APP_LOGIN_TYPE' && it?.name === 'yd');
        return _.includes(String(lov?.value).split('|'), Platform.OS);
      });
      if (isAndroid() && isYDEnable) {
        try {
          mobileNumber = yield call(QuickLogin.prefetchMobileNumber);
          yield put({
            type: 'saveState',
            mobileNumber,
          });
        } catch (err) {
          console.log(err);
        }
      }
      return mobileNumber;
    },

    /**
     * 初始化全局数据
     * */
    *getInitialState(__, {call, put, all, select}) {
      try {
        // 获取应用信息
        const _getAppInfo = yield put({type: 'getAppInfo'}); // 获取应用信息
        const _getXInstallData = yield put({type: 'getXInstallData'}); // 初始化XInstall参数
        yield all([
          call(() => _getAppInfo), //
          call(() => _getXInstallData),
        ]);

        // 加载重要初始数据
        const _getCurrentUser = yield put({type: 'getCurrentUser'}); // 获取当前用户
        const _getLovInitialState = yield put({type: 'lov/getInitialState'}); // 初始化lov数据
        const _getMallInitialState = yield put({type: 'mall/getInitialState'}); // 获取上次初始化数据
        const [_getCurrentUserRes, _getLovInitialStateRes, _getMallInitialStateRes] = yield all([
          call(() => _getCurrentUser), //
          call(() => _getLovInitialState),
          call(() => _getMallInitialState),
        ]);

        [_getLovInitialStateRes, _getMallInitialStateRes].forEach(res => {
          if (!res?.success) {
            throw res?.msg || 'APP初始化失败';
          }
        });

        if (_getCurrentUserRes?.data === null) {
          // 清空用户个人数据
          yield put({type: 'user/cleanUserData'});
        }

        if (_getCurrentUserRes?.success) {
          // 已登录
          // 加载用户相关初始数据
          const _getUserOrderStatistics = yield put({type: 'getUserOrderStatistics'}); // 用户订单统计数据
          const _getUserBoxStatistics = yield put({type: 'getUserBoxStatistics'}); // 用户开盒统计数据
          yield all([
            call(() => _getUserOrderStatistics), //
            call(() => _getUserBoxStatistics),
          ]);
        } else {
          // 未登录或已超时
          const _prefetchMobileNumber = yield put({type: 'prefetchMobileNumber'}); // YD一键登录预取号
          yield all([
            call(() => _prefetchMobileNumber), //
          ]);
        }

        // 加载其他初始数据
        const _getHots = yield put({type: 'search/getHots'}); // 热搜
        const _getRollingNotices = yield put({type: 'box/getRollingNotices'}); // 盲盒滚动通知
        yield all([
          call(() => _getHots), //
          call(() => _getRollingNotices),
        ]);

        return yield select(s => s[namespace]);
      } catch (error) {
        return Promise.reject(error);
      }
    },

    /**
     * 查询当前登录用户
     * */
    *getCurrentUser(_, {call, put}) {
      const res = yield call(getCurrentUser);
      const {success, data} = res || {};
      if (success) {
        yield put({type: 'saveCurrentUser', currentUser: data});
      } else {
        // yield put({type: 'saveCurrentUser', currentUser: null});
      }
      return res;
    },

    /**
     * 查询余额以及订单统计数据
     * */
    *getUserOrderStatistics(_, {call, put, select}) {
      const currentUser = yield select(s => s[namespace].currentUser);
      if (currentUser) {
        const res = yield call(getUserOrderStatistics);
        if (res?.success) {
          yield put({type: 'saveCurrentUser', currentUser: {...currentUser, ...res.data}});
        }
        return res;
      }
      return Promise.resolve({success: false});
    },

    /**
     * 查询余额以及开盒统计数据
     * */
    *getUserBoxStatistics(_, {call, put, select}) {
      const currentUser = yield select(s => s[namespace].currentUser);
      if (currentUser) {
        const res = yield call(getUserBoxStatistics);
        if (res?.success) {
          yield put({type: 'saveCurrentUser', currentUser: {...currentUser, ...res.data}});
        }
        return res;
      }
      return Promise.resolve({success: false});
    },

    /**
     * 获取验证码
     * @actionParam params: fields
     * */
    *getCaptcha({params}, {call, put}) {
      return yield call(getCaptcha, params);
    },

    /**
     * 修改头像
     * @actionParam file
     * */
    *fileUpload({file}, {call}) {
      return yield call(fileUpload, file);
    },

    /**
     * 修改头像
     * @actionParam file
     * */
    *updateAvatar({file}, {call, put, select}) {
      const uploadEffect = yield put({type: 'fileUpload', file});
      const res = yield call(() => uploadEffect);
      if (res?.success) {
        const {currentUser} = yield select(s => s.global);
        const updateEffect = yield put({
          type: 'updateCurrentUser',
          data: {headImg: res.data, id: currentUser?.id},
        });
        return yield call(() => updateEffect);
      }
      return res;
    },

    /**
     * 询问是否触发任务
     * @actionParam trigger: 促发点，box
     * @actionParam triggerId: 促发点实例ID，boxID
     * */
    *askTask({trigger, triggerId}, {call}) {
      return yield call(askTask, trigger, triggerId);
    },

    *selectCurrentUser(__, {select}) {
      return yield select(s => s[namespace].currentUser);
    },

    /**
     * 版本检查
     * */
    *checkVersion(__, {select, call, put}) {
      // if (isWeb() || isAndroid()) {
      const lastVersion = yield select(s => s[namespace].lastVersion);
      const appChannel = yield call(AppInfo.getAppChannel);
      const versionCode = yield call(AppInfo.getVersionCode);
      const res = yield call(getChannelVersion, appChannel, versionCode);
      if (res?.success && res?.data?.versionCode) {
        const thisVersion = res.data;
        const checkTime = (thisVersion.checkTime = dayjs().format('YYYYMMDD'));
        // 保存最新版本，用户比较是否忽略此版本
        yield put({type: 'saveState', lastVersion: thisVersion});
        const ignore = {success: true, data: null},
          update = res;

        if (versionCode >= thisVersion.versionCode) {
          // 已经更新到最新版本，忽略此版本
          return ignore;
        } else if (lastVersion?.id && thisVersion.id === lastVersion.id) {
          if (thisVersion?.forceUpdate) {
            // 强制更新
            return update;
          } else if (thisVersion?.notice && checkTime > lastVersion?.checkTime) {
            // 每日弹窗
            return update;
          } else {
            // 非每日弹窗，忽略此版本
            return ignore;
          }
        } else {
          return update;
        }
      }
      return res;
      // } else {
      //   return {success: true, data: null};
      // }
    },
  },
  reducers: {
    saveState(state, action) {
      const {type, ...nextState} = action || {};
      return {...state, ...nextState};
    },
    saveCurrentUser(state, {currentUser}) {
      return {...state, currentUser};
    },
    mergeCurrentUser(state, {currentUser}) {
      return {...state, currentUser: state?.currentUser && _.extend({}, state.currentUser, currentUser)};
    },
    clearLastVersion(state) {
      return {...state, lastVersion: null};
    },
    saveXInstallData(state, {x_channelCode, x_uo, x_co}) {
      const {x_channelCode: _x_channelCode, x_shareId: _x_shareId} = state;
      const {XinShareId: x_shareId} = x_uo || {};
      return {
        ...state,
        x_channelCode: x_channelCode ?? _x_channelCode,
        x_shareId: x_shareId ?? _x_shareId,
        x_uo,
        x_co,
      };
    },
  },
  subscriptions: {
    XInstallData({dispatch}) {
      if (isNative()) {
        const listener = event => {
          try {
            const x_channelCode = event?.channelCode; // XInstall渠道
            let x_uo, x_co;
            if (isIos()) {
              x_uo = event?.uo || {};
              x_co = event?.co || {};
            } else {
              x_uo = JSON.parse(event?.uo || '{}');
              x_co = JSON.parse(event?.co || '{}');
            }
            dispatch({
              type: 'saveXInstallData',
              x_channelCode,
              x_uo,
              x_co,
            });
            console.warn('onWakeUp', event);
          } catch (e) {}
        };
        const eventEmitter = new NativeEventEmitter(XInstall);
        const onInstall = eventEmitter.addListener('Install', listener);
        const onWakeUp = eventEmitter.addListener('WakeUp', listener);
        return () => {
          onInstall.remove();
          onWakeUp.remove();
        };
      }
    },

    StatisticalData({dispatch}) {
      console.warn('订阅用户统计数据（3分钟刷新）');
      const fetch = () => {
        Promise.all([
          dispatch({type: 'getUserOrderStatistics'}), //
          dispatch({type: 'getUserBoxStatistics'}),
        ]);
      };
      const handleId = setInterval(fetch, 180000);
      return () => clearInterval(handleId);
    },
  },
};

export default model;
