import _, {isFunction} from 'lodash';
import {useEffect, useRef} from 'react';

const stack = new (class GCB {
  stack = {};

  add(callback) {
    const id = this.createUniqueId();
    this.stack[id] = {id, callback, remove: () => this.remove(id)};
    return this.stack[id];
  }

  update(id, callback) {
    const cb = _.find(this.stack, cb => cb?.id === id);
    if (cb) {
      cb.callback = callback;
    }
    return cb;
  }

  remove(callback) {
    if (_.isFunction(callback)) {
      this.stack = _.omitBy(this.stack, cb => cb?.callback === callback);
    } else {
      this.stack = _.omit(this.stack, [callback]);
    }
  }

  get(id) {
    return this.stack[id];
  }

  createUniqueId() {
    return `cb_${Date.now()}${Math.random().toString().replace('.', '').padEnd(10, '0').substring(0, 10)}`;
  }
})();
global._callbacks = stack;
export default function useGlobalCallback(fnOrId) {
  const ref = useRef({});
  useEffect(() => {
    const isFn = _.isFunction(fnOrId);
    const current = isFn ? stack.add(fnOrId) : stack.get(fnOrId);
    if (current) {
      ref.current = _.clone(current);
    }
    return () => {
      isFn && current?.remove?.();
    };
  }, []);

  useEffect(() => {
    if (ref.current && isFunction(fnOrId) && fnOrId !== ref.current.callback) {
      ref.current = stack.update(ref.current.id, fnOrId);
    }
  }, [fnOrId]);
  return ref;
}
