import {Flex} from '@/components/Flex';
import {prod_bg_hou, prod_bg_qian} from '@/pages/box/assets/_prod_bg';
import RarityImage from '@/pages/box/components/RarityImage';
import FontWeight from '@/utils/localData/FontWeight';
import {useImageResize} from '@/utils/remoteData/ImageResize';
import {getDINProBoldStyle} from '@/utils/styleUtils';
import {isWeb} from '@/utils/utils';
import React, {useRef} from 'react';
import {Animated, ImageBackground, StyleSheet, Text, View} from 'react-native';

const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: 310,
    alignItems: 'center',
  },
  bg: {
    width: 310,
    height: 266,
    alignItems: 'center',
  },

  prodImageWarp: {
    width: 310,
    height: 240,
    transform: [{translateX: -310 / 2}, {translateY: -240 / 2}],
    position: 'absolute',
    left: '50%',
    top: 88,
    justifyContent: 'center',
    alignItems: 'center',
  },
  prodImage: {
    width: 310,
    height: 280,
  },

  prodInfoContainer: {
    paddingVertical: 5,
  },
  prodName: {
    color: '#fff',
    fontSize: 12,
    fontWeight: FontWeight.Light,
    textAlign: 'center',
    maxWidth: 192,
    marginTop: 9,
  },
  prodPrice: {
    marginTop: 10,
    color: '#fff',
    ...getDINProBoldStyle(20),
    lineHeight: 25,
  },
});
const ProdDisplay = ({data, showPrice, style, imageBgStyle}) => {
  const {boxPicture, picture, productName, itemName, amount, style: rarity} = data || {};
  const [, resize] = useImageResize({object: 'box', size: 'lg'});
  const image = resize(boxPicture ?? picture);
  const name = productName ?? itemName;

  const animValue = useRef(new Animated.Value(0)).current;
  const animation = useRef(
    Animated.timing(animValue, {
      toValue: 4,
      duration: 2000,
      isInteraction: false,
      useNativeDriver: !isWeb(),
    }),
  ).current;

  const onLoad = () => {
    Animated.loop(animation).start();
  };

  const translateY = animValue.interpolate({
    inputRange: [0, 1, 2, 3, 4],
    outputRange: [4, 0, -4, 0, 4],
  });

  return (
    <View style={[styles.container, style]}>
      <ImageBackground source={prod_bg_hou} resizeMode="contain" style={[styles.bg, imageBgStyle]}>
        {image && (
          <Animated.View style={styles.prodImageWarp}>
            <Animated.Image
              source={{uri: image}}
              resizeMode="contain"
              onLoad={onLoad}
              style={[styles.prodImage, {transform: [{translateY}]}]}
            />
          </Animated.View>
        )}
        <ImageBackground
          source={prod_bg_qian}
          resizeMode="contain"
          style={[styles.bg, StyleSheet.absoluteFill, {zIndex: 9}]}
        />
      </ImageBackground>

      <Flex style={styles.prodInfoContainer} direction="column" justify={'center'} align={'center'}>
        <RarityImage rarity={rarity} />
        <Text numberOfLines={2} style={styles.prodName}>
          {name}
        </Text>
        {showPrice && <Text style={styles.prodPrice}>¥{amount ?? 0}</Text>}
      </Flex>
    </View>
  );
};

export default React.memo(ProdDisplay);
