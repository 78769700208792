import Checkbox from '@/components/Checkbox';
import {Flex} from '@/components/Flex';
import Icon from '@/components/Icon';
import List from '@/components/List';
import useFadeInOut from '@/pages/login/components/useFadeInOut';
import RmbPayType from '@/utils/localData/RmbPayType';
import {getDINProBoldStyle} from '@/utils/styleUtils';
import _ from 'lodash';
import React from 'react';
import {Animated, StyleSheet, Text} from 'react-native';
import useLov from '@/utils/hooks/useLov';

const Step1 = ({state, setState, shouldShow}) => {
  const animStyle = useFadeInOut(shouldShow);
  const payAmount = state.deduct ? _.floor(state.amount - state.deductAmount, 2) : state.amount;
  const payments = useLov('APP_PAYMENT');
  return (
    <Animated.View style={[{width: '100%'}, animStyle]}>
      <Flex direction="column" align="stretch">
        <Flex justify="center">
          <Text style={styles.price}>¥{payAmount}</Text>
        </Flex>
        <List border={false} style={{marginLeft: -20, marginRight: -20}}>
          {RmbPayType.map((item, i) => {
            const onoff = payments?.[item.key] !== 'off';
            return (
              onoff && (
                <List.Item
                  key={`$payment__${i}`}
                  offset={20}
                  thumb={<Icon type={item.icon} />}
                  extra={<Checkbox size={17} checked={state.payType === item.value} disabled={true} />}
                  onPress={() => setState({payType: item.value})}
                  lineStyle={styles.paymentItem}>
                  {item.text}
                </List.Item>
              )
            );
          })}
        </List>
      </Flex>
    </Animated.View>
  );
};
const styles = StyleSheet.create({
  price: {
    ...getDINProBoldStyle(36),
    lineHeight: 45,
    paddingVertical: 5,
    marginBottom: 10,
  },
  paymentItem: {
    minHeight: 60,
  },
});

export default Step1;
