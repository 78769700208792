import {isIosWeb} from '@/utils/utils';

export default {
  getVersionCode: function () {
    return Promise.resolve(40);
  },
  getVersionName: function () {
    return Promise.resolve('1.4.0');
  },
  getAppChannel: function () {
    return Promise.resolve(isIosWeb() ? 'apple' : 'koudai');
  },
  getDeviceIds: function () {
    return Promise.resolve({});
  },
};
