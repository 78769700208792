import {Empty, Flex, LoadingView} from '@/components';
import Button from '@/components/Button';
import {IconButton} from '@/components/Icon';
import List from '@/components/List';
import Tag from '@/components/Tag';
import theme from '@/theme';
import {useDispatch, useSelector} from '@/utils/dva';
import FontWeight from '@/utils/localData/FontWeight';
import {useRequest} from 'ahooks';
import React from 'react';
import {ScrollView, StyleSheet, Text, View} from 'react-native';
import useBottomInset from '@/utils/hooks/useBottomInset';

const AddressListPage = ({navigation, currentUser, onPress}) => {
  const data = useSelector(s => s.address.data);
  const dispatch = useDispatch();
  const {loading} = useRequest(() => dispatch({type: 'address/getAddresses'}), {
    ready: !!(currentUser && !data?.length),
  });

  const edit = item => {
    navigation.push('Address', {
      screen: 'EditAddress',
      params: {id: item?.id ?? 'new', data: item},
    });
  };

  // ios：底部安全区域
  const [, paddingBottom] = useBottomInset(11);
  return (
    <LoadingView loading={loading} style={styles.page}>
      <View style={{flex: 1}}>
        {!data?.length && <Empty />}
        {!!data?.length && (
          <ScrollView style={styles.container}>
            <List border={false}>
              {data.map((item, i) => {
                const {id, name, mobile, province, city, area, address, defaultFlag} = item || {};
                return (
                  <List.Item
                    key={`$address_${id}`}
                    last={i === data.length - 1}
                    extra={<IconButton type="edit" onPress={() => edit(item)} />}
                    onPress={() => onPress?.(item)}>
                    <View style={styles.itemContainer}>
                      <View style={styles.userContainer}>
                        <Text style={styles.userName}>{name}</Text>
                        <Text style={styles.userPhone}>{mobile}</Text>
                      </View>
                      <Flex align="start" style={styles.detailContainer}>
                        {defaultFlag && (
                          <Tag style={{marginRight: 9, marginTop: 2}} small fill="#DBDADF" color="#212121">
                            默认
                          </Tag>
                        )}
                        <Text style={[styles.detail]}>
                          {province}
                          {city}
                          {area}
                          {address}
                        </Text>
                      </Flex>
                    </View>
                  </List.Item>
                );
              })}
            </List>
          </ScrollView>
        )}
      </View>
      <View style={[styles.footer, {paddingBottom}]}>
        <Button style={styles.footerButton} type="primary" onPress={() => edit()}>
          新增地址
        </Button>
      </View>
    </LoadingView>
  );
};
const styles = StyleSheet.create({
  page: {
    flex: 1,
  },
  container: {
    flex: 1,
  },

  itemContainer: {
    paddingTop: 13,
    paddingBottom: 13,
    paddingRight: 15,
  },
  userContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontSize: 16,
    color: '#212121',
  },
  userName: {
    fontWeight: FontWeight.SemiBold,
    lineHeight: 22,
  },
  userPhone: {
    fontWeight: FontWeight.SemiBold,
    lineHeight: 22,
    marginLeft: theme.h_spacing_md,
  },
  detailContainer: {
    marginTop: 8,
    width: '100%',
  },
  detail: {
    flex: 1,
    fontSize: 12,
    lineHeight: 17,
    color: theme.color_text_secondary,
  },

  footer: {
    paddingHorizontal: theme.v_spacing_lg,
    paddingVertical: 11,
    backgroundColor: '#fff',
    borderTopWidth: StyleSheet.hairlineWidth,
    borderTopColor: theme.border_color_base,
  },
  footerButton: {
    height: 48,
  },
});

AddressListPage.screenName = 'AddressList';
AddressListPage.screenOptions = {
  title: '地址管理',
};
AddressListPage.linking = 'list';
export default AddressListPage;
