import List from '@/components/List';
import useGlobalCallback from '@/utils/hooks/useGlobalCallback';
import {isIos} from '@/utils/utils';
import cityData from '@bang88/china-city-data';
import _ from 'lodash';
import React from 'react';
import {ScrollView} from 'react-native';

const CityPicker = ({navigation, route}) => {
  const {id, province} = route?.params || {};
  const provinceItem = _.find(cityData, item => item.value === province) || {};
  const callbackRef = useGlobalCallback(id);
  const handlePress = item => {
    callbackRef?.current?.callback?.({
      navigation,
      value: {
        province: _.pick(provinceItem, ['value', 'label']),
        city: _.pick(item, ['value', 'label']),
      },
    });
  };
  const data = provinceItem?.children;
  return (
    <ScrollView style={{flex: 1}}>
      {data?.length && (
        <List>
          {_.map(data, (item, i) => {
            const {value, label} = item || {};
            return (
              <List.Item key={`$item_${value}`} arrow="right" onPress={handlePress.bind({}, item)}>
                {label}
              </List.Item>
            );
          })}
        </List>
      )}
    </ScrollView>
  );
};

CityPicker.modalName = 'CityPicker';
CityPicker.screenOptions = {
  title: '选择城市',
  animation: 'slide_from_right',
  presentation: isIos() ? 'card' : 'fullScreenModal',
};
export default CityPicker;
