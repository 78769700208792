import request from '@/utils/request';
import _ from 'lodash';

/**
 * 获取全部地址
 * */
export function getAddresses() {
  return request('/server/user/address/list', {
    method: 'GET',
  }).then(res => {
    if (res?.success) {
      _.each(res?.data, it => {
        if (it) {
          it.area = it.county;
        }
      });
    }
    return res;
  });
}

// /**
//  * 获取单个地址
//  * @param id
//  * */
// export function getAddress(id) {
//   return request('/server/getAddress', {
//     method: 'GET',
//     params: {
//       id,
//     },
//   });
// }

/**
 * 保存地址
 * @param data
 * */
export function saveAddress(data) {
  if (data?.mobile) {
    data.mobile = String(data.mobile).replace(/\s/g, '');
  }
  if (data?.address) {
    data.address = String(data.address).replace(/\s/g, '');
  }
  return request('/server/user/address', {
    method: 'POST',
    data: {
      ...data,
      county: data?.area,
    },
  }).then(res => {
    if (res?.success && res?.data) {
      res.data.area = res.data.county;
    }
    return res;
  });
}

/**
 * 删除地址
 * @param id
 * */
export function deleteAddress(id) {
  return request('/server/user/address', {
    method: 'DELETE',
    params: {
      idList: [id],
    },
  });
}
