import HeaderLeft from '@/components/Header/HeaderLeft';
import {useNavigation} from '@react-navigation/core';
import {useFocusEffect} from '@react-navigation/native';
import React from 'react';

export default () => {
  const navigation = useNavigation();
  useFocusEffect(() => {
    navigation.setOptions({
      headerLeft: props => <HeaderLeft {...props} navigation={navigation} />,
    });
  });
  return navigation;
};
