import {Flex} from '@/components/Flex';
import theme from '@/theme';
import FontWeight from '@/utils/localData/FontWeight';
import {getDINProBoldStyle} from '@/utils/styleUtils';
import React from 'react';
import {StyleSheet, Text, View} from 'react-native';

const styles = StyleSheet.create({
  card: {
    flex: 1,
    height: 80,
    paddingVertical: 20,
    paddingHorizontal: 16,
    borderWidth: StyleSheet.hairlineWidth,
    borderColor: theme.border_color_base,
    borderRadius: 4,
    backgroundColor: '#fff',
  },

  title: {
    marginBottom: 4,
  },
  date: {
    fontSize: 12,
    color: theme.color_text_secondary,
  },
  price: {
    ...getDINProBoldStyle(16),
    lineHeight: 20,
  },
  red: {
    color: '#FF0139',
  },
  green: {
    color: '#20D642',
  },
  unit: {
    fontWeight: FontWeight.SemiBold,
    fontSize: 16,
    lineHeight: 20,
    marginLeft: 3,
  },
});
const PointBillItemCard = ({data, style}) => {
  const {name, score, createdDt} = data || {};
  return (
    <View style={[styles.card, style]}>
      <Flex justify="between">
        <View>
          <Text style={styles.title}>{name}</Text>
          <Text style={styles.date}>{createdDt}</Text>
        </View>
        <Flex align="center">
          <Text style={[styles.price, score > 0 ? styles.red : styles.green]}>
            {score > 0 ? '+' : ''}
            {score}
          </Text>
          <Text style={styles.unit}>口袋币</Text>
        </Flex>
      </Flex>
    </View>
  );
};

export default PointBillItemCard;
