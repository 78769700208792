'use strict';

Object.defineProperty(exports, '__esModule', {
  value: true,
});
exports.default = void 0;

var _reactNative = require('react-native');

var _default = function _default(variables) {
  return _reactNative.StyleSheet.create({
    underlayColor: {
      backgroundColor: variables.fill_tap,
    },
    Header: {
      fontSize: variables.font_size_base,
      color: variables.color_text_caption,
      paddingHorizontal: variables.h_spacing_lg,
      paddingTop: variables.v_spacing_lg,
      paddingBottom: variables.v_spacing_md,
      backgroundColor: variables.fill_body,
    },
    Footer: {
      fontSize: variables.font_size_base,
      color: variables.color_text_caption,
      paddingHorizontal: variables.h_spacing_lg,
      paddingVertical: variables.v_spacing_md,
      backgroundColor: variables.fill_body,
    },
    Body: {
      backgroundColor: variables.fill_base,
      borderTopWidth: _reactNative.StyleSheet.hairlineWidth,
      borderTopColor: variables.border_color_base,
    },
    BodyBottomLine: {
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      height: 1,
      backgroundColor: variables.fill_base,
      borderBottomWidth: _reactNative.StyleSheet.hairlineWidth,
      borderBottomColor: variables.border_color_base,
    },
    BodyBottomLineHidden: {
      opacity: 0,
    },
    Item: {
      flexGrow: 1,
      alignItems: 'center',
      flexDirection: 'row',
      // paddingLeft: variables.h_spacing_lg,
      paddingLeft: 16,
      backgroundColor: variables.fill_base,
    },
    Line: {
      flex: 1,
      flexDirection: 'row',
      alignItems: 'center',
      paddingRight: variables.h_spacing_lg,
      paddingVertical: variables.v_spacing_sm,
      minHeight: variables.list_item_height,
      // borderBottomWidth: _reactNative.StyleSheet.hairlineWidth,
      // borderBottomColor: variables.border_color_base,
    },
    borderLine: {
      borderBottomWidth: _reactNative.StyleSheet.hairlineWidth,
      borderBottomColor: variables.border_color_base,
    },
    Thumb: {
      width: variables.icon_size_md,
      height: variables.icon_size_md,
      // marginRight: variables.h_spacing_lg,
      marginRight: variables.h_spacing_md,
    },
    CustomizeThumb: {
      // marginRight: variables.h_spacing_lg,
      marginRight: variables.h_spacing_md,
    },
    Content: {
      color: variables.color_text_base,
      fontSize: variables.font_size_heading,
      textAlignVertical: 'center',
    },
    Extra: {
      color: variables.color_text_caption,
      fontSize: variables.font_size_heading,
      textAlign: 'right',
      textAlignVertical: 'center',
    },
    Brief: {
      minHeight: variables.font_size_icontext,
    },
    BriefText: {
      color: variables.color_text_caption,
      // fontSize: variables.font_size_subhead,
      fontSize: variables.font_size_base - 2,
      paddingTop: variables.v_spacing_xs,
      textAlignVertical: 'center',
    },
    Arrow: {
      // marginLeft: variables.h_spacing_md,
      // marginTop: variables.v_spacing_xs,
      alignItems: 'flex-end',
      width: 20,
      height: 20,
      marginLeft: -5,
    },
    ArrowV: {
      // marginLeft: variables.h_spacing_md,
      alignItems: 'flex-end',
      width: 20,
      height: 20,
      marginLeft: -5,
    },
    multipleLine: {
      paddingVertical: variables.v_spacing_sm,
    },
    multipleThumb: {
      width: variables.icon_size_lg,
      height: variables.icon_size_lg,
    },
    column: {
      flex: 1,
      flexDirection: 'column',
    },
  });
};

exports.default = _default;
