import {AutoHeightImage, Center} from '@/components';
import {assets_close} from '@/components/_modals/ActivityModal/assets';
import Modal from '@/components/Modal/Modal';
import useControlledModalProps from '@/utils/hooks/useControlledModalProps';
import {useImageResize} from '@/utils/remoteData/ImageResize';
import {useLinkTo} from '@react-navigation/native';
import React, {useEffect, useState} from 'react';
import {Image, StyleSheet, TouchableOpacity, View} from 'react-native';

/**
 * todo: 接入分享SKD
 * */
const ActivityModal = ({data, ...rest}) => {
  const {visible, close, modalProps} = useControlledModalProps(rest);
  const {pictureUrl, jumpUrl} = data || {};
  const [, resize] = useImageResize({object: 'popup', size: 'lg'});
  const image = resize(pictureUrl);
  const [prefetched, setPrefetched] = useState(false);
  useEffect(() => {
    if (image) {
      Image.prefetch(image).then(() => setPrefetched(true));
    }
  }, [image]);

  const linkTo = useLinkTo();
  const onPress = () => {
    jumpUrl && linkTo(jumpUrl);
    close();
  };

  return (
    prefetched && (
      <Modal
        visible={visible}
        onClose={close}
        title=""
        closable={false}
        transparent={true}
        maskClosable={false}
        {...modalProps}
        absoluteFill={true}
        style={styles.modal}
        bodyStyle={styles.bodyStyle}>
        <View style={styles.imageContainer}>
          <Center style={{flex: 1}}>
            <AutoHeightImage onPress={onPress} resizeMode="contain" source={{uri: image}} style={styles.image} />
          </Center>

          <TouchableOpacity onPress={close} style={styles.closeBtn}>
            <Image resizeMode="contain" source={assets_close} style={{width: 33, height: 33}} />
          </TouchableOpacity>
        </View>
      </Modal>
    )
  );
};

export default ActivityModal;

const styles = StyleSheet.create({
  modal: {
    paddingTop: 0,
    paddingVertical: 0,
    paddingHorizontal: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    borderRadius: 0,
    overflow: 'visible',
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    width: '100%',
  },
  bodyStyle: {
    paddingHorizontal: 0,
    paddingBottom: 0,
    flex: 1,
  },
  imageContainer: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
  },
  image: {
    width: '100%',
  },
  closeBtn: {
    position: 'absolute',
    right: 27,
    top: 64,
  },
});
