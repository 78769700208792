import {getModalProps} from '@/utils/hooks/useDynamicModal';
import {isFunction} from 'lodash';
import {useState} from 'react';

export default function useControlledModalProps(props) {
  const {visible: __visible, defaultVisible, onClose, ...rest} = Object.assign({}, props);
  const [_visible, setVisible] = useState(__visible ?? defaultVisible ?? false);
  const visible = __visible ?? _visible;
  const close = () => {
    setVisible(false);
    isFunction(onClose) && onClose();
  };
  return {visible, setVisible, close, onClose, modalProps: getModalProps(rest)};
}
