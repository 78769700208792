import {Button, Center, Flex, Link, TranslucentStatusBar} from '@/components';
import theme from '@/theme';
import {useDispatch} from '@/utils/dva';
import useLov from '@/utils/hooks/useLov';
import FontWeight from '@/utils/localData/FontWeight';
import React from 'react';
import {StyleSheet, Text, View, BackHandler} from 'react-native';

const ReadmePage = ({navigation}) => {
  const docs = useLov('DOCUMENT_TYPE');
  const dispatch = useDispatch();

  const onPressNo = () => {
    BackHandler?.exitApp?.();
  };
  const onPressYes = () => {
    dispatch({type: 'global/saveState', agreement: true});
    navigation.replace('Home');
  };

  return (
    <View style={styles.page}>
      <TranslucentStatusBar barStyle="light-content" />
      <Center style={styles.container}>
        <View style={styles.mask} />
        <View style={styles.modal}>
          <Text style={styles.title}>温馨提示</Text>
          <View style={styles.body}>
            <Text style={styles.desc}>欢迎来到口袋盲盒！</Text>
            <Text style={styles.desc}>
              我们十分重视您的个人信息和隐私保护。在您注册成为口袋盲盒用户的过程中，您需要完成我们的注册流程并通过点击同意的形式在线签署以下协议，请您务必仔细阅读，充分理解协议中的条款内容后再点击同意。
            </Text>
            <Flex>
              <Text style={styles.desc}> 1、</Text>
              <Link style={styles.link} to="/document/fwxy">
                《{docs?.fwxy ?? '用户服务协议'}》
              </Link>
              <View style={{marginHorizontal: 5}} />
              <Text style={styles.desc}> 2、</Text>
              <Link style={styles.link} to="/document/yscl">
                《{docs?.yscl ?? '隐私政策'}》
              </Link>
            </Flex>
            <Text style={styles.desc}>
              您点击“同意”即视为您已经阅读并接受上述所有文件。如果您不同意上述协议或其中任何条款约定，请停止注册。若点击“不同意”将无法使用我们的产品和服务，并会退出本APP。如您对以上协议内容有任何疑问，您可以随时与口袋盲盒客服联系。
            </Text>
          </View>
          <View style={styles.footer}>
            <Flex justify={'center'}>
              <Button type="ghost" round style={styles.btn} onPress={onPressNo}>
                不同意
              </Button>
              <Button type="primary" round style={styles.btn} onPress={onPressYes}>
                同意
              </Button>
            </Flex>
          </View>
        </View>
      </Center>
    </View>
  );
};
const styles = StyleSheet.create({
  page: {
    flex: 1,
  },
  container: {
    ...StyleSheet.absoluteFill,
  },
  mask: {
    backgroundColor: '#000',
    opacity: 0.5,
    ...StyleSheet.absoluteFill,
  },
  modal: {
    width: 310,
    // height: 400,
    backgroundColor: '#fff',
    borderRadius: theme.modal_border_radius,
    paddingVertical: 30,
    paddingHorizontal: 20,
  },
  title: {
    textAlign: 'center',
    fontSize: 16,
    fontWeight: FontWeight.SemiBold,
  },
  body: {
    marginTop: 30,
    paddingBottom: 20,
    borderBottomWidth: 0,
  },
  desc: {
    color: theme.color_text_secondary,
    fontSize: 12,
    lineHeight: 17,
    marginBottom: 10,
  },
  link: {
    fontSize: 12,
    lineHeight: 17,
    marginBottom: 10,
  },

  btn: {
    width: 85,
    marginHorizontal: 10,
  },
});

ReadmePage.screenName = 'Readme';
ReadmePage.screenOptions = {
  title: '温馨提示',
  headerShown: false,
};
ReadmePage.linking = 'readme';
export default ReadmePage;
