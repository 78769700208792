import {getBatchValues, getValues} from '@/services/lov';
import _ from 'lodash';

const namespace = 'lov';
const Model = {
  namespace,
  state: {
    // [lovType]: [{name, value, desc}, ...]
  },
  effects: {
    /**
     * 初始化lov数据
     * */
    *getInitialState(__, {call, put}) {
      const fecth = yield put({
        type: 'getByTypes',
        lovTypes: [
          'APP_INFO', // 应用信息
          'APP_SHARE', // 分享落地页
          'APP_CUSTOMER_SERVICE', // 客服配置
          'DOCUMENT_TYPE', // 文档
          'INTERNATIONAL_CODE', // 国际区号
          // 'BOX_RECYCLE_PERCENT', // 盲盒回收比例
          'FRAGMENT_RECYCLE_PERCENT', // 碎片回收比例
          'APP_IMAGES_RESIZE', // 图片尺寸控制
          'SCORE_TO_RMB_ENABLE', // 口袋币抵扣开关
          'RMB_SCORE_RATE', // 人民币兑口袋币比例
          'BOX_CATEGORY', // 盲盒分类
          'APP_PAYMENT', // 支付通道开关
          'APP_LOGIN_TYPE', // 第三方登录开关
        ].join(','),
      });
      return yield call(() => fecth);
    },
    /**
     * 按类型查询配置值列表
     * @actionParam lovType: 值类型
     * */
    *getByType({lovType}, {call, put, select}) {
      if (!lovType) {
        throw "ActionParam 'lovType' is requied for effect 'getByType'!";
      }
      const lovs = yield select(state => state[namespace][lovType]);
      if (!_.isArray(lovs)) {
        const res = yield call(getValues, lovType);
        const {success, data} = res || {};
        if (success) {
          yield put({type: 'saveState', [lovType]: data});
        }
      }
      return yield select(state => state[namespace][lovType]) || [];
    },
    /**
     * 批量查询值列表
     * @actionParam lovTypes: 值类型按逗号相隔
     * */
    *getByTypes({lovTypes}, {call, put}) {
      if (!lovTypes) {
        throw "ActionParam 'lovTypes' is requied for effect 'getByTypes'!";
      }
      const res = yield call(getBatchValues, lovTypes);
      if (res?.success) {
        const data = _.groupBy(res.data, 'type');
        yield put({type: 'saveState', ...data});
      }
      return res;
    },
  },
  reducers: {
    saveState(state, action) {
      const {type, ...nextState} = action || {};
      return {...state, ...nextState};
    },
  },
};

export default Model;
