import {copy, Dialog} from '@/components';
import Button from '@/components/Button';
import {Flex} from '@/components/Flex';
import {color_text_secondary} from '@/theme';
import FontWeight from '@/utils/localData/FontWeight';
import {assign} from 'lodash';
import React from 'react';
import {Platform, StyleSheet, Text, View} from 'react-native';

const Component = ({section, data}) => {
  const {orderNumber, createdDt, payType, expressName, expressNo} = data || {};
  return (
    <View style={[styles.container, section.style]}>
      <Text style={styles.title}>订单信息</Text>
      <Flex style={styles.item}>
        <Text style={styles.label}>订单编号</Text>
        <Text style={styles.text}>{orderNumber}</Text>
        <Button style={styles.copyBtn} onPress={() => copy(orderNumber, Dialog.info.bind({}, '复制成功'))}>
          <Text style={styles.copyBtnText}>复制</Text>
        </Button>
      </Flex>
      <Flex style={styles.item}>
        <Text style={styles.label}>创建时间</Text>
        <Text style={styles.text}>{createdDt}</Text>
      </Flex>
      <Flex style={styles.item}>
        <Text style={styles.label}>支付方式</Text>
        <Text style={styles.text}>{payType}</Text>
      </Flex>
      {!!expressName && (
        <Flex style={styles.item}>
          <Text style={styles.label}>物流快递</Text>
          <Text style={styles.text}>{expressName}</Text>
        </Flex>
      )}
      {!!expressNo && (
        <Flex style={styles.item}>
          <Text style={styles.label}>快递单号</Text>
          <Text style={styles.text}>{expressNo}</Text>
          <Button style={styles.copyBtn} onPress={() => copy(expressNo, Dialog.info.bind({}, '复制成功'))}>
            <Text style={styles.copyBtnText}>复制</Text>
          </Button>
        </Flex>
      )}
    </View>
  );
};
const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 20,
    paddingVertical: 16,
    backgroundColor: '#fff',
    marginBottom: 10,
  },
  title: {
    fontSize: 12,
    fontWeight: FontWeight.Medium,
    lineHeight: 17,
    marginBottom: 13,
  },

  item: {
    paddingVertical: 6,
  },
  label: {
    fontSize: 12,
    color: color_text_secondary,
  },
  text: {
    fontSize: 12,
    color: color_text_secondary,
    marginLeft: 20,
  },
  copyBtn: {
    marginLeft: 8,
    width: 26,
    height: 15,
    paddingLeft: 2,
    paddingRight: 2,
    borderColor: '#333',
    borderWidth: StyleSheet.hairlineWidth,
    lineHeight: 14,
  },
  copyBtnText: Platform.select({
    web: {
      display: 'block',
      // minWidth: '120%',
      minWidth: '140%',
      fontSize: 12,
      lineHeight: 14,
      transformOrigin: 'left center',
      transform: [{scale: 0.833}],
    },
    default: {
      fontSize: 10,
      lineHeight: 14,
    },
  }),
});

export default class InfoSection {
  static type = 'info';
  static component = Component;
  style = {};

  constructor(config) {
    assign(this, config);
  }

  get type() {
    return this?.constructor?.type;
  }
}
