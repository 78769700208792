import {Flex} from '@/components/Flex';
import RarityImage from '@/pages/box/components/RarityImage';
import FontWeight from '@/utils/localData/FontWeight';
import {getDINProBoldStyle} from '@/utils/styleUtils';
import {isWeb} from '@/utils/utils';
import _ from 'lodash';
import React, {useRef} from 'react';
import {Animated, StyleSheet, Text, View} from 'react-native';

const SingleProd = ({data, style}) => {
  const {boxPicture, picture, productName, itemName, style: rarity} = data || {};
  const image = boxPicture ?? picture;
  const name = productName ?? itemName;
  const animValue = useRef(new Animated.Value(0)).current;
  const animation = useRef(
    Animated.timing(animValue, {
      toValue: 4,
      duration: 2000,
      isInteraction: false,
      useNativeDriver: !isWeb(),
    }),
  ).current;

  const onLoad = () => {
    Animated.loop(animation).start();
  };

  const translateY = animValue.interpolate({
    inputRange: [0, 1, 2, 3, 4],
    outputRange: [2, 0, -2, 0, 2],
  });

  return (
    <View style={[styles.container, style]}>
      <Animated.Image
        source={{uri: image}}
        resizeMode="contain"
        onLoad={onLoad}
        style={[styles.prodImage, {transform: [{translateY}]}]}
      />

      <Flex style={styles.prodInfoContainer} direction="column" justify={'center'} align={'center'}>
        <RarityImage rarity={rarity} style={{width: 85, height: 18}} />
        <Text style={styles.prodName} numberOfLines={3}>
          {name}
        </Text>
      </Flex>
    </View>
  );
};

const positions = [
  {top: 112, left: 208},
  {top: 152, left: 82},
  {top: 292, left: 271},
  {top: 332, left: 145},
  {top: 372, left: 19},
];

const FiveProdsDisplay = ({data}) => {
  return (
    <View style={{flex: 1, width: 375, left: '50%', transform: [{translateX: -187}]}}>
      {_.map(data, (item, i) => {
        return <SingleProd key={i} data={item} style={{position: 'absolute', ...positions[i]}} />;
      })}
    </View>
  );
};

export default FiveProdsDisplay;

const styles = StyleSheet.create({
  container: {
    width: 86,
    height: 145,
    alignItems: 'center',
  },
  prodImage: {
    width: 200,
    height: 89,
    marginBottom: 11,
  },

  prodInfoContainer: {
    paddingVertical: 5,
  },
  prodName: {
    color: '#fff',
    fontSize: 10,
    lineHeight: 14,
    fontWeight: FontWeight.Light,
    textAlign: 'center',
    marginTop: 9,
  },
  prodPrice: {
    marginTop: 10,
    color: '#fff',
    ...getDINProBoldStyle(20),
    lineHeight: 25,
  },
});
