import useGlobalCallback from '@/utils/hooks/useGlobalCallback';
import {useNavigation, useRoute} from '@react-navigation/core';
import {isFunction} from 'lodash';
import React, {isValidElement, useState} from 'react';
import {Pressable} from 'react-native';

const AreaPicker = props => {
  const {style, children, onChange} = props;
  const navigation = useNavigation();
  const route = useRoute();
  const [area, setArea] = useState(null);

  const handleChange = value => {
    setArea(value);
    isFunction(onChange) && onChange(value);
  };

  const areaPickerRef = useGlobalCallback(({navigation, value}) => {
    handleChange({
      ...value,
      text: `${value?.province?.label}${value?.province?.isCity ? '' : value?.city?.label}${value?.area?.label}`,
      code: value?.area?.value,
    });
    navigation.navigate(route);
  });
  const cityPickerRef = useGlobalCallback(({navigation, value}) => {
    areaPickerRef?.current?.id &&
      navigation.push('AreaPicker', {id: areaPickerRef.current.id, city: value?.city?.value});
  });
  const provincePickerRef = useGlobalCallback(({navigation, value}) => {
    if (value?.province?.isCity) {
      // 直辖市
      areaPickerRef?.current?.id &&
        navigation.push('AreaPicker', {id: areaPickerRef.current.id, city: value?.city?.value});
      return;
    }
    cityPickerRef?.current?.id &&
      navigation.push('CityPicker', {id: cityPickerRef.current.id, province: value?.province?.value});
  });
  const handlePress = () => {
    provincePickerRef?.current?.id && navigation.push('ProvincePicker', {id: provincePickerRef.current.id});
  };
  return (
    <Pressable onPress={handlePress} style={style}>
      {isFunction(children) && children(area)}
      {isValidElement(children) && children}
    </Pressable>
  );
};

export default React.memo(AreaPicker);
