import HeadSection from '@/pages/box/detail/components/BoxSections/HeadSection';
import ProdSection from '@/pages/box/detail/components/BoxSections/ProdSection';
import {find, isFunction} from 'lodash';
import React from 'react';

const allSections = [HeadSection, ProdSection];
const defaultConfigs = [
  new HeadSection({
    buff: false,
  }),
  new ProdSection({formatData: data => data?.boxProductList}),
];

const getSectionComponent = type => {
  return find(allSections, c => c.type === type)?.component;
};

const BoxSections = ({data, sections = defaultConfigs}) => {
  return (
    <>
      {sections.map((section, i) => {
        const {type, formatData} = section || {};
        section.data = isFunction(formatData) ? formatData(data, section) : data;
        const sectionProps = {
          key: `$section_${type}_${i}`,
          section: section,
          data,
        };
        const sectionComponent = getSectionComponent(type);
        if (sectionComponent) {
          return React.createElement(sectionComponent, sectionProps);
        }
      })}
    </>
  );
};

export default BoxSections;
