import theme from '@/theme';
import Color from 'color';
import React, {useCallback} from 'react';
import {StyleSheet} from 'react-native';
import BaseButton from './Button';

const styles = {
  container: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  defaultHighlight: {
    backgroundColor: theme.fill_tap,
    borderColor: theme.border_color_base,
  },
  primaryHighlight: {
    backgroundColor: theme.primary_button_fill_tap,
    borderColor: theme.primary_button_fill,
  },
  ghostHighlight: {
    backgroundColor: 'transparent',
    borderColor: theme.ghost_button_fill_tap,
  },
  warningHighlight: {
    backgroundColor: theme.warning_button_fill_tap,
    borderColor: theme.warning_button_fill,
  },
  wrapperStyle: {
    alignItems: 'center',
    justifyContent: 'center',
    // borderRadius: theme.radius_md,
    borderRadius: 0,
    borderWidth: 1,
  },
  largeRaw: {
    height: theme.button_height_lg,
    paddingLeft: theme.h_spacing_lg,
    paddingRight: theme.h_spacing_lg,
  },
  middleRaw: {
    height: theme.button_height,
    paddingLeft: theme.h_spacing_md,
    paddingRight: theme.h_spacing_md,
  },
  smallRaw: {
    height: theme.button_height_sm,
    paddingLeft: theme.h_spacing_sm,
    paddingRight: theme.h_spacing_sm,
  },
  defaultRaw: {
    backgroundColor: theme.fill_base,
    borderColor: theme.border_color_base,
  },
  primaryRaw: {
    backgroundColor: theme.primary_button_fill,
    borderColor: theme.primary_button_fill,
    borderWidth: 0,
  },
  ghostRaw: {
    backgroundColor: 'transparent',
    borderColor: theme.ghost_button_color,
  },
  warningRaw: {
    backgroundColor: theme.warning_button_fill,
    borderColor: theme.warning_button_fill,
  },
  defaultDisabledRaw: {
    backgroundColor: theme.fill_disabled,
    borderColor: theme.fill_disabled,
  },
  primaryDisabledRaw: {
    // opacity: 0.4,
    opacity: 1,
    backgroundColor: '#A6AAB5',
    borderColor: '#A6AAB5',
  },
  ghostDisabledRaw: {
    // borderColor: theme.color_text_base + '1A',
    borderColor: theme.color_text_base + '4D',
  },
  warningDisabledRaw: {
    opacity: 0.4,
  },
  defaultHighlightText: {
    color: theme.color_text_base,
  },
  primaryHighlightText: {
    color: theme.color_text_base_inverse + 'CC',
  },
  ghostHighlightText: {
    color: theme.ghost_button_fill_tap,
  },
  warningHighlightText: {
    color: theme.color_text_base_inverse + 'CC',
  },
  largeRawText: {
    fontSize: theme.button_font_size_lg,
  },
  middleRawText: {
    fontSize: theme.button_font_size,
  },
  smallRawText: {
    fontSize: theme.button_font_size_sm,
  },
  defaultRawText: {
    color: theme.color_text_base,
  },
  primaryRawText: {
    color: theme.color_text_base_inverse,
  },
  ghostRawText: {
    color: theme.ghost_button_color,
  },
  warningRawText: {
    color: theme.color_text_base_inverse,
  },
  defaultDisabledRawText: {
    // color: styles.color_text_base + '4D',
    color: theme.color_text_base_inverse,
  },
  primaryDisabledRawText: {
    // color: styles.color_text_base_inverse + '99',
    color: theme.color_text_base_inverse,
  },
  ghostDisabledRawText: {
    // color: theme.color_text_base + '1A',
    color: theme.color_text_base + '4D',
  },
  warningDisabledRawText: {
    color: theme.color_text_base_inverse + '99',
  },
  indicator: {
    marginRight: theme.h_spacing_md,
  },
  roundRow: {
    borderRadius: 100,
  },
};

const Button = props => {
  const {color, isLight, fontWeight, ...rest} = props;
  const getStyles = useCallback(() => {
    if (color || fontWeight) {
      const newStyles = _.merge({}, styles);
      if (fontWeight) {
        _.merge(newStyles, {
          largeRawText: {fontWeight},
          middleRawText: {fontWeight},
          smallRawText: {fontWeight},
        });
      }
      if (color) {
        const _color = Color(color);
        const _isLight = isLight ?? _color.lightness() >= 50;
        const _textColor = _isLight ? Color(theme.color_text_base) : Color(theme.color_text_base_inverse);
        const highlightColor = _isLight ? _color.darken(0.3) : _color.lighten(0.3);
        const textColor = _textColor.string();
        const highlightTextColor = _isLight ? _textColor.darken(0.3) : _textColor.lighten(0.3);
        const disabledColor = _color.alpha(theme.opacity_disabled);

        _.merge(newStyles, {
          defaultHighlightText: {color},
          defaultRawText: {color},
          defaultDisabledRawText: {
            color: disabledColor,
          },
          primaryHighlight: {
            backgroundColor: highlightColor,
            borderColor: highlightColor,
          },
          primaryHighlightText: {
            color: highlightTextColor,
          },
          primaryRaw: {backgroundColor: color, borderColor: color},
          primaryRawText: {
            color: textColor,
          },
          primaryDisabledRaw: {
            backgroundColor: disabledColor,
            borderColor: disabledColor,
          },

          ghostHighlight: {borderColor: highlightColor},
          ghostHighlightText: {color: highlightColor},
          ghostRaw: {borderColor: color},
          ghostRawText: {color},
          ghostDisabledRaw: {
            borderColor: disabledColor,
          },
          ghostDisabledRawText: {
            color: disabledColor,
          },
        });
      }
      return StyleSheet.create(newStyles);
    }
    return StyleSheet.create(styles);
  }, [fontWeight, color]);
  return React.createElement(BaseButton, {
    ...rest,
    styles: getStyles(),
  });
};

export default Button;
