import request from '@/utils/request';

/**
 * 报告应用启动
 * */
export function reportActive() {
  return request('/server/open/active', {
    method: 'GET',
    responseType: 'text',
  });
}
