import theme from '@/theme';
import React, {useState} from 'react';
import {ImageBackground, Platform, Pressable, StyleSheet, Text, View} from 'react-native';
import assets_buff from '../../assets/_buff';

const styles = StyleSheet.create({
  container: {
    position: 'relative',
  },

  tip: {
    width: 94,
    height: 49,
    backgroundColor: theme.brand_secondary,
    borderRadius: 4,
    paddingVertical: 3,
    paddingHorizontal: 7,
    position: 'absolute',
    top: -60,
    right: 0,
    opacity: 0,
  },
  tipText: {
    color: '#fff',
    fontSize: 10,
    ...Platform.select({
      web: {
        transformOrigin: 'left top',
        transform: [{scale: 0.833}],
      },
    }),
  },
  tipArrow: {
    width: 7,
    height: 7,
    backgroundColor: theme.brand_secondary,
    transform: [{rotate: '45deg'}],
    position: 'absolute',
    bottom: -3,
    right: 15,
  },
});
const BoxBuff = ({style}) => {
  const [tipVisible, setTipVisible] = useState(0);

  return (
    <View style={[styles.container, style]}>
      <Pressable onPress={() => setTipVisible(!tipVisible)}>
        <ImageBackground source={assets_buff} style={{width: 45, height: 45}} />
      </Pressable>
      <View style={[styles.tip, {opacity: tipVisible ? 1 : 0}]}>
        <Text style={styles.tipText}>普通款加BUFF后 至尊/稀有/隐藏 概率增加</Text>
        <View style={styles.tipArrow} />
      </View>
    </View>
  );
};

export default BoxBuff;
