import {Dialog, RatioView} from '@/components';
import {Flex} from '@/components/Flex';
import Icon from '@/components/Icon';
import Swiper from '@/components/Swiper';
import theme from '@/theme';
import {useDispatch} from '@/utils/dva';
import FontWeight from '@/utils/localData/FontWeight';
import {getPrice} from '@/utils/priceUtils';
import {useImageResize} from '@/utils/remoteData/ImageResize';
import {getDINProBoldStyle} from '@/utils/styleUtils';
import {useRequest} from 'ahooks';
import _, {assign, isEmpty, merge} from 'lodash';
import React from 'react';
import {Image, StyleSheet, Text, TouchableOpacity, useWindowDimensions, View} from 'react-native';
import _styles from './style';

const Component = ({section, data, mutate}) => {
  const {width} = useWindowDimensions();
  const {id, name, pictureList, pictureRatio, userLiked} = data || {};
  const [, resizeImage] = useImageResize({object: 'prod', size: 'lg'});
  const [price, rmbPrice] = getPrice(data);

  const dispatch = useDispatch();
  const {loading, run: likeOrNot} = useRequest(
    () =>
      dispatch({
        type: userLiked ? 'product/unlike' : 'product/like',
        id,
      }),
    {
      manual: true,
      onSuccess(res) {
        if (res?.success) {
          mutate({...data, userLiked: !userLiked});
        } else {
          Dialog.respError(res);
        }
      },
    },
  );

  return (
    <View style={[_styles.section, styles.container, section.style]}>
      <View style={styles.media}>
        <RatioView
          ratio={pictureRatio || 1}
          defaultHeight={width * (pictureRatio || 1)}
          style={[styles.swiperContainer]}>
          {!isEmpty(pictureList) && (
            <Swiper {...section.swiperProps}>
              {_.map(pictureList, (picture, i) => {
                return (
                  <Swiper.Item key={'$slide__' + i} style={styles.slide}>
                    <Image source={{uri: resizeImage(picture)}} resizeMode="cover" style={styles.slideImage} />
                  </Swiper.Item>
                );
              })}
            </Swiper>
          )}
        </RatioView>
      </View>
      <View style={[styles.content]}>
        <Text style={styles.productName}>{name}</Text>
        <Flex justify="between">
          <Flex align="end" style={styles.productPrice}>
            <Text style={styles.mainPriceText}>{price?.amount}</Text>
            <View style={{marginLeft: 5}} />
            <Text style={styles.mainPriceType}>{price?.unit}</Text>
            {rmbPrice.isAvailable() && (
              <>
                <View style={{marginLeft: 12}} />
                <Text style={styles.secondaryPriceText}>{rmbPrice.text()}</Text>
              </>
            )}
          </Flex>
          <View style={styles.actions}>
            <TouchableOpacity style={styles.action} onPress={!loading && likeOrNot}>
              <Icon type={userLiked ? 'heart' : 'heart_outline'} />
              <Text style={{fontSize: 10}}>{userLiked ? '想要' : '想要'}</Text>
            </TouchableOpacity>
          </View>
        </Flex>
      </View>
    </View>
  );
};
const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 20,
    paddingTop: 0,
  },
  media: {
    marginHorizontal: -20,
    marginBottom: 12,
  },
  swiperContainer: {
    // paddingHorizontal: 15,
  },
  slide: {
    // paddingHorizontal: 15,
  },
  slideImage: {
    width: '100%',
    height: '100%',
    // borderRadius: 2,
    // backgroundColor: theme.fill_grey,
  },

  content: {},
  productName: {
    fontSize: 18,
    lineHeight: 24,
    fontWeight: FontWeight.Light,
    marginBottom: 17,
  },
  productPrice: {},
  mainPriceText: {
    ...getDINProBoldStyle(28),
    lineHeight: 35,
  },
  mainPriceType: {
    fontSize: 14,
    fontWeight: FontWeight.SemiBold,
    lineHeight: 19,
    marginBottom: 5,
  },
  secondaryPriceText: {
    color: theme.color_text_secondary,
    fontWeight: FontWeight.SemiBold,
    lineHeight: 19,
    marginBottom: 5,
  },
  actions: {},
  action: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default class HeadSection {
  static type = 'head';
  static component = Component;

  constructor(config) {
    const {swiperProps, ...rest} = config || {};
    this.swiperProps = merge(this.swiperProps, swiperProps);
    assign(this, rest);
  }

  get type() {
    return this?.constructor?.type;
  }

  style = {};
  swiperProps = {
    loop: false,
    autoplay: false,
    indicatorProps: {
      color: 'rgba(0,0,0,0.2)',
      activeColor: theme.brand_primary,
      style: {
        bottom: 7,
      },
      dotStyle: {
        marginHorizontal: 3,
      },
    },
  };
}
