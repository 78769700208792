export default {
  setUiConfig: () => {},
  prefetchMobileNumber: function () {
    // return new Promise(resolve => {
    //   setTimeout(() => resolve('151****8888'), 0);
    // });
    return Promise.reject('不支持本机号码登录');
  },
  onePass: () => {},
};
