const normal = require('./bomb-normal.gif');
const hidden = require('./bomb-hidden.gif');
const rare = require('./bomb-rare.gif');
const supreme = require('./bomb-supreme.gif');

export {normal, hidden, rare, supreme};

const gifs = {normal, hidden, rare, supreme};
export default function getBombAsset(rarity) {
  return gifs?.[rarity];
}
