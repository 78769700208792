import Notice from '@/pages/box/components/BoxRolingNotices/Notice';
import useBubbleAnim from '@/pages/box/components/BoxRolingNotices/useBubbleAnim';
import useNoticesStatus from '@/pages/box/components/BoxRolingNotices/useNoticesStatus';
import useRollingAnim from '@/pages/box/components/BoxRolingNotices/useRollingAnim2';
import {useSelector} from '@/utils/dva';
import useMergedNavigation from '@/utils/hooks/useMergedNavigation';
import {isWeb} from '@/utils/utils';
import {useFocusEffect} from '@react-navigation/native';
import _ from 'lodash';
import React, {useEffect, useRef} from 'react';
import {Animated, Easing, View} from 'react-native';
import styles from './styles';

const MODES = {
  Rolling: 'rolling',
  Bubble: 'bubble',
};
const getAnimationConfig = (noticesStack, offset = 0) => {
  return {
    toValue: noticesStack.renderCount,
    duration: noticesStack.duration * (noticesStack.renderCount - offset),
    easing: Easing.linear,
    useNativeDriver: !isWeb(),
  };
};

const _BoxRollingNotices = ({navigation: nestingNavigation, style, mode = MODES.Rolling, renderCount}) => {
  const navigation = useMergedNavigation(nestingNavigation);
  const data = useSelector(s => s.box.rollingNotices);
  // const dispatch = useDispatch();
  // const {loading} = useRequest(() => dispatch({type: 'box/getRollingNotices'}), {
  //   ready: !data?.length,
  // });
  // 管理通知队列
  const [notices, setNotices, noticesStack] = useNoticesStatus(data, {
    renderCount,
    duration: mode === MODES.Bubble ? 4000 : 4000,
  });

  // 动画标量
  const animValue = useRef(new Animated.Value(0)).current;
  // 动画控制
  const animCtrl = useRef({
    currentValue: 0,
    listenerId: animValue.addListener(({value}) => (animCtrl.currentValue = value)),
    start() {
      Animated.timing(animValue, getAnimationConfig(noticesStack, animCtrl.currentValue)).start(({finished}) => {
        if (finished) {
          animValue.setValue(0);
          finished && animCtrl?.start();
        }
      });
      animCtrl.__started = true;
    },
    stop() {
      animValue.stopAnimation();
      animCtrl.__started = false;
    },
  }).current;

  useEffect(() => {
    if (navigation.isFocused() && notices?.length && !animCtrl.__started) {
      // 准备就绪后开始轮播
      animCtrl?.start();
    }
  }, [notices, animValue, noticesStack, navigation, animCtrl]);

  useFocusEffect(() => {
    if (notices?.length && !animCtrl.__started) {
      // 进入当前页面时重启轮播
      animCtrl?.start();
    }
    const unsubscribe = navigation.addListener('blur', () => {
      // 离开当前页面时停止轮播
      animCtrl?.stop();
    });
    return unsubscribe;
  });
  return (
    <View style={[styles.container, style]}>
      {_.map(notices, (item, index) => {
        return (
          <Notice
            index={index}
            {...item}
            KEY={item?.key}
            setNotices={setNotices}
            animValue={animValue}
            animHook={mode === MODES.Bubble ? useBubbleAnim : useRollingAnim}
            renderCount={noticesStack.renderCount}
            duration={noticesStack.duration}
            style={mode === MODES.Bubble ? {opacity: 0} : {right: -200}}
          />
        );
      })}
    </View>
  );
};
const BoxRollingNotices = React.memo(_BoxRollingNotices);
BoxRollingNotices.MODES = MODES;
export default BoxRollingNotices;
