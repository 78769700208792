import XInstall from '@/NativeModules/XInstall';
import request from '@/utils/request';
import _ from 'lodash';

/**
 * 登录接口
 * @param data:{
 *    phone,
 *    validateCode
 * }
 * @param token: 第三方授权后零时 ticket, 仅第三方登录
 * */
function makeLoginService(url) {
  return function ({phone, validateCode} = {}, token) {
    return request(url, {
      method: 'POST',
      headers: token ? {token} : {},
      data: {
        mobile: phone,
        checkCode: validateCode,
      },
    }).then(res => {
      if (res?.success) {
        const {ticket, user, newUserFlag} = res.data;
        res.data = _.extend(user, {ticket});
        if (newUserFlag) {
          res.callbacks = {
            award200: true,
          };
          XInstall.reportRegister();
        }
      }
      return res;
    });
  };
}

export const login = makeLoginService('/server/open/ccLogin');
export const bindPhone = makeLoginService('/server/open/bindMobile'); // 第三方授权后，绑定手机号
export function loginByYD(token, accessToken) {
  return request('/server/open/ydLogin', {
    method: 'POST',
    data: {
      source: 'yd',
      token,
      accessToken,
    },
    authentic: false, // 不处理401
  }).then(res => {
    if (res?.success) {
      const {ticket, user, newUserFlag} = res.data;
      res.data = _.extend(user, {ticket});
      if (newUserFlag) {
        res.callbacks = {
          award200: true,
        };
        XInstall.reportRegister();
      }
    }
    return res;
  });
}

export function loginByWechat(code) {
  return request('/server/open/outLogin', {
    method: 'POST',
    data: {
      source: 'wx',
      code,
    },
    authentic: false,
  }).then(res => {
    if (res?.success) {
      const {ticket, user, token} = res.data;
      if (token) {
        _.extend(res, {
          success: false,
          code: 401,
          data: token,
        });
      } else {
        res.data = _.extend(user, {ticket});
      }
    }
    return res;
  });
}

export function bindWechat(code) {
  return request('/server/user/bindPlatform', {
    method: 'POST',
    data: {
      source: 'wx',
      code,
    },
  });
}

export function unbindWechat() {
  return request('/server/user/unbindPlatform', {
    method: 'GET',
    params: {
      source: 'wx',
    },
  });
}

export function loginByQQ(accessToken, openId) {
  return request('/server/open/outLogin', {
    method: 'POST',
    data: {
      source: 'qq',
      accessToken,
      openId,
    },
    authentic: false, // 不处理401
  }).then(res => {
    if (res?.success) {
      const {ticket, user, token} = res.data;
      if (token) {
        _.extend(res, {
          success: false,
          code: 401,
          data: token,
        });
      } else {
        res.data = _.extend(user, {ticket});
      }
    }
    return res;
  });
}

export function bindQQ(accessToken, openId) {
  return request('/server/user/bindPlatform', {
    method: 'POST',
    data: {
      source: 'qq',
      accessToken,
      openId,
    },
  });
}

export function unbindQQ() {
  return request('/server/user/unbindPlatform', {
    method: 'GET',
    params: {
      source: 'qq',
    },
  });
}

export function loginByApple(identityToken, userID) {
  return request('/server/open/outLogin', {
    method: 'POST',
    data: {
      source: 'apple',
      identityToken,
      userID,
    },
    authentic: false, // 不处理401
  }).then(res => {
    if (res?.success) {
      const {ticket, user, token} = res.data;
      if (token) {
        _.extend(res, {
          success: false,
          code: 401,
          data: token,
        });
      } else {
        res.data = _.extend(user, {ticket});
      }
    }
    return res;
  });
}

export function bindApple(identityToken, userID) {
  return request('/server/user/bindPlatform', {
    method: 'POST',
    data: {
      source: 'apple',
      identityToken,
      userID,
    },
  });
}

export function unbindApple() {
  return request('/server/user/unbindPlatform', {
    method: 'GET',
    params: {
      source: 'apple',
    },
  });
}

/**
 * 退出登录
 * */
export function logout() {
  return request('/server/open/logout', {
    method: 'POST',
  });
}

/**
 * 销户接口
 * */
export function logoff() {
  return request('/server/open/logoff', {
    method: 'POST',
  });
}

/**
 * 修改用户资料接口
 * @param data
 * */
export function updateProfile(data) {
  return request('/server/user/update', {
    method: 'POST',
    data,
  });
}
