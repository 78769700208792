import {isFunction, merge} from 'lodash';
import {useRef} from 'react';
import {Animated, Easing} from 'react-native';

const _defaultConfig = {
  useNativeDriver: false,
  duration: 300,
  easing: Easing.inOut(Easing.ease),
};
export default function useEasingInOut(from, to, animConfig) {
  const defaultConfig = merge({}, _defaultConfig, animConfig) || {};
  const anim = useRef(new Animated.Value(from));
  const go = (animate = true, callback, config = {}) => {
    if (animate === false) {
      anim.current = new Animated.Value(to);
      isFunction(callback) && callback({finished: true});
      return;
    }
    Animated.timing(anim.current, {...defaultConfig, ...config, toValue: to}).start(callback);
  };
  const back = (animate = true, callback, config = {}) => {
    if (animate === false) {
      anim.current = new Animated.Value(from);
      isFunction(callback) && callback({finished: true});
      return;
    }
    Animated.timing(anim.current, {...defaultConfig, ...config, toValue: from}).start(callback);
  };
  return [anim.current, go, back, anim];
}
