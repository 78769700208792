'use strict';

var _interopRequireDefault = require('@babel/runtime/helpers/interopRequireDefault');

Object.defineProperty(exports, '__esModule', {
  value: true,
});
exports.default = void 0;

var _zh_CN = _interopRequireDefault(require('../datepicker/locale/zh_CN'));

var _default = {
  okText: '确定',
  dismissText: '取消',
  extra: '请选择',
  DatePickerLocale: _zh_CN.default,
};
exports.default = _default;
