import RmbPayType from '@/utils/localData/RmbPayType';
import React, {useCallback} from 'react';
import alipay from './alipay';
import wxpay from './wxpay';

export const wakeUpPayApp = (payType, data) => {
  return new Promise((resolve, reject) => {
    if (payType === RmbPayType.Alipay) {
      return alipay(data, resolve, reject);
    }
    if (payType === RmbPayType.Wxpay) {
      return wxpay(data, resolve, reject);
    }
  });
};
const useWakeUpPayApp = () => {
  return useCallback(wakeUpPayApp, []);
};

export default useWakeUpPayApp;
