import {Button, Flex, LinearGradient} from '@/components';
import {assets_rocket} from '@/components/_modals/AppUpdateModal/assets';
import Modal from '@/components/Modal/Modal';
import theme from '@/theme';
import useControlledModalProps from '@/utils/hooks/useControlledModalProps';
import FontWeight from '@/utils/localData/FontWeight';
import {isNative, isWeb} from '@/utils/utils';
import NetInfo from '@react-native-community/netinfo';
import React, {useEffect, useState} from 'react';
import {Image, Linking, StyleSheet, Text} from 'react-native';

/**
 * 应用更新
 * */
const AppUpdateModal = ({mandatory, downloadUrl, versionCode, versionName, title, content, ...rest}) => {
  const {visible, close, modalProps} = useControlledModalProps(rest);

  if (isWeb()) {
    mandatory = false;
    title = '下载APP';
    content = 'v' + versionName;
  }

  const [netInfo, setNetInfo] = useState(null);

  useEffect(() => {
    if (isNative()) {
      const unsubscribe = NetInfo.addEventListener(state => {
        setNetInfo(state);
      });
      return unsubscribe;
    }
  }, []);

  const onPress = () => {
    downloadUrl && Linking.openURL(downloadUrl);
  };

  return (
    <Modal
      visible={visible}
      onClose={close}
      transparent={true}
      closable={false}
      maskClosable={false}
      {...modalProps}
      style={styles.modal}
      bodyStyle={styles.bodyStyle}>
      <LinearGradient
        style={[styles.bg]}
        colors={['#D0ECFE', 'rgba(255,255,255,0)']}
        start={{x: 0, y: 0}}
        end={{x: 0, y: 1}}
        backgroundImage={'linear-gradient(to bottom, #D0ECFE 0, rgba(255,255,255,0)  100%)'}
      />
      <Image source={assets_rocket} style={styles.rocket} />

      <Flex direction="column" justify="between" style={{flex: 1}}>
        <Flex direction="column">
          <Text numberOfLines={1} style={styles.title}>
            {title || '应用更新'}
          </Text>
          <Text numberOfLines={3} style={styles.content}>
            {content}
          </Text>
        </Flex>

        <Flex direction="column">
          <Flex style={{marginLeft: -10}}>
            {!mandatory && (
              <Button
                type="primary"
                color="#F1F2F4"
                fontWeight={FontWeight.SemiBold}
                style={styles.btn}
                onPress={close}>
                稍后再说
              </Button>
            )}
            <Button type="primary" fontWeight={FontWeight.SemiBold} style={styles.btn} onPress={onPress}>
              {isWeb() ? '立即下载' : '立即更新'}
            </Button>
          </Flex>
          {netInfo?.type === 'wifi' && <Text style={styles.tip}>当前为Wi-Fi环境，可放心下载</Text>}
          {netInfo?.type === 'cellular' && (
            <Text style={styles.tip}>
              正在使用{netInfo.cellularGeneration ? `${netInfo.cellularGeneration}网络` : '移动数据'}
              ，更新会消耗手机流量
            </Text>
          )}
        </Flex>
      </Flex>
    </Modal>
  );
};

export default AppUpdateModal;

const styles = StyleSheet.create({
  modal: {
    width: 285,
    height: 348,
    overflow: 'visible',
    paddingTop: 0,
  },
  bg: {
    height: 200,
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    zIndex: 0,
    borderTopLeftRadius: theme.modal_border_radius,
    borderTopRightRadius: theme.modal_border_radius,
  },
  rocket: {
    width: 174,
    height: 174,
    position: 'absolute',
    top: -55,
    left: 55,
    right: 55,
  },
  bodyStyle: {
    paddingTop: 174 - 55,
    paddingBottom: 30,
    paddingHorizontal: 22,
    flex: 1,
  },

  title: {
    fontSize: 18,
    lineHeight: 26,
    fontWeight: FontWeight.Medium,
    marginBottom: 18,
  },

  content: {
    fontSize: 14,
    color: '#212121',
    letterSpacing: 0,
    lineHeight: 20,
    textAlign: 'center',
  },

  btn: {
    width: 120,
    height: 44,
    marginBottom: 14,
    marginLeft: 10,
  },

  tip: {
    fontSize: 12,
    lineHeight: 17,
    color: theme.color_text_secondary,
  },
});
