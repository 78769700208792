import React from 'react';
import {View} from 'react-native';

const LinearGradient = ({backgroundImage, style, ...restProps}) => {
  return React.createElement(View, {
    ...restProps,
    style: [style, {backgroundImage}],
  });
};

export default LinearGradient;
