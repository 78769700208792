import request, {getPagedParams} from '@/utils/request';

/**
 * 分页查询我的收藏
 * @param params: {
 *   current: 页码
 *   pageSize
 * }
 * */
export function getPagedFavorites(params) {
  return request('/server/user/product/like/page', {
    method: 'GET',
    params: getPagedParams(params),
  });
}

// /**
//  * 移除收藏
//  * @param id
//  * */
// export function removeFavorite(id) {
//   return request('/server/user/product/like/page', {
//     method: 'GET',
//     params: {id},
//   });
// }

/**
 * 查询推荐商品
 * */
export function getRecommendProds() {
  return request('/server/product/recommend/page', {
    method: 'GET',
    params: {
      current: 1,
      size: 10,
    },
  }).then(res => {
    if (res?.success && res?.data?.list) {
      res.data = res?.data?.list;
    }
    return res;
  });
}
