import {Flex} from '@/components/Flex';
import Selector from '@/components/Selector';
import {useIntlCodes} from '@/utils/remoteData/IntlCodes';
import React from 'react';
import {Text, View} from 'react-native';

const codes = [{text: '中国', value: '+86'}];
const IntlCodeSelector = props => {
  const data = useIntlCodes() || codes;
  return React.createElement(Selector, {
    options: data,
    renderText(option, controlledValue, defaultDom) {
      return (
        <Flex justify="between" style={{flex: 1}}>
          <View>{defaultDom}</View>
          <Text style={{fontSize: 12, marginRight: 20}}>{option.value}</Text>
        </Flex>
      );
    },
    ...props,
  });
};

export default React.memo(IntlCodeSelector);
