import {useDispatch} from '@/utils/dva';
import _ from 'lodash';
import {useEffect, useMemo, useState} from 'react';

if (!global.__static_data__) {
  global.__static_data__ = {};
}

export default global.__static_data__;

function setItem(key, value) {
  if (key) {
    _.extend(global.__static_data__, {[key]: value});
  }
}

function getItem(key) {
  if (key) {
    return global.__static_data__[key];
  }
}

function lovsToMap(lovs, key = 'value') {
  if (_.isArray(lovs) && lovs.length > 0) {
    return _.chain(lovs)
      .keyBy(key)
      .mapValues(o => ({
        value: o.value,
        text: o.name,
      }))
      .value();
  }
}

export function makeRemoteDataTools(key, {service, lovType, itemKey} = {}) {
  const defaultData = getItem(key);
  const getData = () => {
    return getItem(key);
  };

  const fetchData = _.isFunction(service)
    ? service
    : async ({dispatch}) => {
        if (dispatch) {
          const lovs = await dispatch({type: 'lov/getByType', lovType});
          return lovsToMap(lovs, itemKey);
        }
      };

  const hook = ops => {
    const {formatData, onLoad} = ops || {};
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const dispatch = useDispatch();
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [data, setData] = useState(getItem(key));
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      if (data) {
        _.isFunction(onLoad) && onLoad(data);
      } else {
        fetchData({dispatch}).then(map => {
          setItem(key, map);
          setData(map);
          _.isFunction(onLoad) && onLoad(map);
        });
      }
    }, []);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useMemo(() => {
      if (_.isFunction(formatData)) {
        return formatData(data);
      } else {
        return data;
      }
    }, [data, formatData]);
  };

  return {
    defaultData,
    getData,
    fetchData,
    hook,
  };
}

// for decorator, like:
// @addToStateData('some')
// class some {
// }
export function addToStateData(key) {
  return function (value) {
    setItem(key, value);
    return value;
  };
}
