import {Dialog, Flex} from '@/components';
import CustomerService from '@/components/_modals/CustomerService';
import Button from '@/components/Button';
import {IconButton} from '@/components/Icon';
import Toast from '@/components/Toast';
import theme from '@/theme';
import {useDispatch} from '@/utils/dva';
import useBottomInset from '@/utils/hooks/useBottomInset';
import useDynamicModal from '@/utils/hooks/useDynamicModal';
import useOrderPay from '@/utils/hooks/useOrderPay';
import OrderStatus from '@/utils/localData/OrderStatus';
import {useRequest} from 'ahooks';
import _ from 'lodash';
import React from 'react';
import {StyleSheet, View} from 'react-native';

const OrderToolbar = ({data, mutate, state}) => {
  const [, , , paddingBottom] = useBottomInset(12);
  const dispatch = useDispatch();
  const {id} = data || {};

  // 取消订单
  const {loading: canceling, run: cancel} = useRequest(
    () =>
      dispatch({type: 'order/cancel', id}).then(
        res =>
          new Promise(resolve => {
            _.delay(() => resolve(res), 3000);
          }),
      ),
    {
      manual: true,
      onSuccess(res) {
        if (res?.success) {
          Dialog.success(res.msg ?? '操作成功');
          mutate({...data, status: OrderStatus.Canceled});
        } else {
          Dialog.respError(res);
        }
      },
    },
  );

  // 订单支付
  const [submit, {submitting}] = useOrderPay(data, {
    defaultParams: {
      addressId: state?.address?.id,
      remark: state?.remark,
    },
    itemDoms: state.itemDoms,
    onSuccess(res) {
      if (res?.success) {
        mutate({...data, status: OrderStatus.Paid});
      }
    },
  });

  const openCustomerService = useDynamicModal(CustomerService);

  return (
    <View style={[styles.toolbar, {paddingBottom}]}>
      <Flex justify="between" style={styles.container}>
        <IconButton
          type="func_customer_service"
          style={{width: 21, height: 22}}
          onPress={() => openCustomerService()}
        />
        <Flex>
          <Button loading={canceling} style={[styles.btn]} onPress={cancel}>
            {!canceling && '取消订单'}
          </Button>
          <Button type="primary" loading={submitting} style={[styles.btn]} onPress={submit}>
            {!submitting && '立即支付'}
          </Button>
        </Flex>
      </Flex>
    </View>
  );
};
const styles = StyleSheet.create({
  toolbar: {
    borderTopWidth: StyleSheet.hairlineWidth,
    borderTopColor: theme.border_color_base,
    backgroundColor: '#fff',
  },
  container: {
    paddingHorizontal: theme.h_spacing_lg,
    paddingVertical: 12,
  },
  btn: {
    width: 100,
    height: 48,
    marginLeft: 15,
  },

  itemCard: {
    borderRadius: 4,
    backgroundColor: theme.fill_body,
    borderWidth: StyleSheet.hairlineWidth,
    borderColor: theme.border_color_base,
    paddingVertical: 10,
    paddingHorizontal: 10,
  },
});

export default OrderToolbar;
