import createMaterialTopTabNavigator from '@/components/material-top-tabs/navigators/createMaterialTopTabNavigator';
import OrderList from '@/pages/order/list/components/OrderList';
import theme from '@/theme';
import OrderStatus from '@/utils/localData/OrderStatus';
import _ from 'lodash';
import React from 'react';
import {StyleSheet, View} from 'react-native';

const Tab = createMaterialTopTabNavigator();
const tabComponents = {};
const getTabComponent = (key, value, _props) => {
  if (!tabComponents[key]) {
    tabComponents[key] = React.memo(props => <OrderList {...props} {..._props} status={value} />);
  }
  return tabComponents[key];
};
const OrderListPage = () => {
  return (
    <View style={{flex: 1}}>
      <Tab.Navigator
        backBehavior="back"
        sceneContainerStyle={styles.sceneContainerStyle}
        screenOptions={{
          tabBarScrollEnabled: true,
          tabBarIndicatorStyle: styles.tabBarIndicatorStyle,
          tabBarItemStyle: styles.tabBarItemStyle,
          tabBarInactiveTintColor: theme.color_text_secondary,
          lazy: true,
          lazyPreloadDistance: 0,
        }}>
        {_.map(
          OrderStatus.filter(it => it?.link),
          (tab, i) => {
            const {key, text, value} = tab || {};
            return (
              <Tab.Screen
                key={`$orderTab_${key}`}
                name={key}
                options={{
                  title: text + '订单',
                  tabBarLabel: text,
                }}
                component={getTabComponent(key, value)}
              />
            );
          },
        )}
      </Tab.Navigator>
    </View>
  );
};
const styles = StyleSheet.create({
  sceneContainerStyle: {
    padding: 0,
    backgroundColor: theme.fill_body,
  },
  tabBarIndicatorStyle: {
    backgroundColor: theme.brand_primary,
    width: 42,
    marginHorizontal: 14,
  },
  tabBarItemStyle: {
    width: 70,
    minHeight: 46,
    padding: 0,
  },
});

OrderListPage.screenName = 'OrderList';
OrderListPage.screenOptions = {
  title: '订单列表',
};
OrderListPage.linking = {
  path: 'list',
  screens: _.chain(OrderStatus.filter(it => it?.link))
    .keyBy(o => o.key)
    .mapValues((v, key) => key)
    .value(),
};
export default OrderListPage;
