import theme from '@/theme';
import AntTag from '@ant-design/react-native/lib/tag';
import Color from 'color';
import {isString, merge} from 'lodash';
import React from 'react';
import {StyleSheet} from 'react-native';

const styles = {
  tag: {
    borderRadius: theme.radius_sm,
    backgroundColor: 'transparent',
    flexDirection: 'row',
    overflow: 'visible',
  },
  wrap: {
    overflow: 'hidden',
    borderRadius: theme.radius_sm,
    // borderWidth: styles.border_width_sm,
    borderWidth: theme.border_width_md,
    borderStyle: 'solid',
    // paddingVertical: styles.v_spacing_sm,
    // paddingHorizontal: styles.h_spacing_lg,
    paddingVertical: 0,
    paddingHorizontal: 3,
  },
  wrapSmall: {
    borderRadius: theme.radius_xs,
    // paddingVertical: 1.5,
    // paddingHorizontal: styles.h_spacing_sm,
    paddingVertical: 0,
    paddingHorizontal: 2,
  },
  text: {
    textAlign: 'center',
    // fontSize: styles.button_font_size_sm,
    fontSize: 10,
    lineHeight: 14,
  },
  textSmall: {
    // fontSize: styles.font_size_icontext,
    fontSize: 8,
    lineHeight: 12,
  },
  normalWrap: {
    backgroundColor: theme.fill_base,
    borderColor: theme.border_color_base,
  },
  normalText: {
    color: theme.color_text_caption,
  },
  activeWrap: {
    backgroundColor: theme.fill_base,
    borderColor: theme.brand_primary,
  },
  activeText: {
    color: theme.color_link,
  },
  disabledWrap: {
    backgroundColor: theme.fill_disabled,
    borderWidth: 0,
  },
  disabledText: {
    color: theme.color_text_disabled,
  },
  close: {
    position: 'absolute',
    backgroundColor: theme.color_text_placeholder,
  },
  closeIOS: {
    borderRadius: 8,
    width: 16,
    height: 16,
    left: -5,
    top: -4,
    overflow: 'hidden',
  },
  closeAndroid: {
    width: 16,
    height: 32,
    left: -2,
    top: -10,
    transform: [
      {
        rotate: '45deg',
      },
    ],
  },
  closeText: {
    color: theme.color_text_base_inverse,
    textAlign: 'center',
    fontSize: 20,
    lineHeight: 18,
  },
  closeTransform: {
    transform: [
      {
        rotate: '-45deg',
      },
    ],
  },
};
export default function Tag({fill, color, primary, children, ...rest}) {
  let newStyles = styles;
  let _fill = fill,
    _color = color;

  if (primary) {
    _fill = _fill && !isString(_fill) ? theme.brand_primary : _fill;
  }
  if (_fill) {
    _color = _color ?? '#fff';
  } else {
    _color = primary ? theme.brand_primary : _color;
  }

  if (_fill || _color) {
    newStyles = merge({}, styles);
    const bgColor = Color(_color).alpha(0.1).string();
    newStyles.normalWrap = newStyles.activeWrap = {
      backgroundColor: _fill ?? bgColor,
      borderColor: _fill ?? _color,
    };
    newStyles.normalText.color = newStyles.activeText.color = newStyles.disabledText.color = _color;
  }
  return (
    <AntTag styles={StyleSheet.create(newStyles)} {...rest}>
      {children}
    </AntTag>
  );
}
