import Button from '@/components/Button';
import {Flex} from '@/components/Flex';
import Modal from '@/components/Modal';
import theme from '@/theme';
import useControlledModalProps from '@/utils/hooks/useControlledModalProps';
import FontWeight from '@/utils/localData/FontWeight';
import {getShadowButton} from '@/utils/styleUtils';
import {useNavigation} from '@react-navigation/core';
import React from 'react';
import {ImageBackground, StyleSheet, View} from 'react-native';
import {assets_award} from './assets';

const RegisterAwardModal = ({...rest}) => {
  const {visible, close, modalProps} = useControlledModalProps(rest);
  const navigation = useNavigation();
  const goToBoxTab = () => {
    close();
    navigation.navigate('Home', {screen: 'BoxTab'});
  };
  return (
    <Modal
      visible={visible}
      onClose={close}
      title=""
      closable={false}
      transparent={true}
      maskClosable={false}
      {...modalProps}
      style={styles.modal}
      bodyStyle={styles.bodyStyle}>
      <ImageBackground source={assets_award} style={styles.bg} />
      <View style={styles.container}>
        <Flex style={{marginLeft: -11}}>
          <Button
            type="primary"
            fontWeight={FontWeight.SemiBold}
            style={[styles.btn, styles.btnBlackShadow]}
            onPress={close}>
            愉快收下
          </Button>
          <Button
            type="primary"
            fontWeight={FontWeight.SemiBold}
            color={theme.brand_secondary}
            isLight={false}
            style={[styles.btn, styles.btnRedShadow]}
            onPress={goToBoxTab}>
            立即抽盒
          </Button>
        </Flex>
      </View>
    </Modal>
  );
};
const styles = StyleSheet.create({
  modal: {
    paddingTop: 0,
    paddingVertical: 0,
    paddingHorizontal: 0,
    backgroundColor: 'transparent',
    overflow: 'visible',
    width: 310,
    height: 300,
  },
  bodyStyle: {
    paddingHorizontal: 0,
    paddingBottom: 0,
    flex: 1,
  },
  bg: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    width: 357,
    height: 347,
    transform: [{translateX: -20}, {translateY: -18}],
  },
  container: {
    flex: 1,
    paddingHorizontal: 20,
    paddingVertical: 30,
    justifyContent: 'flex-end',
  },

  btn: {
    height: 48,
    marginLeft: 11,
    flex: 1,
  },
  btnBlackShadow: getShadowButton('#000'),
  btnRedShadow: getShadowButton(theme.brand_secondary),
});

export default RegisterAwardModal;
