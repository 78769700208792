import {useSetState} from 'ahooks';
import _ from 'lodash';
import {useCallback, useEffect, useRef} from 'react';
import {StyleSheet} from 'react-native';
import styles from './styles';

export default function useBubbleAnim({KEY, animValue, setNotices, duration, renderCount, pop, index: i}, {onBubbled}) {
  const speed = (StyleSheet.flatten(styles.notice)?.height ?? 26) + 4;
  const getInOutRange = useCallback(() => {
    const tt = renderCount,
      td = 0.1;
    const times = _.concat(_.range(0, i + 1), [i + 1], _.range(i + 1, tt + 1));
    const points = _.fill(Array(tt + 2), -1);
    points[i] = 0;
    points[i + 1] = 1;
    if (i === 0) {
      points[points.length - 1] = 0;
    }
    let inputRange = [],
      outputRange = [];
    for (let j = 0; j < points.length; j++) {
      inputRange.push(times[j]);
      outputRange.push(points[j]);
      if (!_.isUndefined(points[j + 1]) && points[j + 1] > points[j]) {
        // 插入关键帧
        inputRange.push(times[j] + td);
        outputRange.push(points[j + 1]);
      }
    }
    return {inputRange, outputRange};
  }, [i, renderCount]);

  const {inputRange, outputRange} = getInOutRange();
  const translateY = useRef(
    animValue.interpolate({
      inputRange,
      outputRange: outputRange.map(v => v * speed * -1),
    }),
  ).current;
  const opacity = useRef(
    animValue.interpolate({
      inputRange,
      outputRange: outputRange.map(v => (v >= 0 ? 1 : 0)),
    }),
  ).current;
  const [state, setState] = useSetState({bubbled: false, poping: false});
  useEffect(() => {
    const _onBubbled = _.debounce(
      () => {
        if (!state.bubbled) {
          setState({bubbled: true});
          _.isFunction(onBubbled) && onBubbled(true);
        }
      },
      duration,
      {leading: true},
    );
    const _onUnBubbled = _.debounce(
      () => {
        if (state.bubbled) {
          setState({bubbled: false});
          _.isFunction(onBubbled) && onBubbled(false);
        }
      },
      duration,
      {leading: true},
    );
    const _onFinish = _.debounce(
      () => {
        if (state.bubbled && state.poping !== true) {
          state.poping = true;
          pop((success, {queue}) => {
            if (success) {
              setNotices(_.clone(queue));
            }
            state.poping = false;
          });
        }
      },
      duration,
      {leading: true},
    );
    const listenerId = translateY.addListener(({value}) => {
      if (value === -30) {
        _onBubbled();
      }
      if (value === 30) {
        _onUnBubbled();
        _onFinish();
      }
    });
    return () => {
      translateY.removeListener(listenerId);
    };
  }, [translateY, state, duration, setState, onBubbled, pop, setNotices]);

  return {transform: [{translateY}], opacity};
}
