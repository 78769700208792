import {LoadingView} from '@/components';
import Search from '@/components/Search';
import SearchDefaultContent from '@/pages/search/conpoments/SearchDefaultContent';
import SearchResult from '@/pages/search/conpoments/SearchResult';
import useLatentFunction from '@/pages/search/useLatentFunction';
import {useDispatch, useSelector} from '@/utils/dva';
import useHeaderSearchBar from '@/utils/hooks/useHeaderSearchBar';
import usePagedRequest from '@/utils/hooks/usePagedRequest';
import SearchOrderBy from '@/utils/localData/SearchOrderBy';
import {useFocusEffect} from '@react-navigation/native';
import {useSetState} from 'ahooks';
import React, {useCallback, useMemo, useRef, useState} from 'react';
import {StyleSheet} from 'react-native';
import {isAndroid} from '@/utils/utils';
import _ from 'lodash';

const SearchPage = ({navigation, route}) => {
  const params = route?.params || {};
  const dispatch = useDispatch();
  const defaultText = useSelector(s => s.search.defaultText);
  const [value, setValue] = useState(params?.text ?? defaultText ?? ''); // 输入框中的值
  const initialState = useMemo(
    () => ({
      text: params?.text ?? '',
      orderBy: SearchOrderBy.Auto,
    }),
    [params?.text],
  );
  const [state, setState] = useSetState(initialState);

  const {
    data,
    load,
    loading,
    hasMore,
    loadMore: _loadMore,
    loadingMore,
    mutate,
  } = usePagedRequest(
    ({current, pageSize}, _params) => {
      return dispatch({
        type: 'search/search',
        params: {
          ...params,
          ..._params,
          current,
          pageSize,
        },
      });
    },
    {
      manual: true,
    },
  );

  const search = useCallback(
    params => {
      if (params?.text) {
        params.text = String(params.text).trim();
        setValue(params.text);
      }
      const newState = {...state, ...params};
      setState(newState);
      return load(newState);
    },
    [load, setState, state],
  );

  const loadMore = useCallback(() => _loadMore(state), [_loadMore, state]);

  const reset = useCallback(() => {
    mutate(null); // 清空数据
    setValue('');
    setState(initialState); // 重置搜索条件
  }, [initialState, mutate, setState]);

  const {setEnv, setTicket, showChannel, showDeviceIds} = useLatentFunction();
  const actionRef = useRef({});
  _.extend(actionRef.current, {
    setValue,
    reset,
    search,
    setEnv,
    setTicket,
    showChannel,
    showDeviceIds,
  });
  const searchProps = useMemo(() => {
    return {
      value,
      onChangeText: value => {
        // 搜索输入框清空后，重置
        if (value === '') {
          actionRef.current?.reset();
        } else {
          // actionRef.current?.setValue(value);
        }
      },
      onSubmitEditing: evt => {
        const text = evt.nativeEvent.text;
        if (String(text).startsWith('ticket:')) {
          const ticket = String(text).substr(7);
          actionRef.current?.setTicket(ticket);
          actionRef.current?.setValue('');
          return;
        }
        if (String(text).startsWith('channel:show')) {
          actionRef.current?.showChannel();
        }
        if (String(text).startsWith('deviceIds:show')) {
          actionRef.current?.showDeviceIds();
        }
        if (String(text).startsWith('env:')) {
          const onoff = String(text).substr(4);
          actionRef.current?.setEnv(onoff === 'on');
          actionRef.current?.setValue('');
          return;
        }
        actionRef.current?.search({text});
      },
    };
  }, [value]);

  useHeaderSearchBar(searchProps);

  useFocusEffect(() => {
    const unsubscribe = navigation.addListener('beforeRemove', e => {
      if (data) {
        reset();
        isAndroid() && e.preventDefault();
      }
    });
    return unsubscribe;
  });

  return (
    <LoadingView loading={loading} layer style={styles.page}>
      {data ? (
        <SearchResult
          state={state}
          search={search}
          data={data}
          hasMore={hasMore}
          loadMore={loadMore}
          loadingMore={loadingMore}
        />
      ) : (
        <SearchDefaultContent search={search} />
      )}
    </LoadingView>
  );
};
const styles = StyleSheet.create({
  page: {
    flex: 1,
    backgroundColor: '#fff',
  },
});

SearchPage.screenName = 'Search';
SearchPage.screenOptions = {
  title: '',
};
SearchPage.linking = 'search';
export default SearchPage;
