import {Flex} from '@/components/Flex';
import Loading from '@/components/Loading';
import CouponItem from '@/pages/coupon/components/CouponItem';
import {useDispatch, useSelector} from '@/utils/dva';
import {useRequest} from 'ahooks';
import React from 'react';
import {FlatList, StyleSheet, Text, TouchableOpacity, View} from 'react-native';

const styles = StyleSheet.create({
  page: {
    flex: 1,
  },
  container: {
    flex: 1,
  },
});
const renderItem = ({item, index}) => (
  <Flex justify="center">
    <CouponItem />
  </Flex>
);
const keyExtractor = (item, index) => `${item[0]?.id}_${item[1]?.id}_${index}`;
const CouponListPage = ({navigation}) => {
  const data = useSelector(s => s.coupon.all);
  const dispatch = useDispatch();
  const {loading, run} = useRequest(() =>
    dispatch({type: 'coupon/getCoupons', status: 'all', lastId: _.last(data)?.id}),
  );

  return (
    <View style={styles.page}>
      <FlatList
        style={{flex: 1}}
        contentContainerStyle={{justifyContent: 'center'}}
        data={data}
        initialNumToRender={10}
        renderItem={renderItem}
        keyExtractor={keyExtractor}
        // onEndReached={() => {
        //   !loading && run();
        // }}
        ListFooterComponent={
          <View>
            {loading && <Loading />}
            <TouchableOpacity
              style={{marginTop: 150, marginBottom: 60}}
              onPress={e => navigation.navigate('CouponHistory')}>
              <Text style={{fontSize: 14, color: '#999', textAlign: 'center'}}>历史优惠券</Text>
            </TouchableOpacity>
          </View>
        }
      />
    </View>
  );
};

CouponListPage.screenName = 'CouponList';
CouponListPage.screenOptions = {
  title: '优惠券',
};
CouponListPage.linking = 'list';
export default CouponListPage;
