import _, {isFunction} from 'lodash';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Animated, PanResponder, Platform, Pressable, StyleSheet, View} from 'react-native';

const styles = StyleSheet.create({
  swiper: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'stretch',
    // overflow: 'hidden',
    width: '100%',
  },

  insideContainer: {
    flexDirection: 'row',
  },

  slideContainer: {
    width: '100%',
    height: '100%',
  },
  slide: {
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },

  indicator: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    height: 10,
  },
  dot: {
    width: 4,
    height: 4,
    borderRadius: 4,
    marginVertical: 3,
    marginHorizontal: 2.5,
    backgroundColor: 'rgba(255,255,255, 0.15)',
  },
  activeDot: {
    backgroundColor: '#d8d8d8',
  },
});

const Item = ({style, children, ...rest}) => {
  return (
    <Pressable {...rest} style={[styles.slide, style]}>
      {children}
    </Pressable>
  );
};
const Indicator = ({size, activeIndex, color, activeColor, style, dotStyle}) => {
  if (activeIndex < 0) {
    activeIndex = size - 1;
  } else if (activeIndex > size - 1) {
    activeIndex = 0;
  }

  return (
    <View style={[styles.indicator, style]}>
      {_.times(size, i => {
        const isActive = activeIndex === i;
        const colorStyle = isActive ? activeColor && {backgroundColor: activeColor} : color && {backgroundColor: color};
        return <View key={`$dot__${i}`} style={[styles.dot, dotStyle, isActive && styles.activeDot, colorStyle]} />;
      })}
    </View>
  );
};
Indicator.propTypes = {
  size: PropTypes.number.isRequired,
  activeIndex: PropTypes.number.isRequired,
  color: PropTypes.string,
  activeColor: PropTypes.string,
  style: PropTypes.any,
  dotStyle: PropTypes.any,
};

class Swiper extends Component {
  constructor(props) {
    super(props);
    const {defaultIndex, width} = props;

    const {size, items} = this.getItems(this.props.children);

    this.state = {
      x: 0,
      y: 0,
      width: width ?? 0,
      height: 0,
      activeIndex: defaultIndex ?? 0,
      size,
      items,
    };

    const _this = this;
    this.translateX = new Animated.Value(this.getOffset(this.state.activeIndex));
    this.panResponder = PanResponder.create({
      ...Platform.select({
        web: {
          onStartShouldSetPanResponderCapture: () => true,
        },
        default: {
          onStartShouldSetPanResponderCapture: () => false,
        },
      }),
      onMoveShouldSetPanResponderCapture: (evt, gesture) => {
        if (_this.touchable === false) {
          return false;
        }
        if (Math.abs(gesture.dx) > Math.abs(gesture.dy)) {
          return true;
        }
        return false;
      },
      onPanResponderGrant: (e, gesture) => {
        _this.grantX = gesture.dx;
        _this.grantY = gesture.dy;
        _this.stopAutoplay();
        _this.resetOffset(); // 移形换位
        _this.translateX.setOffset(_this.translateX._value);
        _this.translateX.setValue(0);
      },
      onPanResponderMove: Animated.event([null, {dx: _this.translateX}], {
        useNativeDriver: false,
      }),
      onPanResponderRelease: (e, gesture) => {
        _this.autoplay();
        _this.translateX.flattenOffset();
        const minDistanceForAction = 0.15;
        const {width} = _this.state;
        const correction = _this.grantX - gesture.dx;
        if (Math.abs(correction) < width * minDistanceForAction) {
          _this.goto(_this.state.activeIndex);
        } else {
          correction > 0 ? _this.gotoNext() : _this.gotoPrev();
        }
        if (_this.grantX === gesture.dx && _this.grantY === gesture.dy) {
          setTimeout(() => {
            _.isFunction(_this.props.onPress) && _this.props.onPress(_this.state);
          }, 50);
        }
      },
      onPanResponderTerminate: (evt, gestureState) => {
        _this.autoplay();
        _this.translateX.flattenOffset();
        _this.goto(_this.state.activeIndex);
      },
      onPanResponderTerminationRequest: () => false,
      onShouldBlockNativeResponder: () => true,
    });
  }

  getItems = children => {
    const list = _.map(React.Children.toArray(children), (dom, index) => {
      return {dom: React.cloneElement(dom), key: dom?.key ?? index, index};
    });
    const size = list.length;
    let items = list;
    if (this.props.loop) {
      const prev = {
        ...list[size - 1],
        key: list[size - 1].key + '_prev_copied',
        index: -1,
      };
      const next = {
        ...list[0],
        key: list[0].key + '_next_copied',
        index: size,
      };
      items = _.concat([], [prev], list, [next]);
    }
    return {size, items};
  };

  mutate = (elems, callback) => {
    const {size, items} = this.getItems(elems);

    this.setState({size, items}, callback);
  };

  resetOffset = () => {
    const {width, activeIndex, size} = this.state;
    let offset = this.translateX._value;

    if (activeIndex === -1) {
      offset -= width * size;
      this.translateX.setValue(offset);
    }
    if (activeIndex === size) {
      offset += width * size;
      this.translateX.setValue(offset);
    }
    return offset;
  };
  getOffset = i => {
    if (this.props.loop) {
      return (-1 - i) * this.state.width;
    } else {
      return (0 - i) * this.state.width;
    }
  };
  spring = (toValue, callback) => {
    this.moving = true;
    Animated.spring(this.translateX, {
      toValue,
      // friction: 4, // 默认7
      bounciness: 2, // 默认8
      speed: 4, // 默认12
      useNativeDriver: false,
    }).start(() => {
      isFunction(callback) && callback();
      this.moving = false;
    });
  };
  goto = (index, animated = true, callback) => {
    // console.warn(` _this.goto(${index})`);
    this.touchable = this.props.disableTouchOnAnimation ? false : true;
    this.setState({activeIndex: index});
    if (animated) {
      this.spring(this.getOffset(index), () => {
        this.touchable = true;
        isFunction(callback) && callback();
      });
    } else {
      this.translateX.setValue(this.getOffset(index));
      this.touchable = true;
      isFunction(callback) && callback();
    }
  };
  onIndexChange = index => {
    if (index < 0) {
      index = 0;
    } else if (index > this.state.size - 1) {
      index = this.state.size - 1;
    }
    _.isFunction(this.props.onIndexChange) && this.props.onIndexChange.call(this, index);
  };
  onAnimationEnd = (index = this.state.activeIndex) => {
    // if (index < 0) {
    //   index = 0;
    // } else if (index > this.state.size - 1) {
    //   index = this.state.size - 1;
    // }
    _.isFunction(this.props.onAnimationEnd) && this.props.onAnimationEnd.call(this, index);
  };
  gotoNext = () => {
    let next = this.state.activeIndex + 1;
    if (!this.props.loop && next >= this.state.size) {
      // next = 0;
      this.goto(this.state.activeIndex);
      return;
    }
    // console.warn('_this.gotoNext()', next);
    this.touchable = this.props.disableTouchOnAnimation ? false : true;
    this.setState({activeIndex: next}, () => {
      this.onIndexChange(next);
      this.spring(this.getOffset(next), () => {
        if (next >= this.state.size) {
          this.setState({activeIndex: 0}, () => {
            this.translateX.setValue(this.getOffset(0));
            this.touchable = true;
            this.onAnimationEnd();
          });
        } else {
          this.touchable = true;
          this.onAnimationEnd();
        }
      });
    });
  };
  gotoPrev = () => {
    let prev = this.state.activeIndex - 1;
    if (!this.props.loop && prev <= -1) {
      // prev = this.state.size - 1;
      this.goto(this.state.activeIndex);
      return;
    }
    // console.warn('_this.gotoPrev()', prev);
    this.touchable = this.props.disableTouchOnAnimation ? false : true;
    this.setState({activeIndex: prev}, () => {
      this.onIndexChange(prev);
      this.spring(this.getOffset(prev), () => {
        if (prev <= -1) {
          this.setState({activeIndex: this.state.size - 1}, () => {
            this.translateX.setValue(this.getOffset(this.state.size - 1));
            this.touchable = true;
            this.onAnimationEnd();
          });
        } else {
          this.touchable = true;
          this.onAnimationEnd();
        }
      });
    });
  };
  autoplay = () => {
    if (this.props.autoplay) {
      this.intervalID && clearInterval(this.intervalID);
      this.intervalID = setInterval(this.gotoNext, this.props.autoplayInterval ?? 3000);
    }
  };
  stopAutoplay = () => {
    this.intervalID && clearInterval(this.intervalID);
  };

  componentDidMount() {
    this.autoplay();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.autoplay !== prevProps.autoplay) {
      this.props.autoplay ? this.autoplay() : this.stopAutoplay();
    }
    if (this.props.autoplayInterval !== prevProps.autoplayInterval) {
      this.autoplay();
    }
  }

  componentWillUnmount() {
    this.stopAutoplay();
  }

  _onLayout = evt => {
    this.setState(evt.nativeEvent.layout, () => {
      this.translateX.setValue(this.getOffset(this.state.activeIndex));
    });
  };

  render() {
    const {renderIndicator, indicatorProps, onlyShowActive} = this.props;
    return (
      <View style={styles.swiper} onLayout={this._onLayout}>
        <Animated.View
          style={[
            styles.insideContainer, //
            {transform: [{translateX: this.translateX}]},
          ]}
          {...(this.props.allowTouchMove && this.panResponder?.panHandlers)} //
        >
          {_.map(this.state.items, ({dom, key, index}, i) => {
            return (
              <View key={key ?? `$swiper_item__${i}`} style={[styles.slideContainer, {width: this.state.width}]}>
                {onlyShowActive ? (index === this.state.activeIndex ? dom : null) : dom}
              </View>
            );
          })}
        </Animated.View>
        {_.isFunction(renderIndicator) &&
          renderIndicator({
            ...indicatorProps, //
            ..._.pick(this.state, ['size', 'activeIndex']),
          })}
      </View>
    );
  }
}

Swiper.defaultProps = {
  defaultIndex: 0,
  allowTouchMove: true,
  autoplay: false,
  autoplayInterval: 3000,
  loop: true,
  onlyShowActive: false,
  onIndexChange: index => null,
  renderIndicator: props => <Indicator {...props} />,
};
Swiper.propTypes = {
  defaultIndex: PropTypes.number,
  allowTouchMove: PropTypes.bool,
  autoplay: PropTypes.bool,
  autoplayInterval: PropTypes.number,
  loop: PropTypes.bool,
  onlyShowActive: PropTypes.bool,
  onIndexChange: PropTypes.func,
  renderIndicator: PropTypes.func,
  indicatorProps: PropTypes.object,
};
Swiper.Indicator = Indicator;
Swiper.Item = Item;
export default Swiper;
