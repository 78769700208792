import toMap from '@/utils/FrozenMap/toMap';
import {addToStateData} from '@/utils/remoteData';

const PriceASC = 'amount',
  PriceDESC = 'amount desc';

@addToStateData('SearchOrderBy')
@toMap({mapValues: v => v?.value})
export default class SearchOrderBy {
  static Auto = {key: 'auto', text: '综合', value: ''};
  static Sales = {key: 'sales', text: '销量', value: 'sale_count desc'};
  static Price = {
    key: 'price',
    text: '价格',
    value: Object.assign([PriceASC, PriceDESC], {ASC: PriceASC, DESC: PriceDESC}),
  };
}
