import toMap from '@/utils/FrozenMap/toMap';
import {addToStateData} from '@/utils/remoteData';

@addToStateData('BoxRecordsStatus')
@toMap({mapValues: v => v?.value})
export default class BoxRecordsStatus {
  static Paid = {key: 'paid', text: '待提货', value: '待发货', link: '/box/records/paid'};
  static Delivered = {key: 'delivered', text: '待收货', value: '待收货', link: '/box/records/delivered'};
  static Completed = {key: 'completed', text: '已完成', value: '已完成', link: '/box/records/completed'};
  static Recycled = {key: 'recycled', text: '已回收', value: '已回收', link: '/box/records/recycled'};
}
