import {Flex} from '@/components';
import {useImageResize} from '@/utils/remoteData/ImageResize';
import {useLinkTo} from '@react-navigation/native';
import React from 'react';
import {Image, ImageBackground, Pressable, StyleSheet, Text, View} from 'react-native';
import assets_box_tag from './assets/_box_tag';

const styles = StyleSheet.create({
  container: {
    padding: 0,
    width: '100%',
    height: 258,
    backgroundColor: '#EFF0F5',
  },
  head: {
    marginHorizontal: 8,
    marginTop: 8,
    height: 185 - 8,
    backgroundColor: '#fff',
    paddingTop: 35,
    paddingBottom: 16,
    paddingHorizontal: 16,
  },
  tag: {
    width: 65,
    height: 22,
    position: 'absolute',
    left: 0,
    top: 0,
  },
  image: {
    width: '100%',
    height: '100%',
  },

  body: {
    paddingVertical: 16,
    paddingHorizontal: 14,
  },
  title: {
    fontSize: 14,
    marginBottom: 4,
  },
  badge: {
    paddingVertical: 2,
    paddingHorizontal: 4,
    backgroundColor: '#E1CCFF',
    fontSize: 10,
    color: '#5000C0',
    lineHeight: 14,
  },
});
const ProdCardForBox = ({data}) => {
  const linkTo = useLinkTo();
  const {id, name, title, subTitle, picture} = data || {};
  const [, resizeImage] = useImageResize({object: 'box', size: 'sm'});

  return (
    <Pressable style={styles.container} onPress={() => id && linkTo(`/box/detail/${id}`)}>
      <View style={styles.head}>
        <ImageBackground source={assets_box_tag} style={styles.tag} />
        <Image
          style={styles.image}
          resizeMode="contain" // todo: contain or cover ?
          source={{uri: resizeImage(picture)}}
        />
      </View>
      <Flex direction="column" align="start" style={styles.body}>
        <Text style={styles.title} numberOfLines={1}>
          {title ?? name}
        </Text>
        {subTitle && (
          <Text style={styles.badge} numberOfLines={1}>
            {subTitle}
          </Text>
        )}
      </Flex>
    </Pressable>
  );
};

export default ProdCardForBox;
