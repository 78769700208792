import Icon from '@/components/Icon';
import assets_box_bg from '@/pages/box/assets/_box_bg';
import BoxBuff from '@/pages/box/detail/components/BoxBuff';
import BoxRollingNotices from '@/pages/box/components/BoxRolingNotices';
import BoxRarity from '@/utils/localData/BoxRarity';
import {useNavigation} from '@react-navigation/core';
import _, {assign} from 'lodash';
import React from 'react';
import {ImageBackground, StyleSheet, Text, TouchableHighlight, View} from 'react-native';
import DisplaySwiper from './DisplaySwiper';

const DemoBtn = ({navigation, data, style}) => {
  const {id, boxProductList} = data || {};
  const onPress = () => {
    if (boxProductList?.length) {
      const prods = _.filter(boxProductList, it => it?.style !== BoxRarity.Normal);
      const prod = prods[_.random(0, prods.length - 1)];
      navigation.push('BoxResultModal', {id, demo: true, data: [prod]});
    }
  };
  return (
    <TouchableHighlight style={[styles.demo, style]} onPress={onPress}>
      <View style={{alignItems: 'center'}}>
        <Icon type="tap" fill="#fff" />
        <Text style={{color: '#fff', marginTop: 6}}>试玩</Text>
      </View>
    </TouchableHighlight>
  );
};
const Component = ({section, data}) => {
  const BgComponent = section.showBgImage ? ImageBackground : View;
  const navigation = useNavigation();
  const {boxProductList} = data || {};

  return (
    <BgComponent source={assets_box_bg} resizeMode="contain" style={[styles.container, section.style]}>
      {section.swiperProps && boxProductList?.length > 0 && (
        <DisplaySwiper data={boxProductList} swiperProps={section.swiperProps} />
      )}
      {section.demo && <DemoBtn data={data} navigation={navigation} {...section.demo} />}
      {section.buff && <BoxBuff navigation={navigation} {...section.buff} />}
      {section.rollingNotices && <BoxRollingNotices {...section.rollingNotices} />}
    </BgComponent>
  );
};

export default class HeadSection {
  static type = 'head';
  static component = Component;
  style = {};
  showBgImage = true;
  swiperProps = {
    autoplay: false,
    loop: false,
    indicatorProps: {},
  };
  demo = {};
  buff = {style: styles.buff};
  rollingNotices = {
    style: {top: 130, left: 12, bottom: 'auto'},
    mode: BoxRollingNotices.MODES.Bubble,
    renderCount: 3,
  };

  constructor(config) {
    const {swiperProps, ...rest} = config || {};
    assign(this, rest);
  }

  get type() {
    return this?.constructor?.type;
  }
}

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: 608,
    backgroundColor: '#030406',
  },

  buff: {
    position: 'absolute',
    right: 13,
    top: 433,
  },
  demo: {
    height: 54,
    width: 58,
    paddingLeft: 8,
    paddingRight: 0,
    borderRadius: 8,
    backgroundColor: 'rgba(255,255,255, 0.1)',
    position: 'absolute',
    left: -10,
    top: 428,
    justifyContent: 'center',
    alignItems: 'center',
  },
});
