import {LoadingView} from '@/components';
import Button from '@/components/Button';
import {Flex} from '@/components/Flex';
import {IconButton} from '@/components/Icon';
import Modal from '@/components/Modal';
import Stepper from '@/components/Stepper';
import {getPrice} from '@/utils/priceUtils';
import theme from '@/theme';
import useBottomInset from '@/utils/hooks/useBottomInset';
import useControlledModalProps from '@/utils/hooks/useControlledModalProps';
import FontWeight from '@/utils/localData/FontWeight';
import {useImageResize} from '@/utils/remoteData/ImageResize';
import {getDINProBoldStyle} from '@/utils/styleUtils';
import _, {isArray, isFunction} from 'lodash';
import React, {useState} from 'react';
import {Dimensions, Image, Pressable, ScrollView, StyleSheet, Text, View} from 'react-native';

const styles = StyleSheet.create({
  modal: {},
  container: {
    paddingHorizontal: theme.h_spacing_lg,
    height: 420,
    backgroundColor: '#fff',
    paddingBottom: 70,
  },
  close: {
    position: 'absolute',
    top: theme.h_spacing_lg - 4,
    right: theme.h_spacing_lg - 4,
    zIndex: 99,
  },

  product: {
    paddingVertical: 10,
    marginBottom: 14,
  },
  productImage: {
    width: 76,
    height: 76,
    borderWidth: StyleSheet.hairlineWidth,
    borderColor: theme.border_color_base,
    borderRadius: 2,
    // backgroundColor: theme.fill_grey,
  },
  productPrice: {
    marginBottom: 4,
  },
  mainPriceText: {
    ...getDINProBoldStyle(28),
    lineHeight: 35,
  },
  mainPriceType: {
    fontWeight: FontWeight.SemiBold,
    lineHeight: 19,
    marginBottom: 5,
  },
  secondaryPriceText: {
    color: theme.color_text_secondary,
    fontWeight: FontWeight.SemiBold,
    lineHeight: 19,
    marginBottom: 5,
  },
  selected: {
    fontSize: 12,
    color: theme.color_text_secondary,
  },
  variant: {
    flex: 1,
  },
  variantName: {
    marginBottom: 13,
    fontWeight: FontWeight.Medium,
  },
  variantList: {
    marginHorizontal: -5,
  },
  variantItem: {
    marginHorizontal: 5,
    marginBottom: 12,
    minWidth: 48,
    maxWidth: Dimensions.get('window').width - 40,
    paddingVertical: 5,
    paddingHorizontal: 16,
    borderWidth: StyleSheet.hairlineWidth,
    borderColor: theme.border_color_base,
  },
  variantItemSelected: {
    borderColor: theme.brand_primary,
    borderWidth: 2,
    paddingVertical: 5 - 2 + StyleSheet.hairlineWidth,
  },
  variantText: {
    lineHeight: 18,
    fontSize: 12,
  },
  variantImage: {
    borderRadius: 0,
  },

  toolbar: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    borderTopWidth: StyleSheet.hairlineWidth,
    borderTopColor: theme.border_color_base,
    backgroundColor: '#fff',
    paddingHorizontal: theme.h_spacing_lg,
    paddingVertical: theme.v_spacing_md,
    marginLeft: -10,
  },
  toolbarButton: {
    height: 48,
    marginLeft: 10,
  },
});
const PickVariantModal = ({data, defaultCurrent, onChange, defaultCount, onChangeCount, actions, ...rest}) => {
  const [, toolbarPaddingBottom] = useBottomInset(10);
  const {visible, close, modalProps} = useControlledModalProps(rest);
  const [loading, setLoading] = useState(false);
  const [current, setCurrent] = useState(defaultCurrent);
  const [count, setCount] = useState(defaultCount ?? 1);
  const {standardName, skus} = data || {};
  const currentSku = _.find(skus, sku => sku?.id === current);
  const displayProd = currentSku ?? data;
  const {picture} = displayProd || {};
  const [price, rmbPrice] = getPrice(displayProd);
  const [, resizeImage] = useImageResize({object: 'prod', size: 'xs'});

  return (
    <Modal popup visible={visible} animationType="slide-up" maskClosable={false} {...modalProps}>
      <LoadingView loading={loading} layer style={styles.container}>
        <IconButton type="close" size={12} onPress={close} style={styles.close} />

        <Flex style={styles.product}>
          <Image source={{uri: resizeImage(picture)}} style={styles.productImage} />
          <Flex.Item style={{marginLeft: 18}}>
            <Flex align="end" style={styles.productPrice}>
              <Text style={styles.mainPriceText}>{price?.amount}</Text>
              <View style={{marginLeft: 5}} />
              <Text style={styles.mainPriceType}>{price?.unit}</Text>
              {rmbPrice.isAvailable() && (
                <>
                  <View style={{marginLeft: 12}} />
                  <Text style={styles.secondaryPriceText}>{rmbPrice.text()}</Text>
                </>
              )}
            </Flex>
            <Flex justify="between">
              <View style={{flex: 1}}>
                <Text numberOfLines={2} style={styles.selected}>
                  已选择: {currentSku?.standard}
                </Text>
              </View>
              {isFunction(onChangeCount) && (
                <View style={{marginLeft: 10}}>
                  <Stepper
                    value={count}
                    min={1}
                    max={99}
                    onChange={v => {
                      setCount(v);
                      onChangeCount(v);
                    }}
                  />
                </View>
              )}
            </Flex>
          </Flex.Item>
        </Flex>

        <ScrollView style={styles.variant}>
          <Text style={styles.variantName}>{standardName ?? '规格'}</Text>
          <Flex wrap="wrap" style={styles.variantList}>
            {isArray(skus) &&
              skus.map((sku, i) => (
                <Pressable
                  key={`$variant__${i}`}
                  style={[styles.variantItem, sku?.id === current && styles.variantItemSelected]}
                  onPress={() => {
                    setCurrent(sku?.id);
                    onChange?.(sku?.id);
                  }}>
                  <Text numberOfLines={1} style={styles.variantText}>
                    {sku?.standard}
                  </Text>
                </Pressable>
              ))}
          </Flex>
        </ScrollView>

        {actions?.length && (
          <Flex style={[styles.toolbar, {paddingBottom: toolbarPaddingBottom}]}>
            {_.map(_.compact(actions), (action, i) => {
              const {type, text, onPress, ...rest} = action || {};
              return (
                <Flex.Item key={i} style={{marginLeft: 5}}>
                  <Button
                    {...rest}
                    type={type ?? 'primary'}
                    onPress={() => {
                      onPress?.({skuId: current, sku: currentSku, count}, {close, setLoading});
                    }}
                    style={[styles.toolbarButton]}>
                    {text}
                  </Button>
                </Flex.Item>
              );
            })}
          </Flex>
        )}
      </LoadingView>
    </Modal>
  );
};

export default PickVariantModal;
