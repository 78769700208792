// 盲盒产品稀有度
import toMap from '@/utils/FrozenMap/toMap';
import {addToStateData} from '@/utils/remoteData';

@addToStateData('BoxRarity')
@toMap({mapValues: v => v?.value})
export default class BoxRarity {
  static Supreme = {
    key: 'supreme',
    text: '王者款',
    value: '王者款',
    colors: ['#FF5F84', '#FF033B'],
    bgColor: '#FFE7ED',
  }; // 至尊
  static Rare = {key: 'rare', text: '星耀款', value: '星耀款', colors: ['#FF7C00', '#FF4300'], bgColor: '#FFF2E7'}; // 稀有
  static Hidden = {key: 'hidden', text: '钻石款', value: '钻石款', colors: ['#E400FF', '#AC00FF'], bgColor: '#FCE8FF'}; // 隐藏
  static Normal = {key: 'normal', text: '黄金款', value: '黄金款', colors: ['#008DFF', '#004CFF'], bgColor: '#ECF6FF'}; // 普通
}
