import Button from '@/components/Button';
import {Flex} from '@/components/Flex';
import Icon from '@/components/Icon';
import assets_box_header_bg from '@/pages/home/box/assets/_box_header_bg';
import theme from '@/theme';
import React from 'react';
import {Animated, ImageBackground, Platform, StyleSheet, Text, TouchableOpacity} from 'react-native';

const styles = StyleSheet.create({
  container: {
    height: 285 - 60,
    ...Platform.select({
      web: {
        marginTop: -28,
      },
    }),
    position: 'absolute',
    left: 0,
    right: 0,
    zIndex: 99,
    overflow: 'hidden',
  },
  bg: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: 285,
  },
  boxBtn: {
    position: 'absolute',
    left: theme.h_spacing_lg,
    bottom: 63,
    width: 85,
    height: 36,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#fff',
    borderRadius: 36,
    zIndex: 99,
  },
  ruleBtn: {
    position: 'absolute',
    right: -10,
    top: 60,
    backgroundColor: 'rgba(255,255,255, 0.1)',
    borderWidth: 0,
    color: '#fff',
    paddingLeft: 13,
    paddingRight: 21,
  },
});
const HeadSection = ({navigation, style}) => {
  return (
    <Animated.View style={[styles.container, style]}>
      <ImageBackground source={assets_box_header_bg} style={styles.bg}>
        <TouchableOpacity style={styles.boxBtn} activeOpacity={0.8} onPress={() => navigation.jumpTo('BoxCupboardTab')}>
          <Flex>
            <Icon type="box_colorful" style={{marginRight: 5}} />
            <Text>盒柜</Text>
          </Flex>
        </TouchableOpacity>
        <Button
          size="small"
          round
          type="primary"
          style={styles.ruleBtn}
          onPress={() => navigation.navigate('Document', {type: 'mhgz'})}>
          规则
        </Button>
      </ImageBackground>
    </Animated.View>
  );
};

export default HeadSection;
