import Grid from '@/components/Grid';
import ProdCard from '@/pages/product/components/ProdCard';
import theme from '@/theme';
import {assign} from 'lodash';
import React from 'react';
import {StyleSheet, View} from 'react-native';

const styles = StyleSheet.create({
  container: {
    borderTopWidth: StyleSheet.hairlineWidth,
    borderTopColor: theme.border_color_base,
  },
  gridItem: {
    justifyContent: 'center',
    alignItems: 'center',
    height: 'auto',
  },
});

const Component = ({section, data}) => {
  return (
    <View style={[styles.container, section.style]}>
      {data?.length && (
        <Grid
          {...section.gridProps}
          data={data}
          renderItem={(item, i) => {
            return <ProdCard data={item} />;
          }}
        />
      )}
    </View>
  );
};

export default class ProductSection {
  static type = 'product';
  static component = React.memo(Component);
  style = {};
  gridProps = {
    itemStyle: {
      height: 'auto',
    },
    columnNum: 2,
    hasLine: true,
    border: false,
  };
  hasMore = true;

  constructor(config) {
    const {...rest} = config || {};
    assign(this, rest);
  }

  get type() {
    return this?.constructor?.type;
  }

  getMore = () => {};
}
