import Icon from '@/components/Icon';
import React from 'react';
import {Pressable, StyleSheet} from 'react-native';

const styles = StyleSheet.create({
  ShadowIcon: {
    width: 34,
    height: 34,
    backgroundColor: 'rgba(255,255,1255,0.1)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 34,
  },
});
const ShadowIcon = ({onPress, style, iconStyle, fill, ...rest}) => {
  return (
    <Pressable style={[styles.ShadowIcon, style]} onPress={onPress}>
      <Icon size={14} fill={fill ?? '#fff'} style={iconStyle} {...rest} />
    </Pressable>
  );
};

export default ShadowIcon;
