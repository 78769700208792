import {Center} from '@/components/Flex';
import theme from '@/theme';
import React from 'react';
import {Image, StyleSheet, Text, View} from 'react-native';

const ToolCard = ({data, style}) => {
  return (
    <View style={[styles.container, style]}>
      <View style={styles.imageContainer}>
        <Image source={{uri: data?.picture}} style={styles.image} />
      </View>
      <Center style={styles.title}>
        <Text style={styles.titleText}>{data?.description} ×1</Text>
      </Center>
    </View>
  );
};

export default ToolCard;

const styles = StyleSheet.create({
  container: {
    height: 122,
    borderWidth: StyleSheet.hairlineWidth,
    borderColor: theme.border_color_base,
    borderRadius: 4,
    backgroundColor: '#fff',
    position: 'relative',
  },
  imageContainer: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    justifyContent: 'center',
    alignItems: 'center',
  },
  image: {
    width: 131,
    height: 89,
  },
  title: {
    position: 'absolute',
    bottom: 10,
    left: 0,
    right: 0,
    paddingHorizontal: 10,
  },
  titleText: {
    fontSize: 12,
    lineHeight: 17,
  },
});
