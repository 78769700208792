import OrderStatus from '@/utils/localData/OrderStatus';
import {assign} from 'lodash';
import React from 'react';
import {StyleSheet, Text, View} from 'react-native';

const Component = ({section}) => {
  const {status} = section.data || {};
  return (
    <View style={[styles.container, section.style]}>
      <Text style={styles.status}>{OrderStatus.getByValue(status)?.text || status}</Text>
      {status === OrderStatus.Unpaid && <Text style={styles.description}>您的订单已提交，请尽快完成支付。</Text>}
      {status === OrderStatus.Delivered && <Text style={styles.description}>您的商品已发货，请及时确认收货。</Text>}
    </View>
  );
};
const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 20,
    paddingVertical: 22,
    backgroundColor: '#424753',
    marginBottom: 10,
  },
  status: {
    fontSize: 18,
    lineHeight: 25,
    color: '#fff',
  },
  description: {
    fontSize: 12,
    lineHeight: 17,
    marginTop: 4,
    color: '#fff',
  },
});

export default class StatusSection {
  static type = 'status';
  static component = Component;
  style = {};

  constructor(config) {
    assign(this, config);
  }

  get type() {
    return this?.constructor?.type;
  }
}
