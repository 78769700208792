import {Clipboard} from 'react-native-web';

export default Clipboard;

export const copy = (text, callback) => {
  const success = Clipboard.setString(text);
  if (success) {
    callback();
  }
};
