export * from '@/utils/profile';
export * from './styles';
export * from '@/NativeModules/Wechat';
import theme from '@/theme';
import lodash from 'lodash';
import {ActivityIndicator, Platform, ScrollView, StyleSheet, Text, TextInput, View} from 'react-native';
import 'react-native-gesture-handler';
import * as utils from '@/utils/utils';

if (Platform.OS === 'web') {
  global.__DEV__ = process.env.NODE_ENV === 'development';
}

if (!global._) {
  global._ = lodash;
}

global.utils = utils;

const styles = StyleSheet.create({
  text: Platform.select({
    web: {
      color: 'inherit',
      fontSize: 'inherit',
      fontFamily: 'inherit',
    },
    default: {
      fontFamily: 'PingFang SC',
      color: theme.color_text_base,
    },
  }),
  view: Platform.select({
    web: {
      color: 'inherit',
      fontSize: 'inherit',
      fontFamily: 'inherit',
    },
  }),
  textInput: Platform.select({
    web: {
      fontFamily: 'inherit',
      color: 'inherit',
    },
    default: {
      fontFamily: 'PingFang SC',
      color: theme.color_text_base,
    },
  }),
});

function setDefaultProps(Component, customProps) {
  const Render = Component.render;
  const initialDefaultProps = Component.defaultProps;
  Component.defaultProps = {
    ...initialDefaultProps,
    ...customProps,
  };
  Component.render = function render(props) {
    let oldProps = props;
    props = {...props, style: [customProps.style, props.style]};
    try {
      return Render.apply(this, arguments);
    } finally {
      props = oldProps;
    }
  };
}

setDefaultProps(Text, {
  style: styles.text,
});
setDefaultProps(View, {
  style: styles.view,
});
setDefaultProps(TextInput, {
  style: styles.textInput,

  selectionColor: 'rgba(0,0,0,0.5)',
  placeholderTextColor: theme.color_text_placeholder,
});
setDefaultProps(ScrollView, {
  showsHorizontalScrollIndicator: false,
  showsVerticalScrollIndicator: false,
});
setDefaultProps(ActivityIndicator, {
  color: theme.brand_primary,
});
