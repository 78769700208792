import {Empty} from '@/components';
import React, {forwardRef} from 'react';
import {View} from 'react-native';
import useAuthed from './useAuthed';

export default function authedComponent(Component) {
  if (Component.AuthedComponent) {
    return Component.AuthedComponent;
  }

  const AuthedComponent = (props, ref) => {
    const [authed, {currentUser, gotoLogin}] = useAuthed(false);

    return authed ? (
      <Component {...props} currentUser={currentUser} ref={ref} />
    ) : (
      <View style={{flex: 1}}>
        <Empty text="未登录或已超时" actions={[{text: '立即登录', onPress: gotoLogin}]} />
      </View>
    );
  };

  Component.AuthedComponent = forwardRef(AuthedComponent);

  return Component.AuthedComponent;
}
