import {Swiper} from '@/components';
import ProdDisplay from '@/pages/box/detail/components/ProdDisplay';
import _ from 'lodash';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Animated, PanResponder, StyleSheet, useWindowDimensions, View} from 'react-native';

const _displayCount = 10,
  _limit = _displayCount * 2 + 1;

const SampleSwiper = ({data, swiperProps}) => {
  const {width} = useWindowDimensions();
  const _props = {
    loop: true,
    defaultIndex: 0,
    children: _.map(data, it => (
      <Swiper.Item key={`$slide_${it?.id}`}>
        <ProdDisplay showPrice={true} data={it} />
      </Swiper.Item>
    )),
    width,
  };
  return (
    <Swiper
      {...swiperProps} //
      {..._props}
      onlyShowActive={true}
    />
  );
};

const DisplaySwiper = ({data, swiperProps}) => {
  const {width} = useWindowDimensions();
  const swiperRef = useRef();
  const [activeIndex, setActiveIndex] = useState(0);
  const total = data?.length ?? 0;
  const getSwiperProps = useCallback(
    index => {
      const n = _displayCount;
      return {
        children: _.map(_.range(index - _displayCount, index + n + 1), i => {
          i = i < 0 ? total + i : i >= total ? i - total : i;
          return (
            <Swiper.Item key={`$slide_${data[i]?.id}`}>
              <ProdDisplay showPrice={true} data={data[i]} />
            </Swiper.Item>
          );
        }),
      };
    },
    [data, total],
  );

  useEffect(() => {
    if (swiperRef?.current) {
      const {children} = getSwiperProps(activeIndex);
      const _timeoutID = setTimeout(() => {
        swiperRef.current.mutate?.(children, () => {
          swiperRef.current.goto?.(_displayCount, false);
          swiperRef.current.touchable = true;
        });
      }, 400);
      return () => clearTimeout(_timeoutID);
    }
  }, [activeIndex, getSwiperProps]);

  return (
    <Swiper
      {...swiperProps} //
      ref={swiperRef}
      width={width}
      loop={false}
      defaultIndex={_displayCount}
      // disableTouchOnAnimation={true}
      onlyShowActive={true}
      onIndexChange={function (i) {
        if (i === 0) {
          this.touchable = false;
          let index = activeIndex - _displayCount;
          if (index < 0) {
            index += total;
          }
          setActiveIndex(index);
        }
        if (i === 2 * _displayCount) {
          this.touchable = false;
          let index = activeIndex + _displayCount;
          if (index >= total) {
            index -= total;
          }
          setActiveIndex(index);
        }
      }}
    />
  );
};

const DisplaySwiperNext = ({data}) => {
  const {width} = useWindowDimensions();
  const [activeIndex, setActiveIndex] = useState(0);
  const total = data?.length;
  let prev = data[activeIndex - 1],
    next = data[activeIndex + 1],
    current = data[activeIndex];
  if (activeIndex === 0) {
    prev = data[total - 1];
  }
  if (activeIndex === total - 1) {
    next = data[0];
  }
  const translateX = useRef(new Animated.Value(0)).current;

  const _this = useRef({
    grantX: 0,
    back(animated = true) {
      if (animated) {
        Animated.spring(translateX, {
          toValue: 0,
          useNativeDriver: false,
        }).start();
      } else {
        translateX.setValue(0);
      }
    }, //
    gotoNext() {
      Animated.spring(translateX, {
        toValue: -width,
        useNativeDriver: false,
      }).start(() => {
        let next = activeIndex + 1;
        if (next >= total) {
          next = 0;
        }
        setActiveIndex(next);
      });
    },
    gotoPrev() {
      Animated.spring(translateX, {
        toValue: width,
        useNativeDriver: false,
      }).start(() => {
        let prev = activeIndex - 1;
        if (prev < 0) {
          prev = total - 1;
        }
        setActiveIndex(prev);
      });
    },
  }).current;
  const panResponder = useRef(
    PanResponder.create({
      onStartShouldSetPanResponderCapture: () => false,
      onMoveShouldSetPanResponderCapture: (evt, gesture) => {
        if (Math.abs(gesture.dx) > Math.abs(gesture.dy)) {
          return true;
        }
        return false;
      },
      onPanResponderGrant: (e, gesture) => {
        _this.grantX = gesture.dx;
        translateX.setOffset(translateX._value);
        translateX.setValue(0);
      },
      onPanResponderMove: Animated.event([null, {dx: translateX}], {
        useNativeDriver: false,
      }),
      onPanResponderRelease: (e, gesture) => {
        translateX.flattenOffset();
        const minDistanceForAction = 0.15;
        const correction = _this.grantX - gesture.dx;
        if (Math.abs(correction) < width * minDistanceForAction) {
          _this.back();
        } else {
          correction > 0 ? _this.gotoNext() : _this.gotoPrev();
        }
      },
      onPanResponderTerminationRequest: () => false,
      onShouldBlockNativeResponder: () => true,
    }),
  ).current;

  useEffect(() => {
    _this.back(false);
  }, [_this, activeIndex]);
  return (
    <Animated.View style={[styles.container, {transform: [{translateX}]}]} {...panResponder.panHandlers}>
      <View style={[styles.prev, {transform: [{translateX: -width}]}]}>
        <ProdDisplay showPrice={true} data={prev} />
      </View>
      <ProdDisplay showPrice={true} data={current} />
      <View style={[styles.next, {transform: [{translateX: width}]}]}>
        <ProdDisplay showPrice={true} data={next} />
      </View>
    </Animated.View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  prev: {
    position: 'absolute',
  },
  next: {
    position: 'absolute',
  },
});

export default ({data, swiperProps}) => {
  return data?.length > _limit ? (
    <DisplaySwiper data={data} swiperProps={swiperProps} /> //
  ) : (
    <SampleSwiper data={data} swiperProps={swiperProps} />
  );
};
