import HeaderLeft from '@/components/Header/HeaderLeft';
import {useFocusEffect} from '@react-navigation/native';
import React, {useRef} from 'react';

const useTranslucentBoxHeader = navigation => {
  const translucentHeader = useRef({
    headerShown: true,
    headerTransparent: true,
    headerTintColor: '#fff',
    headerStyle: {
      backgroundColor: 'transparent',
    },
    headerLeft: props => (
      <HeaderLeft
        {...props}
        navigation={navigation}
        showBtnShadow={true}
        shadowBtnStyle={{backgroundColor: 'rgba(0,0,0,0.5)'}}
      />
    ),
    title: '',
  }).current;
  const fixedHeader = useRef({
    headerShown: true,
    headerTransparent: true,
    headerTintColor: '#000',
    headerStyle: {
      backgroundColor: '#fff',
    },
    headerLeft: props => <HeaderLeft {...props} navigation={navigation} />,
    title: '口袋商城',
  }).current;
  const scrollView = useRef({scrollTop: 0});
  useFocusEffect(() => {
    if (scrollView?.current?.scrollTop > 400) {
      navigation.setOptions(fixedHeader);
    } else {
      navigation.setOptions(translucentHeader);
    }
  });
  const onScroll = evt => {
    scrollView.current.scrollTop = evt.nativeEvent.contentOffset.y;
    if (evt.nativeEvent.contentOffset.y > 400) {
      navigation.setOptions(fixedHeader);
    } else {
      navigation.setOptions(translucentHeader);
    }
  };
  return {onScroll};
};

export default useTranslucentBoxHeader;
