import {Flex} from '@/components/Flex';
import {IconButton} from '@/components/Icon';
import theme from '@/theme';
import {useDispatch, useSelector} from '@/utils/dva';
import FontWeight from '@/utils/localData/FontWeight';
import SearchOrderBy from '@/utils/localData/SearchOrderBy';
import {useRequest} from 'ahooks';
import _ from 'lodash';
import React from 'react';
import {ScrollView, StyleSheet, Text, TouchableOpacity, View} from 'react-native';

const SearchDefaultContent = ({search}) => {
  const {history, hots} = useSelector(s => s.search) || {};
  const dispatch = useDispatch();

  useRequest(() => dispatch({type: 'search/getHots'}), {
    // ready: !hots?.length,
  });

  const clearHistory = () => dispatch({type: 'search/clearHistory'});

  return (
    <View style={styles.container}>
      {!!history?.length && (
        <View style={styles.section}>
          <Flex justify="between">
            <Text style={styles.sectionTitle}>搜索历史</Text>
            <IconButton type="recycle" fill={theme.color_text_secondary} onPress={clearHistory} />
          </Flex>
          <ScrollView horizontal={true} showsHorizontalScrollIndicator={false} style={styles.history}>
            {_.map(history, (text, i) => (
              <TouchableOpacity
                key={i}
                style={styles.historyItem}
                onPress={() => search({text, orderBy: SearchOrderBy.Auto})}>
                <Text key={i} style={styles.historyText}>
                  {text}
                </Text>
              </TouchableOpacity>
            ))}
          </ScrollView>
        </View>
      )}
      <View style={styles.section}>
        <Text style={styles.sectionTitle}>口袋热搜</Text>
        <Flex wrap="wrap" style={styles.hotBody}>
          {_.map(hots, (text, i) => (
            <TouchableOpacity
              key={i}
              style={styles.hotItem}
              onPress={() => search({text, orderBy: SearchOrderBy.Auto})}>
              <Flex>
                <Text style={[styles.hotNo, i < 3 && styles.hotNoTop3]}>{i + 1}</Text>
                <Text style={styles.hotText}>{text}</Text>
              </Flex>
            </TouchableOpacity>
          ))}
        </Flex>
      </View>
    </View>
  );
};
export default SearchDefaultContent;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  section: {
    marginTop: 20,
    marginBottom: 9,
    paddingHorizontal: 20,
  },
  sectionTitle: {
    fontWeight: FontWeight.SemiBold,
    color: '#333',
  },
  history: {
    marginTop: 13,
  },
  historyItem: {
    paddingHorizontal: 4,
    paddingVertical: 5,
    height: 29,
    backgroundColor: '#F4F3F8',
    marginRight: 10,
    minWidth: 50,
    alignItems: 'center',
  },

  historyText: {
    fontSize: 13,
    lineHeight: 18,
  },

  hotBody: {
    marginTop: 10,
  },
  hotItem: {
    flex: 1,
    flexShrink: 0,
    flexBasis: '50%',
    marginBottom: 10,
  },
  hotNo: {
    fontSize: 12,
    color: '#999',
    marginRight: 5,
    minWidth: 11,
    textAlign: 'right',
  },
  hotNoTop3: {
    color: theme.brand_secondary,
  },
  hotText: {
    fontSize: 12,
    color: '#333',
    lineHeight: 18,
  },
});
