import {LoadingView} from '@/components';
import Empty from '@/components/Empty';
import PieceCard from '@/pages/piece/components/PieceCard';
import {useDispatch, useSelector} from '@/utils/dva';
import {useRequest} from 'ahooks';
import React from 'react';
import {FlatList, StyleSheet, View} from 'react-native';

const MyPiecesPage = ({navigation}) => {
  const initialData = useSelector(s => s.piece.myPieces);
  const dispatch = useDispatch();
  const {data, loading, run} = useRequest(() => dispatch({type: 'piece/getMyPieces'}), {
    initialData,
    formatResult: res => res?.success && res?.data,
  });

  return (
    <LoadingView loading={loading} layer={!!data?.length} style={styles.page}>
      {!loading && !data?.length && (
        <Empty
          text="暂时还没有碎片~去逛逛获取更多"
          actions={[
            {
              text: '去逛逛',
              onPress: () => navigation.navigate('Home', {screen: 'BoxCupboardTab'}),
            },
          ]}
        />
      )}
      <FlatList
        contentContainerStyle={styles.list}
        initialNumToRender={10}
        data={data}
        numColumns={2}
        renderItem={({item}) => (
          <View style={styles.column}>
            <PieceCard data={item} refreshList={() => run()} />
          </View>
        )}
        columnWrapperStyle={styles.row}
      />
    </LoadingView>
  );
};
const styles = StyleSheet.create({
  page: {
    flex: 1,
  },
  list: {
    paddingHorizontal: 12,
    paddingTop: 12,
    paddingBottom: 12 - 5,
  },
  row: {
    marginLeft: -5,
    marginBottom: 5,
  },
  column: {
    flex: 1,
    paddingLeft: 5,
    maxWidth: '50%',
    height: 253,
  },
});

MyPiecesPage.screenName = 'MyPieces';
MyPiecesPage.screenOptions = {
  title: '碎片仓',
};
MyPiecesPage.linking = {
  path: 'my_pieces',
};
export default MyPiecesPage;
