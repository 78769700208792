import {getActivityPopup} from '@/services/activity_popup';
import dayjs from 'dayjs';
import dayOfYear from 'dayjs/plugin/dayOfYear';
import weekOfYear from 'dayjs/plugin/weekOfYear';

dayjs.extend(dayOfYear);
dayjs.extend(weekOfYear);

/**
 * 活动弹窗管理
 * */
export const namespace = 'activity_popup';
const model = {
  namespace,
  state: {},
  effects: {
    /**
     * 获取活动弹窗
     * actionParam: trigger 弹窗触发点
     * */
    *getActivityPopup({trigger}, {call, put, select}) {
      const ignore = {success: false, data: null};
      const lastPopup = yield select(s => s[namespace][trigger]); // 历史弹窗
      if (
        lastPopup &&
        dayjs().year() === dayjs(lastPopup?.checkTime).year() &&
        dayjs().dayOfYear() === dayjs(lastPopup?.checkTime).dayOfYear()
      ) {
        // 为了减少请求次数，若当天已经弹窗过了，不在继续发送请求
        return ignore;
      }
      const res = yield call(getActivityPopup, trigger);
      if (res?.success && res?.data) {
        const thisPopup = res.data; // 当前弹窗
        const checkTime = (thisPopup.checkTime = Date.now());
        // 1.与历史数据比较判断是否需要弹窗
        if (lastPopup && lastPopup.id === thisPopup?.id) {
          const {gap} = thisPopup || {};
          if (gap === 'once') {
            // 一次性弹窗
            return ignore;
          } else if (gap === 'daily') {
            // 每日弹窗
            if (
              dayjs(checkTime).year() === dayjs(lastPopup?.checkTime).year() &&
              dayjs(checkTime).dayOfYear() === dayjs(lastPopup?.checkTime).dayOfYear()
            ) {
              return ignore;
            }
          } else if (gap === 'weekly') {
            // 每周弹窗
            if (
              dayjs(checkTime).year() === dayjs(lastPopup?.checkTime).year() &&
              dayjs(checkTime).week() === dayjs(lastPopup?.checkTime).week()
            ) {
              return ignore;
            }
          } else if (gap === 'monthly') {
            // 每月弹窗
            if (
              dayjs(checkTime).year() === dayjs(lastPopup?.checkTime).year() &&
              dayjs(checkTime).month() === dayjs(lastPopup?.checkTime).month()
            ) {
              return ignore;
            }
          } else if (gap && parseFloat(gap) > 0) {
            // 最小间隔天数
            if (dayjs(checkTime).isBefore(dayjs(lastPopup?.checkTime + parseFloat(gap) * 24 * 60 * 60 * 1000))) {
              return ignore;
            }
          }
        }
        // 2.保存当前数据为历史数据
        yield put({type: 'saveState', [trigger]: thisPopup});
        return res;
      }
      return ignore;
    },
  },
  reducers: {
    saveState(state, action) {
      const {type, ...nextState} = action || {};
      return {...state, ...nextState};
    },
  },
};

export default model;
