import Flex from '@/components/Flex';
import Loading from '@/components/Loading';
import CouponItem from '@/pages/coupon/components/CouponItem';
import {useDispatch, useSelector} from '@/utils/dva';
import {useMount, useRequest} from 'ahooks';
import React from 'react';
import {FlatList, StyleSheet, View} from 'react-native';

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});

const HistoryList = ({status}) => {
  const data = useSelector(s => s.coupon[status]);
  const dispatch = useDispatch();
  const {loading, run: getPagedData} = useRequest(() => dispatch({type: 'coupon/getCoupons', status}), {
    manual: true,
  });

  useMount(() => {
    if (!data?.length) {
      getPagedData();
    }
  });

  return (
    <View style={styles.container}>
      <FlatList
        contentContainerStyle={{paddingBottom: 20}}
        data={data}
        initialNumToRender={10}
        renderItem={item => (
          <Flex justify="center">
            <CouponItem data={item} />
          </Flex>
        )}
        ListFooterComponent={loading && <Loading />}
      />
    </View>
  );
};

export default HistoryList;
