import {Dialog, Link, LoadingView} from '@/components';
import {Flex} from '@/components/Flex';
import List from '@/components/List';
import QQApi from '@/NativeModules/QQ';
import WXApi from '@/NativeModules/Wechat';
import theme from '@/theme';
import {useDispatch} from '@/utils/dva';
import useNestingBackButton from '@/utils/hooks/useNestingBackButton';
import {isAndroid, isIos, isNative, isWeb} from '@/utils/utils';
import {useRequest} from 'ahooks';
import React, {useCallback} from 'react';
import {Platform, ScrollView, StyleSheet, Text, View} from 'react-native';
import _ from 'lodash';
import useLov from '@/utils/hooks/useLov';
import AppleApi from '@/NativeModules/Apple';

const {Item} = List;
const itemProps = {
  offset: [0, 16],
};
const _is_on_off_for_platform = (lovs, type) => {
  if (lovs?.hasOwnProperty(type)) {
    return _.includes(String(lovs[type]).split('|'), Platform.OS);
  }
  return false;
};

const SecurityPage = ({navigation, currentUser}) => {
  useNestingBackButton();
  const loginTypeLovs = useLov('APP_LOGIN_TYPE');
  const isLoginTypeEnable = useCallback(type => _is_on_off_for_platform(loginTypeLovs, type), [loginTypeLovs]);
  const dispatch = useDispatch();
  const {loading: loading1, run: _bindWechat} = useRequest(code => dispatch({type: 'user/bindWechat', code}), {
    manual: true,
    onSuccess(res) {
      if (res?.success) {
        Dialog.success(res?.msg || '绑定成功');
      } else {
        Dialog.respError(res);
      }
    },
  });
  const {loading: loading2, run: unbindWechat} = useRequest(() => dispatch({type: 'user/unbindWechat'}), {
    manual: true,
    onSuccess(res) {
      if (res?.success) {
        Dialog.success(res?.msg || '解绑成功');
      } else {
        Dialog.respError(res);
      }
    },
  });
  const {loading: loading3, run: _bindQQ} = useRequest(
    (accessToken, openId) => dispatch({type: 'user/bindQQ', accessToken, openId}),
    {
      manual: true,
      onSuccess(res) {
        if (res?.success) {
          Dialog.success(res?.msg || '绑定成功');
        } else {
          Dialog.respError(res);
        }
      },
    },
  );
  const {loading: loading4, run: unbindQQ} = useRequest(() => dispatch({type: 'user/unbindQQ'}), {
    manual: true,
    onSuccess(res) {
      if (res?.success) {
        Dialog.success(res?.msg || '解绑成功');
      } else {
        Dialog.respError(res);
      }
    },
  });

  const {loading: loading5, run: _bindApple} = useRequest(
    (identityToken, userID) => dispatch({type: 'user/bindApple', identityToken, userID}),
    {
      manual: true,
      onSuccess(res) {
        if (res?.success) {
          Dialog.success(res?.msg || '绑定成功');
        } else {
          Dialog.respError(res);
        }
      },
    },
  );
  const {loading: loading6, run: unbindApple} = useRequest(() => dispatch({type: 'user/unbindApple'}), {
    manual: true,
    onSuccess(res) {
      if (res?.success) {
        Dialog.success(res?.msg || '解绑成功');
      } else {
        Dialog.respError(res);
      }
    },
  });

  const bindWechat = async () => {
    try {
      const supported = await WXApi.isSupported();
      if (supported) {
        const apiRes = await WXApi.auth(null);
        const {code} = apiRes || {};
        if (code) {
          await _bindWechat(code);
        }
      } else {
        Dialog.alert('', '请先安装微信APP');
      }
    } catch (err) {}
  };

  const bindQQ = async () => {
    try {
      const apiRes = await QQApi.auth(null);
      const {access_token, openid} = apiRes || {};
      if (access_token && openid) {
        await _bindQQ(access_token, openid);
      }
    } catch (err) {}
  };

  const bindApple = async () => {
    try {
      const supported = await AppleApi.isSupported();
      if (supported) {
        const apiRes = await AppleApi.auth();
        const {identityToken, userID} = apiRes || {};
        if (identityToken && userID) {
          await _bindApple(identityToken, userID);
        }
      } else {
        Dialog.alert('', '不支持当前系统版本');
      }
    } catch (err) {}
  };

  const {mobile, openId: wechat, qqOpenId: qq, appleUserId: apple, wxNickName, qqNickName} = currentUser || {};

  const isWechatEnable = isLoginTypeEnable('wechat');
  const isQQEnable = isLoginTypeEnable('qq');
  const isAppleEnable = isLoginTypeEnable('apple');
  return (
    <LoadingView
      loading={loading1 || loading2 || loading3 || loading4 || loading5 || loading6}
      layer={true}
      style={styles.page}>
      <ScrollView>
        <List border={false}>
          <Item {...itemProps} extra={<Text>{mobile}</Text>}>
            手机号
          </Item>

          {(isWeb() || isWechatEnable) && (
            <Item
              {...itemProps}
              extra={
                wechat ? (
                  <View>
                    <Flex>
                      <Text style={styles.bindText}>{wxNickName}</Text>
                      {isNative() && isWechatEnable && (
                        <Link style={styles.bindLink} onPress={bindWechat}>
                          更改
                        </Link>
                      )}
                      <Link style={styles.bindLink} onPress={unbindWechat}>
                        解绑
                      </Link>
                    </Flex>
                  </View>
                ) : isNative() && isWechatEnable ? (
                  <Link style={styles.bindLink} onPress={bindWechat}>
                    去绑定
                  </Link>
                ) : (
                  <Text style={styles.unbindText}>未绑定</Text>
                )
              }>
              微信账号
            </Item>
          )}

          {(isWeb() || isQQEnable) && (
            <Item
              {...itemProps}
              extra={
                qq ? (
                  <View>
                    <Flex>
                      <Text style={styles.bindText}>{qqNickName}</Text>
                      {isNative() && isQQEnable && (
                        <Link style={styles.bindLink} onPress={bindQQ}>
                          更改
                        </Link>
                      )}
                      <Link style={styles.bindLink} onPress={unbindQQ}>
                        解绑
                      </Link>
                    </Flex>
                  </View>
                ) : isNative() && isQQEnable ? (
                  <Link style={styles.bindLink} onPress={bindQQ}>
                    去绑定
                  </Link>
                ) : (
                  <Text style={styles.unbindText}>未绑定</Text>
                )
              }>
              QQ 账号
            </Item>
          )}

          {(!isAndroid() || isAppleEnable) && (
            <Item
              {...itemProps}
              extra={
                apple ? (
                  <View>
                    <Flex>
                      <Text style={styles.bindText}>{apple}</Text>
                      {isIos() && isAppleEnable && (
                        <Link style={styles.bindLink} onPress={bindApple}>
                          更改
                        </Link>
                      )}
                      <Link style={styles.bindLink} onPress={unbindApple}>
                        解绑
                      </Link>
                    </Flex>
                  </View>
                ) : isIos() && isAppleEnable ? (
                  <Link style={styles.bindLink} onPress={bindApple}>
                    去绑定
                  </Link>
                ) : (
                  <Text style={styles.unbindText}>未绑定</Text>
                )
              }>
              Apple 账号
            </Item>
          )}
        </List>

        <List border={false} style={{marginTop: 10}}>
          <Item last={true} onPress={() => navigation.navigate('Logoff')}>
            <Flex align="center" justify="center">
              <Text style={{color: theme.brand_secondary}}>注销账户</Text>
            </Flex>
          </Item>
        </List>
      </ScrollView>
    </LoadingView>
  );
};
const styles = StyleSheet.create({
  page: {
    flex: 1,
  },
  bindText: {
    fontSize: 14,
    color: theme.color_text_base,
    flexShrink: 1,
    maxWidth: 190,
  },
  unbindText: {
    fontSize: 14,
    color: theme.color_text_secondary,
  },
  bindLink: {
    fontSize: 14,
    color: '#1C83F2',
    flexShrink: 0,
    marginLeft: 9,
  },
});

SecurityPage.screenName = 'Security';
SecurityPage.screenOptions = {
  title: '账户与安全',
};
SecurityPage.linking = 'security';
export default SecurityPage;
