import {Empty, LoadingView} from '@/components';
import CatalogSection from '@/pages/catalogs/components/CatalogSection';
import {useDispatch, useSelector} from '@/utils/dva';
import {useLinkTo} from '@react-navigation/native';
import {useRequest} from 'ahooks';
import React from 'react';
import {ScrollView} from 'react-native';

const CatalogContent = ({catalogId, scrollViewRef}) => {
  const linkTo = useLinkTo();
  const dispatch = useDispatch();
  const initialData = useSelector(s => s.catalogs[`data_${catalogId}`]);
  const {data, loading} = useRequest(() => dispatch({type: 'catalogs/getCatalogData', id: catalogId}), {
    initialData,
    ready: !!(catalogId && !initialData),
    refreshDeps: [catalogId],
    formatResult: res => res?.data,
  });

  return (
    <LoadingView loading={loading} style={{flex: 1}}>
      {data ? (
        <ScrollView ref={scrollViewRef}>
          <CatalogSection
            data={data?.smallCategoryList}
            title={catalogId === 1 ? '热门品类' : '品类'}
            onPressItem={item => item?.id && linkTo(`/product/list?categoryId=${item?.id}&title=${item?.name}`)}
          />
          <CatalogSection
            data={data?.brandList}
            title={catalogId === 1 ? '热门品牌' : '品牌'}
            onPressItem={item => item?.id && linkTo(`/product/list?brandId=${item?.id}&title=${item?.name}`)}
          />
        </ScrollView>
      ) : (
        <Empty style={{flex: 1}} />
      )}
    </LoadingView>
  );
};

export default CatalogContent;
