import Icon from '@/components/Icon';
import {isFunction, isString} from 'lodash';
import React, {useMemo, useState} from 'react';
import {Pressable, StyleSheet, Text, View} from 'react-native';
import _ from 'lodash';

const defaultProps = {
  size: 11,
};

const Checkbox = ({children, ...rest}) => {
  const {
    size,
    defaultChecked,
    checked: controlledChecked,
    indeterminate,
    disabled,
    onChange,
    style,
    hitSlop,
  } = Object.assign({}, defaultProps, rest);
  const state = useState(defaultChecked);
  const checked = controlledChecked ?? state[0];

  const toggle = () => {
    state[1](!checked);
    isFunction(onChange) && onChange(!checked);
  };

  const onPress = () => {
    !disabled && toggle();
  };

  const _hitSlop = useMemo(() => _.extend({bottom: 5, left: 8, right: 8, top: 5}, hitSlop), [hitSlop]);
  return (
    <Pressable onPress={onPress} disabled={disabled} style={[styles.container, style]} hitSlop={_hitSlop}>
      <View style={[styles.checkbox, children && styles.marginRight]}>
        {checked ? (
          <Icon type="checked" size={size} />
        ) : indeterminate ? (
          <Icon type="checked_circle" size={size} />
        ) : (
          <Icon type="unchecked" size={size} />
        )}
      </View>
      {isString(children) ? <Text>{children}</Text> : children}
    </Pressable>
  );
};

export default Checkbox;

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  checkbox: {},
  marginRight: {
    marginRight: 7,
  },
});
