import AddressListPage from '@/pages/address/list';
import useGlobalCallback from '@/utils/hooks/useGlobalCallback';
import {isIos} from '@/utils/utils';
import React from 'react';

const AddressPicker = props => {
  const {route, navigation} = props;
  const {id} = route?.params || {};
  const callbackRef = useGlobalCallback(id);
  const onPress = item => {
    callbackRef?.current?.callback?.({
      navigation,
      value: item,
    });
  };
  return React.createElement(AddressListPage, {...props, onPress});
};

AddressPicker.modalName = 'AddressPicker';
AddressPicker.screenOptions = {
  title: '选择地址',
  animation: 'slide_from_right',
  presentation: isIos() ? 'card' : 'fullScreenModal',
};
export default AddressPicker;
