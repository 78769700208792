import Button from '@/components/Button';
import {Flex} from '@/components/Flex';
import Modal from '@/components/Modal';
import ProdMeta from '@/pages/product/components/ProdMeta';
import theme from '@/theme';
import useBottomInset from '@/utils/hooks/useBottomInset';
import useControlledModalProps from '@/utils/hooks/useControlledModalProps';
import FontWeight from '@/utils/localData/FontWeight';
import {getPrice} from '@/utils/priceUtils';
import _, {isFunction} from 'lodash';
import React, {useState} from 'react';
import {FlatList, Image, Platform, StyleSheet, Text, TouchableOpacity, View} from 'react-native';

const PickProdsModal = ({data, defaultSelectKeys, onChange, ...rest}) => {
  const [, , , redress] = useBottomInset(10);
  const {visible, close, modalProps} = useControlledModalProps(rest);
  const [selectKeys, setSelectKeys] = useState(defaultSelectKeys ?? []);
  const handlePress = item => {
    if (_.some(selectKeys, key => key === item?.id)) {
      setSelectKeys(_.without(selectKeys, item?.id));
    } else {
      setSelectKeys(_.concat(selectKeys, item?.id));
    }
  };

  const handleConfirm = () => {
    isFunction(onChange) && onChange(selectKeys);
    close();
  };

  return (
    <Modal
      popup
      visible={visible}
      animationType="slide-up"
      maskClosable={false} // onClose={() => setVisible(false)}
      {...modalProps}
      style={[styles.modal, {paddingBottom: redress}]}>
      <View style={styles.container}>
        <View style={styles.content}>
          <FlatList
            contentContainerStyle={styles.list}
            data={data}
            renderItem={({item}) => {
              const {itemName, recycleRate} = item || {};
              const [price] = getPrice(item);
              const selected = _.some(selectKeys, _key => _key === item?.id);
              return (
                <TouchableOpacity
                  onPress={() => handlePress(item)}
                  style={[styles.card, selected && styles.selectedCard]}>
                  <ProdMeta
                    style={{flex: 1}}
                    data={item}
                    card={false}
                    thumb={(__, {style, image}) => (
                      <Image source={{uri: image}} style={[style, selected && {borderColor: '#DCDFE5'}]} />
                    )}
                    title={(__, {style}) => (
                      <Text numberOfLines={1} style={[style, styles.cardTitle]}>
                        {itemName}
                      </Text>
                    )}
                    brief={(__, {style}) => (
                      <Text style={[style, styles.cardBrief]}>官方回收返还{parseFloat(recycleRate).mul(100)}%</Text>
                    )}
                    content={(__, {styles: _styles}) => (
                      <Flex align="end" style={[_styles.price, styles.cardPrice]}>
                        <Text style={_styles.priceAmount}>{price?.amount}</Text>
                        <View style={{width: 6}} />
                        <Text style={_styles.priceUnit}>{price?.unit}</Text>
                      </Flex>
                    )}
                  />
                </TouchableOpacity>
              );
            }}
          />
        </View>
        <Flex style={[styles.toolbar, {marginLeft: -11}]}>
          <Button type="primary" fontWeight={FontWeight.SemiBold} style={[styles.toolbarButton]} onPress={close}>
            返回
          </Button>
          <Button
            type="primary"
            fontWeight={FontWeight.SemiBold}
            color={theme.brand_secondary}
            isLight={false}
            style={[styles.toolbarButton]}
            onPress={handleConfirm}>
            确认选择
          </Button>
        </Flex>
      </View>
    </Modal>
  );
};
const styles = StyleSheet.create({
  modal: {
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
    overflow: 'hidden',
  },
  container: {
    height: 436,
    backgroundColor: theme.fill_body,
    paddingBottom: 70,
  },
  close: {
    position: 'absolute',
    top: theme.h_spacing_lg - 4,
    right: theme.h_spacing_lg - 4,
    zIndex: 99,
  },
  content: {
    flex: 1,
    paddingHorizontal: 10,
  },
  list: {
    paddingTop: 20,
    padding: 0,
    paddingBottom: 10,
  },
  card: {
    borderRadius: 4,
    borderWidth: StyleSheet.hairlineWidth,
    borderColor: theme.border_color_base,
    padding: 10,
    backgroundColor: '#fff',
    marginBottom: 8,
  },
  selectedCard: {
    // borderWidth: 1,
    borderColor: '#FF3865',
    backgroundColor: 'rgba(255,56,100,0.1)',
  },
  cardTitle: {
    fontSize: 14,
    lineHeight: 20,
    marginTop: 7,
  },
  cardBrief: {
    color: theme.color_text_secondary,
    ...Platform.select({
      web: {
        fontSize: 12,
        lineHeight: 14,
        transformOrigin: 'left center',
        transform: [{scale: 0.833}],
      },
      default: {
        fontSize: 10,
        lineHeight: 14,
      },
    }),
  },
  cardPrice: {
    marginBottom: 7,
  },
  toolbar: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'transparent',
    paddingHorizontal: theme.h_spacing_lg,
    paddingVertical: theme.v_spacing_md,
    marginBottom: 10,
  },
  toolbarButton: {
    height: 48,
    marginLeft: 11,
    flex: 1,
    ...Platform.select({
      android: {
        elevation: 4,
      },
      ios: {
        shadowOpacity: 0.5,
        shadowRadius: 4,
        shadowColor: '#000',
        shadowOffset: {
          width: 0,
          height: 2,
        },
      },
      web: {
        boxShadow: '0 2px 4px 0 rgb(0, 0, 0, 50%)',
      },
    }),
  },
});

export default PickProdsModal;
