import OrderStatus from '@/utils/localData/OrderStatus';
import _, {find, isFunction} from 'lodash';
import React from 'react';
import AddressSection from './AddressSection';
import BillSection from './BillSection';
import InfoSection from './InfoSection';
import ItemsSection from './ItemsSection';
import RemarkSection from './RemarkSection';
import StatusSection from './StatusSection';

const allSections = [StatusSection, AddressSection, ItemsSection, BillSection, RemarkSection, InfoSection];
const defaultConfigs = [
  new StatusSection(), //
  new AddressSection({
    formatData: data => {
      const address = _.pick(data, ['province', 'city', 'area', 'address', 'name', 'receiver', 'mobile']);
      if (address?.addressId) {
        address.id = address.addressId;
      }
      if (address?.receiver) {
        address.name = address.receiver;
      }
      const editable = !!(data?.status === OrderStatus.Unpaid);
      return {
        editable,
        address,
        visible: !!data?.address || editable,
      };
    },
  }),
  new ItemsSection({
    formatData: data => data?.orderItemDtoList,
  }),
  new BillSection(),
  new RemarkSection({
    formatData: data => {
      const editable = !!(data?.status === OrderStatus.Unpaid);
      return {
        editable,
        remark: data?.remark,
        visible: !!data?.remark || editable,
      };
    },
  }),
  new InfoSection(),
];

const getSectionComponent = type => {
  return find(allSections, c => c.type === type)?.component;
};

const OrderSections = ({data, sections = defaultConfigs}) => {
  return (
    <>
      {sections.map((section, i) => {
        const {type, formatData} = section || {};
        section.data = isFunction(formatData) ? formatData(data, section) : data;
        const sectionProps = {
          key: `$section_${type}_${i}`,
          section,
          data,
        };
        const sectionComponent = getSectionComponent(type);
        if (sectionComponent) {
          return React.createElement(sectionComponent, sectionProps);
        }
      })}
    </>
  );
};

export default OrderSections;
