import {Flex} from '@/components';
import Icon from '@/components/Icon';
import ProductContext from '@/pages/product/detail/components/ProductContext';
import theme from '@/theme';
import _, {assign} from 'lodash';
import React, {useContext, useEffect} from 'react';
import {StyleSheet, Text, TouchableOpacity, View} from 'react-native';
import _styles from './style';

const Component = ({section, data}) => {
  const {standardName, skus} = data || {};
  const {state, setState, submitting, selectVariant} = useContext(ProductContext);
  const currentSku = _.find(skus, sku => sku?.id === state.skuId);

  useEffect(() => {
    if (skus?.length && !state?.skuId) {
      setState({skuId: _.first(skus)?.id});
    }
  }, [setState, skus, state?.skuId]);

  return (
    <TouchableOpacity
      style={[_styles.section, styles.container, section.style]}
      onPress={!submitting ? selectVariant : _.noop}>
      <Text style={styles.label}>{standardName ?? '规格'}</Text>
      <Flex justify="start" style={styles.content}>
        <Text style={styles.text}>
          已选 {currentSku?.standard}
          {state?.count > 1 && <Text style={[styles.text, {marginLeft: 20}]}>{`数量 × ${state.count}`}</Text>}
        </Text>
      </Flex>
      <View style={styles.iconWrap}>
        <Icon type="arrow_right" />
      </View>
    </TouchableOpacity>
  );
};
const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
  },
  label: {
    fontSize: 12,
    lineHeight: 30,
    color: theme.color_text_caption,
    minWidth: 46,
  },
  content: {
    flex: 1,
  },
  text: {
    fontSize: 12,
  },
  iconWrap: {
    width: 20,
    height: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: -5,
    marginVertical: 5,
  },
});

export default class VariantSection {
  static type = 'variant';
  static component = Component;
  style = {};

  constructor(config) {
    assign(this, config);
  }

  get type() {
    return this?.constructor?.type;
  }
}
