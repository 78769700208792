import {LoadingView} from '@/components';
import Button from '@/components/Button';
import {Flex} from '@/components/Flex';
import Icon, {IconButton} from '@/components/Icon';
import Modal from '@/components/Modal';
import ProductSections from '@/pages/product/detail/components/ProductSections';
import DetailSection from '@/pages/product/detail/components/ProductSections/DetailSection';
import theme from '@/theme';
import {useDispatch} from '@/utils/dva';
import useControlledModalProps from '@/utils/hooks/useControlledModalProps';
import {useRequest} from 'ahooks';
import _ from 'lodash';
import React from 'react';
import {ScrollView, StyleSheet, Text, View} from 'react-native';
import {useSafeAreaInsets} from 'react-native-safe-area-context';

const sections = [
  new DetailSection({
    showHeader: false,
    style: {
      padding: 0,
      marginTop: 0,
      paddingHorizontal: 0,
      paddingVertical: 0,
      marginBottom: 0,
    },
    formatData: data => _.compact(data?.descriptionList),
  }),
];

const BoxProdDetailModal = ({height, id, gotoMall, ...rest}) => {
  const {visible, close} = useControlledModalProps(rest);
  const {top} = useSafeAreaInsets();
  const dispatch = useDispatch();
  const {data, loading} = useRequest(() => dispatch({type: 'product/getProd', id}), {
    ready: !!id,
    formatResult: res => res?.data,
    refreshDeps: [id],
  });
  const {hidden = true} = data || {};

  return (
    <Modal
      popup
      visible={visible}
      animationType="slide-up"
      maskClosable={false} // onClose={() => setVisible(false)}
      style={[styles.modal]}>
      <LoadingView loading={loading} style={[styles.container, {height: (height ?? 0) - top - theme.header_height}]}>
        <Flex justify="center" style={styles.header}>
          {!hidden && (
            <Button size="small" style={styles.gotoMallBtn} onPress={gotoMall}>
              <Icon type="mall" style={{marginRight: 3}} />
              <Text>去商城查看</Text>
            </Button>
          )}
          <Text style={styles.title}>商品详情</Text>
          <IconButton type="close" size={12} onPress={close} style={styles.close} />
        </Flex>
        <View style={{flex: 1}}>
          <ScrollView>
            <ProductSections data={data} sections={sections} />
          </ScrollView>
        </View>
      </LoadingView>
    </Modal>
  );
};

export default BoxProdDetailModal;

const styles = StyleSheet.create({
  modal: {
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
    overflow: 'hidden',
  },
  container: {
    backgroundColor: theme.fill_base,
  },
  close: {
    position: 'absolute',
    top: theme.h_spacing_lg,
    right: theme.h_spacing_lg - 4,
    zIndex: 99,
  },
  gotoMallBtn: {
    height: 30,
    width: 92,
    borderRadius: 2,
    position: 'absolute',
    top: theme.h_spacing_lg - 4,
    left: theme.h_spacing_lg - 4,
  },
  header: {
    paddingVertical: 20,
    paddingHorizontal: theme.h_spacing_lg,
  },
  title: {},

  boxRarityBar: {
    paddingVertical: 6,
    paddingHorizontal: theme.h_spacing_lg,
  },
});
