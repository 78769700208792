import {TextareaItem} from '@/components/TextareaItem';
import OrderContext from '@/pages/order/detail/components/OrderContext';
import theme from '@/theme';
import FontWeight from '@/utils/localData/FontWeight';
import {assign} from 'lodash';
import React, {useCallback, useContext} from 'react';
import {Platform, StyleSheet, Text, View} from 'react-native';

export const DisplayRemark = ({editable, value, onChange, rows = 2, style}) => {
  return (
    <View style={[displayStyles.container, style]}>
      <Text style={displayStyles.title}>订单备注</Text>

      {editable ? (
        <TextareaItem
          value={value}
          onChange={onChange}
          last
          rows={rows}
          autoHeight
          placeholder="填写备注"
          style={[displayStyles.textarea, {minHeight: 17 * (rows ?? 2)}]}
        />
      ) : (
        <Text style={displayStyles.value}>{value}</Text>
      )}
    </View>
  );
};
const displayStyles = StyleSheet.create({
  container: {
    paddingVertical: 16,
    paddingHorizontal: 20,
    backgroundColor: '#fff',
    marginBottom: 10,
  },
  title: {
    fontSize: 12,
    fontWeight: FontWeight.Medium,
    lineHeight: 17,
    marginBottom: 13,
  },
  value: {
    fontSize: 12,
    lineHeight: 17,
    color: theme.color_text_secondary,
  },

  textarea: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingHorizontal: 0,
    fontSize: 12,
    lineHeight: 17,
    minHeight: 17 * 2,
    ...Platform.select({
      web: {},
      default: {height: 'auto'},
    }),
  },
});
export const InputRemark = ({value, onChange, rows = 4, style}) => {
  return (
    <View style={[inputStyles.container, style]}>
      <TextareaItem
        value={value}
        onChange={onChange}
        last
        rows={rows}
        placeholder="订单备注"
        autoHeight
        style={[inputStyles.textarea, {minHeight: 17 * (rows ?? 2)}]}
      />
    </View>
  );
};
const inputStyles = StyleSheet.create({
  container: {
    paddingVertical: 16,
    paddingHorizontal: 20,
    backgroundColor: '#fff',
    marginBottom: 10,
  },
  textarea: {
    paddingBottom: 0,
    paddingTop: 0,
    paddingHorizontal: 0,
    fontSize: 12,
    lineHeight: 17,
    minHeight: 17 * 2,
    ...Platform.select({
      web: {},
      default: {height: 'auto'},
    }),
  },
});

const Component = ({section}) => {
  const {state, setState} = useContext(OrderContext);
  const onChange = useCallback(remark => setState({remark}), [setState]);

  if (section?.mode === 'display') {
    const {visible = true, remark, editable} = section.data || {}; // for display mode only
    return visible ? (
      <DisplayRemark
        value={state?.remark ?? remark} //
        onChange={onChange}
        editable={editable}
        rows={section?.rows}
        style={section?.style}
      />
    ) : null;
  }
  if (section?.mode === 'input') {
    return (
      <InputRemark
        value={state?.remark} //
        onChange={onChange}
        rows={section?.rows}
        style={section?.style}
      />
    );
  }

  return null;
};

export default class RemarkSection {
  static type = 'remark';
  static component = Component;
  style = {};
  rows = 2;

  constructor(config) {
    assign(this, config);
  }

  get type() {
    return this?.constructor?.type;
  }

  mode = 'display'; // display or input
}
