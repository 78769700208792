import createMaterialTopTabNavigator from '@/components/material-top-tabs/navigators/createMaterialTopTabNavigator';
import MallTabBar from '@/pages/home/mall/components/MallTabBar';
import MallTabContent from '@/pages/home/mall/components/MallTabContent';
import FontWeight from '@/utils/localData/FontWeight';
import _ from 'lodash';
import React from 'react';
import {StyleSheet, Text} from 'react-native';

const Tab = createMaterialTopTabNavigator();
const tabComponents = {};
const getTabComponent = (key, _props) => {
  if (!tabComponents[key]) {
    tabComponents[key] = React.memo(props => <MallTabContent {...props} {..._props} tab={key} />);
  }
  return tabComponents[key];
};
const styles = StyleSheet.create({
  sceneContainerStyle: {
    backgroundColor: '#fff',
  },
  tabBarLabelStyle: {
    fontSize: 14,
  },
  activeTabBarLabelStyle: {
    fontSize: 18,
    fontWeight: FontWeight.SemiBold,
  },
});
const MallTabs = ({tabs}) => {
  return (
    <Tab.Navigator
      tabBar={MallTabBar}
      backBehavior="none"
      sceneContainerStyle={styles.sceneContainerStyle}
      screenOptions={{
        lazy: true,
        lazyPreloadDistance: 0,
        useNativePagedView: false,
      }}>
      {_.map(tabs, (tab, i) => {
        const {text, value} = tab || {};
        return (
          <Tab.Screen
            key={`$mallTab_${value ?? '_' + i}`}
            name={'Tab_' + value}
            options={{
              title: '口袋商城-' + text,
              tabBarLabel: ({focused, color}) => (
                <Text style={[styles.tabBarLabelStyle, focused && styles?.activeTabBarLabelStyle]}>{text}</Text>
              ),
            }}
            component={getTabComponent(value)}
          />
        );
      })}
    </Tab.Navigator>
  );
};

export default MallTabs;
