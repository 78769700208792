'use strict';

Object.defineProperty(exports, '__esModule', {
  value: true,
});
exports.default = void 0;
var _default = {
  year: '年',
  month: '月',
  day: '日',
  hour: '时',
  minute: '分',
  am: '上午',
  pm: '下午',
};
exports.default = _default;
