import {Dialog, LoadingView} from '@/components';
import {Flex} from '@/components/Flex';
import Stepper from '@/components/Stepper';
import PieceMeta from '@/pages/piece/components/PieceMeta';
import PieceProdList from '@/pages/piece/components/PieceProdList';
import OrderPayBar from '@/pages/piece/order/components/OrderPayBar';
import {useDispatch} from '@/utils/dva';
import {getDINProBoldStyle} from '@/utils/styleUtils';
import {useRequest} from 'ahooks';
import React, {useMemo, useState} from 'react';
import {StyleSheet, Text, View} from 'react-native';

const PieceOrderPage = ({navigation, route, currentUser}) => {
  const {id, data: initialData} = route?.params || {};
  const dispatch = useDispatch();
  const [count, setCount] = useState(1);
  const {data, loading} = useRequest(() => dispatch({type: 'piece/getPiece', id}), {
    ready: !!id,
    initialData,
    formatResult: res => res?.data,
  });

  const {loading: submitting, run: submit} = useRequest(
    params =>
      dispatch({
        type: 'piece/buy',
        data: {id, count},
      }),
    {
      manual: true,
      onSuccess(res) {
        if (res?.success) {
          if (res?.data?.id) {
            navigation.replace('Order', {
              screen: 'OrderDetail',
              params: {
                id: res?.data?.id,
              },
            });
          }
        } else {
          Dialog.respError(res);
        }
      },
    },
  );

  const ListHeaderComponent = useMemo(
    () => (
      <>
        <PieceMeta
          data={data}
          style={styles.piece}
          card={false}
          extra={<Stepper value={count} onChange={setCount} min={1} style={{transform: [{translateY: -6}]}} />}
        />
        <Flex justify="between" align="center" style={styles.wallet}>
          <Text style={styles.walletText}>我的口袋币</Text>
          <Text style={[styles.walletText, styles.walletAmount]}>{currentUser?.score ?? 0}</Text>
        </Flex>

        <View>
          <Text style={styles.alert}>{data?.description}</Text>
        </View>
        {!!data?.productList?.length && <PieceProdList.ListHead />}
      </>
    ),
    [count, currentUser?.score, data],
  );

  return (
    <LoadingView loading={loading} style={styles.page}>
      {!!data?.id && (
        <>
          <View style={{flex: 1}}>
            <PieceProdList data={data?.productList} ListHeaderComponent={ListHeaderComponent} />
          </View>
          <OrderPayBar price={data?.score * count} submit={submit} submitting={submitting} />
        </>
      )}
    </LoadingView>
  );
};
const styles = StyleSheet.create({
  page: {
    flex: 1,
  },
  piece: {
    backgroundColor: '#fff',
    paddingHorizontal: 20,
    paddingVertical: 10,
  },
  wallet: {
    backgroundColor: '#000',
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    height: 42,
    marginHorizontal: 10,
    marginBottom: 10,
    paddingHorizontal: 20,
  },
  walletText: {
    color: '#fff',
    fontSize: 14,
  },
  walletAmount: {
    ...getDINProBoldStyle(24),
    lineHeight: 30,
  },

  alert: {
    marginHorizontal: 10,
    paddingHorizontal: 20,
    paddingVertical: 10,
    borderRadius: 2,
    borderWidth: 1,
    borderColor: '#A5C0E0',
    backgroundColor: '#EFF2F8',
    color: '#333',
    lineHeight: 20,
    fontSize: 14,
    marginBottom: 10,
  },
});

PieceOrderPage.screenName = 'PieceOrder';
PieceOrderPage.screenOptions = {
  title: '确认购买',
};
PieceOrderPage.linking = {
  path: 'order/:id',
  parse: {
    id: Number,
  },
};
export default PieceOrderPage;
