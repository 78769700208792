import _ from 'lodash';
import {makeRemoteDataTools} from './index';

const key = 'BoxType';
const {
  defaultData: BoxType,
  getData: getBoxType,
  fetchData: fetchBoxType,
  hook: useBoxType,
} = makeRemoteDataTools(key, {
  service: async ({dispatch}) => {
    if (dispatch) {
      const lovs = await dispatch({type: 'lov/getByType', lovType: 'BOX_CATEGORY'});
      return _.map(lovs, o => ({
        key: o.name,
        text: o.value,
        value: o.value,
      }));
    }
  },
  // lovType: 'BOX_CATEGORY',
  // itemKey: 'id',
});

export default BoxType;

export {getBoxType, fetchBoxType, useBoxType};
