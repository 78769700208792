import request, {getPagedParams} from '@/utils/request';
import _ from 'lodash';

/**
 * 查询盲盒
 * */
export function getBoxes() {
  return request('/server/box/list', {
    method: 'GET',
  });
}

/**
 * 查询盲盒详情
 * @param id: 盲盒id
 * */
export function getBox(id) {
  return request(`/server/box/${id}`, {
    method: 'GET',
  });
}

/**
 * 查询盲盒详情
 * @param boxId: 盲盒id
 * */
export function getRecommendBoxes(boxId) {
  return request('/server/box/recommend', {
    method: 'GET',
    params: {
      boxId,
    },
  });
}

/**
 * 购买接口
 * @param data: {
 *   id: 盲盒id
 *   count: 数量
 *   payType: 支付方式
 * }
 * @resp: order
 * */
export function buy(data) {
  return request('/server/order/box', {
    method: 'POST',
    data: {
      ..._.pick(data, ['id', 'count']),
      orderPayType: data?.payType,
    },
  });
}

/**
 * 开盒结果查询（需要轮询直到返回成功）
 * @param orderId
 * @resp: boxProds
 * */
export function getResult(orderId) {
  return request(`/server/order/getBoxProduct/${orderId}`, {
    method: 'GET',
  });
}

/**
 * 盲盒回收接口
 * @param data: {
 *  ids: [],
 *  toolId,
 * }
 * */
export function recovery(data) {
  return request('/server/order/recycleBoxProduct', {
    method: 'POST',
    data: {
      itemIds: data?.ids,
      userCouponId: data?.toolId,
    },
  });
}

/**
 * 提货
 * @param data: {
 *    items,
 *    payType,
 * }
 * */
export function pickUp(data) {
  return request('/server/order/delivery', {
    method: 'POST',
    data: {
      items: _.map(data?.items, it => _.pick(it, ['id', 'skuId'])),
      orderPayType: data?.payType,
    },
  });
}

/**
 * 查询提货确认订单信息
 * @param id: 确认订单的id
 * */
export function getPickUpOrder(id) {
  return request('/server/box/getPickUpOrder', {
    method: 'GET',
    params: {
      id,
    },
  });
}

/**
 * 获取全网开盒滚动通知数据
 * */
export async function getRemarnd() {
  return request('/server/box/saleList', {
    method: 'GET',
  });
}

/**
 * 获取全网开盒滚动通知数据
 * */
export async function getRollingNotices() {
  return request('/server/box/saleList', {
    method: 'GET',
  });
}

/**
 * 分页查询开盒记录
 * @param params: {
 *     current,
 *     pageSize,
 *     status: 状态，见BoxProdStatus.js
 *     rarity: 稀有度
 * }
 * */
export function getPagedOpendBoxes(params) {
  return request('/server/box/userBoxList', {
    method: 'GET',
    params: getPagedParams({
      ...params,
      style: params?.rarity,
    }),
  });
}
