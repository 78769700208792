import Icon from '@/components/Icon';
import Modal from '@/components/Modal';
import _, {isFunction} from 'lodash';
import React, {isValidElement, useState} from 'react';
import {Pressable, StyleSheet, Text, View} from 'react-native';

const styles = StyleSheet.create({
  option: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    paddingHorizontal: 15,
  },
  optionText: {
    // color: 'red',
  },
  optionIcon: {},
});
/*
 * children: (value, selectedOption)=> dom
 * renderOption: (option, value)=> dom // 自定义选项
 * renderText: (option, value)=> dom // 自定义选项的文字
 * */
const Selector = ({style, value, defaultValue, options, renderOption, renderText, children, onChange}) => {
  const [_value, setValue] = useState(value ?? defaultValue);

  const handleChange = value => {
    if (value !== _value) {
      setValue(value);
      isFunction(onChange) && onChange(value);
    }
  };

  const __value = value ?? _value;
  const handlePress = () => {
    Modal.operation(
      _.map(options, option => ({
        text: renderOption ? (
          renderOption(option, __value)
        ) : (
          <View style={styles.option}>
            {renderText ? (
              renderText(option, __value, <Text style={styles.optionText}>{option.text}</Text>)
            ) : (
              <Text style={styles.optionText}>{option.text}</Text>
            )}
            <Icon style={styles.optionIcon} type={__value === option.value ? 'checked' : 'unchecked'} size={15} />
          </View>
        ),
        onPress: () => handleChange(option.value),
      })),
    );
  };
  return (
    <Pressable onPress={handlePress} style={style}>
      {isFunction(children) &&
        children(
          __value,
          _.find(options, o => o.value === __value),
          handlePress,
        )}
      {isValidElement(children) && children}
    </Pressable>
  );
};

export default Selector;
