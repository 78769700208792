import request from '@/utils/request';

/**
 * 获取文档内容
 * type: 文档唯一Key
 * */
export function getDocument(type) {
  return request(`/server/system/document/${type}`, {
    method: 'GET',
  });
}
