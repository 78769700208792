import theme from '@/theme';
import {StyleSheet} from 'react-native';

export default StyleSheet.create({
  section: {
    paddingHorizontal: theme.h_spacing_lg,
    paddingVertical: theme.v_spacing_md,
    borderTopWidth: StyleSheet.hairlineWidth,
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderColor: theme.border_color_base,
    backgroundColor: '#fff',
    marginTop: -StyleSheet.hairlineWidth,
    marginBottom: theme.v_spacing_md,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});
