import BoxRecordsStatus from '@/utils/localData/BoxRecordStatus';
import OrderStatus from '@/utils/localData/OrderStatus';
import request from '@/utils/request';
import {isWeb} from '@/utils/utils';
import _ from 'lodash';

/**
 * 获取当前登录用户接口
 * */
export function getCurrentUser() {
  return request('/server/open/getUserInfo', {
    method: 'GET',
    authentic: false,
  }).then(res => {
    if (res?.success) {
      const {ticket, user} = res.data;
      res.data = _.extend(user, {ticket});
    }
    return res;
  });
}

/**
 * 查询余额以及订单统计数据
 * @resp: {
 *   completeCount: "0"
 *   deliveryCount: "0"
 *   fragmentCount: 2920
 *   paidCount: "67"
 *   saleOutCount: "0"
 *   unPaidCount: "94"
 *   userScore: 87108
 * }
 * */
export function getUserOrderStatistics() {
  return request('/server/user/index', {
    method: 'GET',
    authentic: false,
  }).then(res => {
    if (res?.success) {
      const data = _.mapValues(res.data, parseInt);
      const {unPaidCount, paidCount, deliveryCount, completeCount, saleOutCount, userScore, fragmentCount} = data || {};
      res.data = {
        score: userScore,
        fragmentCount,
        orderCounts: {
          [OrderStatus.Unpaid]: unPaidCount,
          [OrderStatus.Paid]: paidCount,
          [OrderStatus.Delivered]: deliveryCount,
          [OrderStatus.Completed]: completeCount,
          [OrderStatus.AfterSale]: saleOutCount,
        },
      };
    }
    return res;
  });
}

/**
 * 查询余额以及订单统计数据
 * @resp: {
 *   completeCount: "0"
 *   deliveryCount: "0"
 *   fragmentCount: 2920
 *   paidCount: "0"
 *   recycleCount: "0"
 *   userScore: 6540
 * }
 * */
export function getUserBoxStatistics() {
  return request('/server/box/index', {
    method: 'GET',
    authentic: false,
  }).then(res => {
    if (res?.success) {
      const data = _.mapValues(res.data, parseInt);
      const {paidCount, deliveryCount, completeCount, recycleCount, userScore, fragmentCount} = data || {};
      res.data = {
        score: userScore,
        fragmentCount,
        boxCounts: {
          [BoxRecordsStatus.Paid]: paidCount,
          [BoxRecordsStatus.Delivered]: deliveryCount,
          [BoxRecordsStatus.Completed]: completeCount,
          [BoxRecordsStatus.Recycled]: recycleCount,
        },
      };
    }
    return res;
  });
}

/**
 * 获取验证码接口
 * @param params: {phone, code, ...}
 * */
export function getCaptcha({phone, code}) {
  return request('/server/open/sendCheckCode', {
    method: 'GET',
    params: {
      mobile: phone,
      code,
    },
  });
}

/**
 * 文件上传接口
 * @param file: 原始file对象
 * */
export function fileUpload(file) {
  const formData = new FormData();
  formData.append('file', file);
  return request('/server/system/file/upload', {
    method: 'POST',
    body: formData,
    useFormData: true,
  });
}

/**
 * 询问是否触发任务
 * @actionParam trigger: 促发点，box
 * @actionParam triggerId: 促发点实例ID，boxID
 * */
export function askTask(trigger, triggerId) {
  return request('/server/system/task', {
    method: 'GET',
    params: {
      point: trigger,
      id: triggerId,
    },
  });
}

export function getChannelVersion(channel, versionCode) {
  return request('/server/open/getChannelVersion', {
    method: 'GET',
    params: {
      channelNumber: channel,
      versionCode,
    },
  }).then(res => {
    if (res?.success && res?.data?.versionCode && isWeb()) {
      res.data.forceUpdate = false;
      res.data.notice = true;
    }
    return res;
  });
}
