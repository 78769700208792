import {Dialog} from '@/components';
import RmbPayType from '@/utils/localData/RmbPayType';
import {isIosWeb} from '@/utils/utils';

export default (data, resolve, reject) => {
  try {
    const openPayPage = () => {
      const dom = document.createElement('div');
      dom.innerHTML = data;
      const form = dom.children[0];
      form.setAttribute('target', '_blank');
      document.body.appendChild(dom);
      form.submit();
      setTimeout(() => {
        document.body.removeChild(dom);
      }, 1000);

      resolve(
        Promise.resolve({
          paid: true,
          canceled: false,
          memo: '',
        }),
      );
    };
    const cancel = () => {
      resolve(
        Promise.resolve({
          paid: false,
          canceled: true,
          memo: '',
        }),
      );
    };
    if (isIosWeb()) {
      Dialog.confirm(RmbPayType._Alipay.text, '立即跳转支付页面？', ok => {
        if (ok) {
          openPayPage();
        } else {
          cancel();
        }
      });
    } else {
      openPayPage();
    }
  } catch (err) {
    reject(err);
  }
};
