import Button from '@/components/Button';
import Empty from '@/components/Empty';
import {Flex} from '@/components/Flex';
import Modal from '@/components/Modal';
import ToolList from '@/pages/tools/components/ToolList';
import theme from '@/theme';
import useBottomInset from '@/utils/hooks/useBottomInset';
import useControlledModalProps from '@/utils/hooks/useControlledModalProps';
import FontWeight from '@/utils/localData/FontWeight';
import ToolStatus from '@/utils/localData/ToolStatus';
import {isFunction} from 'lodash';
import React, {useState} from 'react';
import {Platform, StyleSheet, View} from 'react-native';

const PickToolModal = ({defaultCurrent, onChange, ...rest}) => {
  const {visible, close, modalProps} = useControlledModalProps(rest);
  const [current, setCurrent] = useState(defaultCurrent);

  const handlePress = item => {
    setCurrent(item);
  };

  const handleConfirm = () => {
    isFunction(onChange) && onChange(current);
    close();
  };

  const [, , , redress] = useBottomInset(10);
  return (
    <Modal
      popup
      visible={visible}
      animationType="slide-up"
      maskClosable={false} // onClose={() => setVisible(false)}
      {...modalProps}
      style={[styles.modal, {paddingBottom: redress}]}>
      <View style={styles.container}>
        <View style={styles.content}>
          <ToolList
            current={current}
            status={ToolStatus.Valid}
            contentContainerStyle={styles.list}
            onPress={handlePress}
            EmptyComponent={<Empty text="暂时还没有道具~" style={{paddingBottom: 0}} />}
          />
        </View>
        <Flex style={[styles.toolbar, {marginLeft: -11}]}>
          <Button type="primary" fontWeight={FontWeight.SemiBold} style={[styles.toolbarButton]} onPress={close}>
            返回
          </Button>
          <Button
            type="primary"
            fontWeight={FontWeight.SemiBold}
            color={theme.brand_secondary}
            isLight={false}
            style={[styles.toolbarButton]}
            onPress={handleConfirm}>
            确认选择
          </Button>
        </Flex>
      </View>
    </Modal>
  );
};
const styles = StyleSheet.create({
  modal: {
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
    overflow: 'hidden',
  },
  container: {
    height: 436,
    backgroundColor: '#fff',
    paddingBottom: 70,
  },
  close: {
    position: 'absolute',
    top: theme.h_spacing_lg - 4,
    right: theme.h_spacing_lg - 4,
    zIndex: 99,
  },
  header: {
    paddingVertical: 16,
    paddingHorizontal: theme.h_spacing_lg,
  },
  title: {},
  content: {
    flex: 1,
    paddingHorizontal: theme.h_spacing_lg,
  },
  list: {
    paddingTop: 20,
    padding: 0,
    paddingBottom: 10,
  },
  toolbar: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'transparent',
    paddingHorizontal: theme.h_spacing_lg,
    paddingVertical: theme.v_spacing_md,
    marginBottom: 10,
  },
  toolbarButton: {
    height: 48,
    marginLeft: 11,
    flex: 1,
    ...Platform.select({
      android: {
        elevation: 4,
      },
      ios: {
        shadowOpacity: 0.5,
        shadowRadius: 4,
        shadowColor: '#000',
        shadowOffset: {
          width: 0,
          height: 2,
        },
      },
      web: {
        boxShadow: '0 2px 4px 0 rgb(0, 0, 0, 50%)',
      },
    }),
  },
});

export default PickToolModal;
