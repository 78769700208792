import theme from '@/theme';
import FontWeight from '@/utils/localData/FontWeight';
import _, {isFunction, isString} from 'lodash';
import React, {useCallback, useEffect, useRef} from 'react';
import {Animated, Pressable, ScrollView, StyleSheet, View} from 'react-native';

const MallTabBar = props => {
  const {descriptors, state} = props || {};
  const {routes} = state || {};
  const scrollView = useRef();
  const itemLayouts = useRef({}).current;

  const scrollTo = useCallback(
    index => {
      const targetItemLayout = itemLayouts[index];
      const containerLayout = itemLayouts.container;
      if (targetItemLayout && containerLayout) {
        if (!targetItemLayout._left) {
          targetItemLayout._left = _.reduce(_.range(index), (v, i) => v + itemLayouts[i].width, 0);
        }
        const x = targetItemLayout._left + targetItemLayout.width / 2 - containerLayout.width / 2;
        scrollView.current?.scrollTo?.({x, animated: true});
      }
    },
    [itemLayouts],
  );
  useEffect(() => {
    state && scrollTo(state.index);
  }, [scrollTo, state]);

  if (!routes?.length) {
    return null;
  }

  return (
    <View style={styles.tabBar}>
      <ScrollView
        ref={scrollView}
        horizontal={true}
        style={styles.tabBarContainer}
        showsHorizontalScrollIndicator={false}
        onLayout={evt => _.extend(itemLayouts, {container: evt.nativeEvent.layout})}>
        {_.map(descriptors, ({options, navigation, route} = {}, key) => {
          const tabIndex = _.findIndex(routes, r => r.key === route.key);
          const focused = navigation.isFocused();
          const {tabBarLabel} = options || {};
          return (
            <Pressable key={key} onPress={e => navigation.jumpTo(route.name)}>
              <View
                style={[
                  styles.tabBarItem,
                  tabIndex === 0 && {paddingLeft: 0},
                  tabIndex === routes.length - 1 && {paddingRight: 0},
                ]}
                onLayout={evt =>
                  _.extend(itemLayouts, {
                    [tabIndex]: evt.nativeEvent.layout,
                  })
                }>
                {isFunction(tabBarLabel) ? (
                  tabBarLabel({focused})
                ) : isString(tabBarLabel) ? (
                  <Animated.Text style={[styles.tabBarLabel, focused && styles?.activeTabBarLabel, {}]}>
                    {tabBarLabel}
                  </Animated.Text>
                ) : (
                  tabBarLabel
                )}
              </View>
            </Pressable>
          );
        })}
      </ScrollView>
    </View>
  );
};

export default MallTabBar;

const styles = StyleSheet.create({
  tabBar: {
    backgroundColor: '#fff',
  },
  tabBarContainer: {
    marginHorizontal: theme.h_spacing_lg,
  },
  tabBarItem: {
    fontSize: 14,
    height: 50,
    paddingVertical: theme.h_spacing_md,
    paddingHorizontal: theme.h_spacing_md,
    width: 'auto',
    justifyContent: 'center',
    alignItems: 'center',
  },
  tabBarLabel: {
    // marginLeft: styles.h_spacing_lg,
    fontSize: 14,
  },
  activeTabBarLabel: {
    fontSize: 18,
    fontWeight: FontWeight.SemiBold,
  },
});
