import _ from 'lodash';
import {getHots, search} from './service';

export const namespace = 'search';
const model = {
  namespace,
  state: {
    defaultText: '',
    history: [],
    hots: [], // 热搜
  },
  effects: {
    /**
     * 搜索接口
     * @actionParam params: {
     *   current: 页码
     *   pageSize,
     *   text: 输入文字
     *   orderBy: 排序
     *   brandId: 品牌id
     *   categoryId: 品类id
     * }
     * */
    *search({params}, {call, put}) {
      const res = yield call(search, params);
      if (params?.text) {
        yield put({type: 'addToHistory', text: params.text});
      }
      return res;
    },

    /**
     * 查询热搜
     * */
    *getHots(__, {call, put}) {
      const res = yield call(getHots);
      if (res?.success) {
        yield put({type: 'saveState', hots: res.data, defaultText: _.first(res.data || [])});
      }
      return res;
    },
  },
  reducers: {
    saveState(state, action) {
      const {type, ...nextState} = action || {};
      return {...state, ...nextState};
    },
    /**
     * 添加到历史记录
     * */
    addToHistory(state, action) {
      return {...state, history: _.chain([]).concat(action.text, state.history).uniq().value()};
    },
    /**
     * 清除历史记录
     * */
    clearHistory(state) {
      return {...state, history: []};
    },
  },
};

export default model;
