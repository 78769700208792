import {Dialog} from '@/components';
import Button from '@/components/Button';
import {Center, Flex} from '@/components/Flex';
import Modal from '@/components/Modal';
import TextareaItem from '@/components/TextareaItem';
import theme from '@/theme';
import {useDispatch} from '@/utils/dva';
import useControlledModalProps from '@/utils/hooks/useControlledModalProps';
import FontWeight from '@/utils/localData/FontWeight';
import {useRequest} from 'ahooks';
import _ from 'lodash';
import React, {useState} from 'react';
import {StyleSheet} from 'react-native';

/**
 * 口令兑换碎片
 * */
const PieceConvertModal = ({onSuccess, ...rest}) => {
  const {visible, close, modalProps} = useControlledModalProps(rest);
  const [token, setToken] = useState('');
  const dispatch = useDispatch();
  const {loading, run: submit} = useRequest(() => dispatch({type: 'piece/convert', token}), {
    ready: !!token,
    manual: true,
    onSuccess(res) {
      _.isFunction(onSuccess) && onSuccess(res);
      if (res?.success) {
        Dialog.success(res.msg ?? '兑换成功');
        close();
      } else {
        Dialog.respError(res);
      }
    },
  });

  const handleSubmit = () => {
    if (!token) {
      return Dialog.info('请输入口令');
    }
    submit();
  };

  return (
    <Modal
      visible={visible}
      onClose={close}
      title="请输入口令"
      closable={true}
      transparent={true}
      maskClosable={true}
      {...modalProps}
      style={styles.container}
      bodyStyle={styles.bodyStyle}>
      <Flex direction="column" align="center">
        <TextareaItem
          value={token}
          onChange={setToken}
          placeholder="请输入口令"
          rows={2}
          last
          style={styles.tokenInput}
        />
      </Flex>
      <Center direction="column">
        <Button
          type="primary"
          loading={loading}
          style={styles.btn}
          fontWeight={FontWeight.SemiBold}
          onPress={handleSubmit}>
          确定
        </Button>
      </Center>
    </Modal>
  );
};

export default PieceConvertModal;

const styles = StyleSheet.create({
  container: {
    paddingTop: 30,
  },
  bodyStyle: {
    marginTop: 12,
    paddingBottom: 28,
    borderBottomWidth: 0,
  },
  messageText: {
    fontSize: 14,
  },
  tokenInput: {
    width: 250,
    height: 70,
    backgroundColor: theme.fill_body,
    borderRadius: 6,
    marginTop: 11,
    marginBottom: 26,
    paddingVertical: 12,
    paddingLeft: 20,
    paddingRight: 20,
    lineHeight: 20,
  },
  tokenText: {
    fontSize: 14,
    lineHeight: 20,
    color: theme.color_text_secondary,
  },
  btn: {
    width: 162,
    height: 48,
  },
});
