import {LoadingView} from '@/components';
import SearchResult from '@/pages/search/conpoments/SearchResult';
import {useDispatch} from '@/utils/dva';
import usePagedRequest from '@/utils/hooks/usePagedRequest';
import SearchOrderBy from '@/utils/localData/SearchOrderBy';
import {useFocusEffect} from '@react-navigation/native';
import {useSetState} from 'ahooks';
import React from 'react';
import {StyleSheet} from 'react-native';

const ProductListPage = ({navigation, route}) => {
  const params = route?.params || {};

  useFocusEffect(() => {
    navigation.setOptions({
      title: params?.title,
    });
  });

  const dispatch = useDispatch();
  const [state, setState] = useSetState({orderBy: SearchOrderBy.Auto});

  const {
    data,
    load,
    loading,
    hasMore,
    loadMore: _loadMore,
    loadingMore,
  } = usePagedRequest(
    ({current, pageSize}, _params) => {
      return dispatch({
        type: 'search/search',
        params: {
          ...params,
          ..._params,
          current,
          pageSize,
        },
      });
    },
    {
      defaultParams: [{...params, ...state}],
    },
  );

  const search = params => {
    const newState = {...state, ...params};
    setState(newState);
    return load(newState);
  };

  const loadMore = () => _loadMore(state);

  return (
    <LoadingView loading={loading} layer={!!data?.length} style={styles.page}>
      <SearchResult
        state={state}
        search={search}
        data={data}
        hasMore={hasMore}
        loadMore={loadMore}
        loadingMore={loadingMore}
      />
    </LoadingView>
  );
};
const styles = StyleSheet.create({
  page: {
    flex: 1,
    backgroundColor: '#fff',
  },
});

ProductListPage.screenName = 'ProductList';
ProductListPage.screenOptions = {
  title: '商品列表',
  // headerTitle: () => null,
};
ProductListPage.linking = {
  path: 'list',
  parse: {
    brandId: Number,
    categoryId: Number,
  },
};
export default ProductListPage;
