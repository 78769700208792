import {useRequest, useUnmount} from 'ahooks';
import _ from 'lodash';
import React, {useRef, useState} from 'react';

const taskIds = [];
const delay = (time, checkAbort) =>
  new Promise(resolve => {
    let id1, id2;
    id1 = setInterval(() => {
      if (checkAbort()) {
        id2 && clearTimeout(id2);
        resolve();
      }
    }, 300);
    id2 = setTimeout(() => {
      id1 && clearInterval(id1);
      resolve();
    }, time);
  });
const useLoopRequest = (
  service,
  {maxTimes = 100, duration = 3000, timeout = 5 * 60 * 1000, isSuccess, formatResult, onSuccess, ...rest} = {},
) => {
  const [loading, setLoading] = useState(false);
  const thisTaskIds = useRef([]).current;
  const {run: fetch, ...others} = useRequest(
    function () {
      return service.apply(this, arguments).then(res => {
        if (_.isFunction(isSuccess)) {
          if (isSuccess(res)) {
            return res;
          }
        } else {
          return res;
        }
      });
    },
    {
      ...rest,
      manual: true,
    },
  );

  const unsubscribe = () => {
    _.pullAll(taskIds, thisTaskIds);
    _.pullAll(thisTaskIds, thisTaskIds);
  };

  useUnmount(unsubscribe);

  return {
    ...others,
    loading,
    run: function () {
      const taskId = Date.now() + _.random(0, 100);
      taskIds.push(taskId);
      thisTaskIds.push(taskId);
      const checkAbort = () => !_.some(taskIds, v => v === taskId);

      const promise = new Promise(async (resolve, reject) => {
        try {
          const fn = async (...args) => {
            let res;
            let startTime = Date.now();
            let times = 1;
            const checkTimeout = () => times > maxTimes || (timeout > 0 && Date.now() - startTime > timeout);
            setLoading(true);
            while (times) {
              if (checkAbort()) {
                res = {
                  success: false,
                  msg: '请求中断',
                  code: 499,
                };
                break;
              }
              if (checkTimeout()) {
                res = {
                  success: false,
                  msg: '请求超时',
                  code: 408,
                };
                break;
              }
              // do request
              res = await fetch(...args);
              if (res) {
                break;
              }
              times++;
              if (checkTimeout()) {
                res = {
                  success: false,
                  msg: '请求超时',
                  code: 408,
                };
                break;
              }
              await delay(duration, checkAbort);
            }
            setLoading(false);
            if (_.isFunction(formatResult)) {
              res = formatResult(res);
            }
            if (_.isFunction(onSuccess)) {
              onSuccess(res, args);
            }
            return res;
          };
          const res = await fn.apply(this, arguments);
          resolve(res);
        } catch (err) {
          reject(err);
        }
      });
      promise.abort = () => {
        _.pull(taskIds, taskId);
        _.pull(thisTaskIds, taskId);
      };
      return promise;
    },
    unsubscribe,
  };
};

export default useLoopRequest;
