import {getPagedNotices} from '@/pages/notice/service';

export const namespace = 'notice';
const model = {
  namespace,
  state: {},
  effects: {
    /**
     * 分页查询通知
     * @actionParam params: {
     *   current,
     *   pageSize,
     * }
     * */
    *getPagedNotices({params}, {call}) {
      return yield call(getPagedNotices, params);
    },
  },
  reducers: {
    saveState(state, action) {
      const {type, ...nextState} = action || {};
      return {...state, ...nextState};
    },
  },
};

export default model;
