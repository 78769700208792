import {Dialog} from '@/components';
import BoxRecoveryModal from '@/components/_modals/BoxRecoveryModal';
import Button from '@/components/Button';
import Checkbox from '@/components/Checkbox';
import {Flex} from '@/components/Flex';
import theme from '@/theme';
import {useDispatch} from '@/utils/dva';
import useDynamicModal from '@/utils/hooks/useDynamicModal';
import FontWeight from '@/utils/localData/FontWeight';
import {useNavigation} from '@react-navigation/core';
import {useRequest} from 'ahooks';
import _ from 'lodash';
import React, {useMemo} from 'react';
import {StyleSheet, Text, View} from 'react-native';
import useBottomInset from '@/utils/hooks/useBottomInset';

const OpenedBoxFooterBar = ({listRef, data, onCheckedAll, selectedKeys, refreshList}) => {
  const navigation = useNavigation();
  const allKeys = _.map(data, 'id');
  const selectedLength = selectedKeys?.length;
  const isAllChecked = selectedKeys?.length > 0 && selectedKeys?.length === allKeys?.length;
  const isUnChecked = selectedKeys?.length === 0;

  const selectProds = useMemo(() => {
    return _.chain(data)
      .filter(it => _.includes(selectedKeys, it.id))
      .value();
  }, [data, selectedKeys]);

  const openModal = useDynamicModal(BoxRecoveryModal, {
    data: selectProds, //
    selectable: false,
    onSuccess(res) {
      if (res?.success) {
        refreshList();
      }
    },
  });

  const dispatch = useDispatch();
  const {loading: pickingUp, run: pickUp} = useRequest(
    () =>
      dispatch({
        type: 'box/pickUp',
        data: {items: _.map(selectProds, ({id, currentSkuId}) => ({id, skuId: currentSkuId}))},
      }),
    {
      manual: true,
      onSuccess: Dialog.respError,
    },
  );

  const handleRecovery = () => {
    if (!(selectProds?.length > 0)) {
      return Dialog.info({content: '请选择至少一项', position: 'bottom'});
    }
    openModal();
  };
  const handlePickup = () => {
    if (!(selectProds?.length > 0)) {
      return Dialog.info({content: '请选择至少一项', position: 'bottom'});
    }
    let ok = true;
    _.each(selectProds, it => {
      if (!it?.currentSkuId) {
        listRef?.current?.scrollToItem?.({item: it});
        Dialog.info({content: '请选择规格', position: 'bottom'});
        return (ok = false);
      }
    });
    if (ok) {
      pickUp().then(res => {
        if (res?.success && res?.data.id) {
          navigation.push('Box', {
            screen: 'BoxPickUp',
            params: {
              id: res?.data?.id,
              data: res?.data,
              callback: refreshList,
            },
          });
        }
      });
    }
  };

  // ios：底部安全区域
  const [, paddingBottom] = useBottomInset(11);
  return (
    <View style={[styles.footer, {paddingBottom}]}>
      <Flex align="center" justify="between">
        <Checkbox
          size={18}
          style={{flexShrink: 1}}
          checked={isAllChecked}
          onChange={onCheckedAll}
          indeterminate={!isAllChecked && !isUnChecked}>
          <Text>全选{selectedLength > 0 && `（${selectedLength}）`}</Text>
        </Checkbox>
        <Flex justify="end" style={{marginRight: -7}}>
          <Button
            type="primary"
            isLight={false}
            color={theme.brand_secondary}
            fontWeight={FontWeight.SemiBold}
            style={styles.footerBtn}
            onPress={handleRecovery}>
            立即回收
          </Button>
          <Button
            type="primary" //
            fontWeight={FontWeight.SemiBold}
            loading={pickingUp}
            onPress={handlePickup}
            style={styles.footerBtn}>
            {!pickingUp && '立即发货'}
          </Button>
        </Flex>
      </Flex>
    </View>
  );
};
const styles = StyleSheet.create({
  footer: {
    // height: 70,
    paddingHorizontal: 20,
    paddingVertical: 11,
    backgroundColor: '#fff',
    borderTopWidth: StyleSheet.hairlineWidth,
    borderColor: theme.border_color_base,
  },
  footerBtn: {
    width: 100,
    height: 48,
    marginLeft: 10,
  },
});
export default OpenedBoxFooterBar;
