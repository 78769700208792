import {Flex} from '@/components/Flex';
import Search from '@/components/Search';
import React from 'react';
import {Platform} from 'react-native';

const HeaderSearch = ({width, onPress, ...searchProps}) => {
  return (
    <Flex
      onPress={onPress}
      align="center"
      style={{
        width: width - 77,
        height: 32,
        marginLeft: 17,
        ...Platform.select({
          web: {
            position: 'absolute',
            marginLeft: -(width - 77) / 2 + 17,
          },
          android: {
            marginLeft: 7,
          },
          ios: {
            marginLeft: 17,
          },
        }),
      }}>
      <Search {...searchProps} />
    </Flex>
  );
};

export default HeaderSearch;
