import AppInfo from '@/NativeModules/AppInfo';
import {reportActive} from '@/services/report';
import {isNative} from '@/utils/utils';

export const namespace = 'report';
const model = {
  namespace,
  state: {
    reportDeviceIds: '',
  },
  effects: {
    /**
     * 报告应用激活
     * */
    *reportActive(__, {call, put, select}) {
      if (isNative()) {
        const {reportDeviceIds} = yield select(s => s[namespace]);
        const deviceIds = yield call(AppInfo.getDeviceIds);
        const newReportDeviceIds = JSON.stringify(deviceIds);
        if (newReportDeviceIds !== reportDeviceIds) {
          yield call(reportActive);
          yield put({type: 'saveState', reportDeviceIds: newReportDeviceIds});
        }
      }
    },
  },
  reducers: {
    saveState(state, action) {
      const {type, ...nextState} = action || {};
      return {...state, ...nextState};
    },
  },
};

export default model;
