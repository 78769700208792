import {getPagedFavorites, getRecommendProds} from '@/pages/favorites/service';

export const namespace = 'favorites';
const model = {
  namespace,
  state: {
    recommendProds: [],
  },
  effects: {
    /**
     * 查询我的收藏
     * @actionParam params: {
     *   current: 页码
     *   pageSize
     * }
     * */
    *getPagedFavorites({params}, {call}) {
      return yield call(getPagedFavorites, params);
    },

    // /**
    //  * 移除收藏
    //  * @actionParam id
    //  * */
    // *remove({id}, {call}) {
    //   return yield call(removeFavorite, id);
    // },

    /**
     * 查询推荐商品（猜你喜欢）
     * */
    *getRecommendProds(__, {call, put}) {
      const res = yield call(getRecommendProds);
      if (res?.success) {
        yield put({type: 'saveState', recommendProds: res.data});
      }
      return res;
    },
  },
  reducers: {
    saveState(state, action) {
      const {type, ...nextState} = action || {};
      return {...state, ...nextState};
    },
  },
};

export default model;
