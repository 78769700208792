import MyPiecesPage from '@/pages/piece/myPieces';
import PieceOrderPage from '@/pages/piece/order';
import authedPage from '@/utils/hooks/authedPage';
import {createStackNavigator} from '@/utils/navigatorUtils';
import React from 'react';

export default createStackNavigator(
  [MyPiecesPage, PieceOrderPage],
  {
    screenName: 'Piece',
    screenOptions: {
      headerShown: false,
    },
    linking: 'piece',
  },
  [],
  [authedPage],
);
