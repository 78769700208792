import {LoadingView} from '@/components';
import Empty from '@/components/Empty';
import List from '@/components/List';
import Loading from '@/components/Loading';
import brandImage from '@/pages/notice/brandImage';
import theme from '@/theme';
import {useDispatch} from '@/utils/dva';
import usePagedRequest from '@/utils/hooks/usePagedRequest';
import React from 'react';
import {Image, StyleSheet, VirtualizedList} from 'react-native';

const styles = StyleSheet.create({
  page: {
    flex: 1,
  },
  container: {flex: 1},
  noticeWrap: {
    paddingVertical: 20,
  },
});

const NoticePage = ({navigation}) => {
  const dispatch = useDispatch();
  const {data, loading, hasMore, loadingMore, loadMore} = usePagedRequest(
    params => dispatch({type: 'notice/getPagedNotices', params}),
    {
      pageSize: 20,
    },
  );

  return (
    <LoadingView loading={loading} style={styles.page}>
      {!loading && !data?.length && <Empty />}
      <VirtualizedList
        data={data}
        initialNumToRender={10}
        renderItem={({item}) => (
          <List.Item
            lineStyle={styles.noticeWrap}
            thumb={<Image source={brandImage} style={{width: 40, height: 40, backgroundColor: theme.fill_grey}} />}
            // arrow="right"
          >
            {item?.content}
            <List.Item.Brief>{item?.publishTime}</List.Item.Brief>
          </List.Item>
        )}
        getItemCount={data => data?.length}
        getItem={(data, index) => data[index]}
        onEndReached={() => {
          hasMore && !loadingMore && loadMore();
        }}
        ListFooterComponent={loadingMore && <Loading />}
      />
    </LoadingView>
  );
};

NoticePage.screenName = 'Notice';
NoticePage.screenOptions = {
  title: '官方通知',
};
NoticePage.linking = 'notice';
export default NoticePage;
