import request from '@/utils/request';
import _ from 'lodash';

/**
 * 查询一级分类
 * */
export function getCatalogs() {
  return request('/server/shopping/getBigCategoryList', {
    method: 'GET',
  }).then(res => {
    if (res?.success) {
      res.data = _.map(res.data, item => _.pick(item, ['id', 'name']));
    }
    return res;
  });
}

/**
 * 查询一级分类下详细的二级分类及活动等数据
 * @param id: 一级分类id
 * */
export function getCatalogData(id) {
  return request(`/server/shopping/getBigCategoryDetail/${id}`, {
    method: 'GET',
  });
}
