import BoxRarity from '@/utils/localData/BoxRarity';
import React from 'react';
import {ImageBackground, View} from 'react-native';
import assets_rarityTags from '../assets/_rarityTags';

const RarityTag = ({style, rarity, small}) => {
  const Rarity = BoxRarity.getByValue(rarity);
  const tagKey = small ? `${Rarity?.key}_small` : Rarity?.key;
  const asset = assets_rarityTags[tagKey];
  const sizeStyle = small ? {width: 32, height: 16} : {width: 42, height: 18};
  return (
    <View
      style={[
        {
          position: 'absolute',
          top: 0,
          left: 0,
        },
        style,
      ]}>
      <ImageBackground source={asset} style={sizeStyle} />
    </View>
  );
};

export default RarityTag;
