import Icon, {IconButton} from '@/components/Icon';
import {useSelector} from '@/utils/dva';
import {useNavigation} from '@react-navigation/core';
import {isFunction} from 'lodash';
import React, {useState} from 'react';
import {Pressable, StyleSheet, TextInput, View} from 'react-native';

const Search = ({style, clearable, value: _value, defaultValue, onChangeText, onChangeText1, ...props}) => {
  const [__value, setValue] = useState(_value ?? defaultValue ?? '');
  const value = _value ?? __value;

  const handleChangeText = v => {
    isFunction(onChangeText) && onChangeText(v);
    setValue(v);
  };

  const clear = () => {
    handleChangeText('');
  };

  return (
    <View style={styles.search}>
      <View style={styles.searchIcon}>
        <Icon type="search" fill={'black'} />
      </View>
      <TextInput
        key={_value}
        defaultValue={value ?? _value}
        onChangeText={handleChangeText}
        {...props}
        style={styles.searchInput}
      />
      {clearable && value ? (
        <IconButton
          type="close_fill"
          style={styles.clearBtn}
          iconStyle={{width: 12, height: 12}}
          fill="#cccccc"
          onPress={clear}
        />
      ) : null}
    </View>
  );
};

export default Search;

export const SearchButton = ({style}) => {
  const navigation = useNavigation();
  const defaultText = useSelector(s => s.search.defaultText);
  return (
    <Pressable style={style} onPress={() => navigation.push('Search')}>
      <Search
        {...{
          editable: false,
          placeholder: defaultText,
          onPressIn: () => navigation.push('Search'),
        }}
      />
    </Pressable>
  );
};

const styles = StyleSheet.create({
  search: {
    flex: 1,
    height: 32,
    position: 'relative',
  },
  searchIcon: {
    position: 'absolute',
    left: 10,
    top: 8,
    zIndex: 1,
  },
  searchInput: {
    height: 32,
    paddingVertical: 6,
    paddingLeft: 36,
    paddingRight: 10,
    fontSize: 16,
    lineHeight: 20,
    backgroundColor: '#F6F6F6',
    borderRadius: 2,
    // border: 'none',
    borderStyle: undefined,
    borderWidth: 0,
  },

  clearBtn: {
    zIndex: 99,
    position: 'absolute',
    top: 6,
    right: 6,
  },
});
