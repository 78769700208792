import {Dialog} from '@/components';
import AppInfo from '@/NativeModules/AppInfo';
import {useDispatch, useSelector} from '@/utils/dva';
import React, {useCallback} from 'react';

const useLatentFunction = () => {
  const dispatch = useDispatch();
  const {currentUser, channel, deviceIds} = useSelector(s => s.global);
  const setTicket = useCallback(
    ticket =>
      dispatch({
        type: 'global/saveCurrentUser',
        currentUser: {
          ...currentUser,
          ticket,
        },
      }),
    [currentUser, dispatch],
  );

  const showChannel = useCallback(() => {
    Dialog.alert('', '当前渠道：' + channel);
  }, [channel]);

  const showDeviceIds = useCallback(() => {
    try {
      AppInfo.getDeviceIds().then(ids => {
        Dialog.alert('DeviceIds', JSON.stringify(deviceIds) + JSON.stringify(ids));
      });
    } catch (e) {}
  }, [deviceIds]);

  const setEnv = useCallback(
    on => {
      dispatch({
        type: 'global/saveState',
        env: !!on,
      });
    },
    [dispatch],
  );

  return {setTicket, showChannel, showDeviceIds, setEnv};
};

export default useLatentFunction;
