import {LoadingView} from '@/components';
import Empty from '@/components/Empty';
import Loading from '@/components/Loading';
import PointBillItemCard from '@/pages/point/bill/components/PointBillItemCard';
import {useDispatch} from '@/utils/dva';
import usePagedRequest from '@/utils/hooks/usePagedRequest';
import React from 'react';
import {StyleSheet, VirtualizedList} from 'react-native';

const PointBillPage = ({navigation}) => {
  const dispatch = useDispatch();
  const {data, loading, hasMore, loadMore, loadingMore} = usePagedRequest(
    ({current, pageSize}, params) => {
      return dispatch({
        type: 'point/getPagedPointBillItems',
        params: {
          current,
          pageSize,
        },
      });
    },
    {
      pageSize: 20,
    },
  );

  return (
    <LoadingView loading={loading} style={styles.page}>
      {!loading && !data?.length && (
        <Empty
          text="暂时还没有交易记录~去逛逛"
          actions={[
            {
              text: '去逛逛',
              onPress: () => navigation.navigate('Home', {screen: 'BoxCupboardTab'}),
            },
          ]}
        />
      )}
      <VirtualizedList
        contentContainerStyle={styles.list}
        initialNumToRender={10}
        data={data}
        renderItem={({item}) => <PointBillItemCard data={item} style={{marginBottom: 8}} />}
        getItemCount={data => data?.length}
        getItem={(data, index) => data[index]}
        onEndReached={() => {
          hasMore && !loadingMore && loadMore();
        }}
        ListFooterComponent={loadingMore && <Loading />}
      />
    </LoadingView>
  );
};
const styles = StyleSheet.create({
  page: {
    flex: 1,
  },
  list: {
    paddingTop: 12,
    paddingBottom: 12 - 8,
    paddingHorizontal: 10,
  },
});

PointBillPage.screenName = 'PointsBill';
PointBillPage.screenOptions = {
  title: '交易记录',
};
PointBillPage.linking = 'bill';
export default PointBillPage;
