import {makeRemoteDataTools} from './index';

const key = 'IntlCodes';
const {
  defaultData: IntlCodes,
  getData: getIntlCodes,
  fetchData: fetchIntlCodes,
  hook: useIntlCodes,
} = makeRemoteDataTools(key, {lovType: 'INTERNATIONAL_CODE'});

export default IntlCodes;

export {getIntlCodes, fetchIntlCodes, useIntlCodes};
