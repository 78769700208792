import _, {find, isFunction} from 'lodash';
import React from 'react';
import ActivitySection from './ActivitySection';
import BannerSection from './BannerSection';
import {ensures} from './data';
import EnsureSection from './EnsureSection';
import ProductSection from './ProductSection';

const allSections = [BannerSection, EnsureSection, ActivitySection, ProductSection];
const sectionConfigs = [
  new BannerSection({
    formatData: data => data?.productBanner,
  }),
  new EnsureSection({
    formatData: () => ensures,
  }),
  new ActivitySection({
    formatData: data => _.map(data?.smallCategoryList),
  }),
  new BannerSection({
    formatData: data => data?.boxBanner,
    style: {
      marginTop: 10,
      marginBottom: 20,
    },
    height: 126,
    swiperProps: {
      autoplay: false,
    },
  }),
  // new ProductSection({
  //   formatData: data => data?.productBoxes,
  // }),
];

const getSectionComponent = type => {
  return find(allSections, c => c.type === type)?.component;
};

const MallSections = ({data}) => {
  return (
    <>
      {sectionConfigs.map((section, i) => {
        const {type, formatData} = section || {};
        const sectionProps = {
          key: `$section-${type}-${i}`,
          section,
          data: (isFunction(formatData) && formatData(data)) || data,
        };
        const sectionComponent = getSectionComponent(type);
        if (sectionComponent) {
          return React.createElement(sectionComponent, sectionProps);
        }
      })}
    </>
  );
};

export default React.memo(MallSections);
