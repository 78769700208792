import {Empty} from '@/components';
import React, {forwardRef} from 'react';
import {View} from 'react-native';
import useAuthed from './useAuthed';

export default function authedPage(Component) {
  if (Component.AuthedPage) {
    return Component.AuthedPage;
  }

  const AuthedPage = (props, ref) => {
    const [authed, {currentUser, gotoLogin}] = useAuthed(true, true);

    return authed ? (
      <Component {...props} currentUser={currentUser} ref={ref} />
    ) : (
      <View style={{flex: 1}}>
        <Empty text="未登录或已超时" actions={[{text: '立即登录', onPress: gotoLogin}]} />
      </View>
    );
  };

  Component.AuthedPage = forwardRef(AuthedPage);

  return Component.AuthedPage;
}
