import request from '@/utils/request';

// /**
//  * 分页查询碎片
//  * @param params: {
//  *   lastId
//  * }
//  * */
// export function getPagedPieces11(params) {
//   return request('/server/getPagedPieces', {
//     method: 'GET',
//     params,
//   });
// }

/**
 * 查询碎片列表
 * */
export function getPieces() {
  return request('/server/fragment/list', {
    method: 'GET',
  });
}

/**
 * 查询碎片列表
 * @param: id
 * */
export function getPiece(id) {
  return request(`/server/fragment/${id}`, {
    method: 'GET',
  });
}

/**
 * 碎片购买
 * @param: data {
 *   id,
 *   count
 * }
 * */
export function buy(data) {
  return request('/server/order/fragment', {
    method: 'POST',
    data: {
      fragmentId: data?.id,
      count: data?.count,
    },
  });
}

/**
 * 查询我的碎片
 * */
export function getMyPieces() {
  return request('/server/fragment/userFragmentList', {
    method: 'GET',
  });
}

/**
 * 回收我的碎片
 * @param data: {
 *   id,
 *   count,
 * }
 * */
export function recovery(data) {
  return request('/server/order/recycleFragment', {
    method: 'POST',
    data: {
      fragmentId: data?.id,
      count: data?.count,
    },
  });
}

/**
 * 我的碎片转换成赠送口令
 * @param data: {
 *   id,
 *   count,
 * }
 * @resp token: 口令
 * */
export function exchange(data) {
  return request('/server/fragment/generateCommand', {
    method: 'POST',
    data: {
      fragmentId: data?.id,
      count: data?.count,
    },
  });
}

/**
 * 口令兑换碎片
 * @param token: 口令
 * */
export function convert(token) {
  return request('/server/fragment/useCommand', {
    method: 'GET',
    params: {
      command: token,
    },
  });
}

/**
 * 查询碎片余额接口
 * @param pieceId
 * */
export function getPieceCount(pieceId) {
  return request(`/server/fragment/getUserFragmentCount/${pieceId}`, {
    method: 'GET',
  });
}

/**
 * 查询推荐的碎片商品
 * @param pieceId
 * */
export function getRecommendProds(pieceId) {
  return request('/server/fragment/getRecommendProds', {
    method: 'GET',
    params: {
      pieceId,
    },
  });
}
