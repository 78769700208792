import theme from '@/theme';
import React from 'react';
import {ActivityIndicator, StyleSheet, Text, TouchableHighlight, View} from 'react-native';
import buttonStyles from './style';

const _styles = buttonStyles(theme);
export default class Button extends React.Component {
  static defaultProps = {
    pressIn: false,
    disabled: false,
    loading: false,

    onPress: (_?: any) => {},
    onPressIn: (_?: any) => {},
    onPressOut: (_?: any) => {},
    onShowUnderlay: (_?: any) => {},
    onHideUnderlay: (_?: any) => {},
  };

  constructor(props) {
    super(props);
    this.state = {
      pressIn: false,
      touchIt: false,
    };
  }

  onPressIn = event => {
    this.setState({pressIn: true});
    if (this.props.onPressIn) {
      this.props.onPressIn(event);
    }
  };
  onPressOut = event => {
    this.setState({pressIn: false});
    if (this.props.onPressOut) {
      this.props.onPressOut(event);
    }
  };
  onShowUnderlay = () => {
    this.setState({touchIt: true});
    if (this.props.onShowUnderlay) {
      this.props.onShowUnderlay();
    }
  };
  onHideUnderlay = () => {
    this.setState({touchIt: false});
    if (this.props.onHideUnderlay) {
      this.props.onHideUnderlay();
    }
  };

  render() {
    // for using setNativeProps to improve performance
    const {
      size = 'middle',
      type = 'default',
      round = false,
      // disabled,
      activeStyle,
      onPress,
      style,
      containerStyle,
      textStyle: _textStyle,
      loading,
      styles = _styles,
      children,
      ...restProps
    } = this.props;
    const disabled = loading || this.props?.disabled;

    const textStyle = [
      styles[`${size}RawText`],
      styles[`${type}RawText`],
      _textStyle,
      disabled && styles[`${type}DisabledRawText`],
      this.state.pressIn && styles[`${type}HighlightText`],
    ];

    const wrapperStyle = [
      styles.wrapperStyle,
      styles[`${size}Raw`],
      styles[`${type}Raw`],
      round && styles.roundRow,
      disabled && styles[`${type}DisabledRaw`],
      this.state.pressIn && activeStyle && styles[`${type}Highlight`],
      activeStyle && this.state.touchIt && activeStyle,
      style,
    ];

    const underlayColor =
      this.props.underlayColor ??
      StyleSheet.flatten(activeStyle ? activeStyle : styles[`${type}Highlight`]).backgroundColor;

    const indicatorColor = StyleSheet.flatten(
      this.state.pressIn ? styles[`${type}HighlightText`] : styles[`${type}RawText`],
    ).color;

    return (
      <TouchableHighlight
        activeOpacity={0.4}
        {...restProps}
        style={wrapperStyle}
        disabled={disabled}
        underlayColor={underlayColor}
        onPress={e => onPress && onPress(e)}
        onPressIn={this.onPressIn}
        onPressOut={this.onPressOut}
        onShowUnderlay={this.onShowUnderlay}
        onHideUnderlay={this.onHideUnderlay}>
        <View style={[styles.container, containerStyle]}>
          {loading ? (
            // tslint:disable-next-line:jsx-no-multiline-js
            <ActivityIndicator style={styles.indicator} animating color={indicatorColor} size="small" />
          ) : null}
          {typeof children === 'function' ? children(textStyle) : <Text style={textStyle}>{children}</Text>}
        </View>
      </TouchableHighlight>
    );
  }
}
