import theme from '@/theme';
import FontWeight from '@/utils/localData/FontWeight';
import {useImageResize} from '@/utils/remoteData/ImageResize';
import React from 'react';
import {Image, StyleSheet, Text, View} from 'react-native';

const PieceProdCard = ({data, style}) => {
  const {name, picture, fragmentAmount, fragmentName, amount} = data || {};
  const [, resizeImage] = useImageResize({object: 'prod', size: 'sm'});
  return (
    <View style={[styles.container, style]}>
      <View style={{width: '100%'}}>
        <Image source={{uri: resizeImage(picture)}} style={styles.image} resizeMode="cover" />
      </View>
      <View style={styles.body}>
        <Text style={styles.title} numberOfLines={2}>
          {name}
        </Text>
        <Text style={styles.price}>
          {fragmentAmount} {fragmentName}
        </Text>
        <Text style={styles.rmbPrice}>¥{amount}</Text>
      </View>
    </View>
  );
};

export default PieceProdCard;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    height: 223,
    paddingTop: 11,
    paddingBottom: 9,
    paddingHorizontal: 16,
    borderWidth: StyleSheet.hairlineWidth,
    borderColor: theme.border_color_base,
    borderRadius: 4,
    backgroundColor: '#fff',
  },

  image: {
    height: 117,
    marginBottom: 2,
  },

  body: {
    // paddingHorizontal: 16,
    // marginBottom: 14,
  },

  title: {
    fontSize: 12,
    lineHeight: 17,
    marginBottom: 8,
    fontWeight: FontWeight.Light,
  },
  price: {
    fontSize: 14,
    lineHeight: 20,
    fontWeight: FontWeight.SemiBold,
    marginBottom: 5,
  },
  rmbPrice: {
    fontSize: 12,
    lineHeight: 17,
    color: theme.color_text_secondary,
  },
});
