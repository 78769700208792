/**
 * request 网络请求工具
 * 更详细的 api 文档: https://github.com/umijs/umi-request
 */
import {getRequestHeaderParams, getServer, isNative} from '@/utils/utils';
import * as _ from 'lodash';
import {extend} from 'umi-request';
import LoginPage from '@/pages/login';

export const codeMessage = {
  200: '服务器成功返回请求的数据。',
  201: '新建或修改数据成功。',
  202: '一个请求已经进入后台排队（异步任务）。',
  204: '删除数据成功。',
  400: '请求错误。',
  401: '用户没有权限（令牌、用户名、密码错误）。',
  403: '用户得到授权，但是访问是被禁止的。',
  404: '发出的请求针对的是不存在的记录，服务器没有进行操作。',
  406: '请求的格式不可得。',
  410: '请求的资源被永久删除，且不会再得到的。',
  422: '当创建一个对象时，发生一个验证错误。',
  500: '服务器发生错误，请检查服务器。',
  502: '网关错误。',
  503: '服务不可用，服务器暂时过载或维护。',
  504: '网关超时。',
};

// class RequestError extends Error {
//   constructor(message, code) {
//     super(message);
//     this.name = 'RequestError';
//     this.code = code;
//   }
// }
/**
 * 异常处理程序
 */
const errorHandler = error => {
  const {response} = error;
  if (response && response.status) {
    const errorText = codeMessage[response.status] || response.statusText;
    const {status, url} = response;
    //Toast.offline({content: errorText, mask: false});
    // todo: 数据请求异常处理
    // return Promise.reject(new RequestError(errorText, status));
    return formatResult({code: status, message: errorText});
  } else if (!response) {
    //Toast.offline({content: 'Network connection failed !!!', mask: false});
    // todo: 数据请求异常处理
    // return Promise.reject(new RequestError('您的网络发生异常，无法连接服务器'));
    return formatResult({code: 400, message: error?.toString() ?? codeMessage[400]});
  }
  return response;
};

/**
 * 配置request请求时的默认参数
 */
const request = extend({
  errorHandler,
  headers: {
    'Content-Type': 'application/json',
    // ticket: getTicket(),
    // channel: getChannel(),
    ...getRequestHeaderParams(),
  },
  // 默认错误处理
  credentials: 'include', // 默认请求是否带上cookie
});

request.interceptors.request.use(
  (url, options) => {
    if (!options?.headers) {
      options.headers = {};
    }
    // options.headers.ticket = getTicket();
    // options.headers.channel = getChannel();
    _.extend(options.headers, getRequestHeaderParams());
    console.log(url, options.data || options.params);
    return {
      url: String(url).replace(/^\/*(api|mock|server)\//i, `${getServer()}/`),
      options,
    };
  },
  {global: true},
);

/**
 * 解决 Content-Type 设置为 ‘multipart/form-data’ 因缺失 boundary 而出错的问题
 * 解决方案: 删除 options.headers['Content-Type'], 使浏览器能正确设置 Content-Type
 * */
request.interceptors.request.use(
  (url, options) => {
    const {useFormData} = options || {};
    if (options?.headers && useFormData) {
      delete options.headers['Content-Type'];
    }
    return {url, options};
  },
  {global: true},
);

/**
 * 拦截器，预处理请求的返回数据
 * */
request.interceptors.response.use(async (response, options) => {
  if (!options.responseType || options.responseType === 'json') {
    try {
      const res = await response?.clone().json();
      const {code} = res || {};
      if (code === 401 && options.authentic !== false) {
        openLogin();
      }
      return formatResult(res);
    } catch (e) {
      console.warn(e);
    }
  }
  return response;
});

export default request;

export function getPagedParams(params, clearNull) {
  const {pageSize, page, ...rest} = params || {};

  return {
    current: page,
    size: pageSize,
    ...(clearNull ? _.pickBy(rest, v => v !== undefined && v !== null && v !== '') : rest),
  };
}

export function formatResult(res) {
  const {code, msg, data, status, message} = res || {};
  const {records, total, current, size} = data || {};

  return {
    success: code == 0,
    msg: msg || message,
    data:
      _.isArray(records) && !_.isUndefined(total)
        ? {list: records, total: parseInt(total), current: parseInt(current), pageSize: parseInt(size)}
        : data,
    code: code ?? status,
  };
}

export function openLogin() {
  if (global._navigation) {
    if (global._callbacks) {
      const route = global._navigation.getCurrentRoute();
      const {id, remove} = global._callbacks.add(() => {
        remove();
        global._navigation.navigate(route);
      });
      global._navigation.navigate(LoginPage.screenName, {id});
      return;
    }
    global._navigation.navigate(LoginPage.screenName);
  }
}
