import Button from '@/components/Button';
import {Flex} from '@/components/Flex';
import theme from '@/theme';
import useBottomInset from '@/utils/hooks/useBottomInset';
import FontWeight from '@/utils/localData/FontWeight';
import {getDINProBoldStyle} from '@/utils/styleUtils';
import React from 'react';
import {StyleSheet, Text, View} from 'react-native';

const styles = StyleSheet.create({
  toolbar: {
    borderTopWidth: StyleSheet.hairlineWidth,
    borderTopColor: theme.border_color_base,
    backgroundColor: '#fff',
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  container: {
    paddingHorizontal: theme.h_spacing_lg,
    paddingVertical: theme.v_spacing_md,
  },
  amount: {
    color: theme.brand_warning,
  },
  amountLabel: {
    fontSize: 12,
    fontWeight: FontWeight.Medium,
    marginRight: 5,
    color: theme.brand_warning,
  },
  amountValue: {
    color: theme.brand_warning,
    ...getDINProBoldStyle(16),
    lineHeight: 20,
  },
  amountUnit: {
    fontSize: 14,
    fontWeight: FontWeight.SemiBold,
    marginLeft: 2,
    color: theme.brand_warning,
  },

  payBtn: {
    width: 140,
    height: 48,
    marginLeft: 15,
  },
});
const OrderPayBar = ({price, submit, submitting}) => {
  const [, , , inset] = useBottomInset(10);

  return (
    <View style={[styles.toolbar, {paddingBottom: inset}]}>
      <View style={[styles.row, styles.container]}>
        <View style={[styles.row, styles.left]}>
          <Flex justify="center" style={styles.amount}>
            <Text style={styles.amountLabel}>合计差价：</Text>
            <Text style={styles.amountValue}>
              {price?.prefix}
              {price?.amount}
            </Text>
            <Text style={styles.amountUnit}>{price?.unit}</Text>
          </Flex>
        </View>
        <View style={[styles.row, styles.right]}>
          <Button type="primary" loading={submitting} onPress={submit} style={styles.payBtn}>
            {price?.amount === 0 ? '确认提货' : '确认补差价'}
          </Button>
        </View>
      </View>
    </View>
  );
};

export default OrderPayBar;
