import request from '@/utils/request';

/**
 * 活动弹窗管理
 * */
export function getActivityPopup(trigger) {
  return request(`/server/open/getFrame/${trigger}`, {
    method: 'GET',
  });
}
