import React from 'react';
import {useSafeAreaInsets} from 'react-native-safe-area-context';

const useBottomInset = (reserved = 0) => {
  const {bottom} = useSafeAreaInsets();
  const max = Math.max(reserved, bottom);
  const min = Math.min(reserved, bottom);
  const redress = max - reserved;
  return [bottom, max, min, redress];
};

export default useBottomInset;
