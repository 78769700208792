import _InputItem from '@/components/InputItem';
import styles from '@/pages/login/components/styles';
import theme from '@/theme';
import React from 'react';

const inputItemProps = {
  clear: true,
  style: styles.inputItem,
  inputStyle: styles.inputStyle,
  inputFillStyle: styles.inputFillStyle,
  labelNumber: (64 - 30) / theme.input_font_size,
  placeholderTextColor: '#BAB9C9',
  keyboardType: 'numeric',
};

const InputItem = ({inputRef, ...rest}) => {
  return React.createElement(_InputItem, {
    ref: inputRef,
    ...inputItemProps,
    ...rest,
  });
};

export default InputItem;
