import {useDispatch, useSelector} from '@/utils/dva';
import {useRequest} from 'ahooks';
import _, {isFunction} from 'lodash';
import React from 'react';

const useLov = (lovType, {toMap = true, formatData} = {}) => {
  const dispatch = useDispatch();
  const initialData = useSelector(s => s.lov[lovType]);
  const {data} = useRequest(() => dispatch({type: 'lov/getByType', lovType}), {
    initialData,
    ready: !!(lovType && !initialData?.length),
  });
  if (isFunction(formatData)) {
    return formatData(data);
  }
  if (toMap) {
    return _.chain(data).keyBy('name').mapValues('value').value();
  }
  return data;
};

export default useLov;
