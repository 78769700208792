const assets_head_1 = require('./head_1@2x.png');
const assets_head_2 = require('./head_2@2x.png');
const assets_head_3 = require('./head_3@2x.png');
const assets_head_4 = require('./head_4@2x.png');
const assets_head_5 = require('./head_5@2x.png');
const assets_head_6 = require('./head_6@2x.png');
const assets_head_7 = require('./head_7@2x.png');
const assets_head_8 = require('./head_8@2x.png');
const assets_head_9 = require('./head_9@2x.png');
const assets_head_10 = require('./head_10@2x.png');
const assets_head_11 = require('./head_11@2x.png');
const assets_head_12 = require('./head_12@2x.png');

export {
  assets_head_1,
  assets_head_2,
  assets_head_3,
  assets_head_4,
  assets_head_5,
  assets_head_6,
  assets_head_7,
  assets_head_8,
  assets_head_9,
  assets_head_10,
  assets_head_11,
  assets_head_12,
};
