import {Center} from '@/components/Flex';
import Grid from '@/components/Grid';
import theme from '@/theme';
import FontWeight from '@/utils/localData/FontWeight';
import {useImageResize} from '@/utils/remoteData/ImageResize';
import {isEmpty} from 'lodash';
import React from 'react';
import {Image, StyleSheet, Text, TouchableOpacity, View} from 'react-native';

const CatalogSection = ({title, data, onPressItem}) => {
  const [, resizeImage] = useImageResize({object: 'prod', size: 'xxs'});

  return (
    <View style={styles.section}>
      {!isEmpty(data) && (
        <View style={styles.sectionTitle}>
          <View style={styles.sectionTitleDecorate} />
          <Text style={styles.sectionTitleText}>{title}</Text>
          <View style={styles.sectionTitleDecorate} />
        </View>
      )}
      <Grid
        data={data}
        columnNum={3}
        hasLine={false}
        itemStyle={styles.item}
        renderItem={item => {
          const {name, picture} = item || {};
          return (
            <TouchableOpacity onPress={() => onPressItem(item)}>
              <Center direction="column">
                <Image source={{uri: resizeImage(picture)}} style={styles.itemImage} esizeMode="cover" />
                <Text style={styles.itemText} numberOfLines={1}>
                  {name}
                </Text>
              </Center>
            </TouchableOpacity>
          );
        }}
      />
    </View>
  );
};

export default CatalogSection;

const styles = StyleSheet.create({
  section: {
    marginTop: 20,
  },
  sectionTitle: {
    lineHeight: 21,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 25,
  },
  sectionTitleDecorate: {
    width: 28,
    height: 1,
    backgroundColor: theme.border_color_base,
  },
  sectionTitleText: {
    fontSize: 13,
    fontWeight: FontWeight.SemiBold,
    marginHorizontal: 16,
  },

  item: {
    height: 58,
    marginBottom: 28,
  },
  itemImage: {
    width: 60,
    height: 40,
    marginBottom: 4,
    backgroundColor: theme.fill_grey,
  },
  itemText: {
    fontSize: 10,
    lineHeight: 13,
    fontWeight: FontWeight.SemiBold,
  },
});
