import {Dialog, LoadingView} from '@/components';
import Button from '@/components/Button';
import {Flex} from '@/components/Flex';
import {IconButton} from '@/components/Icon';
import Modal from '@/components/Modal';
import theme from '@/theme';
import useBottomInset from '@/utils/hooks/useBottomInset';
import useControlledModalProps from '@/utils/hooks/useControlledModalProps';
import useLov from '@/utils/hooks/useLov';
import FontWeight from '@/utils/localData/FontWeight';
import PayType from '@/utils/localData/PayType';
import RmbPayType from '@/utils/localData/RmbPayType';
import {useNavigation} from '@react-navigation/core';
import {useSetState} from 'ahooks';
import _ from 'lodash';
import React, {useEffect, useState} from 'react';
import {StyleSheet, Text, View} from 'react-native';
import Step0 from './Step0';
import Step1 from './Step1';

const CommonTradeModal = ({
  params,
  defaultStep,
  renderDisplay,
  submit, // 提交支付
  onSuccess, //
  onError,
  deductEnable = false,
  ...rest
}) => {
  const {visible, close, modalProps} = useControlledModalProps(rest);
  const [, , , redress] = useBottomInset(10);
  const navigation = useNavigation();
  const docs = useLov('DOCUMENT_TYPE');

  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(defaultStep ?? 0);
  const [state, setState] = useSetState({
    agreement: true,
    deduct: false,
    payType: RmbPayType.Alipay,
    amount: 0, // 金额
    deductAmount: 0, // 抵扣金额
    ...params, // {amount}
  });

  useEffect(() => {
    if (!visible) {
      setStep(defaultStep ?? 0);
      setState({agreement: false});
    }
  }, [defaultStep, setState, visible]);

  const doSubmit = async input => {
    if (_.isFunction(submit)) {
      try {
        setLoading(true);
        const res = await submit(_.extend({}, state, input), {...params, close});
        setLoading(false);
        _.isFunction(onSuccess) && onSuccess(res);
      } catch (err) {
        setLoading(false);
        _.isFunction(onError) && onError(err);
      }
    }
  };

  const onPress = () => {
    if (step === 0) {
      if (!state.agreement) {
        return Dialog.info({
          content: `请同意《${docs?.gmxz ?? '口袋盲盒买家须知'}》`,
          mask: false,
          bottom: 85,
        });
      } else if (state.deduct && state.deductAmount >= state.amount) {
        // 全额抵扣
        doSubmit({
          payType: PayType.Score,
        });
      } else {
        // 选择支付方式
        setStep(1);
      }
    } else {
      doSubmit();
    }
  };

  const height = deductEnable && step === 0 ? 397 : 340;
  const payAmount = state.deduct ? _.floor(state.amount - state.deductAmount, 2) : state.amount;
  return (
    <Modal
      popup
      visible={visible}
      animationType="slide-up"
      maskClosable={false} // onClose={() => setVisible(false)}
      {...modalProps}
      style={[styles.modal, {paddingBottom: redress}]}>
      <IconButton type="close" size={12} onPress={close} style={styles.close} />
      <LoadingView loading={loading} layer style={[styles.container, {height}]}>
        <Flex justify="center" style={styles.header}>
          <Text style={styles.title}>{step === 0 ? '确认支付' : '选择支付方式'}</Text>
        </Flex>
        <View style={styles.content}>
          <View style={styles.stepWrap}>
            <Step0
              renderDisplay={renderDisplay}
              params={params}
              state={state}
              setState={setState}
              navigation={navigation}
              shouldShow={step === 0}
              deductEnable={deductEnable}
            />
            <Step1 state={state} setState={setState} navigation={navigation} shouldShow={step === 1} />
          </View>
        </View>
        <Flex style={[styles.toolbar]}>
          <Flex.Item>
            <Button
              type="primary"
              style={[styles.toolbarButton]}
              onPress={onPress}
              fontWeight={FontWeight.SemiBold}
              {...(deductEnable
                ? {
                    color: theme.brand_secondary,
                    isLight: false,
                  }
                : {})}>
              实付¥{payAmount}
            </Button>
          </Flex.Item>
        </Flex>
      </LoadingView>
    </Modal>
  );
};
const styles = StyleSheet.create({
  modal: {
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
    overflow: 'hidden',
  },
  container: {
    height: 340,
    backgroundColor: '#fff',
    paddingBottom: 70,
  },
  close: {
    position: 'absolute',
    top: theme.h_spacing_lg - 4,
    right: theme.h_spacing_lg - 4,
    zIndex: 99,
  },
  header: {
    paddingVertical: 16,
    paddingHorizontal: theme.h_spacing_lg,
  },
  title: {},
  content: {
    paddingHorizontal: theme.h_spacing_lg,
    width: '100%',
    flex: 1,
  },
  stepWrap: {
    position: 'absolute',
    left: theme.h_spacing_lg,
    right: theme.h_spacing_lg,
    top: 0,
    bottom: 0,
  },

  toolbar: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    borderTopWidth: StyleSheet.hairlineWidth,
    borderTopColor: theme.border_color_base,
    backgroundColor: '#fff',
    paddingHorizontal: theme.h_spacing_lg,
    paddingVertical: theme.v_spacing_md,
  },
  toolbarButton: {
    height: 48,
  },
});

export default CommonTradeModal;
