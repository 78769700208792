import List from '@/components/List';
import useGlobalCallback from '@/utils/hooks/useGlobalCallback';
import {isIos} from '@/utils/utils';
import cityData from '@bang88/china-city-data';
import _ from 'lodash';
import React from 'react';
import {ScrollView} from 'react-native';

const AreaPicker = ({navigation, route}) => {
  const {id, city} = route?.params || {};
  const provinceItem = _.find(cityData, item => _.some(item.children, subitem => subitem.value === city));
  const cityItem = _.find(provinceItem.children, subitem => subitem.value === city);
  const callbackRef = useGlobalCallback(id);
  const handlePress = item => {
    callbackRef?.current?.callback?.({
      navigation,
      value: {
        province: {
          ..._.pick(provinceItem, ['value', 'label']),
          isCity: provinceItem?.children?.length === 1,
        },
        city: _.pick(cityItem, ['value', 'label']),
        area: _.pick(item, ['value', 'label']),
      },
    });
  };
  const data = cityItem?.children;
  return (
    <ScrollView style={{flex: 1}}>
      {data?.length && (
        <List>
          {_.map(data, (item, i) => {
            const {value, label} = item || {};
            return (
              <List.Item key={`$item_${value}`} arrow="right" onPress={handlePress.bind({}, item)}>
                {label}
              </List.Item>
            );
          })}
        </List>
      )}
    </ScrollView>
  );
};

AreaPicker.modalName = 'AreaPicker';
AreaPicker.screenOptions = {
  title: '选择区/县',
  animation: 'slide_from_right',
  presentation: isIos() ? 'card' : 'fullScreenModal',
};
export default AreaPicker;
