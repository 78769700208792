import AutoHeightImage from '@/components/CustomizedImage/AutoHeightImage';
import FontWeight from '@/utils/localData/FontWeight';
import {useImageResize} from '@/utils/remoteData/ImageResize';
import {assign} from 'lodash';
import React from 'react';
import {StyleSheet, Text, View} from 'react-native';
import _styles from './style';

const Component = ({section}) => {
  const images = section.data;
  const [, resizeImage] = useImageResize({object: 'prod', size: 'lg'});

  return (
    <View style={[_styles.section, styles.container, section.style]}>
      {section.showHeader && (
        <View style={styles.header}>
          <Text style={styles.title}>商品详情</Text>
        </View>
      )}
      <View style={styles.content}>
        {!!images?.length &&
          images.map((picture, i) => <AutoHeightImage key={`$image__${i}`} source={{uri: resizeImage(picture)}} />)}
      </View>
    </View>
  );
};
const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 0,
    paddingVertical: 0,
  },
  header: {
    marginBottom: 15,
    marginTop: 10,
    paddingHorizontal: 20,
  },
  title: {
    paddingRight: 20,
    fontWeight: FontWeight.SemiBold,
    lineHeight: 30,
    minWidth: 46,
  },
  content: {},
});

export default class DetailSection {
  static type = 'detail';
  static component = Component;
  style = {
    marginBottom: 0,
  };
  showHeader = true;

  constructor(config) {
    assign(this, config);
  }

  get type() {
    return this?.constructor?.type;
  }
}
