import request, {getPagedParams} from '@/utils/request';

/**
 * 分页查询交易记录
 * @param params: {
 *   current,
 *   pageSize,
 * }
 * */
export function getPagedPointBillItems(params) {
  return request('/server/user/trade/page', {
    method: 'GET',
    params: getPagedParams(params),
  });
}
