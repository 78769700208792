import _ from 'lodash';
import {useCallback, useMemo, useState} from 'react';
import {makeRemoteDataTools} from './index';

const key = 'ImageResize';
const {
  defaultData: ImageResize,
  getData: getImageResize,
  fetchData: fetchImageResize,
  hook: hook,
} = makeRemoteDataTools(key, {lovType: 'APP_IMAGES_RESIZE', itemKey: 'name'});

export default ImageResize;

const useImageResize = ({object, size, ...rest} = {}) => {
  const data = hook({
    ...rest,
    formatData: useCallback(data => _.mapValues(data, it => it?.value), []),
  });

  return useMemo(() => {
    if (object && size) {
      const urlParam = data?.[`${object}_image_${size}`] || '';
      return [urlParam, src => (src ? `${src}?${urlParam}` : '')];
    }
    if (object) {
      const items = _.chain(data)
        .pickBy((v, k) => {
          return String(k).startsWith(`${object}_image_`);
        })
        .mapKeys((v, k) => String(k).split('_image_')[1])
        .value();
      return [
        items,
        (src, size) => {
          const urlParam = items?.[size] || '';
          return src ? `${src}?${urlParam}` : '';
        },
      ];
    }
    return [
      data,
      (src, object, size) => {
        const urlParam = data?.[`${object}_image_${size}`] || '';
        return src ? `${src}?${urlParam}` : '';
      },
    ];
  }, [data, object, size]);
};

const useImageResizeReady = children => {
  const [ready, setReady] = useState(false);
  hook({
    onLoad() {
      setReady(true);
    },
  });

  const renderChildren = useCallback(() => {
    return ready && children;
  }, [children, ready]);

  return renderChildren;
};
export {getImageResize, fetchImageResize, useImageResize, useImageResizeReady};
