import ActivityModal from '@/components/_modals/ActivityModal';
import AppUpdateModal from '@/components/_modals/AppUpdateModal';
import {useDispatch, useSelector} from '@/utils/dva';
import useDynamicModal from '@/utils/hooks/useDynamicModal';
import {useImageResizeReady} from '@/utils/remoteData/ImageResize';
import {isAndroid, isNative} from '@/utils/utils';
import {useRequest} from 'ahooks';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {NativeEventEmitter, Platform} from 'react-native';
import QuickLogin from '@/NativeModules/QuickLogin';
import {openLogin} from '@/utils/request';
import {isFunction} from 'lodash';
import {Dialog, LoadingView} from '@/components';
import useLov from '@/utils/hooks/useLov';
import _ from 'lodash';

export const AppLaunchedContext = React.createContext(null);

const _is_on_off_for_platform = (lovs, type) => {
  if (lovs?.hasOwnProperty(type)) {
    return _.includes(String(lovs[type]).split('|'), Platform.OS);
  }
  return false;
};
/*
 * 引用启动后一些事务处理
 * */
const AppLaunched = ({children}) => {
  const loginTypeLovs = useLov('APP_LOGIN_TYPE');
  const isYDEnable = useMemo(type => _is_on_off_for_platform(loginTypeLovs, 'yd'), [loginTypeLovs]);
  const dispatch = useDispatch();
  const {currentUser, agreement, deviceIds, mobileNumber} = useSelector(s => s.global);
  const docs = useLov('DOCUMENT_TYPE', {
    formatData(data) {
      return _.chain(data)
        .map(it => {
          const {link} = it?.extra || {};
          return {key: it?.name, text: it?.value, link};
        })
        .filter(it => it.link)
        .keyBy('key')
        .value();
    },
  });
  const render = useImageResizeReady(children);
  const [updated, setUpdated] = useState(false);
  const [launched, setLaunched] = useState(false);
  const [loading, setLoading] = useState(false);

  // 激活上报
  useRequest(() => dispatch({type: 'report/reportActive'}), {
    ready: !!deviceIds,
    refreshDeps: [deviceIds],
  });

  // 配置一键启动页面和监听事件
  useEffect(() => {
    if (isNative()) {
      QuickLogin.setUiConfig({
        protocolText: docs?.fwxy?.text,
        protocolLink: docs?.fwxy?.link,
        protocol2Text: docs?.yscl?.text,
        protocol2Link: docs?.yscl?.link,
      });
      const eventEmitter = new NativeEventEmitter(QuickLogin);
      const onLoginByPhone = eventEmitter.addListener('LoginByPhone', () => {
        openLogin(false);
      });
      return () => {
        onLoginByPhone.remove();
      };
    }
  }, [docs]);

  // 打开更新弹窗
  const openUpdateModal = useDynamicModal(
    AppUpdateModal,
    {
      onClose: () => setUpdated(true),
    },
    {
      onBeforeRemove: () => false,
    },
  );

  // 检查应用更新
  const {loading: loading_checkVersion} = useRequest(() => dispatch({type: 'global/checkVersion'}), {
    ready: !!agreement,
    onSuccess(res) {
      if (res?.success && res?.data?.versionCode) {
        const {downloadUrl, versionCode, versionName, title, content, forceUpdate} = res.data || {};
        if (isNative()) {
          return openUpdateModal({
            downloadUrl,
            versionCode,
            versionName,
            title,
            content,
            mandatory: !!forceUpdate,
          });
        }
      }
      setUpdated(true);
    },
  });

  // 打开活动弹窗
  const openActivityModal = useDynamicModal(ActivityModal, {
    onClose() {
      setLaunched(true);
    },
  });

  // 登录服务
  const logged = !!currentUser;
  const {run: loginByYD, loading: loading_loginByYD} = useRequest(
    (token, accessToken) =>
      dispatch({
        type: 'user/loginByYD',
        token,
        accessToken,
      }),
    {
      manual: true,
      onSuccess(res) {
        if (res?.success) {
          Dialog.success(res.msg || '登录成功');
        } else {
          Dialog.respError(res);
        }
      },
    },
  );

  // YD一键登录
  // console.log('0.', mobileNumber);
  const quickLogin = useCallback(
    ({uiConfig = {}, passPrefetch = false, otherLoginEnable = true, onSuccess, final, otherLogin = openLogin} = {}) => {
      setLoading(launched);
      const prefetch = () => dispatch({type: 'global/prefetchMobileNumber'});
      const reset = () => dispatch({type: 'global/saveState', mobileNumber: ''});
      const fn = async () => {
        try {
          let _mobileNumber = mobileNumber;
          // console.log('1.', _mobileNumber);
          if (!passPrefetch && !_mobileNumber) {
            // 预取号
            _mobileNumber = await prefetch();
            // console.log('2.', _mobileNumber);
          }
          if (_mobileNumber) {
            // 取号成功：则一键登录
            const apiRes = await QuickLogin.onePass(uiConfig);
            const {token, accessToken} = apiRes || {};
            // console.log('3.', apiRes);
            if (token && accessToken) {
              // 下次重新取号
              reset();
              // 登录
              const res = await loginByYD(token, accessToken);
              isFunction(onSuccess) && onSuccess(res);
            }
          } else {
            // 取号失败：则使用其他登录
            otherLoginEnable && otherLogin();
          }
        } catch (e) {
          // console.log(e);
          // 一键登录失败，清空号码，下一重新执行需重新预取号
          reset();
          Dialog.info(e.toString());
        }
        setLoading(false);
        isFunction(final) && final();
      };
      fn();
    },
    [dispatch, launched, loginByYD, mobileNumber],
  );

  useEffect(() => {
    if (isAndroid() && isYDEnable) {
      global.quickLogin = quickLogin;
    }
  }, [isYDEnable, quickLogin]);

  // 检查启动活动弹窗
  const {loading: loading_getActivityPopup} = useRequest(
    () =>
      dispatch({
        type: 'activity_popup/getActivityPopup',
        trigger: logged ? 'launch' : 'launch_not_logged_in',
      }),
    {
      ready: !!updated,
      onSuccess(res) {
        if (res?.success) {
          if (isAndroid() && isYDEnable && !logged && res?.data?.jumpUrl === '/login') {
            // 检查是否支持一键登录
            return quickLogin({
              uiConfig: {
                dialogMode: true,
                dialogImage: res?.data?.pictureUrl,
              },
              otherLoginEnable: false,
              final: () => setLaunched(true),
            });
          }
          return openActivityModal({data: res?.data});
        }
        setLaunched(true);
      },
      onError() {
        setLaunched(true);
      },
    },
  );

  if (!agreement) {
    return children;
  }
  return (
    <AppLaunchedContext.Provider value={{updated, launched, quickLogin}}>
      <LoadingView
        delay={0}
        loading={loading || loading_loginByYD}
        layer={true}
        hideIndicator={!(loading_loginByYD || loading)}
        style={{flex: 1}}>
        {render()}
      </LoadingView>
    </AppLaunchedContext.Provider>
  );
};

export default AppLaunched;
