import {color_link} from '@/theme';
import {useLinkTo, useNavigation} from '@react-navigation/native';
import {isFunction, isString} from 'lodash';
import React from 'react';
import {TouchableWithoutFeedback, Text} from 'react-native';

const Link = ({title, to, onPress, children, style}) => {
  const navigation = useNavigation();
  const linkTo = useLinkTo();
  const handlePress = () => {
    if (isFunction(onPress)) {
      return onPress();
    }
    if (isString(to)) {
      return linkTo(to);
    }
    to && navigation.navigate(to);
  };
  const textStyle = {
    color: color_link,
  };
  const text = children ? children : isString(title) && title.length > 0 ? title : null;
  return (
    <TouchableWithoutFeedback onPress={handlePress}>
      <Text suppressHighlighting={true} style={[textStyle, style]}>
        {text}
      </Text>
    </TouchableWithoutFeedback>
  );
};

export default Link;
