import {Loading, LoadingView} from '@/components';
import BoxCard from '@/pages/box/components/BoxCard';
import theme from '@/theme';
import {useDispatch, useSelector} from '@/utils/dva';
import {useRequest} from 'ahooks';
import React, {useMemo} from 'react';
import {StyleSheet, VirtualizedList, Text, Platform} from 'react-native';
import _ from 'lodash';
import {isIos} from '@/utils/utils';

const BoxList = ({type, style, contentContainerStyle, loadingView = false}) => {
  const initialData = useSelector(s => s.box.data);
  const dispatch = useDispatch();
  const {data, loading} = useRequest(() => dispatch({type: 'box/getBoxes'}), {
    initialData,
    formatResult: res => res?.data,
  });

  const _data = useMemo(
    () =>
      _.filter(data, it => {
        if (type && type !== 'all') {
          return it?.type === type;
        }
        return true;
      }),
    [data, type],
  );

  return (
    <LoadingView loading={loadingView && loading} delay={300} layer={!!data?.length} style={[styles.container, style]}>
      {isIos() && <Text style={styles.alertText}>本活动与Apple Inc. 无关</Text>}
      <VirtualizedList
        nestedScrollEnabled={true}
        contentContainerStyle={[styles.list, contentContainerStyle]}
        data={_data}
        initialNumToRender={10}
        renderItem={({item}) => <BoxCard data={item} style={{marginBottom: 8}} />}
        keyExtractor={item => `${type}_${item.id}`}
        getItemCount={data => data?.length || 0}
        getItem={(data, index) => data[index]}
        ListFooterComponent={!loadingView && loading && <Loading />}
      />
    </LoadingView>
  );
};
const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: theme.fill_body,
    overflow: 'hidden',
    ...Platform.select({
      ios: {
        paddingBottom: 30,
      },
    }),
  },
  list: {
    paddingTop: 10,
    paddingHorizontal: 10,
  },

  alertText: {
    fontSize: 12,
    color: theme.color_text_secondary,
    lineHeight: 20,
    textAlign: 'center',
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 10,
    zIndex: 0,
  },
});

export default BoxList;
