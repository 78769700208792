import {AppLaunchedContext} from '@/AppLaunched';
import ActivityModal from '@/components/_modals/ActivityModal';
import {useDispatch} from '@/utils/dva';
import useDynamicModal from '@/utils/hooks/useDynamicModal';
import {useFocusEffect} from '@react-navigation/native';
import {useRequest} from 'ahooks';
import _, {isFunction} from 'lodash';
import React, {useCallback, useContext} from 'react';

class Popup {
  constructor({openHandle, closeHandle, onOpen, afterOpen, onClose, afterClose, name} = {}) {
    this.name = name;
    this.createTime = Date.now();
    this.opened = false;
    this.openHandle = openHandle;
    this.closeHandle = closeHandle;
    this.onOpen = onOpen;
    this.afterOpen = afterOpen;
    this.onClose = onClose;
    this.afterClose = afterClose;
  }

  open = async callback => {
    // console.log('[PM]Popup open', this.name, this);
    const {openHandle, onOpen, afterOpen} = this;
    this.opened = true;
    isFunction(onOpen) && onOpen.apply(this, [this]);
    const _open = () => {
      if (isFunction(openHandle)) {
        let result = openHandle(this);
        if (!(result instanceof Promise)) {
          result = Promise.resolve(result);
        }
        return result.then(res => {
          return res;
        });
      }
    };
    await _open(this);
    isFunction(afterOpen) && afterOpen.apply(this, [this]);
    isFunction(callback) && callback.apply(this, [this]);
  };

  close = async callback => {
    // console.log('[PM]Popup close', this.name);
    const {closeHandle, onClose, afterClose} = this;
    this.opened = false;
    isFunction(onClose) && onClose.apply(this, [this]);
    const _open = () => {
      if (isFunction(closeHandle)) {
        let result = closeHandle(this);
        if (!(result instanceof Promise)) {
          result = Promise.resolve(result);
        }
        return result.then(res => {
          return res;
        });
      }
    };
    await _open(this);
    isFunction(afterClose) && afterClose.apply(this, [this]);
    isFunction(callback) && callback.apply(this, [this]);
  };
}

class PopupManager {
  constructor() {}

  queue = [];

  create = (...args) => {
    const options = args?.length === 1 ? args[0] : args[2];
    let [openHandle, closeHandle] = args;
    if (!_.isFunction(openHandle)) {
      openHandle = options?.openHandle;
      closeHandle = options?.closeHandle;
    }
    const {autoRemove = true, afterClose, ...popupParams} = options || {};
    if (!isFunction(openHandle)) {
      throw 'PopupManager need an openHandle to create a new popup!';
    }

    const pm = this;
    const popup = new Popup({
      ...popupParams,
      openHandle,
      closeHandle,
      afterClose: p => {
        _.isFunction(afterClose) && afterClose(p);
        const next = this.getNextPopup(p);
        next?.open();
        autoRemove && pm.pop(p);
      },
    });
    // console.log('[PM]Popup created', popup.name, popup);
    this.push(popup);
    return popup;
  };

  push = popup => {
    this.queue.push(popup);
    this.showPopupIfNeeded();
    return this;
  };

  pop = popup => {
    _.pull(this.queue, popup);
    return this;
  };

  showFirstPopup = () => {
    const pm = this;
    _.chain(pm.queue)
      .filter(p => p.opened)
      .each(p => {
        try {
          p.close();
        } catch (e) {}
      })
      .value();
    _.first(pm.queue)?.open();
    return pm;
  };

  getNextPopup = popup => {
    const pm = this;
    const index = _.findIndex(pm.queue, p => p === popup);
    let nextIndex = index + 1;
    return pm.queue[nextIndex];
  };

  showPopupIfNeeded = () => {
    const pm = this;
    const opened = _.find(pm.queue, p => p.opened);
    if (!opened) {
      _.first(pm.queue)?.open();
    }
    return pm;
  };
}

let defaultPopupManager = '';

function getDefaultPopupManager() {
  if (!defaultPopupManager) {
    defaultPopupManager = new PopupManager();
  }
  return defaultPopupManager;
}

export const usePopupManager = () => {
  return getDefaultPopupManager();
};

export const useFocusCheckActivePopup = trigger => {
  const dispatch = useDispatch();
  // 若多个触发点同时触发弹窗，会影响点击跳转的功能。故此处订阅AppLaunched的launched状态来避免同时多个弹窗
  const {launched} = useContext(AppLaunchedContext);
  const pm = usePopupManager();
  // 活动弹窗
  const openActivityModal = useDynamicModal(ActivityModal);
  // 检查商城活动弹窗
  const {run: getActivityPopup} = useRequest(
    () =>
      dispatch({
        type: 'activity_popup/getActivityPopup',
        trigger,
      }),
    {
      manual: true,
      onSuccess(res) {
        if (res?.success) {
          pm.create(popup => {
            openActivityModal({
              data: res?.data,
              onAnimationEnd(visible) {
                if (!visible) {
                  popup.close(); //!important
                }
              },
            });
          });
        }
      },
    },
  );

  useFocusEffect(
    useCallback(() => {
      launched && getActivityPopup();
    }, [getActivityPopup, launched]),
  );
};

export default usePopupManager;
