import {InputItem} from '@/components';
import DatePicker from '@/components/DatePicker';
import List from '@/components/List';
import Selector from '@/components/Selector';
// import AvatarPicker from '@/pages/profile/components/AvatarPicker';
import EditAvatarPage from '@/pages/profile/editAvatar';
import theme from '@/theme';
import {useDispatch} from '@/utils/dva';
import authedPage from '@/utils/hooks/authedPage';
// import EditAvatarPage from './editAvatar';
// import EditIntroductionPage from './editIntroduction';
// import EditNamePage from './editName';
// import EditSexPage from './editSex';
// import EditBirthdayPage from '@/pages/profile/editBirthday';
import Gender from '@/utils/localData/Gender';
import {createStackNavigator} from '@/utils/navigatorUtils';
import {useLinkTo} from '@react-navigation/native';
import {useRequest} from 'ahooks';
import dayjs from 'dayjs';
import React from 'react';
import {ScrollView, Text} from 'react-native';
import Avatar from '@/components/Avatar';

const {Item} = List;
const itemProps = {
  offset: [0, 16],
  lineStyle: {paddingVertical: 20},
  // arrow: 'right',
};
const ProfilePage = ({currentUser}) => {
  const linkTo = useLinkTo();
  const dispatch = useDispatch();
  const {loading, run: update} = useRequest(data => dispatch({type: 'user/updateProfile', data}), {
    debounceInterval: 500,
    manual: true,
  });
  return (
    <ScrollView>
      <List border={false}>
        {/*<AvatarPicker value={currentUser?.headImg} onChange={headImg => update({headImg})} />*/}
        <List.Item
          {...itemProps}
          extra={<Avatar src={currentUser?.headImg} />}
          onPress={() => linkTo('/profile/edit_avatar')}>
          头像
        </List.Item>
        <InputItem
          {...itemProps}
          style={[itemProps.lineStyle, {height: 60}]}
          inputStyle={{fontSize: 14, textAlign: 'right', paddingRight: 0}}
          placeholder="请填写昵称"
          selectTextOnFocus={true}
          textAlign="right"
          defaultValue={currentUser?.nickName}
          onChange={nickName => update({nickName})}>
          昵称
        </InputItem>
        <Item {...itemProps} extra={currentUser?.mobile ? <Text>{currentUser.mobile}</Text> : '未绑定'}>
          手机号
        </Item>
        <Selector options={Gender.values()} defaultValue={currentUser?.sex} onChange={sex => update({sex})}>
          {(value, __, open) => (
            <Item
              {...itemProps}
              onPress={open}
              extra={value ? <Text>{value}</Text> : <Text style={{color: theme.color_text_placeholder}}>未选择</Text>}>
              性别
            </Item>
          )}
        </Selector>

        <DatePicker
          mode="date"
          defaultDate={new Date()}
          minDate={new Date(1950, 0, 1)}
          maxDate={new Date(new Date().getFullYear(), 11, 31)}
          onChange={value =>
            update({
              birthday: dayjs(value).format('YYYY-MM-DD'),
            })
          }
          extra={
            currentUser?.birthday ? (
              <Text>{dayjs(currentUser.birthday).format('YYYY-MM-DD')}</Text>
            ) : (
              <Text style={{color: theme.color_text_placeholder}}>未选择</Text>
            )
          }>
          <Item {...itemProps}>生日</Item>
        </DatePicker>

        {/*<Item
          {...itemProps}
          wrap
          align="top"
          multipleLine
          contentStyle={{flex: 0, flexBasis: 75}}
          extra={
            currentUser?.introduction ? <Text style={{flex: 1}}>{currentUser.introduction}</Text> : '您还未设置个性签名'
          }
          onPress={() => linkTo('/profile/edit_introduction')}>
          个性签名
        </Item>*/}
        <InputItem
          {...itemProps}
          style={[itemProps.lineStyle, {height: 60}]}
          inputStyle={{fontSize: 14, textAlign: 'right', paddingRight: 0}}
          placeholder="您还未设置个性签名"
          selectTextOnFocus={true}
          textAlign="right"
          type="text"
          numberOfLines={1}
          defaultValue={currentUser?.introduction}
          onChange={introduction => update({introduction})}>
          个性签名
        </InputItem>
      </List>
    </ScrollView>
  );
};

ProfilePage.screenName = 'MineProfileIndex';
ProfilePage.screenOptions = {
  title: '编辑个人资料',
};
ProfilePage.linking = '';

export default createStackNavigator(
  [
    ProfilePage,
    EditAvatarPage,
    // EditIntroductionPage,
    // EditNamePage,
    // EditSexPage,
    // EditBirthdayPage,
  ],
  {
    screenName: 'Profile',
    screenOptions: {
      headerShown: false,
    },
    linking: 'profile',
  },
  [],
  [authedPage],
);
