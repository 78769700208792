import {Devider} from '@/components';
import {getOrderPrice} from '@/utils/priceUtils';
import FontWeight from '@/utils/localData/FontWeight';
import {getDINProBoldStyle} from '@/utils/styleUtils';
import {assign} from 'lodash';
import React from 'react';
import {Platform, StyleSheet, Text, View} from 'react-native';
import {Flex} from '../../../../../components/Flex';

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#fff',
    paddingHorizontal: 20,
    paddingTop: 16, // 24 -> 16
    paddingBottom: 18, // 24 -> 18
    marginBottom: 10,
  },
  billText: {
    fontSize: 12,
    fontWeight: FontWeight.Medium,
    lineHeight: 17,
  },
  priceText: {
    ...getDINProBoldStyle(16),
    lineHeight: 20,
    marginLeft: 13,
    // ...Platform.select({
    //   android: {
    //     lineHeight: 22,
    //   },
    //   default: {
    //     marginBottom: -3,
    //   },
    // }),
  },
  priceUnit: {
    fontWeight: FontWeight.SemiBold,
    fontSize: 14,
    lineHeight: 20,
  },
  devider: {
    marginTop: 13,
    marginBottom: 18,
  },
});

const Component = ({section, data}) => {
  const price = getOrderPrice(data) || {};
  return (
    <View style={[styles.container, section.style]}>
      <Flex justify="between" style={{marginBottom: 13}}>
        <Text style={styles.billText}>商品总价</Text>
        <Text style={styles.billText}>{price?.text(2)}</Text>
      </Flex>
      <Flex justify="between">
        <Text style={styles.billText}>邮费</Text>
        <Text style={styles.billText}>0.00</Text>
      </Flex>
      <Devider borderStyle="dashed" borderWidth={1} style={styles.devider} />
      <Flex justify="end" align="center">
        <Text style={styles.billText}>合计</Text>
        <Text style={styles.priceText}>
          {price?.prefix}
          {price?.amount}
        </Text>
        <Text style={styles.priceUnit}>{price?.unit ? ' ' + price?.unit : ''}</Text>
      </Flex>
    </View>
  );
};

export default class BillSection {
  static type = 'bill';
  static component = Component;
  style = {};

  constructor(config) {
    assign(this, config);
  }

  get type() {
    return this?.constructor?.type;
  }
}
