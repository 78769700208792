import Icon from '@/components/Icon';
import {SearchButton} from '@/components/Search';
import theme from '@/theme';
import {useNavigation} from '@react-navigation/core';
import React from 'react';
import {Platform, StyleSheet, TouchableOpacity, View} from 'react-native';

const MallSearchBar = () => {
  const navigation = useNavigation();
  return (
    <View style={styles.searchBar}>
      <SearchButton style={{flex: 1}} />
      <TouchableOpacity
        onPress={() => {
          navigation.navigate('Catalogs');
        }}>
        <View style={styles.showAll}>
          <Icon type="showAll" />
        </View>
      </TouchableOpacity>
    </View>
  );
};

export default MallSearchBar;

const styles = StyleSheet.create({
  searchBar: {
    marginBottom: -9,
    paddingTop: 3 + 9,
    paddingBottom: 3 + 9,
    paddingHorizontal: theme.h_spacing_lg,
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#fff',
  },

  showAll: {
    marginLeft: 20,
    ...Platform.select({
      web: {
        top: 1,
      },
      default: {
        top: 3,
      },
    }),
  },
});
