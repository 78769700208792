import {
  bindApple,
  bindPhone,
  bindQQ,
  bindWechat,
  login,
  loginByApple,
  loginByQQ,
  loginByWechat,
  loginByYD,
  logoff,
  logout,
  unbindApple,
  unbindQQ,
  unbindWechat,
  updateProfile,
} from '@/services/user';
import LoginType from '@/utils/localData/LoginType';

export const namespace = 'user';
const model = {
  namespace,
  state: {
    loginType: LoginType.Phone,
  },
  effects: {
    /**
     * 登录
     * @actionParam data: {
     *    phone,
     *    validateCode
     * }
     * @actionParam token: 第三方授权后零时 ticket, 仅第三方登录
     * */
    *login({data, token}, {call, put}) {
      const service = token ? bindPhone : login;
      const res = yield call(service, data, token);
      if (res?.success) {
        yield put({type: 'global/saveCurrentUser', currentUser: res.data});
      }
      return res;
    },

    /**
     * 微信授权登录
     * @actionParam code
     * */
    *loginByWechat({code}, {call, put}) {
      const res = yield call(loginByWechat, code);
      if (res?.success) {
        yield put({type: 'global/saveCurrentUser', currentUser: res.data});
      }
      return res;
    },

    /**
     * 绑定微信
     * @actionParam code
     * */
    *bindWechat({code}, {call, put}) {
      const res = yield call(bindWechat, code);
      if (res?.success && res?.data) {
        // 更新用户数据
        yield put({type: 'global/mergeCurrentUser', currentUser: res.data});
      }
      return res;
    },

    *unbindWechat(__, {call, put}) {
      const res = yield call(unbindWechat);
      if (res?.success && res?.data) {
        // 更新用户数据
        yield put({type: 'global/mergeCurrentUser', currentUser: res.data});
      }
      return res;
    },

    /**
     * QQ授权登录
     * @actionParam accessToken
     * @actionParam openId
     * */
    *loginByQQ({accessToken, openId}, {call, put}) {
      const res = yield call(loginByQQ, accessToken, openId);
      if (res?.success) {
        yield put({type: 'global/saveCurrentUser', currentUser: res.data});
      }
      return res;
    },

    /**
     * 绑定QQ
     * @actionParam accessToken
     * @actionParam openId
     * */
    *bindQQ({accessToken, openId}, {call, put}) {
      const res = yield call(bindQQ, accessToken, openId);
      if (res?.success && res?.data) {
        // 更新用户数据
        yield put({type: 'global/mergeCurrentUser', currentUser: res.data});
      }
      return res;
    },

    *unbindQQ(__, {call, put}) {
      const res = yield call(unbindQQ);
      if (res?.success && res?.data) {
        // 更新用户数据
        yield put({type: 'global/mergeCurrentUser', currentUser: res.data});
      }
      return res;
    },

    /**
     * Apple授权登录
     * @actionParam identityToken
     * @actionParam userID
     * */
    *loginByApple({identityToken, userID}, {call, put}) {
      const res = yield call(loginByApple, identityToken, userID);
      if (res?.success) {
        yield put({type: 'global/saveCurrentUser', currentUser: res.data});
      }
      return res;
    },

    /**
     * 绑定Apple
     * @actionParam identityToken
     * @actionParam userID
     * */
    *bindApple({identityToken, userID}, {call, put}) {
      const res = yield call(bindApple, identityToken, userID);
      if (res?.success && res?.data) {
        // 更新用户数据
        yield put({type: 'global/mergeCurrentUser', currentUser: res.data});
      }
      return res;
    },

    *unbindApple(__, {call, put}) {
      const res = yield call(unbindApple);
      if (res?.success && res?.data) {
        // 更新用户数据
        yield put({type: 'global/mergeCurrentUser', currentUser: res.data});
      }
      return res;
    },

    /**
     * 网易易盾授权登录
     * @actionParam token
     * @actionParam accessToken
     * */
    *loginByYD({token, accessToken}, {call, put}) {
      const res = yield call(loginByYD, token, accessToken);
      if (res?.success) {
        yield put({type: 'global/saveCurrentUser', currentUser: res.data});
      }
      return res;
    },

    *cleanUserData(__, {put}) {
      yield put({type: 'global/saveCurrentUser', currentUser: null});
      yield put({type: 'piece/clearMyPieces'});
      yield put({type: 'point/clearBillItems'});
      yield put({type: 'address/clear'});
      yield put({type: 'tools/clear'});
    },

    /**
     * 退出登录
     * */
    *logout(__, {call, put}) {
      const res = yield call(logout);
      if (res?.success) {
        yield put({type: 'cleanUserData'});
      }
      return res;
    },

    /**
     * 销户
     * */
    *logoff(__, {call, put}) {
      const res = yield call(logoff);
      if (res?.success) {
        yield put({type: 'cleanUserData'});
      }
      return res;
    },
    /**
     * 资料当前用户修改
     * @actionParam data
     * */
    *updateProfile({data}, {call, select, put}) {
      const res = yield call(updateProfile, data);
      if (res?.success) {
        // 更新全局用户数据
        const currentUser = yield select(s => s.global.currentUser);
        yield put({type: 'global/saveCurrentUser', currentUser: {...currentUser, ...data}});
      }
      return res;
    },
  },
  reducers: {
    setLoginType(state, {loginType}) {
      return {...state, loginType};
    },
  },
};

export default model;
