import Button from '@/components/Button';
import createMaterialTopTabNavigator from '@/components/material-top-tabs/navigators/createMaterialTopTabNavigator';
import BoxRecordsContext from '@/pages/box/records/components/BoxRecordsContext';
import OpenedBoxList from '@/pages/box/records/components/OpenedBoxList';
import theme from '@/theme';
import authedPage from '@/utils/hooks/authedPage';
import BoxRarity from '@/utils/localData/BoxRarity';
import BoxRecordStatus from '@/utils/localData/BoxRecordStatus';
import _ from 'lodash';
import React, {useMemo, useState} from 'react';
import {Dimensions, StyleSheet, View} from 'react-native';

const Tab = createMaterialTopTabNavigator();
const tabComponents = {};
const getTabComponent = (key, value, _props) => {
  if (!tabComponents[key]) {
    tabComponents[key] = React.memo(props => <OpenedBoxList {...props} {..._props} status={value} />);
  }
  return tabComponents[key];
};

const rarities = [{text: '全部', value: ''}, ...BoxRarity.values()];
const BoxRecordsPage = authedPage(() => {
  const [rarity, setRarity] = useState('');
  const [containerHeight, setContainerHeight] = useState(Dimensions.get('window').height);
  const providerValue = useMemo(() => ({rarity, containerHeight}), [containerHeight, rarity]);
  return (
    <View
      style={{flex: 1}}
      onLayout={evt => {
        setContainerHeight(evt.nativeEvent.layout.height);
      }}>
      <BoxRecordsContext.Provider value={providerValue}>
        <Tab.Navigator
          backBehavior="back"
          sceneContainerStyle={styles.sceneContainerStyle}
          screenOptions={{
            tabBarStyle: styles.tabBarStyle,
            tabBarIndicatorStyle: styles.tabBarIndicatorStyle,
            tabBarItemStyle: styles.tabBarItemStyle,
            tabBarInactiveTintColor: theme.color_text_secondary,
            lazy: true,
            lazyPreloadDistance: 0,
          }}>
          {_.map(
            BoxRecordStatus.filter(it => it?.link),
            (tab, i) => {
              const {key, text, value} = tab || {};
              return (
                <Tab.Screen
                  key={`$orderTab_${key}`}
                  name={key}
                  options={{
                    tabBarLabel: text,
                  }}
                  component={getTabComponent(key, value)}
                />
              );
            },
          )}
        </Tab.Navigator>
      </BoxRecordsContext.Provider>
      <View style={styles.rarityBar}>
        {rarities.map(({key, text, value}) => (
          <Button
            key={key}
            style={styles.rarityBarBtn}
            size="small" // type={value === rarity ? 'primary' : 'ghost'}
            type="primary"
            color={value === rarity ? '#000' : '#E5E6E9'}
            textStyle={value === rarity ? {} : {color: '#666'}}
            onPress={() => setRarity(value)}>
            {text}
          </Button>
        ))}
      </View>
    </View>
  );
});
BoxRecordsPage.screenName = 'BoxRecords';
BoxRecordsPage.screenOptions = {
  title: '开盒记录',
};
BoxRecordsPage.linking = {
  path: 'records',
  screens: _.chain(BoxRecordStatus.filter(it => it?.link))
    .keyBy('key')
    .mapValues((v, key) => key)
    .value(),
};
export default BoxRecordsPage;
const styles = StyleSheet.create({
  sceneContainerStyle: {
    padding: 0,
    backgroundColor: theme.fill_body,
  },
  tabBarIndicatorStyle: {
    backgroundColor: theme.brand_primary,
    width: 42,
    marginHorizontal: (Dimensions.get('window').width / 4 - 42) / 2,
  },
  tabBarItemStyle: {
    minHeight: 46,
    padding: 0,
  },
  tabBarStyle: {
    marginBottom: 37,
  },
  rarityBar: {
    position: 'absolute',
    top: 46,
    left: -5,
    right: 0,
    height: 37,
    paddingTop: 8,
    paddingBottom: 5,
    paddingHorizontal: 10,
    flexDirection: 'row',
  },
  rarityBarBtn: {
    flex: 1,
    marginLeft: 5,
    height: 24,
  },
});
