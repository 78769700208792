import ShadowIcon from '@/components/Header/ShadowIcon';
import {IconButton} from '@/components/Icon';
import {isWeb} from '@/utils/utils';
import {useNavigation} from '@react-navigation/core';
import {HeaderBackButton} from '@react-navigation/elements';
import React from 'react';
import {Platform, StyleSheet, View} from 'react-native';

const styles = StyleSheet.create({
  HeaderLeft: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    ...Platform.select({
      web: {
        marginLeft: 5,
      },
      android: {
        marginLeft: -10,
      },
    }),
  },
  BackButton: {
    // marginLeft: 0,
  },
  BtnShadow: {
    marginLeft: 15,
  },
});
const HeaderLeft = ({
  tintColor = '#000',
  children,
  navigation: _navigation,
  showBtnShadow = false,
  shadowBtnStyle,
} = {}) => {
  const __navigation = useNavigation();
  const navigation = _navigation || __navigation;
  const onPress = () => {
    if (navigation.canGoBack()) {
      navigation.goBack();
    } else {
      navigation?.replace?.('Home');
    }
  };
  return (
    <View style={styles.HeaderLeft}>
      {showBtnShadow ? (
        <ShadowIcon
          type="arrow_left"
          style={[styles.BackButton, styles.BtnShadow, shadowBtnStyle]}
          fill={tintColor}
          size={15}
          iconStyle={{marginLeft: -4}}
          onPress={onPress}
        />
      ) : (
        <HeaderBackButton
          style={[styles.BackButton]}
          tintColor={tintColor}
          pressColor={'transparent'}
          backImage={() => <IconButton type="arrow_left" fill={tintColor} />}
          onPress={onPress}
          canGoBack={true}
        />
      )}

      {children}
    </View>
  );
};

export default HeaderLeft;
