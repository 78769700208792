import Button from '@/components/Button';
import {Flex} from '@/components/Flex';
import theme from '@/theme';
import useBottomInset from '@/utils/hooks/useBottomInset';
import FontWeight from '@/utils/localData/FontWeight';
import {getDINProBoldStyle} from '@/utils/styleUtils';
import React from 'react';
import {StyleSheet, Text, View} from 'react-native';

const styles = StyleSheet.create({
  toolbar: {
    borderTopWidth: StyleSheet.hairlineWidth,
    borderTopColor: theme.border_color_base,
    backgroundColor: '#fff',
  },
  container: {
    paddingHorizontal: theme.h_spacing_lg,
    paddingVertical: theme.v_spacing_md,
  },
  amount: {},
  amountLabel: {
    fontWeight: FontWeight.SemiBold,
    fontSize: 14,
    lineHeight: 17,
    marginRight: 5,
  },
  amountValue: {
    ...getDINProBoldStyle(14),
    lineHeight: 17,
  },
  amountUnit: {
    fontWeight: FontWeight.SemiBold,
    fontSize: 14,
    lineHeight: 17,
    marginLeft: 2,
  },
  payBtn: {
    width: 140,
    height: 48,
    marginLeft: 15,
  },
});
const OrderPayBar = ({price, submitting, submit}) => {
  const [, , , inset] = useBottomInset(10);
  return (
    <View style={[styles.toolbar, {paddingBottom: inset}]}>
      <Flex justify="between" style={[styles.container]}>
        <Flex align="center">
          <Flex align="center">
            <Text style={styles.amountLabel}>总价：</Text>
            <Text style={styles.amountValue}>{price ?? 0}</Text>
            <Text style={styles.amountUnit}>口袋币</Text>
          </Flex>
        </Flex>
        <Flex align="center">
          <Button type="primary" loading={submitting} style={styles.payBtn} onPress={submit}>
            立即支付
          </Button>
        </Flex>
      </Flex>
    </View>
  );
};

export default OrderPayBar;
