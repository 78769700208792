import OrderDetailPage from '@/pages/order/detail';
import OrderListPage from '@/pages/order/list';
import authedPage from '@/utils/hooks/authedPage';
import {createStackNavigator} from '@/utils/navigatorUtils';
import React from 'react';

export default createStackNavigator(
  [OrderListPage, OrderDetailPage],
  {
    screenName: 'Order',
    screenOptions: {
      headerShown: false,
    },
    linking: 'order',
  },
  [],
  [authedPage],
);
