import {LoadingView} from '@/components';
import OrderContext from '@/pages/order/detail/components/OrderContext';
import OrderPayBar from '@/pages/order/detail/components/OrderPayBar';
import OrderReceiveBar from '@/pages/order/detail/components/OrderReceiveBar';
import OrderSections from '@/pages/order/detail/components/OrderSections';
import {useDispatch} from '@/utils/dva';
import useNestingBackButton from '@/utils/hooks/useNestingBackButton';
import OrderStatus from '@/utils/localData/OrderStatus';
import {useRequest, useSetState} from 'ahooks';
import _ from 'lodash';
import React from 'react';
import {ScrollView, StyleSheet} from 'react-native';

const styles = StyleSheet.create({
  page: {
    flex: 1,
    zIndex: 0,
  },
});

const OrderDetailPage = ({navigation, route}) => {
  useNestingBackButton();
  const {id, data: initialData, refreshData} = route?.params || {};
  const dispatch = useDispatch();

  // 加载数据
  const {
    loading,
    data,
    mutate: _mutate,
  } = useRequest(() => dispatch({type: 'order/getOrder', id}), {
    ready: !!id,
    initialData,
    formatResult: res => res?.data,
  });

  const [state, setState] = useSetState({
    address: null,
    remark: '',
    itemDoms: null,
  });
  const {status} = data || {};
  const mutate = newData => {
    _mutate(newData);
    _.isFunction(refreshData) && refreshData(newData);
  };

  return (
    <LoadingView loading={loading} style={[styles.page]}>
      {data && (
        <OrderContext.Provider value={{state, setState}}>
          <ScrollView>
            <OrderSections data={data} mutate={mutate} />
          </ScrollView>
          {status === OrderStatus.Unpaid && <OrderPayBar data={data} mutate={mutate} state={state} />}
          {status === OrderStatus.Delivered && <OrderReceiveBar data={data} mutate={mutate} />}
        </OrderContext.Provider>
      )}
    </LoadingView>
  );
};

OrderDetailPage.screenName = 'OrderDetail';
OrderDetailPage.screenOptions = {
  title: '订单详情',
  headerTransparent: false,
};
OrderDetailPage.linking = {
  path: 'detail/:id',
  parse: {
    id: Number,
  },
};
export default OrderDetailPage;
