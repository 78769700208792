import _ from 'lodash';
import {
  buy,
  getBox,
  getBoxes,
  getPagedOpendBoxes,
  getPickUpOrder,
  getRecommendBoxes,
  getResult,
  getRollingNotices,
  pickUp,
  recovery,
} from './service';

export const namespace = 'box';
const model = {
  namespace,
  state: {
    data: [],
    recommendBoxes: [], // 推荐盲盒
    rollingNotices: [], // 用户开头滚动通知
  },
  effects: {
    /**
     * 盲盒查询
     * */
    *getBoxes(__, {call, put}) {
      const res = yield call(getBoxes);
      if (res?.success) {
        yield put({type: 'saveState', data: res?.data});
      }
      return res;
    },

    /**
     * 盲盒详情查询
     * @actionParam id: 盲盒id
     * */
    *getBox({id}, {call}) {
      return yield call(getBox, id);
    },

    /**
     * 查询推荐盲盒
     * @actionParam boxId
     * */
    *getRecommendBoxes({boxId}, {call, put}) {
      const res = yield call(getRecommendBoxes, boxId);
      if (res?.success) {
        yield put({type: 'saveState', recommendBoxes: res.data});
      }
      return res;
    },

    /**
     * 查询全网开盒滚动通知
     * */
    *getRollingNotices(__, {call, put}) {
      const res = yield call(getRollingNotices);
      if (res?.success) {
        yield put({type: 'saveState', rollingNotices: res?.data});
      }
      return res;
    },

    /**
     * 购买盲盒
     * @actionParam params: {
     *   id
     *   count: 数量
     *   payType: 支付方式
     * }
     * */
    *buy({data}, {call}) {
      return yield call(buy, data);
    },

    /**
     * 开盒结果查询（需要轮询直到返回成功）
     * @param orderId
     * */
    *getResult({orderId}, {call}) {
      return yield call(getResult, orderId);
    },

    /**
     * 回收
     * @param data: {
     *   ids: [],
     *   toolId,
     * }
     * */
    *recovery({data}, {call}) {
      return yield call(recovery, data);
    },

    /**
     * 批量提货
     * @actionParam data: {
     *    items,
     *    payType,
     * }
     * */
    *pickUp({data}, {call, put}) {
      return yield call(pickUp, data);
    },

    /**
     * 查询提货确认订单信息
     * @actionParam id: 确认订单的id
     * */
    *getPickUpOrder({id}, {call, put}) {
      return yield call(getPickUpOrder, id);
    },

    /**
     * 开盒记录分页查询
     * @actionParam params: {
     *     current,
     *     pageSize,
     *     status: 状态，见BoxProdStatus.js
     *     rarity: 稀有度
     * }
     * */
    *getPagedOpendBoxes({params}, {call}) {
      return yield call(getPagedOpendBoxes, params);
    },
  },
  reducers: {
    saveState(state, action) {
      const {type, ...nextState} = action || {};
      return {...state, ...nextState};
    },

    addData(state, action) {
      const {data} = action || {};
      const oldData = state.data;
      return {...state, data: _.concat([], oldData ?? [], data ?? [])};
    },

    clearData(state, action) {
      return {...state, data: []};
    },
  },
  subscriptions: {
    RollingNotices({dispatch}) {
      console.warn('订阅全网开盒的滚动通知（3分钟刷新）');
      const fetch = () => {
        // console.warn('刷新滚动通知');
        dispatch({type: 'getRollingNotices'});
      };
      const handleId = setInterval(fetch, 60000 * 3);
      return () => clearInterval(handleId);
    },
  },
};

export default model;
