import React from 'react';
import {Text} from 'react-native';

const LinearTextGradient = ({style, backgroundImage, ...restProps}) => {
  return React.createElement(Text, {
    ...restProps,
    style: [
      style,
      {
        backgroundClip: 'text',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
        backgroundImage,
      },
    ],
  });
};
export default LinearTextGradient;
