import ProductDetailPage from '@/pages/product/detail';
import ProductListPage from '@/pages/product/list';
import ProductOrderPage from '@/pages/product/order';
import {createStackNavigator} from '@/utils/navigatorUtils';
import React from 'react';

export default createStackNavigator([ProductListPage, ProductDetailPage, ProductOrderPage], {
  screenName: 'Product',
  screenOptions: {
    headerShown: false,
  },
  linking: 'product',
});
