import Icon from '@/components/Icon';
import Loading from '@/components/Loading';
import BoxCard from '@/pages/box/components/BoxCard';
import theme from '@/theme';
import {useDispatch, useSelector} from '@/utils/dva';
import {useNavigation} from '@react-navigation/core';
import {useRequest, useTimeout} from 'ahooks';
import React, {useState} from 'react';
import {FlatList, StyleSheet, Text, View} from 'react-native';
import _ from 'lodash';

const styles = StyleSheet.create({
  list: {
    backgroundColor: theme.fill_body,
    paddingBottom: 70,
  },
  listHead: {
    height: 37,
    paddingTop: 8,
    paddingBottom: 12,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.fill_body,
  },
  listHeadText: {
    fontSize: 12,
    lineHeight: 17,
  },
  item: {
    height: 155,
    marginHorizontal: 10,
    marginBottom: 8,
    borderWidth: StyleSheet.hairlineWidth,
    borderRadius: 4,
    borderColor: theme.border_color_base,
    backgroundColor: '#fff',
  },
});
const ListHead = () => {
  return (
    <View style={styles.listHead}>
      <Icon type="star" size={10} fill="#B1B1B1" style={{marginRight: 8}} />
      <Text style={styles.listHeadText}>更多推荐</Text>
      <Icon type="star" size={10} fill="#B1B1B1" style={{marginLeft: 8}} />
    </View>
  );
};
const BoxRecommends = ({boxId, ListHeaderComponent, ...restProps}) => {
  const initialData = useSelector(s => s.box.recommendBoxes);
  const dispatch = useDispatch();
  const navigation = useNavigation();

  // 为了更快加载盲盒内图片，延迟加载推荐盲盒
  const [ready, setReady] = useState(false);
  useTimeout(() => {
    setReady(true);
  }, 800);

  const {data, loading} = useRequest(() => dispatch({type: 'box/getRecommendBoxes', boxId}), {
    ready: ready,
    initialData: _.filter(initialData, dt => dt?.id !== boxId),
    formatResult: res => _.filter(res?.data, dt => dt?.id !== boxId),
  });

  return (
    <FlatList
      {...restProps}
      alwaysBounceVertical={false}
      bounces={false}
      ListHeaderComponent={ListHeaderComponent}
      contentContainerStyle={styles.list}
      data={data}
      renderItem={({item}) => {
        return <BoxCard data={item} style={styles.item} onPress={() => navigation.push('BoxDetail', {id: item.id})} />;
      }}
      ListFooterComponent={loading && <Loading />}
    />
  );
};
BoxRecommends.ListHead = ListHead;
export default BoxRecommends;
