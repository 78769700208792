import {Dialog, Icon, LoadingView, TranslucentStatusBar} from '@/components';
import ActivityModal from '@/components/_modals/ActivityModal';
import {Flex} from '@/components/Flex';
import BindPhone from '@/pages/login/components/BindPhone';
import LoginByQQ from '@/pages/login/components/LoginByQQ';
import LoginBySMS from '@/pages/login/components/LoginBySMS';
import LoginByWechat from '@/pages/login/components/LoginByWechat';
import LoginByYD from '@/pages/login/components/LoginByYD';
import QuickLogin from '@/pages/login/components/QuickLogin';
import RegisterAwardModal from '@/pages/login/components/RegisterAwardModal';
import {useDispatch, useSelector} from '@/utils/dva';
import useDynamicModal from '@/utils/hooks/useDynamicModal';
import useGlobalCallback from '@/utils/hooks/useGlobalCallback';
import usePopupManager from '@/utils/hooks/usePopupManager';
import FontWeight from '@/utils/localData/FontWeight';
import LoginType from '@/utils/localData/LoginType';
import {isAndroid, isIos, isNative} from '@/utils/utils';
import {useRequest, useSetState, useUnmount} from 'ahooks';
import React, {useCallback, useEffect} from 'react';
import {Platform, StyleSheet, Text, View} from 'react-native';
import LoginByApple from '@/pages/login/components/LoginByApple';
import useLov from '@/utils/hooks/useLov';
import _ from 'lodash';

const _is_on_off_for_platform = (lovs, type) => {
  if (lovs?.hasOwnProperty(type)) {
    return _.includes(String(lovs[type]).split('|'), Platform.OS);
  }
  return false;
};

const LoginPage = ({route, navigation}) => {
  const {id} = route?.params || {};
  const loginTypeLovs = useLov('APP_LOGIN_TYPE');
  const isLoginTypeEnable = useCallback(type => _is_on_off_for_platform(loginTypeLovs, type), [loginTypeLovs]);
  const dispatch = useDispatch();
  const pm = usePopupManager();
  const callbackRef = useGlobalCallback(id);
  const reward200 = useDynamicModal(RegisterAwardModal, {
    closeBeforeRemove: false,
  });

  // 活动弹窗
  const openActivityModal = useDynamicModal(ActivityModal);
  // 检查新用户弹窗
  const {run: getActivityPopup} = useRequest(
    () =>
      dispatch({
        type: 'activity_popup/getActivityPopup',
        trigger: 'new_user',
      }),
    {
      manual: true,
      onSuccess(res) {
        if (res?.success) {
          pm.create(popup => {
            openActivityModal({
              data: res?.data,
              onAnimationEnd(visible) {
                if (!visible) {
                  popup.close(); //!important
                }
              },
            });
          });
        }
      },
    },
  );

  const loginType = useSelector(s => s.user.loginType);
  const onChangeLoginType = loginType => dispatch({type: 'user/setLoginType', loginType});
  const [state, setState] = useSetState({
    loading: false,
    agreementTipVisible: false,
    agreement: false,
    phone: '',
    validateCode: '',
    // ...
    // token 绑定手机号
    // mobileNumber 本机号码一键登录
  });

  const onSuccess = res => {
    if (res?.success) {
      if (res?.callbacks?.award200) {
        // 注册赠送200口袋币
        setTimeout(() => {
          pm.create(
            popup => {
              reward200({
                onAnimationEnd(visible) {
                  if (!visible) {
                    popup.close(); //!important
                  }
                },
              });
            },
            null,
            {
              onClose() {
                getActivityPopup();
              },
            },
          );
        }, 0);
      } else {
        Dialog.success(res.msg || '登录成功');
      }
      if (callbackRef?.current?.callback) {
        callbackRef.current.callback({
          navigation,
        });
      } else {
        navigation.canGoBack() ? navigation.goBack() : navigation.navigate('Home');
      }
    }
  };

  useUnmount(() => {
    onChangeLoginType(LoginType.Phone);
  });

  useEffect(() => {
    setState({agreementTipVisible: false});
  }, [loginType, setState]);

  useEffect(() => {
    if (loginType === LoginType.Phone) {
      navigation.setOptions({
        headerTitle: () => <Icon type="text_logo" />,
      });
    }
  }, [loginType, navigation]);

  return (
    <LoadingView loading={state?.loading} layer={true} style={styles.page}>
      <TranslucentStatusBar />
      <View style={styles.container}>
        <Text style={styles.title}>欢迎登录口袋盲盒</Text>
        <View style={styles.forms}>
          {/*<LoginByPassword state={state} setState={setState} onChangeLoginType={onChangeLoginType} />*/}
          <LoginBySMS
            loginType={loginType}
            onChangeLoginType={onChangeLoginType}
            state={state}
            setState={setState}
            onSuccess={onSuccess}
          />
        </View>
        {isNative() && (
          <View style={styles.others}>
            {(isLoginTypeEnable('apple') ||
              isLoginTypeEnable('wechat') ||
              isLoginTypeEnable('qq') ||
              isLoginTypeEnable('yd')) && (
              <Flex justify="center">
                <View style={styles.othersTitleBorder} />
                <Text style={styles.othersTitle}>其他方式登录</Text>
                <View style={styles.othersTitleBorder} />
              </Flex>
            )}

            <Flex justify="center" style={styles.othersContent}>
              {isIos() && isLoginTypeEnable('apple') && (
                <LoginByApple
                  loginType={loginType}
                  onChangeLoginType={onChangeLoginType}
                  state={state}
                  setState={setState}
                  onSuccess={onSuccess}
                />
              )}
              {isLoginTypeEnable('wechat') && (
                <LoginByWechat
                  loginType={loginType}
                  onChangeLoginType={onChangeLoginType}
                  state={state}
                  setState={setState}
                  onSuccess={onSuccess}
                />
              )}
              {isLoginTypeEnable('qq') && (
                <LoginByQQ
                  loginType={loginType}
                  onChangeLoginType={onChangeLoginType}
                  state={state}
                  setState={setState}
                  onSuccess={onSuccess}
                />
              )}
              {isAndroid() && isLoginTypeEnable('yd') && (
                <LoginByYD
                  loginType={loginType}
                  onChangeLoginType={onChangeLoginType}
                  state={state}
                  setState={setState}
                  onSuccess={onSuccess}
                />
              )}
            </Flex>
          </View>
        )}
      </View>
      {loginType === LoginType.BindPhone && state?.token && (
        <View style={[styles.bindPhoneContainer, StyleSheet.absoluteFill]}>
          <BindPhone
            navigation={navigation}
            loginType={loginType}
            onChangeLoginType={onChangeLoginType}
            onSuccess={onSuccess}
            token={state.token}
          />
        </View>
      )}
      {loginType === LoginType.QuickLogin && (
        <View style={[styles.bindPhoneContainer, StyleSheet.absoluteFill]}>
          <QuickLogin
            navigation={navigation}
            loginType={loginType}
            onChangeLoginType={onChangeLoginType}
            state={state}
            setState={setState}
            onSuccess={onSuccess}
            hasCallback={!!callbackRef?.current?.callback}
          />
        </View>
      )}
    </LoadingView>
  );
};

LoginPage.screenName = 'Login';
LoginPage.screenOptions = {
  title: '登录',
  headerTitle: () => null,
};
LoginPage.linking = 'login';
export default LoginPage;

const styles = StyleSheet.create({
  page: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    overflow: 'hidden',
  },

  container: {
    marginTop: 69,
    width: 300,
    // overflow: 'hidden',
  },
  bindPhoneContainer: {
    backgroundColor: '#fff',
  },

  title: {
    marginBottom: 32,
    lineHeight: 32,
    fontSize: 24,
    fontWeight: FontWeight.Light,
  },

  forms: {
    position: 'relative',
    width: '100%',
    height: 330,
  },

  others: {},
  othersTitle: {
    fontSize: 12,
    lineHeight: 20,
    color: '#a6aab5',
  },
  othersTitleBorder: {
    width: 54,
    height: 1,
    backgroundColor: '#e4e4e6',
    marginHorizontal: 12,
  },
  othersContent: {
    marginVertical: 28,
  },
  socialIcon: {
    marginHorizontal: 25,
    width: 24,
    height: 24,
  },
});
