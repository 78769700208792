import {Flex} from '@/components/Flex';
import {getPrice} from '@/utils/priceUtils';
import theme from '@/theme';
import FontWeight from '@/utils/localData/FontWeight';
import {useImageResize} from '@/utils/remoteData/ImageResize';
import {useLinkTo} from '@react-navigation/native';
import React from 'react';
import {Image, Pressable, StyleSheet, Text, View} from 'react-native';

const styles = StyleSheet.create({
  container: {
    padding: 15,
    width: '100%',
    height: 258,
  },
  image: {
    width: '100%',
    height: 135,
    marginBottom: 10,
  },
  title: {
    fontSize: 12,
    fontWeight: FontWeight.Light,
  },
  mainPrice: {
    fontSize: 14,
    fontWeight: FontWeight.SemiBold,
  },
  rmbPrice: {
    fontSize: 10,
    color: theme.color_text_secondary,
  },
  likeCount: {
    fontSize: 10,
    color: theme.color_text_secondary,
  },
});

const ProdCardCommon = ({data, style}) => {
  const linkTo = useLinkTo();
  const {id, name, picture, wantCount = 0} = data || {};
  const [price, rmbPrice] = getPrice(data);
  const [, resizeImage] = useImageResize({object: 'prod', size: 'sm'});

  return (
    <Pressable style={[styles.container, style]} onPress={() => id && linkTo(`/product/detail/${id}`)}>
      <Image
        style={styles.image}
        resizeMode="contain" // todo: contain or cover ?
        source={{uri: resizeImage(picture)}}
      />
      <Flex style={{flex: 1}} direction="column" align="stretch" justify="between">
        <Text style={styles.title} numberOfLines={2}>
          {name}
        </Text>
        <View>
          <Text style={styles.mainPrice}>{price.text()}</Text>
          <Flex justify="between" style={{marginTop: 4}}>
            <Text style={styles.rmbPrice}>{rmbPrice.isAvailable() && rmbPrice.text()}</Text>
            <Text style={styles.likeCount}>{wantCount} 人想要</Text>
          </Flex>
        </View>
      </Flex>
    </Pressable>
  );
};

export default ProdCardCommon;
