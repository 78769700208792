import {Center} from '@/components';
import theme from '@/theme';
import _ from 'lodash';
import React, {useEffect, useState} from 'react';
import {ActivityIndicator, StyleSheet, View} from 'react-native';

const styles = StyleSheet.create({
  layer: {
    opacity: 0.7,
    backgroundColor: '#fff',
    zIndex: 99998,
  },
  activityIndicator: {
    zIndex: 99999,
  },
});
const LoadingView = ({
  style,
  loading,
  delay = 300,
  layer = false,
  hideIndicator = false,
  opacity = 0.7,
  children,
  ...rest
}) => {
  const [delayLoading, setDelayLoading] = useState(false);
  const isDelay = _.isNumber(delay) && delay > 0;

  useEffect(() => {
    if (isDelay && loading) {
      const id = setTimeout(() => {
        setDelayLoading(true);
      }, delay);
      return () => clearTimeout(id);
    }
    if (!loading) {
      setDelayLoading(false);
    }
  }, [loading, delay, isDelay]);

  const activityIndicator = (
    <Center style={[StyleSheet.absoluteFill]}>
      <Center style={[StyleSheet.absoluteFill, layer && styles.layer, hideIndicator && {opacity: 0}]} />
      {!hideIndicator && (
        <ActivityIndicator color={theme.brand_primary} size="large" style={styles.activityIndicator} />
      )}
    </Center>
  );

  const _loading = isDelay ? delayLoading : loading;
  return (
    <View style={[style]} {...rest}>
      {(!loading || layer) && children}
      {_loading && activityIndicator}
    </View>
  );
};

export default LoadingView;
