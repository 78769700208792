import {Center, Dialog, LoadingView} from '@/components';
import Button from '@/components/Button';
import {Flex} from '@/components/Flex';
import Icon from '@/components/Icon';
import IntlCodeSelector from '@/components/IntlCodeSelector';
import theme from '@/theme';
import {useDispatch} from '@/utils/dva';
import FontWeight from '@/utils/localData/FontWeight';
import LoginType from '@/utils/localData/LoginType';
import {isValidPhone} from '@/utils/formUtils';
import {useCountDown, useRequest, useSetState, useUnmount} from 'ahooks';
import _, {isFunction} from 'lodash';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Animated, StyleSheet, Text, TextInput, TouchableOpacity, View} from 'react-native';
import InputItem from './InputItem';
import _styles from './styles';
import useFadeInOut from './useFadeInOut';
import {isAndroid} from '@/utils/utils';

const Step0 = ({visible, state, setState, loading, countdown, getCaptcha}) => {
  const style = useFadeInOut(visible);
  const inputRef = useRef(null);

  useEffect(() => {
    if (!visible) {
      inputRef?.current?.blur?.();
    }
  }, [visible, inputRef]);

  return (
    <Animated.View style={[styles.step0, style]}>
      <View style={{marginBottom: 36}}>
        <InputItem
          inputRef={inputRef}
          value={state?.phone}
          onChange={phone => {
            setState({phone});
          }}
          placeholder="输入手机号">
          <IntlCodeSelector
            defaultValue={'+86'}
            onChange={intlCode => {
              setState({intlCode});
            }}>
            {code => (
              <Flex>
                <Text style={_styles.formItemLabel}>{code}</Text>
                <Icon style={{marginLeft: 3}} type="arrow_down" size={6} fill={'#000'} />
              </Flex>
            )}
          </IntlCodeSelector>
        </InputItem>
        <Text style={_styles.alertText}>未注册的手机号验证后自动创建账户</Text>
      </View>
      <View style={{marginBottom: 26}}>
        <Button
          type="primary"
          disabled={loading || !(isValidPhone(state?.phone) && countdown === 0)}
          loading={loading}
          size="large"
          onPress={getCaptcha}>
          获取验证码
        </Button>
      </View>
    </Animated.View>
  );
};
const Step1 = ({visible, state, setState, loading, countdown, getCaptcha, submit, submitting}) => {
  const style = useFadeInOut(visible);

  const handleGetCaptcha = () => {
    if (loading || countdown !== 0) {
      return;
    }
    getCaptcha();
  };

  const handleChangeText = value => {
    setState({
      validateCode: String(value).substring(0, 6),
    });
  };

  useEffect(() => {
    if (_.isString(state?.validateCode)) {
      if (String(state.validateCode).length === 6) {
        submit();
      }
    }
  }, [state?.validateCode, submit]);

  return (
    <Animated.View style={[styles.step1, style]}>
      <Flex>
        {_.times(6, i => {
          return (
            <Center key={`$input_box__${i}`} style={styles.inputBox}>
              <Text style={styles.inputText}>{_.isString(state.validateCode) ? state.validateCode.charAt(i) : ''}</Text>
            </Center>
          );
        })}
        <TextInput
          value={state?.validateCode}
          style={[StyleSheet.absoluteFill, styles.hiddenInput]}
          selectionColor={'transparent'}
          keyboardType="numeric"
          onChangeText={handleChangeText}
        />
      </Flex>
      <TouchableOpacity
        disabled={loading || countdown !== 0}
        onPress={() => handleGetCaptcha()}
        style={styles.getValidCode}>
        <Text style={styles.linkBtn}>
          {countdown === 0 ? '重新获取验证码' : `验证码已发送 ${Math.round(countdown / 1000)}s`}
        </Text>
      </TouchableOpacity>
    </Animated.View>
  );
};

const BindPhone = ({navigation, onChangeLoginType, token, onSuccess}) => {
  const [state, setState] = useSetState({
    phone: '',
    validateCode: '',
  });
  const [step, setStep] = useState(0);
  useUnmount(() => {
    setStep(0);
  });

  useEffect(() => {
    navigation.setOptions({
      headerTitle: () => null,
    });
    const unsubscribe = navigation.addListener('beforeRemove', e => {
      isAndroid() && e.preventDefault();
      onChangeLoginType(LoginType.Phone);
      navigation.setOptions({
        headerTitle: () => <Icon type="text_logo" />,
      });
    });
    return unsubscribe;
  }, []);

  const [countdown, setTargetDate] = useCountDown();
  const dispatch = useDispatch();
  const {loading, run: getCaptcha} = useRequest(
    () =>
      dispatch({
        type: 'global/getCaptcha',
        params: {
          intlCode: state?.intlCode,
          phone: state?.phone,
        },
      }),
    {
      manual: true,
      loadingDelay: 300,
      onSuccess(res) {
        if (res?.success) {
          setTargetDate(Date.now() + 60000);
          setStep(1);
        } else {
          Dialog.respError(res);
        }
      },
    },
  );

  const {loading: submitting, run: submit} = useRequest(() => dispatch({type: 'user/login', data: state, token}), {
    manual: true,
    onSuccess(res) {
      isFunction(onSuccess) && onSuccess(res);
      if (res?.success) {
        // Dialog.success(res.msg || '登录成功');
      } else {
        Dialog.respError(res);
      }
    },
  });

  const handleGetCaptcha = useCallback(() => {
    const phone = state?.phone;
    if (!isValidPhone(phone)) {
      return Dialog.info('请输入手机号码');
    }
    getCaptcha();
  }, [state?.phone, getCaptcha]);

  const handleSubmit = useCallback(() => {
    const phone = state?.phone;
    const validateCode = state?.validateCode;
    if (!isValidPhone(phone)) {
      return Dialog.info('请输入手机号码');
    } else if (!(validateCode && validateCode?.length >= 4)) {
      return Dialog.info('请输入验证码');
    }
    submit();
  }, [state?.phone, state?.validateCode, submit]);

  return (
    <LoadingView loading={submitting} layer={true} style={styles.container}>
      <Center direction="column">
        <View style={styles.header}>
          <Icon type="text_logo" width={112} height={35} style={styles.logo} />
          <Flex justify="between">
            <Text style={styles.slogan}>正品行货</Text>
            <Text style={styles.slogan}>边逛边玩</Text>
          </Flex>
        </View>
        <View style={styles.body}>
          <Step0
            visible={step === 0}
            state={state}
            setState={setState}
            getCaptcha={handleGetCaptcha}
            loading={loading}
            countdown={countdown}
            next={() => setStep(1)}
          />
          <Step1
            visible={step === 1}
            state={state}
            setState={setState}
            getCaptcha={handleGetCaptcha}
            loading={loading}
            countdown={countdown}
            submit={handleSubmit}
            submitting={submitting}
            back={() => setStep(0)}
          />
        </View>
      </Center>
    </LoadingView>
  );
};

export default BindPhone;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  header: {
    marginVertical: 79,
  },
  body: {
    position: 'relative',
    width: 300,
    height: 300,
    // overflow: 'hidden',
  },
  logo: {
    width: 112,
    height: 35,
    marginBottom: 3.5,
  },
  slogan: {
    color: '#333',
    // letterSpacing: 1,
    fontSize: 12,
    lineHeight: 17,
  },
  step0: {
    position: 'absolute',
    top: 0,
    width: 300,
  },
  step1: {
    position: 'absolute',
    top: 0,
    width: 300,
  },

  inputBox: {
    width: 42,
    height: 42,
    marginHorizontal: 5,
    borderRadius: 8,
    // borderWidth: StyleSheet.hairlineWidth,
    borderWidth: 1,
    borderColor: theme.border_color_base,
  },
  inputText: {
    fontSize: 24,
    fontWeight: FontWeight.Light,
    lineHeight: 33,
  },

  hiddenInput: {
    opacity: 0,
    fontSize: 0,
    marginLeft: -200,
  },
  getValidCode: {
    marginTop: 25,
    alignItems: 'center',
    justifyContent: 'center',
  },
  linkBtn: {
    fontSize: 12,
  },
});
