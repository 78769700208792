import FontWeight from '@/utils/localData/FontWeight';
import Color from 'color';
import _ from 'lodash';
import {Platform} from 'react-native';

const styles = {
  DINProBold: {
    fontWeight: FontWeight.Bold,
    ...Platform.select({
      android: {
        fontFamily: 'DINPro-Bold',
      },
      default: {
        fontFamily: 'DINPro',
      },
    }),
  },
  DINProMedium: {
    fontWeight: FontWeight.Medium,
    ...Platform.select({
      android: {
        fontFamily: 'DINPro-Medium',
      },
      default: {
        fontFamily: 'DINPro',
      },
    }),
  },

  ShadowButton: color => {
    const webColor = Color(color).alpha(0.5).string();
    return Platform.select({
      android: {
        elevation: 4,
      },
      ios: {
        shadowOpacity: 0.5,
        shadowRadius: 4,
        shadowColor: '#000',
        shadowOffset: {
          width: 0,
          height: 2,
        },
      },
      web: {
        boxShadow: `0 2px 4px 0 ${webColor}`,
      },
    });
  },
};

export function getDINProBoldStyle(fontSize) {
  return _.extend({}, styles.DINProBold, _.isObject(fontSize) && fontSize, _.isNumber(fontSize) && {fontSize});
}

export function getDINProMediumStyle(fontSize) {
  return _.extend({}, styles.DINProMedium, _.isObject(fontSize) && fontSize, _.isNumber(fontSize) && {fontSize});
}

export function getShadowButton(color) {
  return styles.ShadowButton(color);
}
