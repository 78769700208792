'use strict';

Object.defineProperty(exports, '__esModule', {
  value: true,
});
exports.default = void 0;

var _reactNative = require('react-native');
const theme = require('@/theme');

var _default = function _default() {
  return _reactNative.StyleSheet.create({
    modal: {
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'flex-end',
    },
    header: {
      flexGrow: 1,
      height: 48,
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'center',
      borderBottomWidth: _reactNative.StyleSheet.hairlineWidth,
      borderBottomColor: theme.border_color_base,
    },
    headerItem: {
      height: 48,
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
    },
    actionText: {
      color: theme.brand_primary,
      fontSize: 16,
      textAlign: 'center',
    },
    okText: {},
    dismissText: {},
    title: {
      color: '#666',
      fontSize: 16,
      textAlign: 'center',
    },
  });
};

exports.default = _default;
