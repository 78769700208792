import _ from 'lodash';
import {
  buy,
  convert,
  exchange,
  getMyPieces,
  getPiece,
  getPieceCount,
  getPieces,
  getRecommendProds,
  recovery,
} from './service';

export const namespace = 'piece';
const model = {
  namespace,
  state: {
    data: [], // 所有碎片数据
    myPieces: [], // 我的碎片数据
    recommendProds: [], // 推荐的碎片商品
  },
  effects: {
    // /**
    //  * 碎片分页查询
    //  * @actionParam lastId
    //  * */
    // *getPagedPieces({lastId}, {call, put}) {
    //   const res = yield call(getPagedPieces, {lastId});
    //   if (res?.success) {
    //     yield put({type: 'addData', data: res?.data});
    //   }
    //   return res;
    // },

    /**
     * 查询碎片列表
     * */
    *getPieces(__, {call, put}) {
      const res = yield call(getPieces);
      if (res?.success) {
        yield put({type: 'saveState', data: res?.data});
      }
      return res;
    },

    /**
     * 查询碎片详情
     * @actionParam id
     * */
    *getPiece({id}, {call, put}) {
      return yield call(getPiece, id);
    },

    /**
     * 碎片购买
     * @actionParam data: {
     *    id,
     *    count,
     * }
     * */
    *buy({data}, {call}) {
      return yield call(buy, data);
    },

    /**
     * 查询我的碎片
     * */
    *getMyPieces(__, {call, put}) {
      const res = yield call(getMyPieces);
      if (res?.success) {
        yield put({type: 'saveState', myPieces: res?.data});
      }
      return res;
    },

    /**
     * 回收我的碎片
     * @param data: {
     *   id: 碎片id
     *   count
     * }
     * */
    *recovery({data}, {call}) {
      return yield call(recovery, data);
    },

    /**
     * 我的碎片转换成赠送口令
     * @actionParam data: {
     *   id: 碎片id
     *   count
     * }
     * */
    *exchange({data}, {call}) {
      return yield call(exchange, data);
    },

    /**
     * 赠送我的碎片
     * @actionParam token
     * */
    *convert({token}, {call}) {
      return yield call(convert, token);
    },

    /**
     * 查询碎片余额
     * */
    *getPieceCount({pieceId}, {call}) {
      return yield call(getPieceCount, pieceId);
    },

    /**
     * 查询推荐的碎片商品
     * @actionParam pieceId
     * */
    *getRecommendProds({pieceId}, {call}) {
      return yield call(getRecommendProds, pieceId);
    },
  },
  reducers: {
    saveState(state, action) {
      const {type, ...nextState} = action || {};
      return {...state, ...nextState};
    },

    addData(state, action) {
      const {data} = action || {};
      const oldData = state.data;
      return {...state, data: _.concat([], oldData ?? [], data ?? [])};
    },

    clearData(state, action) {
      return {...state, data: []};
    },

    clearMyPieces(state, action) {
      return {...state, myPieces: []};
    },
  },
};

export default model;
