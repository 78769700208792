import request from '@/utils/request';
import _ from 'lodash';

/**
 * 配置值查询接口
 * @param type: lov类型
 * @return []
 * */
export function getValues(type) {
  return request('/server/system/lov/getByType', {
    method: 'GET',
    params: {
      type,
    },
  }).then(res => {
    if (res?.success) {
      _.each(res?.data, it => {
        try {
          if (it?.extra) {
            it.extra = JSON.parse(it?.extra);
          }
        } catch (e) {}
      });
    }
    return res;
  });
}

/**
 * 配置值批量查询查询接口
 * @param types: lov类型按逗号相隔
 * @return {}
 * */
export function getBatchValues(types) {
  return request('/server/system/lov/getByTypes', {
    method: 'GET',
    params: {
      types,
    },
  }).then(res => {
    if (res?.success) {
      _.each(res?.data, it => {
        try {
          if (it?.extra) {
            it.extra = JSON.parse(it?.extra);
          }
        } catch (e) {}
      });
    }
    return res;
  });
}
