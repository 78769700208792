import {Flex} from '@/components/Flex';
import RarityTag from '@/pages/box/components/RarityTag';
import {getPrice} from '@/utils/priceUtils';
import theme from '@/theme';
import FontWeight from '@/utils/localData/FontWeight';
import {useImageResize} from '@/utils/remoteData/ImageResize';
import {getDINProBoldStyle} from '@/utils/styleUtils';
import {isFunction, isString} from 'lodash';
import React, {isValidElement} from 'react';
import {Image, Platform, StyleSheet, Text, View} from 'react-native';

const styles = StyleSheet.create({
  container: {},
  cardContainer: {
    paddingVertical: 16,
    paddingHorizontal: theme.h_spacing_lg,
    backgroundColor: '#fff',
  },
  tag: {
    position: 'absolute',
    left: 0,
    right: 0,
    zIndex: 9,
  },
  thumb: {
    width: 88,
    height: 88,
    borderWidth: StyleSheet.hairlineWidth,
    borderColor: theme.border_color_base,
    borderRadius: 2,
    marginRight: 12,
    // backgroundColor: theme.fill_grey,
  },
  right: {
    flex: 1,
    // minHeight: 88,
  },
  rightBody: {
    width: '100%',
    justifyContent: 'space-between',
  },
  rightFooter: {
    width: '100%',
    justifyContent: 'space-between',
    marginTop: 13,
  },
  title: {
    fontSize: 12,
  },
  brief: {
    fontSize: 12,
    color: theme.color_text_secondary,
    marginTop: 4,
  },
  content: {},
  extra: {
    color: theme.color_text_secondary,
  },
  price: {
    textAlignVertical: 'bottom',
  },
  priceAmount: {
    ...getDINProBoldStyle(16),
    lineHeight: 20,
  },
  priceUnit: {
    fontWeight: FontWeight.SemiBold,
    fontSize: 14,
    lineHeight: 20,
  },
  priceRmb: {
    fontSize: 12,
    lineHeight: 15,
    color: theme.color_text_secondary,
  },
});
const Tag = ({data, value, children}) => {
  if (isFunction(value)) {
    return value(data, {style: styles.tag, styles});
  }
  if (value) {
    return <View style={styles.tag}>{value}</View>;
  }
  return <View style={styles.tag}>{children}</View>;
};
const Thumb = ({data, value, image, children}) => {
  if (isFunction(value)) {
    return value(data, {style: styles.thumb, styles, image});
  }
  if (isString(value)) {
    return <Image source={{uri: value}} style={styles.thumb} />;
  }
  if (isValidElement(value)) {
    return value;
  }

  if (isString(children)) {
    return <Image source={{uri: children}} style={styles.thumb} />;
  }
  return children;
};
const Title = ({data, value, children, numberOfLines = 2}) => {
  if (isFunction(value)) {
    return value(data, {style: styles.title, styles});
  }
  if (value) {
    return (
      <Text numberOfLines={numberOfLines} style={styles.title}>
        {value}
      </Text>
    );
  }

  return (
    <Text numberOfLines={numberOfLines} style={styles.title}>
      {children}
    </Text>
  );
};
const Brief = ({data, value, children}) => {
  if (isFunction(value)) {
    return value(data, {style: styles.brief, styles});
  }
  if (value) {
    return <Text style={styles.brief}>{value}</Text>;
  }

  return <Text style={styles.brief}>{children}</Text>;
};
const Content = ({data, value, children}) => {
  if (isFunction(value)) {
    return value(data, {style: styles.content, styles, defaultDom: children});
  }
  if (isString(value)) {
    return <Text style={styles.content}>{value}</Text>;
  }
  if (isValidElement(value)) {
    return value;
  }
  if (isString(children)) {
    return <Text style={styles.content}>{children}</Text>;
  }
  return children;
};
const Extra = ({data, value, children}) => {
  if (isFunction(value)) {
    return value(data, {style: styles.extra, styles});
  }
  if (value) {
    return <Text style={styles.extra}>{value}</Text>;
  }

  return <Text style={styles.extra}>{children}</Text>;
};
const ProdMeta = ({data, card = true, style, tag, thumb, title, brief, content, extra}) => {
  const [price, rmbPrice] = getPrice(data);
  const [, resizeImage] = useImageResize({object: 'prod', size: 'xs'});

  return (
    <Flex align="stretch" style={[styles.container, card && styles.cardContainer, style]}>
      <Tag data={data} value={tag}>
        {data?.style && <RarityTag rarity={data?.style} />}
      </Tag>
      <Thumb data={data} value={thumb} image={resizeImage(data?.picture)}>
        <Image source={{uri: resizeImage(data?.picture)}} style={styles.thumb} />
      </Thumb>
      <Flex direction="column" justify="between" align="start" style={styles.right}>
        <View style={styles.rightBody}>
          <Title data={data} value={title}>
            {data?.name}
          </Title>
          <Brief data={data} value={brief} />
        </View>
        <Flex justify="between" align="end" style={styles.rightFooter}>
          <Content data={data} value={content}>
            <Flex align="end" style={styles.price}>
              <Text style={styles.priceAmount}>{price?.amount}</Text>
              <View style={{width: 6}} />
              <Text style={styles.priceUnit}>{price?.unit}</Text>
              <View style={{width: 6}} />
              <Text style={styles.priceRmb}>{rmbPrice.text()}</Text>
            </Flex>
          </Content>
          <Extra data={data} value={extra} />
        </Flex>
      </Flex>
    </Flex>
  );
};
ProdMeta.Thumb = Thumb;
ProdMeta.Title = Title;
ProdMeta.Brief = Brief;
ProdMeta.Content = Content;
ProdMeta.Extra = Extra;
export default ProdMeta;
